import React, { useContext } from 'react';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import moment from 'moment';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../intl/idioms';
import { getCountryByInitials } from '../../../../intl/utils';
import { formatCustomDate } from '../../../../utils/date';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const HotelBurglaryDetail = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';

  const formatDateAndHour = date =>
    moment(date).format(
      isPTBR || isMX ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : '-';

  const fromDate =
    travelInformation && travelInformation.from_date
      ? formatCustomDate(travelInformation.from_date, null, idiom, country)
      : '-';

  const toDate =
    travelInformation && travelInformation.to_date
      ? formatCustomDate(travelInformation.to_date, null, idiom, country)
      : '-';

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : '';

  const discoveredAt =
    incident && incident.discovered_at
      ? formatDateAndHour(incident.discovered_at)
      : '-';

  const incidentCity =
    incident && incident.location.locality ? incident.location.locality : '';

  const incidentCountry =
    incident && incident.location.country
      ? getCountryByInitials(incident.location.country, idiom)
      : '';

  const formalComplaint =
    claimDetails && claimDetails.formal_complaint
      ? claimDetails.formal_complaint
      : {};

  const isSubmitted =
    formalComplaint && formalComplaint.is_submitted
      ? formalComplaint.is_submitted
      : '' || (formalComplaint && formalComplaint.is_submitted === false)
      ? formalComplaint.is_submitted
      : '';

  const submissionReason =
    formalComplaint && formalComplaint.submission_reason
      ? formalComplaint.submission_reason
      : '';

  const totalAmountValue =
    claimDetails && claimDetails.total_amount_claimed.value
      ? claimDetails.total_amount_claimed.value
      : '';

  const amountCurrency =
    claimDetails && claimDetails.total_amount_claimed.currency
      ? claimDetails.total_amount_claimed.currency
      : '';

  const totalAmountClaimed = `${totalAmountValue} - ${amountCurrency}`;

  const getYesOrNo = (title, intl) => {
    switch (title) {
      case true:
        return intl.TEXT_YES;
      case false:
        return intl.TEXT_NO;
    }
  };

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <InfoLabelValue
          label={intl.LABEL_TRIP_PERIOD}
          value={`${fromDate} - ${toDate}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_DATE_THEFT} value={discoveredAt} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_PLACE_THEFT}
          value={`${incidentCountry} - ${incidentCity}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_SUBMITTED_COMPLAINT}
          value={getYesOrNo(isSubmitted, intl)}
        />
      </div>
      {isSubmitted === true && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue label={intl.LABEL_PIR} value={submissionReason} />
        </div>
      )}
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_AMOUNT_CLAIMED}
          value={totalAmountClaimed}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} - ${reimbursedCurrency}`}
          />
        </div>
      )}
    </div>
  );
};

export default HotelBurglaryDetail;
