import React, { useContext } from 'react';
import styles from './NavigateBenefits.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import ArrowRightIcon from '../../../assets/icons/ArrowRight';
import ArrowLeftIcon from '../../../assets/icons/ArrowLeftIcon';

const NavigateBenefits = props => {
  const { previousLink = '/', nextLink = '/', borderBottom } = props;
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();

  return (
    <div
      className={`${styles.navigationContainer} ${styles[theme]} ${
        borderBottom ? styles.borderBottom : ''
      }`}
    >
      <div className={styles.navigateItem}>
        <Link className={styles[theme]} to={previousLink}>
          <span className={styles.iconLeft}>
            <ArrowLeftIcon
              width={20}
              height={20}
              aria-label={intl.RIGHT_ARROW}
            />
          </span>

          {intl.LINK_PREVIOUS}
        </Link>
      </div>
      <div className={styles.navigateItem}>
        <Link className={styles[theme]} to={nextLink}>
          {intl.LINK_NEXT}
          <span className={styles.iconRight}>
            <ArrowRightIcon
              width={20}
              height={20}
              aria-label={intl.RIGHT_ARROW}
            />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default NavigateBenefits;
