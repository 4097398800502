import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import Header from './Header/Header';
import styles from './ActiveCertificates.module.scss';
import { certificatesServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import ListCertificates from '../../../components/ListCertificates/ListCertificate';
import DividingLine from '../../../components/DividingLine/DividingLine';
import Loading from '../../../components/Loading/Loading';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import AdvancedSearchCertificate from './AdvancedSearchCertificates/AdvancedSearchCertificates';
import TitleSectionProfile from '../../../components/TitleSectionProfile/TitleSectionProfile';
import SearchIcon from '../../../assets/icons/SearchIcon';
import ChevronGeneric from '../../../assets/icons/ChevronGeneric';
import { isIssuerDomain } from '../../../utils/banks';

const ActiveCertificates = props => {
  const { advancedSearch, userCertificates, setUserCertificates } = props;
  const { utils } = useContext(StateContext);
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const [orderAsc, setOrderAsc] = useState('recent');
  const [orderAscPast, setOrderAscPast] = useState('recent');
  const [certificatesActive, setCertificatesActive] = useState([]);
  const [certificatesPast, setCertificatesPast] = useState([]);
  const [seeMore, setSeeMore] = useState('');
  const [certificatesPastSliced, setCertificatesPastSliced] = useState([]);
  const [advancedSearchIsVisible, setAdvancedSearchIsVisible] =
    useState(advancedSearch);
  const [numberActive, setNumberActive] = useState(5);
  const [numberPast, setNumberPast] = useState(4);
  const [showListCertificate, setShowListCertificate] = useState(
    !advancedSearch
  );
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const [loading, setLoading] = useState(true);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  // Advanced Search is disabled in BMP
  const enableAdvancedSearch = !isIssuerDomain();

  const getGenerated = async binPanSelected => {
    setLoading(true);

    const userBin = binPanSelected ? binPanSelected : binOrPan;
    let certificateData = userCertificates;
    if (setUserCertificates) {
      const { data } = await certificatesServices.getCertificatesByBin(
        cn,
        userBin,
        idiomForApi()
      );
      setUserCertificates(data);
      certificateData = data;
    }

    //ACTIVE
    let certificatesActiveTemp = certificateData.filter(item => {
      if (
        moment(item.valid_until).isSameOrAfter(moment().format('YYYY-MM-DD'))
      ) {
        return item;
      } else {
        return false;
      }
    });

    handleOrderMostRecent(certificatesActiveTemp);
    setCertificatesActive(certificatesActiveTemp);

    //PAST
    let certificatesPastTemp = certificateData.filter(item => {
      if (moment(item.valid_until).isBefore(moment().format('YYYY-MM-DD'))) {
        return item;
      } else if (item.valid_until === null) {
        return item;
      } else {
        return false;
      }
    });
    handleOrderMostRecent(certificatesPastTemp);
    setCertificatesPast(certificatesPastTemp);
    setCertificatesPastSliced(certificatesPastTemp);

    setLoading(false);
  };

  useEffect(() => {
    getGenerated();
    setSeeMore(intl.TEXT_SEE_MORE);
  }, [binOrPan]);

  useEffect(() => {
    if (!advancedSearch) {
      setAdvancedSearchIsVisible(false);
      setShowListCertificate(true);
      getGenerated();
    }
  }, [advancedSearch, binOrPan]);

  function handleOrderCertificates(orderType) {
    let certificatesOrdered = [];

    if (orderType === 'recent' && certificatesActive.length > 0) {
      certificatesOrdered = certificatesActive;

      if (orderAsc === 'recent') {
        handleOrderLessRecent(certificatesOrdered);
      } else {
        handleOrderMostRecent(certificatesOrdered);
      }
      setCertificatesActive(certificatesOrdered);

      if (orderAsc === 'recent') {
        setOrderAsc('less');
      } else {
        setOrderAsc('recent');
      }
    }

    if (orderType === 'past' && certificatesPast.length > 0) {
      certificatesOrdered = certificatesPast;

      if (orderAscPast === 'recent') {
        handleOrderLessRecent(certificatesOrdered);
      } else {
        handleOrderMostRecent(certificatesOrdered);
      }
      setCertificatesPast(certificatesOrdered);

      if (orderAscPast === 'recent') {
        setOrderAscPast('less');
      } else {
        setOrderAscPast('recent');
      }
    }
  }

  function handleOrderMostRecent(data) {
    data.sort(function (a, b) {
      return new Date(a.valid_until) - new Date(b.valid_until);
    });
  }

  function handleOrderLessRecent(data) {
    data.sort(function (a, b) {
      return new Date(b.valid_until) - new Date(a.valid_until);
    });
  }

  return (
    <div className={styles.container}>
      {/* commented for refund rules review */}
      {enableAdvancedSearch && (
        <div className={styles.advancedSearch}>
          {!advancedSearchIsVisible ? (
            <div className={styles.buttonAdvancedSearch}>
              <div
                className={styles.headerActions}
                onClick={() => {
                  props.history.push('/profile/certificates/search');
                  setAdvancedSearchIsVisible(!advancedSearchIsVisible);
                  setCertificatesActive([]);
                  setCertificatesPastSliced([]);
                  setShowListCertificate(false);
                }}
              >
                <span className={styles[themes]}>
                  {intl.TEXT_BUTTON_ADVANCED_SEARCH}
                </span>
                <SearchIcon width={15} height={15} />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.backToCertificates}>
                <div
                  className={styles.headerActions}
                  onClick={() => {
                    props.history.push('/profile/certificates#active');
                    setAdvancedSearchIsVisible(!advancedSearchIsVisible);
                    setShowListCertificate(true);
                    getGenerated();
                  }}
                >
                  <span className={styles.iconActions}>
                    <ChevronGeneric rotate={90} width={20} height={20} alt="" />
                  </span>

                  <span className={styles[themes]}>
                    {intl.TEXT_YOUR_CERTIFICATES}
                  </span>
                </div>
              </div>
              <TitleSectionProfile
                title={intl.TEXT_PAGE_TITLE_ADVANCED_SEARCH}
                subtitle={intl.TEXT_PAGE_SUBTITLE_ADVANCED_SEARCH}
                showNumber={false}
              />
            </>
          )}
        </div>
      )}
      {advancedSearchIsVisible && (
        <>
          <AdvancedSearchCertificate
            searchCertificate={getGenerated}
            setShowListCertificate={setShowListCertificate}
          />
          <DividingLine />
        </>
      )}
      {showListCertificate && (
        <>
          <Header
            id="active"
            title={intl.TEXT_TITLE_ACTIVE}
            qtdCertificates={certificatesActive.length}
            subtitle={intl.TEXT_SUBTITLE}
            handleOrderCertificates={() => handleOrderCertificates('recent')}
            orderAsc={orderAsc}
            orderType="recent"
            loading={loading}
            messageEmpty={intl.TEXT_NO_HAVE_ACTIVE_CERTIFICATE}
          />
          {loading ? (
            <Loading />
          ) : (
            <>
              <ListCertificates
                certificates={certificatesActive}
                numberToFilter={numberActive}
                profile
                showButtonClaim={!advancedSearch}
              />
              {certificatesActive &&
                certificatesActive.length > 0 &&
                certificatesActive.length > numberActive && (
                  <div className={styles.showMore}>
                    <span
                      className={styles[themes]}
                      onClick={() => setNumberActive(numberActive + 5)}
                    >
                      {seeMore}
                    </span>
                  </div>
                )}
            </>
          )}

          <DividingLine />

          <Header
            id="past"
            title={intl.TEXT_TITLE_PAST}
            qtdCertificates={certificatesPast.length}
            handleOrderCertificates={() => handleOrderCertificates('past')}
            orderAscPast={orderAscPast}
            orderType="past"
            loading={loading}
            messageEmpty={intl.TEXT_NO_HAVE_EXPIRED_CERTIFICATE}
          />
          {loading ? (
            <Loading />
          ) : (
            <>
              <ListCertificates
                certificates={certificatesPastSliced}
                showButtonClaim={false}
                numberToFilter={numberPast}
                showVerticalDivider={false}
                profile
              />

              {certificatesPastSliced &&
                certificatesPastSliced.length > 0 &&
                certificatesPastSliced.length > numberPast && (
                  <div className={styles.showMore}>
                    <span
                      className={styles[themes]}
                      onClick={() => setNumberPast(numberPast + 4)}
                    >
                      {seeMore}
                    </span>
                  </div>
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(ActiveCertificates);
