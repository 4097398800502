import { en_nationalities } from '../../intl/en_nationalities';
import { pt_nationalities } from '../../intl/pt_nationalities';
import { es_nationalities } from '../../intl/es_nationalities';
import { en_countries } from '../../intl/en_countries';
import { pt_countries } from '../../intl/pt_countries';
import { es_countries } from '../../intl/es_countries';

export const TITLE_PAGE = {
  en_US: 'Complete your registration',
  pt_BR: 'Complete seu cadastro',
  es_ES: 'Completa tu registro',
};

export const TEXT_TITLE_REGISTER_STEP_1 = {
  en_US: 'First we need your information',
  pt_BR: 'Primeiramente precisamos dos seus dados pessoais',
  es_ES: 'Primero necesitamos tu información personal',
};

export const TEXT_TITLE_REGISTER_STEP_2 = {
  en_US: 'Now we need to validate your Visa card',
  pt_BR: 'Agora precisamos validar o seu cartão Visa',
  es_ES: 'Ahora necesitamos validar tu tarjeta Visa',
};

export const TEXT_DISCLAIMER_REGISTER_STEP_3 = {
  en_US: 'Visa will not make any charges to your card.',
  pt_BR: 'A Visa não realizará nenhum débito em seu cartão.',
  es_ES: 'Visa no realizará ningún cargo a su tarjeta.',
};
export const TEXT_SUBTITLE_REGISTER_STEP_3 = {
  en_US: 'First we need your information',
  pt_BR: 'Primeiramente precisamos dos seus dados pessoais',
  es_ES: 'Primero necesitamos tu información personal',
};

export const PERSONAL_INFORMATION = {
  en_US: 'Complete your personal information',
  pt_BR: 'Complete os seus dados pessoais',
  es_ES: 'Completa tus datos personales',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_NUMBER_PASSPORT = {
  en_US: 'Passport Number',
  pt_BR: 'Número de Passaporte',
  es_ES: 'Número de Pasaporte',
};

export const LABEL_IDENTIFICATION = {
  en_US: 'Document type',
  pt_BR: 'Tipo de documento',
  es_ES: 'Tipo de documento',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

export const LABEL_NUMBER_NATIONAL_ID = {
  en_US: 'ID Number',
  pt_BR: 'Número da Identidade',
  es_ES: 'Número del Documento Nacional de Identidad',
};

export const LABEL_CPF_BR = {
  en_US: 'CPF number *',
  pt_BR: 'Número do CPF *',
  es_ES: 'Número de CPF *',
};

export const TEXT_TITLE_REGISTER = {
  en_US:
    'Register today and instant access all the benefits of your Visa cards',
  pt_BR:
    'Registre-se hoje e acesse instantaneamente todos os benefícios dos seus cartões Visa',
  es_ES:
    'Regístrate hoy y acceda instantáneamente a todos los beneficios de tus tarjetas Visa',
};

export const CARD_INFORMATION = {
  en_US: 'Card validation',
  es_ES: 'Validación de tarjeta',
  pt_BR: 'Validação de cartão',
};

export const SECURITY_INFORMATION = {
  en_US: 'Security',
  pt_BR: 'Segurança',
  es_ES: 'Seguridad',
};

/* FORM */
export const LABEL_TITLE = {
  en_US: 'Title *',
  pt_BR: 'Título *',
  es_ES: 'Título *',
};

export const LABEL_FIRSTNAME = {
  en_US: 'First name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};
export const LABEL_LASTNAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};
export const LABEL_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo electrónico',
};
export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};
export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};
export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};
export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};
export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

export const LABEL_EXPIRATION_DATE = {
  en_US: 'Expiration date (MM/YY) *',
  es_ES: 'Fecha de caducidad (MM/AA) *',
  pt_BR: 'Data de validade (MM/AA) *',
};

export const LABEL_QUESTION = {
  en_US: 'Select a question',
  pt_BR: 'Selecione a pergunta',
  es_ES: 'Selecciona una pregunta',
};
export const LABEL_ANSWER = {
  en_US: 'Answer',
  pt_BR: 'Resposta',
  es_ES: 'Respuesta',
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};

export const LABEL_REGISTRATIONS_TYPE = {
  en_US: 'Passport *',
  pt_BR: 'CPF *',
  es_ES: 'Pasaporte *',
};

export const LABEL_PASSPORT_BR = {
  en_US: 'Passport *',
  pt_BR: 'Passaporte *',
  es_ES: 'Pasaporte *',
};

export const NATIONALITY_OPTIONS = {
  en_US: en_nationalities,
  pt_BR: pt_nationalities,
  es_ES: es_nationalities,
};

export const CONTACT_PHONE_NUMBER = {
  en_US: 'Contact phone number',
  pt_BR: 'Telefone para contato',
  es_ES: 'Teléfono de contacto',
};

export const BTN_CREATE_ACCOUNT = {
  en_US: 'Accept',
  pt_BR: 'Aceitar',
  es_ES: 'Aceptar',
};

export const TITLE_NOTIFICATION = {
  en_US: 'Communication preferences',
  es_ES: 'Preferencias de comunicación',
  pt_BR: 'Preferências de Comunicação',
};

export const TEXT_WHATSAPP_CUSTOMER_NOTIFICATION_TITLE = {
  en_US: 'CUSTOMER ONGOING PROCESSES NOTIFICATIONS - WHATSAPP',
  es_ES: 'NOTIFICACIONES DE PROCESOS DE CLIENTE EN CURSO - WHATSAPP',
  pt_BR: 'NOTIFICAÇÕES DE PROCESSOS EM ANDAMENTO DO CLIENTE - WHATSAPP',
};

export const TEXT_WHATSAPP_CUSTOMER_NOTIFICATION_DESCRIPTION = {
  en_US:
    'Once you have Whatsapp valid number registered in Visa Benefits Portal, you will receive push notifications related with your certificates creation and claim status change during its whole lifecycle.',
  es_ES:
    'Una vez que tenga un número válido de Whatsapp registrado en el Portal de Beneficios de Visa, recibirás notificaciones automáticas relacionadas con la creación de tus certificados y el cambio de estado de tu reclamo a lo largo de tu ciclo de vida.',
  pt_BR:
    'Uma vez que você tenha um número válido do Whatsapp registrado no Portal de Benefícios Visa, você receberá notificações push relacionadas à criação de seus bilhetes e a mudança de status do seu sinistro durante todo o seu ciclo de vida.',
};

/* VALIDATION */
export const INVALID_EMAIL = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un e-mail válido.',
};
export const EMPTY_EMAIL = {
  en_US: 'Please enter an e-mail.',
  pt_BR: 'Por favor, insira um e-mail.',
  es_ES: 'Por favor, introduzca un e-mail.',
};
export const EMPTY_NAME = {
  en_US: 'Please enter a first name.',
  pt_BR: 'Por favor, insira um nome.',
  es_ES: 'Por favor, introduzca un nombre.',
};
export const EMPTY_LASTNAME = {
  en_US: 'Please enter a last name.',
  pt_BR: 'Por favor, insira um sobrenome.',
  es_ES: 'Por favor, introduzca un apellido.',
};
export const EMPTY_CARD_NUMBER = {
  en_US: 'Please enter a credit card.',
  pt_BR: 'Por favor, insira um cartão de crédito.',
  es_ES: 'Por favor, introduzca una tarjeta de crédito.',
};

export const INVALID_CARD_NUMBER = {
  en_US: 'Invalid format, please check and try again.',
  pt_BR: 'Formato inválido, por favor verifique.',
  es_ES: 'El formato no es válido, por favor verifica.',
};

export const EMPTY_LABEL = {
  en_US: 'This field is required.',
  pt_BR: 'Este campo é obrigatório.',
  es_ES: 'Este campo es requerido.',
};

export const VALIDATE_CPF = {
  en_US: 'Please enter a valid CPF.',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: 'Introduzca un CPF válido.',
};

export const INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const NOT_VISA_PRODUCT = {
  en_US:
    'The informed card does not correspond to a Visa product. Please check and try again.',
  pt_BR:
    'Os dígitos informados não correspondem a um cartão Visa. Por favor verifique e tente novamente.',
  es_ES:
    'Los 16 digitos ingresados no corresponden a una tarjea Visa. Por favor verifique e intenta nuevamente.',
};

/* TEXT */
export const TITLE_ACCEPT_TERMS = {
  en_US: 'Accepting terms and conditions',
  pt_BR: 'Aceitação dos termos e condições',
  es_ES: 'Aceptación de términos y condiciones',
};
export const PDF_TERMS_OF_SERVICE = {
  en_US: 'Terms of service',
  pt_BR: 'Termos de serviço',
  es_ES: 'Términos de servicio',
};
export const PDF_SUPLEMENTARY_PRIVACE_NOTES = {
  en_US: 'Visa Complimentary Privacy Policy',
  pt_BR: 'Aviso Complementar de Privacidade da Visa',
  es_ES: 'Aviso Complementario de Privacidad de Visa',
};
export const TEXT_VISA_GLOBAL_PRIVACY = {
  en_US: 'Visa Global Privacy Notice',
  pt_BR: 'Aviso de Privacidade Global Visa',
  es_ES: 'Aviso de Privacidad Global de Visa',
};

export const TEXT_ACCEPT_TERMS = {
  en_US: 'I read and accept the Terms of service.',
  pt_BR: 'Eu li e aceito os Termos de serviço.',
  es_ES: 'Leí y acepto los Términos de servicio.',
};
export const TEXT_ACCEPT_TERMS_2 = {
  en_US: 'I read and accept the Visa Global Privacy Notice.',
  pt_BR: 'Eu li e aceito o Aviso de Privacidade Global Visa.',
  es_ES: 'Leí y acepto el Privacidad Global de Visa',
};
export const TEXT_ACCEPT_TERMS_3 = {
  en_US: 'I read and accept the Visa Complimentary Privacy Policy.',
  pt_BR: 'Eu li e aceito o Aviso Complementar de Privacidade da Visa.',
  es_ES: 'Leí y acepto el Aviso Complementario de Privacidad de Visa.',
};

export const TEXT_CARD_NOT_ELIGIBLE = {
  en_US: 'This credit card is not eligible to use the services of the Visa.',
  pt_BR:
    'Este cartão de crédito não é elegível para utilização dos serviços do Visa.',
  es_ES: 'Esta tarjeta no es elegible para los servicios de Visa.',
};

export const PAN_ERROR_OCCURRED = {
  en_US:
    'An error occurred while validating your card number. Please try again.',
  pt_BR:
    'Ocorreu um erro ao validar seu número de cartão. Por favor tente novamente.',
  es_ES:
    'Se ha producido un error al validar tu número de tarjeta. Por favor, inténtalo de nuevo.',
};

export const ALREADY_USED_ERROR_OCCURRED = {
  en_US:
    'Please make sure you informed the 16-digit number correctly (this number is unique).',
  pt_BR:
    'Por favor, certifique-se de que informou o número de 16 dígitos corretamente (essa numeração é única).',
  es_ES:
    'Por favor, asegúrate de haber informado correctamente el número de 16 dígitos (este número es único).',
};

export const RETRY_ADD_PAN = {
  en_US:
    'Please make sure you informed the 16-digit number correctly (this number is unique).',
  pt_BR:
    'Por favor, certifique-se de que informou o número de 16 dígitos corretamente (essa numeração é única).',
  es_ES:
    'Por favor, asegúrate de haber informado correctamente el número de 16 dígitos (este número es único).',
};

export const TEXT_ERROR = {
  en_US: 'The operation could not be completed.',
  pt_BR: 'Não foi possível concluir a operação.',
  es_ES: 'No se pudo completar la operación.',
};

export const TEXT_ERROR_BIN = {
  en_US: 'Your banking information has not been saved yet.',
  pt_BR: 'Suas informações bancárias não foram salvas ainda.',
  es_ES: 'Tu información bancaria aún no ha sido guardada.',
};

export const TEXT_ERROR_CUSTOMER = {
  en_US: 'Your personal information has not been saved yet.',
  pt_BR: 'Suas informações pessoais não foram salvas ainda.',
  es_ES: 'Tu información personal aún no ha sido guardada.',
};

export const TRY_AGAIN = {
  en_US: 'Try again',
  pt_BR: 'Tente novamente',
  es_ES: 'Intentar de nuevo',
};

export const CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const ERROR_ALREADY_EXISTS = {
  en_US: 'Your personal information has been successfully saved.',
  pt_BR: 'Suas informações pessoais foram salvas com sucesso.',
  es_ES: 'Tu información personal ha sido guardada con éxito.',
};

export const TEXT_LINK_PDF = {
  en_US:
    'https://prod-axa.cdn.prismic.io/prod-axa/25db35fe-1c9d-4118-ac51-9fd99d19c38a_English.pdf',
  pt_BR:
    'https://prod-axa.cdn.prismic.io/prod-axa/33364c92-5652-4602-8d53-9f02e25e962d_Portuguese.pdf',
  es_ES:
    'https://prod-axa.cdn.prismic.io/prod-axa/6412ed8d-af9c-477a-8a34-626bf5af8238_Spanish.pdf',
};

export const TEXT_LINK_PRIVACY_POLICY = {
  en_US: 'https://www.visa.com/privacy',
  pt_BR: 'https://www.visa.com/privacy',
  es_ES: 'https://www.visa.com/privacy',
};

export const BTN_NEXT = {
  en_US: 'Next step',
  pt_BR: 'Próximo passo',
  es_ES: 'Próximo paso',
};

export const LABEL_TERMS_SUPPLEMENTARY = {
  en_US: 'Visa Complimentary Privacy Policy',
  pt_BR: 'Aviso Complementar de Privacidade da Visa',
  es_ES: 'Aviso Complementario de Privacidad de Visa',
};

export const LABEL_AND = {
  en_US: 'and',
  pt_BR: 'e o',
  es_ES: 'y lo',
};

export const TEXT_LINK_PDF_SUPPLEMENTARY = {
  en_US:
    'https://prod-axa.cdn.prismic.io/prod-axa/74a3086a-d5b9-4a0f-b752-f1bfd09f6f65_Aviso+de+Privacidade+-+EN.pdf',
  pt_BR:
    'https://prod-axa.cdn.prismic.io/prod-axa/8a2d0385-e886-4ce9-bb83-3e0846eb31e5_Aviso+de+Privacidade+-+PT.pdf',
  es_ES:
    'https://prod-axa.cdn.prismic.io/prod-axa/1c12b210-fc7b-4562-a241-726ce6b07d50_Aviso+de+Privacidade+-+ES.pdf',
};

export const TEXT_IF_NOT_ACCEPT_TERMS = {
  en_US:
    'If you do not agree to the Terms and Conditions and the privacy notice, Visa will not be able to process your account activity and therefore the services will not be available to you.',
  pt_BR:
    'Se não aceitar os Termos e Condições e o aviso de privacidade, a Visa não conseguirá processar a atividade da sua conta e, portanto, os serviços não estarão disponíveis para você.',
  es_ES:
    'Si no aceptas los Términos y Condiciones y el aviso de privacidad, Visa no podrá procesar la actividad de tu cuenta y, por lo tanto, los servicios no estarán disponibles para ti.',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Regresar',
};

export const COMPLETING_YOUR_REGISTRATION = {
  en_US: 'Saving your data...',
  pt_BR: 'Salvando seus dados...',
  es_ES: 'Guardando tus datos ...',
};

export const CONGRATULATIONS = {
  en_US: 'Congratulations! Your registration is now complete.',
  pt_BR: 'Parabéns! Seu cadastro agora está completo.',
  es_ES: '¡Felicidades! Tu registro ya está completo.',
};

export const BTN_ENJOY = {
  en_US: 'Enjoy my benefits',
  pt_BR: 'Desfrutar meus benefícios',
  es_ES: 'Disfrutar de mis beneficios',
};

export const TEXT_ALERT_PASSPORT = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com o passport de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};

export const TEXT_ALERT_NATIONAL_ID = {
  en_US:
    'For minors, please complete with the national ID of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com a identidade nacional de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el Documento Nacional de Identidad de uno de los padres/tutores.',
};

export const TEXT_ALERT_CPF = {
  en_US: 'For minors, please complete with the CPF of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el CPF de uno de los padres/tutores.',
};

export const ERROR_CUSTOMER_DUPLICATED = {
  en_US: 'The CPF is already used by another cardholder.',
  pt_BR: 'O CPF informado já é utilizado por outro titular.',
  es_ES: 'El CPF introducido ya lo utiliza otro titular.',
};

export const TEXT_PUSH_NOTIFICATION_TITLE = {
  en_US: 'CUSTOMER ONGOING PROCESSES NOTIFICATIONS',
  es_ES: 'NOTIFICACIONES DE PROCESOS DE CLIENTE EN CURSO',
  pt_BR: 'NOTIFICAÇÕES DE PROCESSOS EM ANDAMENTO DO CLIENTE',
};

export const TEXT_PUSH_NOTIFICATION_DESCRIPTION = {
  en_US: 'I agree to receive push notifications for my processes in progress.',
  es_ES: 'Acepto recibir notificaciones push de mis procesos en curso.',
  pt_BR: 'Aceito receber notificações push dos meus processo em andamento.',
};

export const TEXT_MARKETING_NOTIFICATION_TITLE = {
  en_US: 'PRODUCT AND MARKETING',
  es_ES: 'MARKETING Y PRODUCTO',
  pt_BR: 'MARKETING E PRODUTO',
};

export const TEXT_MARKETING_NOTIFICATION_DESCRIPTION = {
  en_US:
    'I agree to receive emails with news about Visa products, promotions and benefits. (You can unsubscribe from these updates at any time from your account settings).',
  es_ES:
    'Acepto recibir correos electrónicos con novedades sobre productos, promociones y beneficios de Visa. (Tu puedes dar de baja de estas novedades en cualquier momento desde la configuración de tu cuenta).',
  pt_BR:
    'Aceito receber e-mails com novidades sobre produtos, promoções e benefícios Visa. (Você pode cancelar a assinatura dessas atualizações a qualquer momento nas configurações da sua conta).',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth *',
  pt_BR: 'Data de nascimento *',
  es_ES: 'Fecha de nacimiento *',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const FORM_LABEL_COUNTRY = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País *',
};
