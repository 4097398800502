import React from 'react';
import styles from './Bluebox.module.scss';

interface BlueboxTextProps {
  text: string;
  margin?: boolean;
  center?: boolean;
}

const BlueboxText = ({ text, margin = true, center }: BlueboxTextProps) => {
  return (
    <p
      className={`${styles.description} ${margin ? '' : styles.withoutMargin} ${
        center ? styles.center : ''
      }`}
    >
      {text}
    </p>
  );
};

export default BlueboxText;
