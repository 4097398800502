import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';
import { getThemeIcon } from '../../utils/configIcon';

const ChevronUpIcon = props => {
  const { rotate, axa } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme, axa);
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="m1 11 5.586-5.586a2 2 0 0 1 2.828 0L15 11"
        stroke={config_icon.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronUpIcon;
