export const ADD = {
  en_US: 'Add',
  pt_BR: 'Adicionar',
  es_ES: 'Agregar',
};

export const REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const REMOVING = {
  en_US: 'Removing',
  pt_BR: 'Removendo',
  es_ES: 'Quitando',
};

export const PENDING = {
  en_US: 'Pending file',
  pt_BR: 'Arquivo pendente',
  es_ES: 'Archivo pendiente',
};

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const TEXT_POSTED_ON = {
  en_US: 'Posted on:',
  pt_BR: 'Enviado em:',
  es_ES: 'Publicado el:',
};

export const TEXT_VALID_UNTIL = {
  en_US: 'Valid until:',
  pt_BR: 'Válido até:',
  es_ES: 'Válido hasta:',
};

export const DESCRIPTION_NATIONAL_ID = {
  en_US:
    'Copy of the national ID (front and back). The document must be complete, it should not have cut-outs from the edges.',
  pt_BR:
    'Cópia de um documento nacional de identificação completo, com foto e que contenha CPF (FRENTE e VERSO).',
  es_ES:
    'Copia de tu documento nacional de identificación completo (ANVERSO y REVERSO). El documento debe verse completo, no deberá presentar recortes de los bordes.',
};

export const DESCRIPTION_PROOF_OF_RESIDENCY = {
  en_US:
    'Proof of Address (water, electricity, telephone bills, credit card statement that informs the complete address, etc.).',
  pt_BR:
    'Comprovante de residência que contenha CEP e que esteja em nome do beneficiário, (contas água, luz, telefone, fatura de cartão de crédito que informe o endereço completo, etc.).',
  es_ES:
    'Comprobante de residencia (facturas de agua, electricidad, teléfono, estado de cuenta de la tarjeta de crédito que informe la dirección completa, etc.).',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US: 'An error occurred while uploading this file.',
  pt_BR: 'Ocorreu um erro ao enviar este arquivo.',
  es_ES: 'Se ha producido un error al subir este archivo.',
};

export const FILE_SIZE_ERROR = {
  es_ES: 'Todos los archivos deben tener menos de 1.5 MB.',
  en_US: 'All files must be below 1.5mb.',
  pt_BR: 'Todos os arquivos devem ser abaixo de 1.5mb.',
};
export const FILE_TYPE_ERROR = {
  en_US: 'Invalid file format.',
  pt_BR: 'Formato do arquivo é inválido.',
  es_ES: 'Formato de archivo inválido.',
};

export const TEXT_NO_KEEP_DOCUMENT = {
  en_US: 'No, keep the document',
  pt_BR: 'Não, manter o documento',
  es_ES: 'No, mantener documento',
};

export const DELETE_DOCUMENT_TITLE = {
  en_US: 'Are you sure you want to remove the selected document?',
  pt_BR: 'Você está certo de que deseja remover o documento selecionado?',
  es_ES: '¿Está seguro de que desea eliminar el documento seleccionado?',
};

export const BTN_REMOVE_DOCUMENT = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const DELETE_DOCUMENT_SUBTITLE = {
  en_US: 'This action cannot be reversed.',
  pt_BR: 'Essa ação não pode ser revertida.',
  es_ES: 'No se podrá revertir esta acción.',
};

export const UNEXPECTED_ERROR_OCCURRED_DOWNLOAD = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};
