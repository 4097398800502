import React, { useContext } from 'react';
import styles from './BannerAXA.module.scss';
import backgroundAXA from '../../assets/images/B2B2C/AXABanner.jpg';
import LogoAXA from '../../assets/images/B2B2C/LogoAXA_WhiteBorder.svg';
import Button from '../Button/Button';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { withRouter } from 'react-router-dom';
import { dataLayerB2B2C } from '../../utils/GTM_helper';

const BannerAXA = props => {
  const { CONFIG_B2B2C, benefitDetail, history } = props;
  const { translate } = useContext(IntlContext);

  const intl = translate(translations);
  const configBackground = {
    crosselBackground: backgroundAXA,
    upsellBackground: backgroundAXA,
  };
  const bannerConfig = CONFIG_B2B2C.quoteDetail.getBanner(
    configBackground,
    intl
  );

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${bannerConfig.background})` }}
    >
      <div className={styles.logoArea}>
        <img src={LogoAXA} className={styles.logo} alt="" />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{bannerConfig.title}</h1>
        <div className={styles.wrapButton}>
          <Button
            type="axaBlue"
            onClick={() => {
              dataLayerB2B2C('Cotizar cobertura Extendida con AXA');
              history.push(
                bannerConfig.linkButton(benefitDetail.external_reference)
              );
            }}
          >
            {bannerConfig.textButton}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(BannerAXA);
