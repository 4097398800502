import React, { useContext } from 'react';

import styles from './Generate.module.scss';
import rightMaster from '../../../../assets/images/icon/arrow-right-figma-master.svg';
import rightAfluent from '../../../../assets/images/icon/arrow-right-figma-afluent.svg';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerContentMenuTrack } from '../../../../utils/GTM_helper';
import ArrowRightIcon from '../../../../assets/icons/ArrowRight';

const Generate = props => {
  const { icon, url, title, blue, border, children, GTM_id, GTM_type } = props;
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const imgRigth = isAfluent() ? rightAfluent : rightMaster;

  const typeGTM = GTM_type ? GTM_type : null;

  return (
    <div className={` ${styles.generate} ${blue ? styles.blue : styles.white}`}>
      <Link
        onClick={() => dataLayerContentMenuTrack(GTM_id, typeGTM)}
        to={url}
        className={`${styles[theme]} ${blue ? styles.blue : styles.white}`}
      >
        <span className={styles.icon}>
          {icon}
          {title}
        </span>
        <span className={styles.iconArrow}>
          <ArrowRightIcon width={20} height={20} />
        </span>
      </Link>
      <div
        className={`${styles[theme]} ${styles.separator} ${
          border ? styles.border : styles.none
        }`}
      ></div>
      {children}
    </div>
  );
};

export default Generate;
