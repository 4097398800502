import { kindBusinessExceptions } from './kindBusinessException';
let observer = {};
let appInsightObserver = {};

import { stringContainsValue } from '../utils/stringUtils';

export const setObserver = _observer => {
  observer = _observer;
};

export const setAppInsightObserver = _appInsightObserver => {
  appInsightObserver = _appInsightObserver;
};

export const serviceUnavaliable = () => {
  appInsightObserver.trackEventUserAction('#### serviceUnavaliable ####', null);
  observer.actions.maintenance.setStatus(503);
};

export const unauthorized = () => {
  appInsightObserver.trackEventUserAction('#### unauthorized ####', null);
  observer.actions.modal.closeModal();
  observer.actions.user.sessionExpired();
};

export const timeoutMove = response => {
  if (response && response.data && response.data.offline) {
    appInsightObserver.trackEventUserAction('#### timeoutMove ####', null);
    observer.actions.modal.showError('PORTAL_OFFLINE');
  }
};

const forbiddenBusiness = response => {
  if (
    response &&
    response.data &&
    response.data.error_description &&
    stringContainsValue(
      response.data.error_description,
      'At least one of these scopes'
    )
  ) {
    appInsightObserver.trackEventUserAction('#### 403 Forbidden ####', null);
    return unauthorized();
  }
  return;
};

export const statusReaction = {
  401: response => unauthorized(),
  503: response => serviceUnavaliable(),
  406: response => timeoutMove(response),
  403: response => forbiddenBusiness(response),
};

const isBusinessTrackException = (error, error_name) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error_description
  ) {
    const error_description = error.response.data.error_description
      .replace(/ /g, '_')
      .toUpperCase();
    const findKindBusinessExceptions = kindBusinessExceptions.find(
      exceptions =>
        exceptions.error_name === error_name &&
        exceptions.error_description === error_description
    );
    return (
      findKindBusinessExceptions &&
      findKindBusinessExceptions.error_description.includes(error_description)
    );
  }
  return false;
};

export const errorHandler = async (request, error_name, bodyRequest) => {
  try {
    const response = await request;
    if (response && error_name === 'updateBinWithTokenex') {
      appInsightObserver.trackEventUserAction(
        '#### (updateBinWithTokenex) TOKENEX POLICY SF - SUCCESS ####',
        bodyRequest
      );
    }
    return response;
  } catch (error) {
    if (error.response) {
      observer.actions.modal.hideError();
      if (!isBusinessTrackException(error, error_name)) {
        appInsightObserver.trackExceptionMonitor(
          error_name,
          error,
          bodyRequest
        );
      }

      if (statusReaction[error.response.status]) {
        setTimeout(() => {
          statusReaction[error.response.status](error.response);
        }, 1000);
      }

      throw error;
    } else {
      if (error.message !== 'Cancelation') {
        if (!window.navigator.onLine) {
          observer.actions.modal.showError('CONNECTION_ERROR');
        }
      }
    }
  }
};
