import React, { useContext } from 'react';
import styles from './GrayBox.module.scss';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerContentMenuTrack } from '../../../../utils/GTM_helper';
import ArrowRightIcon from '../../../../assets/icons/ArrowRight';

const GrayBox = props => {
  const { title, benefits, link, to = '/', GTM_item_id } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const content =
    Array.isArray(benefits) &&
    benefits.map((benefit, index) => (
      <p className={styles.benefit} key={index}>
        {benefit}
      </p>
    ));

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <h2 className={`${styles[theme]} ${styles.title}`}>{title}</h2>
      <div className={`${styles[theme]} ${styles.content}`}>{content}</div>
      <div className={styles.footer}>
        <Link
          to={to}
          className={styles[theme]}
          onClick={() =>
            dataLayerContentMenuTrack(GTM_item_id, 'Service access cards')
          }
        >
          {link}
          <span className={styles.icon}>
            <ArrowRightIcon width={20} height={20} />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default GrayBox;
