export const TEXT_BIN = {
  en_US: 'BIN is made by the first 10 digits on the front of your card. ',
  es_ES:
    'El BIN consta de los primeros 10 dígitos ubicados en el frente de tu tarjeta.',
  pt_BR:
    'O BIN é composto pelos 10 primeiros dígitos localizados na frente do seu cartão. ',
};

export const TEXT_BUTTON_BIN = {
  en_US: 'Add by BIN',
  es_ES: 'Agregar por BIN',
  pt_BR: 'Adicionar através do BIN',
};

export const TEXT_ADD_YOUR_CARD_BIN = {
  en_US: 'Add your CTA card',
  es_ES: 'Agrega tu tarjeta CTA',
  pt_BR: 'Adicione seu cartão CTA',
};

export const TEXT_FIRST_10_DIGITS = {
  en_US: 'Type in the first 10 digits on the front of your CTA card.',
  es_ES: 'Escribe los 10 digitos de tu tarjeta CTA.',
  pt_BR:
    'Digite os primeiros 10 dígitos que se encontram na frente do seu cartão CTA.',
};

export const TEXT_REQUIRED_FORM = {
  en_US: 'This field is required.',
  pt_BR: 'Este campo é obrigatório.',
  es_ES: 'Este campo es requerido.',
};

export const TEXT_LABEL_ADD_CARD = {
  en_US: 'Card number (first 10 digits)',
  pt_BR: 'Número do cartão (10 primeiros dígitos)',
  es_ES: 'Número de la tarjeta (primeros 10 dígitos)',
};

export const TEXT_ERROR_MESSAGE = {
  en_US: 'Oops. Unfortunately your card could not be added at this time.',
  pt_BR:
    'Opa. Infelizmente, não foi possível adicionar seu cartão neste momento.',
  es_ES:
    '¡Ups! Desafortunadamente, no pudimos agregar tu tarjeta en este momento.',
};

export const TEXT_ERROR_TRY_AGAIN = {
  en_US: 'Try again',
  pt_BR: 'Tente novamente',
  es_ES: 'Inténtalo de nuevo',
};

export const SUBTITLE_ERROR_MESSAGE = {
  en_US: 'The number entered is not recognized by the Benefits Portal.',
  pt_BR: 'A numeração informada não é reconhecida pelo Portal de Benefícios.',
  es_ES: 'El portal de beneficios no reconoce el número ingresado.',
};

export const TEXT_ERROR_NEED_HELP = {
  en_US: 'Need help?',
  pt_BR: 'Precisa de ajuda?',
  es_ES: '¿Necesita ayuda?',
};

export const TEXT_SUCCESS = {
  en_US: 'Congratulations! Your new card was added successfully.',
  pt_BR: 'Parabéns! Seu novo cartão foi adicionado com sucesso.',
  es_ES: '¡Felicidades! Tu nueva tarjeta fue agregada con éxito.',
};

export const OK = {
  en_US: 'OK',
  pt_BR: 'OK',
  es_ES: 'OK',
};

export const TEXT_MAIN_CARD = {
  en_US: 'I want this card to be the main one',
  es_ES: 'Quiero que esta sea la tarjeta principal',
  pt_BR: 'Quero esse cartão como sendo o principal',
};

export const TEXT_CARD_ALREADY_REGISTER = {
  en_US: 'This card number is already registered in your account.',
  pt_BR: 'Este número de cartão já está registrado em sua conta.',
  es_ES: 'Este número de tarjeta ya está registrado en tu cuenta.',
};

export const LOADING_NEW_CARD = {
  en_US: 'Adding your card...',
  pt_BR: 'Adicionando seu cartão...',
  es_ES: 'Agregando tu tarjeta...',
};

export const BTN_ADD_CARD = {
  en_US: 'Add card',
  pt_BR: 'Adicionar cartão',
  es_ES: 'Agregar tarjeta',
};

export const DISCLAIMER = {
  en_US:
    'Registration available for Visa cards issued in Latin America and the Caribbean.',
  pt_BR:
    'Registro disponível para cartões Visa emitidos na América Latina e Caribe.',
  es_ES:
    'Registro disponible para tarjetas Visa emitidas en América Latina y el Caribe.',
};

export const TEXT_WARNING_VISA = {
  en_US:
    'Visa will not make any additional charges to your card, the information provided will be used for validation purposes. Only Visa cards issued in Latin America and the Caribbean are eligible.',
  pt_BR:
    'A Visa não efetuará nenhuma cobrança adicional ao seu cartão, a informação fornecida é apenas para validação. Apenas os cartões Visa emitidos na América Latina e no Caribe são elegíveis.',
  es_ES:
    'Visa no hará ningún cargo adicional a tu tarjeta, la información brindada será usada con fines de validación. Solo son elegibles las tarjetas Visa emitidas en América Latina y el Caribe.',
};

export const TEXT_10_DIGITS = {
  en_US: 'Type in the first 10 digits on the front of your CTA card.',
  es_ES: 'Escribe los 10 digitos de tu tarjeta CTA.',
  pt_BR:
    'Digite os primeiros 10 dígitos que se encontram na frente do seu cartão CTA.',
};

export const TEXT_ADD_YOUR_CARD_PAN = {
  en_US: 'Add another Visa card',
  es_ES: 'Agregar otra tarjeta Visa',
  pt_BR: 'Adicionar outro cartão Visa',
};
