import React, { useContext } from 'react';
import styles from './SubMenuCountry.module.scss';
import { IntlContext } from '../../../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import {
  listCountries,
  NORTH_AMERICA,
  SOUTH_AMERICA,
  CENTRAL_AMERICA,
  CARIBBEAN,
} from '../../../intl/countries';
import ListCountries from '../../ListCountries/ListCountries';
import SelectCountry from '../../../assets/icons/SelectCountryIcon';

//These countries are currently excluded because they have no products
const excludedCountries = ['GP', 'MQ', 'US'];

const getCountries = continent => {
  let countries = listCountries.filter(
    country =>
      country.continent === continent &&
      !excludedCountries.find(element => element === country.initials)
  );
  return countries;
};

const SubMenuCountry = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const countriesSouthAmerica = getCountries(SOUTH_AMERICA);
  const countriesNorthAmerica = getCountries(NORTH_AMERICA);
  const countriesCentralAmerica = getCountries(CENTRAL_AMERICA);
  const countriesCaribbean = getCountries(CARIBBEAN);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <menu
      className={`${styles[theme]} ${styles.subMenu}`}
      aria-label={intl.LIST_OF_COUNTRIES}
      aria-description={intl.DESCRIBE_MENU_COUNTRY_LIST}
      role="dialog"
    >
      <div className={styles.title}>
        <SelectCountry
          width={50}
          height={50}
          aria-label={intl.IMAGE_OF_THE_TERRESTRIAL_GLOBE}
        />

        <h1 className={styles[theme]}>{intl.TEXT_TITLE_SELECT_COUNTRY}</h1>
      </div>
      <span className={`${styles[theme]} ${styles.divisor}`}></span>

      <div className={styles.countryList}>
        <ListCountries
          countries={countriesSouthAmerica}
          column="2"
          continent={intl.TEXT_SOUTH_AMERICA}
        />
        <ListCountries
          countries={countriesCentralAmerica}
          column="1"
          borderLeft
          continent={intl.TEXT_CENTRAL_AMERICA}
        />
        <ListCountries
          countries={countriesCaribbean}
          column="3"
          borderLeft
          continent={intl.TEXT_CARIBBEAN}
        />
        <ListCountries
          countries={countriesNorthAmerica}
          column="2"
          borderLeft
          continent={intl.TEXT_NORTH_AMERICA}
        />
      </div>
    </menu>
  );
};

export default SubMenuCountry;
