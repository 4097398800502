import { ENABLE_CLAIM_APPROVED_VALUE } from '../../../utils/environments_variables';
import { LABEL_AMOUNT_TO_BE_PAYED } from './intl';

const SATUS_CLOSED = 'Closed';
const SUBSTATUS_APPROVED = 'Claims - Case Closed';

export const getTheCorrectAmountByStatus = (claimDetails, idiom) => {
  if (ENABLE_CLAIM_APPROVED_VALUE && claimDetails && claimDetails.statuses) {
    const lastStatusIndex = claimDetails.statuses.length - 1;
    if (
      claimDetails.statuses[lastStatusIndex] &&
      claimDetails.statuses[lastStatusIndex].name === SATUS_CLOSED &&
      claimDetails.statuses[lastStatusIndex].sub_statuses
    ) {
      const lastSubStatusIndex =
        claimDetails.statuses[lastStatusIndex].sub_statuses.length - 1;
      if (
        claimDetails.statuses[lastStatusIndex].sub_statuses[
          lastSubStatusIndex
        ] &&
        claimDetails.statuses[lastStatusIndex].sub_statuses[lastSubStatusIndex]
          .name === SUBSTATUS_APPROVED
      ) {
        return {
          reimbursedValue:
            claimDetails.total_amount_reimbursed &&
            claimDetails.total_amount_reimbursed.value
              ? claimDetails.total_amount_reimbursed.value
              : null,
          reimbursedLabel: LABEL_AMOUNT_TO_BE_PAYED[idiom],
          reimbursedCurrency:
            claimDetails.total_amount_reimbursed &&
            claimDetails.total_amount_reimbursed.currency
              ? claimDetails.total_amount_reimbursed.currency
              : null,
        };
      }
    }
  }

  return {
    reimbursedValue: null,
    reimbursedLabel: null,
    reimbursedCurrency: null,
  };
};
