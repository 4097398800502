import React, { useContext } from 'react';
import styles from './Offer.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import Button from '../../../components/Button/Button';
import { withRouter } from 'react-router-dom';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const Offer = props => {
  const { history } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, getImageByName } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const bgImage = getImageByName('offersHome');

  return (
    <div className={`${styles.containerOffer} ${styles[theme]}`}>
      <img
        src={bgImage}
        className={styles.image}
        loading="lazy"
        alt={intl.OFFERS}
      ></img>
      <div className={`${styles.infoArea} ${styles[theme]}`}>
        <h2 className={`${styles.info} ${styles[theme]}`}>
          {intl.DESCRIPTION_OFFERS}
        </h2>
        <Button
          type="borderGold"
          width="180px"
          height="53px"
          onClick={() => history.push('/subscription-benefits')}
          id="Login-offers"
        >
          {intl.BTN_OFFERS}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(Offer);
