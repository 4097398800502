import { formatCar_rentalToApi } from './car_rental';
import { formatPurchase_protectionToApi } from './purchase_protection';
import { formatTripCancellation_toApi } from './trip_cancellation';
import { formatMissed_ConnectionToApi } from './missed_connection';
import { extendedWarranty_ProtectionToApi } from './extended_warranty';
import { formatPrice_ProtectionToApi } from './price_protection';
import { formatBaggageDelay_toApi } from './baggage_delay';
import { formatIems_toApi } from './iems';
import { formatBaggageLoss_toApi } from './baggage_loss';
import { travel_accident_insuranceToApi } from './travel_accident_insurance';
import { injured_journay_accidentalToApi } from './injured_journay_accidental';
import { corporate_liability_waiverToApi } from './corporate_liability_waiver';
import { formatAtmAssault_toApi } from './atm_assault';
import { cell_phone_protection_ToApi } from './cell_phone_protection';
import { bag_protection_ToApi } from './bag_protection';
import { trip_delayToApi } from './trip_delay';
import { satisfaction_guarantee_toApi } from './satisfaction_guarantee';
import { hotel_burglary_toApi } from './hotel_burglary';
import { rideshare_toApi } from './rideshare';
import { formatEnhanced_PurchaseToApi } from './enhanced_purchase';
import { formatIdTheftProtection } from './id_theft_protection';
import { legal_protection_toApi } from './legal_protection';
import { fraudulentChargesToApi } from './fraudulent_charges';
import { formatProtectedVacations_toApi } from './protected_vacations';

const incidents = {
  Trip_Delay: trip_delayToApi,
  Auto_Rental_Insurance: formatCar_rentalToApi,
  Purchase_Protection: formatPurchase_protectionToApi,
  Trip_Cancellation: formatTripCancellation_toApi,
  Missed_Connection: formatMissed_ConnectionToApi,
  Price_Protection: formatPrice_ProtectionToApi,
  Baggage_Delay: formatBaggageDelay_toApi,
  Extended_Warranty: extendedWarranty_ProtectionToApi,
  EMC_Emergency_Medical_Assistance: formatIems_toApi,
  Baggage_Loss: formatBaggageLoss_toApi,
  Travel_Accident_Insurance: travel_accident_insuranceToApi,
  Injured_Journay_Accidental_D_D: injured_journay_accidentalToApi,
  Corporate_Liability_Waiver: corporate_liability_waiverToApi,
  Assault_Cash_Death: formatAtmAssault_toApi,
  Cellphone_Protection: cell_phone_protection_ToApi,
  Bag_Protection: bag_protection_ToApi,
  Satisfaction_Guarantee: satisfaction_guarantee_toApi,
  Hotel_Motel_Burglary: hotel_burglary_toApi,
  Rideshare: rideshare_toApi,
  Legal_Protection: legal_protection_toApi,
  Enhanced_Purchase_and_eCommerce_Protection: formatEnhanced_PurchaseToApi,
  Identity_Theft: formatIdTheftProtection,
  fraudulent_charges: fraudulentChargesToApi,
  protected_vacations: formatProtectedVacations_toApi,
};

export const formatIncidentToApi = props => {
  return incidents[props.claimType](props);
};
