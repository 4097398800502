import React, { useContext, useState, useEffect } from 'react';

import { NavLink, withRouter, Link } from 'react-router-dom';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './MenuMobile.module.scss';
import SVG_MENU_AFLUENT from '../../assets/images/icon/afluent/svg_menu.svg';
import SVG_MENU_MASTER from '../../assets/images/icon/master/svg_menu.svg';
import {
  listCountries,
  NORTH_AMERICA,
  SOUTH_AMERICA,
  CENTRAL_AMERICA,
  CARIBBEAN,
} from '../../intl/countries';
import ListCountries from '../ListCountries/ListCountries';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ButtonLogin from '../ButtonLogin/ButtonLogin';
import HeadPhoneIcon from '../../assets/icons/HeadphoneIcon';
import SelectCountry from '../../assets/icons/SelectCountryIcon';
import SpecialBenefitsIcon from '../../assets/icons/SpecialBenefitsIcon';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import CloseIcon from '../../assets/icons/CloseIcon';
import Logo from '../Logo/Logo';

const getCountries = continent => {
  let countries = listCountries.filter(
    country => country.continent === continent
  );
  return countries;
};

const MenuMobile = ({ isLogged }) => {
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [showContinent, setShowContinent] = useState(false);
  const [showCountries, setShowCountries] = useState(false);
  const [continent, setContinent] = useState('');
  const { translate, countryFullName } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const countriesSouthAmerica = getCountries(SOUTH_AMERICA);
  const countriesNorthAmerica = getCountries(NORTH_AMERICA);
  const countriesCentralAmerica = getCountries(CENTRAL_AMERICA);
  const countriesCaribbean = getCountries(CARIBBEAN);

  useEffect(() => {
    setShowMenuMobile(false);
  }, [countryFullName]);
  return (
    <div className="showOnMobile">
      {!showMenuMobile && (
        <div onClick={() => setShowMenuMobile(true)}>
          <img
            src={isAfluent() ? SVG_MENU_AFLUENT : SVG_MENU_MASTER}
            className={`${styles[theme]} ${styles.iconMenu}`}
            alt={intl.OPEN_MENU_MOBILE}
            aria-label={intl.OPEN_MENU_MOBILE}
          />
        </div>
      )}

      {showMenuMobile && (
        <div className={`${styles[theme]} ${styles.menuMobileList}`}>
          <div className={`${styles[theme]} ${styles.headerMenuMobile}`}>
            <Link to="/">
              <Logo color="#000000" size="MEDIUM" />
            </Link>
            <div>
              <span className={styles.iconMenu}>
                <CloseIcon
                  width={30}
                  height={30}
                  alt={intl.CLOSE_MENU_MOBILE}
                  aria-label={intl.CLOSE_MENU_MOBILE}
                  onClick={() => setShowMenuMobile(false)}
                />
              </span>
            </div>
          </div>
          <ul>
            {!isLogged && (
              <li className={`${styles[theme]}`}>
                <NavLink
                  to="/subscription-benefits"
                  className={`${styles[theme]}`}
                >
                  <span className={styles.icon}>
                    <SpecialBenefitsIcon
                      aria-label={intl.TEXT_SPECIAL_BENEFITS}
                      width={40}
                      height={40}
                    />
                  </span>{' '}
                  {intl.TEXT_SPECIAL_BENEFITS}
                </NavLink>

                <span className={styles.iconChevron}>
                  <ChevronGeneric rotate={270} width={20} height={20} />
                </span>
              </li>
            )}
            <li className={`${styles[theme]}`}>
              <NavLink to="/help" className={`${styles[theme]}`}>
                <span className={styles.icon}>
                  <HeadPhoneIcon
                    aria-label={intl.TEXT_HELP}
                    width={40}
                    height={40}
                  />
                </span>{' '}
                {intl.TEXT_HELP}
              </NavLink>
              <span className={styles.iconChevron}>
                <ChevronGeneric
                  rotate={270}
                  width={20}
                  height={20}
                  aria-label={intl.REDIRECT_TO_HELP_PAGE}
                />
              </span>
            </li>
            {!isLogged && (
              <>
                <li
                  aria-label={intl.OPEN_MENU_COUNTRIES}
                  onClick={() => setShowContinent(!showContinent)}
                  className={`${styles[theme]}`}
                >
                  <span className={styles.icon}>
                    <SelectCountry
                      aria-label={intl.COUNTRY}
                      width={40}
                      height={40}
                      key={'selectCountryMobile'}
                    />
                  </span>{' '}
                  <span>{intl.TEXT_COUNTRY}</span>
                  <span className={styles.iconChevron}>
                    <ChevronGeneric
                      rotate={showContinent ? 180 : 0}
                      width={20}
                      height={20}
                      aria-label={intl.ICON}
                    />
                  </span>
                </li>
              </>
            )}
          </ul>

          {showContinent && !isLogged && (
            <ul className={`${styles[theme]} ${styles.countriesMenuMobile}`}>
              <li className={`${styles[theme]}`}>
                <span
                  className={`${styles[theme]} ${styles.continentName}`}
                  onClick={() => {
                    setShowCountries(!showCountries);
                    setContinent(SOUTH_AMERICA);
                  }}
                >
                  {intl.TEXT_SOUTH_AMERICA}

                  <span className={styles.iconChevron}>
                    <ChevronGeneric
                      rotate={
                        continent === SOUTH_AMERICA && showCountries ? 180 : 0
                      }
                      width={20}
                      height={20}
                      aria-label={intl.OPEN_COUNTRIES_SOUTH_AMERICA}
                    />
                  </span>
                </span>

                <ListCountries
                  countries={countriesSouthAmerica}
                  showCountries={continent === SOUTH_AMERICA && showCountries}
                  title={false}
                />
              </li>
              <li className={`${styles[theme]}`}>
                <span
                  className={`${styles[theme]} ${styles.continentName}`}
                  onClick={() => {
                    setShowCountries(!showCountries);
                    setContinent(CENTRAL_AMERICA);
                  }}
                >
                  {intl.TEXT_CENTRAL_AMERICA}

                  <span className={styles.iconChevron}>
                    <ChevronGeneric
                      rotate={
                        continent === CENTRAL_AMERICA && showCountries ? 180 : 0
                      }
                      width={20}
                      height={20}
                      aria-label={intl.OPEN_MENU_CENTRAL_AMERICA}
                    />
                  </span>
                </span>
                <ListCountries
                  countries={countriesCentralAmerica}
                  showCountries={continent === CENTRAL_AMERICA && showCountries}
                  title={false}
                />
              </li>
              <li className={`${styles[theme]}`}>
                <span
                  className={`${styles[theme]} ${styles.continentName}`}
                  onClick={() => {
                    setShowCountries(!showCountries);
                    setContinent(CARIBBEAN);
                  }}
                >
                  {intl.TEXT_CARIBBEAN}

                  <span className={styles.iconChevron}>
                    <ChevronGeneric
                      rotate={
                        continent === CARIBBEAN && showCountries ? 180 : 0
                      }
                      width={20}
                      height={20}
                      aria-label={intl.OPEN_MENU_CENTRAL_AMERICA}
                    />
                  </span>
                </span>
                <ListCountries
                  countries={countriesCaribbean}
                  showCountries={continent === CARIBBEAN && showCountries}
                  title={false}
                />
              </li>
              <li className={`${styles[theme]}`}>
                <span
                  className={`${styles[theme]} ${styles.continentName}`}
                  onClick={() => {
                    setShowCountries(!showCountries);
                    setContinent(NORTH_AMERICA);
                  }}
                >
                  {intl.TEXT_NORTH_AMERICA}

                  <span className={styles.iconChevron}>
                    <ChevronGeneric
                      rotate={
                        continent === NORTH_AMERICA && showCountries ? 180 : 0
                      }
                      width={20}
                      height={20}
                      aria-label={intl.OPEN_MENU_NORTH_AMERICA}
                    />
                  </span>
                </span>
                <ListCountries
                  countries={countriesNorthAmerica}
                  showCountries={continent === NORTH_AMERICA && showCountries}
                  title={false}
                />
              </li>
            </ul>
          )}

          {!isLogged && (
            <>
              <div className={styles.buttonArea}>
                <ButtonLogin type="gold" />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(MenuMobile);
