/* eslint-disable no-unused-vars */
// types
export const HEADER_TRANSPARENT_COLOR_WHITE = 'HEADER_TRANSPARENT_COLOR_WHITE';
export const HEADER_TRANSPARENT_COLOR_DARK_BLUE =
  'HEADER_TRANSPARENT_COLOR_DARK_BLUE';
export const HEADER_PRIVATE = 'HEADER_PRIVATE';

const MASTER = 'theme-master';
const AFLUENT = 'theme-afluent';
const PORTO_BANK = 'theme-portoBank';

// themes config
const THEME_MASTER_CONFIG = {
  HEADER_TRANSPARENT_COLOR_WHITE: {
    colorLogo: '#FFFFFF',
    logoSize: 'BIGGER',
    colorText: '#FFFFFF',
    backgroundColor: false,
    searchIcon: '#FFFFFF',
  },
  HEADER_TRANSPARENT_COLOR_DARK_BLUE: {
    colorLogo: '#1434CB',
    logoSize: 'BIGGER',
    colorText: '#1a2655',
    backgroundColor: false,
    searchIcon: '#FCC015',
  },
  HEADER_PRIVATE: {
    colorLogo: '#1434CB',
    logoSize: 'MEDIUM',
    colorText: '#1a2655',
    backgroundColor: true,
    searchIcon: '#FCC015',
  },
};

const THEME_AFLUENT_CONFIG = {
  HEADER_TRANSPARENT_COLOR_WHITE: {
    colorLogo: '#FFFFFF',
    logoSize: 'BIGGER',
    colorText: '#FFFFFF',
    backgroundColor: false,
    searchIcon: '#FFFFFF',
  },
  HEADER_TRANSPARENT_COLOR_DARK_BLUE: {
    colorLogo: '#021E4C',
    logoSize: 'BIGGER',
    colorText: '#021E4C',
    backgroundColor: false,
    searchIcon: '#C2A161',
  },
  HEADER_PRIVATE: {
    colorLogo: '#021E4C',
    logoSize: 'MEDIUM',
    colorText: '#021E4C',
    backgroundColor: true,
    searchIcon: '#C2A161',
  },
};

const THEME_PORTO_BANK = {
  HEADER_TRANSPARENT_COLOR_WHITE: {
    colorLogo: '#FFFFFF',
    logoSize: 'BIGGER',
    colorText: '#FFFFFF',
    backgroundColor: false,
    searchIcon: '#FFFFFF',
  },
  HEADER_TRANSPARENT_COLOR_DARK_BLUE: {
    colorLogo: '#0A0047',
    logoSize: 'BIGGER',
    colorText: '#0A0047',
    backgroundColor: false,
    searchIcon: '#546AC0',
  },
  HEADER_PRIVATE: {
    colorLogo: '#0A0047',
    logoSize: 'MEDIUM',
    colorText: '#0A0047',
    backgroundColor: true,
    searchIcon: '#546AC0',
  },
};

export const getHeaderConfigByTheme = theme => {
  if (theme == PORTO_BANK) {
    return THEME_PORTO_BANK;
  }
  return theme === MASTER ? THEME_MASTER_CONFIG : THEME_AFLUENT_CONFIG;
};
