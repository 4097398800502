import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../../intl';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from '../intl';
import styles from '../ConcernedItems.module.scss';
import Input from '../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../components/Select/Select';
import ItemRemove from './ItemRemove';
import { sanitize } from 'dompurify';

const Item = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const { values, index, handleChange, handleBlur, _touched, _errors } = props;

  return (
    <div key={`additionalItem-${index + 1}`} className={styles.item}>
      <div className="row">
        <div className="col-12">
          <h4 className={`${styles[theme]}`}>
            {intl.SUBTITLE_AREA} #{index + 1}
          </h4>
        </div>
        <div className="col-12 col-md-4">
          <Input
            value={values.incident.concerned_items[index].description}
            name={`incident.concerned_items[${index}].description`}
            dataTestid={`InputItemDescription-${index}`}
            label={intl.LABEL_DESCRIPTION}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={_touched && _touched.description}
            error={_errors && _errors.description}
            maxLength="255"
          />
        </div>
        <div className="col-12 col-md-4">
          <Input
            value={
              values.incident.concerned_items[index].price.value === 0
                ? '0.00'
                : values.incident.concerned_items[index].price.value
            }
            maskedMoney
            name={`incident.concerned_items[${index}].price.value`}
            dataTestid={`InputItemValue-${index}`}
            label={intl.LABEL_VALUE}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={_touched && _touched.price && _touched.price.value}
            error={_errors && _errors.price && _errors.price.value}
          />
        </div>
        <div className="col-12 col-md-4">
          <Select
            label={intl.LABEL_CURRENCY}
            value={values.total_amount_claimed.currency}
            name={`values.total_amount_claimed.currency`}
            disabled
            onChange={handleChange}
            onBlur={handleBlur}
            error={_errors && _errors.price && _errors.price.currency}
            dataTestid={`SelectItemCurrency-${index}`}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
              ),
            }}
          ></Select>
        </div>
      </div>
      <div className={`row ${styles.footer}`}>
        <ItemRemove {...props} intl={intl} />
      </div>
    </div>
  );
};

export default Item;
