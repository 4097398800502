import React, { useContext } from 'react';
import styles from './DividingLine.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

const DividingLine = () => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div
      data-testid="line"
      className={`${styles.divisor} ${styles[themes]}`}
    ></div>
  );
};

export default DividingLine;
