export const LABEL_BOOKING_COMPANY = {
  en_US: 'Booking company / Airline *',
  pt_BR: 'Empresa de reserva / Companhia aérea *',
  es_ES: 'Compañía de reserva / Aerolínea *',
};

export const LABEL_RECORD_LOCATOR = {
  en_US: 'Record locator *',
  pt_BR: 'Localizador de registro *',
  es_ES: 'Localizador de registro *',
};

export const COUNTRY_ORIGIN = {
  en_US: 'Origin country',
  pt_BR: 'País de origem',
  es_ES: 'País de origen',
};

export const COUNTRY_DESTINATION = {
  en_US: 'Destination country',
  pt_BR: 'País de destino',
  es_ES: 'País de destino',
};
