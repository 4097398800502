import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const TITLE_LEGAL_EXPESES = {
  en_US: 'Legal expenses',
  pt_BR: 'Despesas legais',
  es_ES: 'Gastos legales',
};

export const TEXT_DESCRIPTION = {
  en_US: 'Description *',
  pt_BR: 'Descrição *',
  es_ES: 'Descripción *',
};

export const FIELD_LEGAL_EXPENSES_DATE = {
  en_US: 'Expense date *',
  pt_BR: 'Data da despesa *',
  es_ES: 'Fecha de gasto *',
};

export const FIELD_LEGAL_EXPENSES_VALUE = {
  en_US: 'Amount *',
  pt_BR: 'Valor total do sinistro *',
  es_ES: 'Monto *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  es_ES: 'Moneda *',
  pt_BR: 'Moeda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TITLE_LEGAL_EXPENSES = {
  en_US: 'Legal expenses',
  pt_BR: 'Despesas legais',
  es_ES: 'Gastos legales',
};

export const TEXT_TOTAL_AMOUNT = {
  en_US: 'Total amount of claim *',
  es_ES: 'Importe total de la reivindicación *',
  pt_BR: 'Valor total do sinistro *',
};

export const TEXT_ITEM = {
  en_US: 'Expense #',
  pt_BR: 'Despesa #',
  es_ES: 'Gasto #',
};

export const BTN_ADD = {
  en_US: 'Add expense',
  pt_BR: 'Adicionar despesa',
  es_ES: 'Agregar gasto',
};
