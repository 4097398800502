import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as translations from './intl';
import styles from './ConsultationItem.module.scss';
import {
  STATUS_CANCELED,
  STATUS_DONE,
  STATUS_PENDING,
  getConsultationStatus,
} from '../../../../../utils/status';
import { IntlContext } from '../../../../../intl';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../utils/sanitize';
import ChevronGeneric from '../../../../../assets/icons/ChevronGeneric';
import Button from '../../../../../components/Button/Button';
import StatusLabel from '../../../../../components/StatusLabel/StatusLabel';
import ModalWithImage from '../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../components/ModalResult/ModalResult';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import {
  ConsultationPhoneType,
  ConsultationVideoType,
} from '../../../../../utils/consultationCategory';
import { AppInsightTrackContext } from '../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import {
  amosConsultationsService,
  amosOthersService,
  amosVideocallsService,
} from '../../../../../services';
import ConsultationInfo from './ConsultationInfo/ConsultationInfo';
import { formatCustomDate } from '../../../../../utils/date';
import PosScreenIcon from '../../../../../assets/icons/PosScreenIcon';
import MobileIcon from '../../../../../assets/icons/MobileIcon';
import { differenceInMinutes, isBefore, parse } from 'date-fns';
import Loading from '../../../../../components/Loading/Loading';

const ConsultationItem = props => {
  const { translate, idiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const history = useHistory();
  const intl = translate(translations);
  const { appointmentType, moreInfo, data, customer } = props;
  const [showInfo, setShowInfo] = useState(false);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const [loadingCanJoin, setLoadingCanJoin] = useState(false);
  const [canJoin, setCanJoin] = useState(false);
  const isCanceled = data.status === STATUS_CANCELED.toLowerCase();
  const isDone = data.status === STATUS_DONE.toLowerCase();
  const isPending = data.status === STATUS_PENDING.toLowerCase();

  const isConsultationDateBeforeCurrentDate = () => {
    const dateString = data.date.substring(0, 10);
    const timeString =
      appointmentType === ConsultationPhoneType
        ? `23:59:59`
        : `${data.time}:00`;
    const consultationDate = new Date(`${dateString}T${timeString}`);
    const currentDate = new Date();
    return isBefore(consultationDate, currentDate);
  };

  const isBeforeCurrentDate = isConsultationDateBeforeCurrentDate();

  const cancelPhoneCall = async consultation => {
    try {
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Canceling phone appointment',
        { consultation_id: consultation.id }
      );

      actions.modal.showLoading(intl.CANCELING_MESSAGE);
      const { data } =
        await amosConsultationsService.cancelAppointmentPhoneCall(
          customer,
          consultation.id
        );
      setShowInfo(false);
      consultation.status = data.data.status;
      actions.modal.hideLoading();
      actions.modal.showModal(
        '',
        true,
        <ModalInformation
          type="success"
          message={intl.CANCEL_PHONECALL_MESSAGE_SUCCESS}
          clickBtn={actions.modal.closeModal}
        />
      );
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Cancel phone appointment',
        { consultation_id: consultation.id }
      );
    } catch (error) {
      actions.modal.hideLoading();
      actions.modal.showModal(
        '',
        true,
        <ModalInformation
          type="error"
          message={intl.CANCEL_PHONECALL_MESSAGE_ERROR}
          clickBtn={actions.modal.closeModal}
        />
      );
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Error to cancel phone appointment',
        { error }
      );
    }
  };

  const cancelVideoCall = async consultation => {
    try {
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Cancelando videochamada',
        { consultation_id: consultation.id }
      );

      actions.modal.showLoading(intl.CANCELING_MESSAGE);
      const { data } = await amosVideocallsService.cancelAppointmentVideoCall(
        customer,
        consultation.id
      );
      setShowInfo(false);
      consultation.status = data.data.status;
      actions.modal.hideLoading();
      actions.modal.showModal(
        '',
        true,
        <ModalInformation
          type="success"
          message={intl.CANCEL_VIDEOCALL_MESSAGE_SUCCESS}
          clickBtn={actions.modal.closeModal}
        />
      );
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Videochamada cancelada',
        { consultation_id: consultation.id }
      );
    } catch (error) {
      actions.modal.hideLoading();
      actions.modal.showModal(
        '',
        true,
        <ModalInformation
          type="error"
          message={intl.CANCEL_VIDEOCALL_MESSAGE_ERROR}
          clickBtn={actions.modal.closeModal}
        />
      );
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Erro ao cancelar videochamada',
        { error }
      );
    }
  };

  const showModalToCancel = async consultation => {
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={
          appointmentType === ConsultationPhoneType
            ? intl.LABEL_MODAL_MESSAGE_PHONECALL
            : intl.LABEL_MODAL_MESSAGE_VIDEOCALL
        }
        subtitle={intl.MODAL_TEXT_CONFIRMATION_CANCEL_APPOINTMENT}
      >
        <ModalResult
          textConfirm={intl.BUTTON_CONFIRM}
          textDeny={intl.BUTTON_DENY}
          buttonConfirmType="borderRed"
          clickConfirm={async () =>
            appointmentType === ConsultationPhoneType
              ? await cancelPhoneCall(consultation)
              : await cancelVideoCall(consultation)
          }
          clickDeny={() => actions.modal.closeModal()}
        />
      </ModalWithImage>,
      true
    );
  };

  const showCancelButton = isBeforeCurrentDate => {
    return (
      <>
        {!isBeforeCurrentDate && (
          <div
            className={`${styles.button} ${styles.buttonCreateClaim} ${styles[themes]}`}
          >
            <Button
              css={styles[themes]}
              type="borderBlue"
              onClick={() => {
                showModalToCancel(data);
              }}
            >
              {intl.TEXT_CANCEL_APPOINTMENT}
            </Button>
          </div>
        )}
      </>
    );
  };

  const showButtons = (showArrow = true) => {
    return (
      <>
        {!isCanceled && !isDone ? (
          showCancelButton(isBeforeCurrentDate)
        ) : (
          <span className={styles.status}>
            {!isPending && (
              <StatusLabel
                appointments
                status={getConsultationStatus(data.status)}
              />
            )}
          </span>
        )}

        {showArrow && (
          <span
            className={`${styles.arrow} ${
              showInfo ? styles.showMoreActive : ''
            }`}
          >
            <ChevronGeneric
              data-testid="arrow"
              onClick={() => {
                setShowInfo(!showInfo);
              }}
              aria-label={intl.IMAGE_ARROW}
              rotate={showInfo ? 180 : 0}
              width={20}
              height={20}
            />
          </span>
        )}
      </>
    );
  };

  const renderRightOption = () => {
    if (loadingCanJoin) {
      return <Loading checkBoxLoading scale="0.5" />;
    } else if (canJoin) {
      return (
        <>
          <div
            className={`${styles.button} ${styles.buttonCreateClaim} ${styles[themes]}`}
          >
            <Button
              css={styles[themes]}
              type="borderBlue"
              onClick={() =>
                history.push('Teleconsultation/VideoCall', {
                  videocallId: data.id,
                  customer,
                })
              }
            >
              {intl.LABEL_JOIN.toUpperCase()}
            </Button>
          </div>
          <span className={`${styles.arrow}`}>
            <ChevronGeneric
              onClick={() =>
                history.push('Teleconsultation/VideoCall', {
                  videocallId: data.id,
                  customer,
                })
              }
              aria-label={intl.IMAGE_ARROW}
              rotate={270}
              width={20}
              height={20}
            />
          </span>
        </>
      );
    } else {
      return showButtons();
    }
  };

  const handleCanJoin = useCallback(
    async consultation => {
      if (
        appointmentType === ConsultationVideoType &&
        (String(data.status).toLowerCase() === 'pending' ||
          String(data.status).toLowerCase() === 'in-progress')
      ) {
        try {
          trackEventUserAction(
            '#### VMO ### Appointments - Requisição - horário AMOS',
            true
          );

          setLoadingCanJoin(true);
          const {
            data: {
              data: { time: serverTime },
            },
          } = await amosOthersService.getTime(consultation.time_zone);

          trackEventUserAction('#### VMO ### Appointments - Horário AMOS', {
            serverTime,
          });

          const serverTimeDate = parse(
            serverTime,
            'yyyy-MM-dd HH:mm:ss',
            new Date()
          );
          const appointmentTimeDate = parse(
            `${consultation.date} ${consultation.time}`,
            'yyyy-MM-dd HH:mm',
            new Date()
          );

          const canJoin =
            differenceInMinutes(appointmentTimeDate, serverTimeDate) <= 5 &&
            differenceInMinutes(serverTimeDate, appointmentTimeDate) <= 60;

          setCanJoin(canJoin);
          setLoadingCanJoin(false);

          trackEventUserAction(
            '#### VMO ### Appointments - Usuário pode entrar em videochamada',
            { canJoin, consultation }
          );
        } catch (error) {
          trackEventUserAction('#### VMO ### Appointments - Erro - canJoin', {
            consultation,
            error,
          });
          setCanJoin(false);
        }
      }
    },
    [appointmentType, data.status, trackEventUserAction]
  );

  useEffect(() => {
    handleCanJoin(data);
    const canJoinInterval = setInterval(() => handleCanJoin(data), 60000);
    return () => clearInterval(canJoinInterval);
  }, [data, handleCanJoin]);

  return (
    <li className={`${styles.recentClaimsItem} ${styles[themes]}`}>
      <div className={styles.left}>
        <div className={`${styles.icon} ${styles[themes]}`}>
          {appointmentType === ConsultationPhoneType ? (
            <MobileIcon data-testid="PhoneIcon" />
          ) : (
            <PosScreenIcon data-testid="VideoIcon" />
          )}
        </div>
        <div className={`${styles.text} ${styles[themes]}`}>
          <strong>
            {data.patient.name} {data.patient.surname}
          </strong>
          <span
            className={styles.moreInfo}
            dangerouslySetInnerHTML={{ __html: sanitize(moreInfo) }}
          />
        </div>
      </div>
      <div className={styles.center}>
        <span>
          {appointmentType === ConsultationPhoneType
            ? intl.TEXT_DATE
            : intl.TEXT_DATE_TIME}
          :{' '}
          {appointmentType === ConsultationPhoneType
            ? formatCustomDate(data.date, 'YYYY-MM-DD', idiom)
            : formatCustomDate(data.date, 'YYYY-MM-DD HH:mm', idiom)}
        </span>
        <span>{appointmentType === ConsultationVideoType && data.time}</span>
      </div>
      <div
        className={
          showInfo ? `${styles.right} ${styles.hideButtons}` : styles.right
        }
      >
        {renderRightOption()}
      </div>
      {showInfo && (
        <div style={{ width: '100%' }}>
          <ConsultationInfo
            appointment={data}
            appointmentType={appointmentType}
            handleCancel={showModalToCancel}
            customer={customer}
            hasFiles={data.patient_files.length > 0}
            showButtons={showButtons}
            isCanceled={isCanceled}
            isDone={isDone}
            isBeforeCurrentDate={isBeforeCurrentDate}
          />
        </div>
      )}
    </li>
  );
};

export default withRouter(ConsultationItem);
