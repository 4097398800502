/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Loading from '../../../../components/Loading/Loading';
import {
  nurseService,
  productsAndBenefitsServices,
} from '../../../../services';
import styles from './PreviousAppointments.module.scss';
import AppointmentItemNAL from './AppointmentItem/AppointmentItemNAL';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../../../components/Button/Button';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { findBenefitsByExternalReference } from '../../../../utils/benefits';

const PreviousAppointmentsNAL = props => {
  const { history } = props;
  const { translate, idiom, idiomForApi } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const { getBin } = utils;
  const [loading, setLoading] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState();

  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const binOrPan = getBin(true);

  const mappedData =
    appointmentList.length > 0 &&
    appointmentList.map(value => {
      return (
        <AppointmentItemNAL
          key={`AppointmentItem-${value.appointment_details.appointment_id}`}
          data={value}
        />
      );
    });

  useEffect(() => {
    setLoading(true);
    const checkBenefit = async () => {
      try {
        const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
          binOrPan,
          idiomForApi(),
          false,
          false
        );
        return findBenefitsByExternalReference(
          'Nurse_Advice_Line',
          benefits.data
        );
      } catch (error) {
        return false;
      }
    };

    const callService = async () => {
      try {
        const hasBenefit = await checkBenefit();

        if (hasBenefit) {
          const { records: lists, paginator } = (
            await nurseService.getUserReports(page)
          ).data;
          setAppointmentList(lists || []);
          setPaginator(paginator);
          setLoading(false);
        } else {
          history.push('/dashboard');
        }
      } catch (error) {
        setAppointmentList([]);
        setLoading(false);
      }
    };

    callService();
  }, [idiom]);

  if (loading)
    return (
      <div className={styles.loading} data-testid="PreviousAppointmentsLoading">
        <Loading />
      </div>
    );

  const loadMore = async () => {
    try {
      setLoading(true);
      setPage(prev => prev + 1);
      const { records: lists, paginator } = (
        await nurseService.getUserReports(page + 1)
      ).data;
      const tempList = [...appointmentList, ...lists];
      setAppointmentList(tempList);
      setPaginator(paginator);
      setLoading(false);
    } catch (error) {
      setAppointmentList([]);
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <h1 data-testid="title" className={`${styles.title} ${styles[themes]}`}>
        {intl.NAV_ITEM_TITLE_APPOINTMENTS}
      </h1>
      <span className={`${styles.subtitle} ${styles[themes]}`}>
        {appointmentList.length > 0
          ? intl.SUBTITLE_NAL
          : intl.TEXT_APPONTMENT_EMPTY_LIST}
      </span>

      <div id="mainContent" className={styles.appointmentsArea}>
        <div className={` ${styles.previousAppointments} ${styles[themes]}`}>
          {mappedData}
        </div>
      </div>
      {paginator && paginator.hasNext && (
        <div className={`${styles.Containerbutton} ${styles[themes]}`}>
          <Button
            css={`
              ${styles.button} ${styles[themes]}
            `}
            type="borderBlue"
            onClick={() => {
              loadMore();
            }}
          >
            {intl.VIEW_MORE}
          </Button>
        </div>
      )}
    </div>
  );
};

export default withRouter(PreviousAppointmentsNAL);
