import React, { useContext } from 'react';
import { PublicContext } from '../PublicContextParent/PublicContextParent';

const waitForPublicCard = Component => {
  return function Wrapper() {
    const { productActive } = useContext(PublicContext);

    if (productActive && productActive.name) {
      return <Component />;
    }
    return <></>;
  };
};

export default waitForPublicCard;
