import React, { useContext } from 'react';
import iconTrashMaster from '../../../../../../../../../assets/images/icon/master/icon-trash.svg';
import iconTrashAfluent from '../../../../../../../../../assets/images/icon/afluent/icon-trash.svg';

import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import ButtonWithIcon from '../../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';

const ExpenseItemRemove = props => {
  const { values, setFieldValue, index, intl } = props;
  const { isAfluent } = useContext(ThemeContext);

  const removeExpense = event => {
    event.preventDefault();

    const expenses = [...values.expenses];
    const priceFromExpenseRemoved = expenses[index].price.value
      ? expenses[index].price.value
      : 0;
    const diff =
      values.amount_estimation_damage.value - priceFromExpenseRemoved;
    setFieldValue('amount_estimation_damage.value', diff);
    expenses.splice(index, 1);
    setFieldValue('expenses', expenses);
  };

  const removeButton = () => {
    if (index === 0) return;
    return (
      <ButtonWithIcon
        type="blueWithoutBorder"
        dataTestid={`BtnRemoveExpenseItem-${index}`}
        onClick={removeExpense}
        value={intl.BTN_REMOVE}
        isSVGComponent={true}
        img={<TrashIcon width={24} height={24} />}
      />
    );
  };

  return <div className="col-2">{removeButton()}</div>;
};

export default ExpenseItemRemove;
