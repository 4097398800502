import { TEXT_ADD_YOUR_CARD_PAN, TEXT_16_DIGITS } from '../ModalAddCard/intl';

export { TEXT_ADD_YOUR_CARD_PAN, TEXT_16_DIGITS };

export const TITLE = {
  en_US: product => `Explore your ${product} card's benefits`,
  es_ES: product => `Explora los beneficios de tu ${product}`,
  pt_BR: product => `Conheça os benefícios de seu ${product}`,
};

export const YOUR_CARDS = {
  en_US: 'Your cards',
  es_ES: 'Tus tarjetas',
  pt_BR: 'Seus cartões',
};

export const YOUR_CARD = {
  en_US: 'Your card',
  es_ES: 'Tus tarjeta',
  pt_BR: 'Seu cartão',
};

export const ADD_NEW_CARD_MOBILE = {
  en_US: 'Add',
  es_ES: 'Agregar',
  pt_BR: 'Adicionar',
};

export const ADD_NEW_CARD_WEB = {
  en_US: 'Add a new card',
  es_ES: 'Agregar una nueva tarjeta',
  pt_BR: 'Adicionar um novo cartão',
};

export const TEXT_ADD_BIN_OR_PAN = {
  en_US: 'Add your card by PAN or BIN?',
  es_ES: '¿Agregar tu tarjeta a través de PAN o BIN?',
  pt_BR: 'Adicionar seu cartão através do PAN ou do BIN?',
};

export const MODAL_DELETE_TITLE = {
  en_US: 'Are you sure you want to remove the selected card?',
  es_ES: '¿Está seguro de que desea eliminar la tarjeta seleccionada?',
  pt_BR: 'Você está certo de que deseja remover o cartão selecionado?',
};

export const MODAL_DELETE_SUBTITLE = {
  en_US: 'This action cannot be reversed.',
  es_ES: 'No se podrá revertir esta acción.',
  pt_BR: 'Essa ação não pode ser revertida.',
};

export const MODAL_DELETE_YES = {
  en_US: 'Remove',
  es_ES: 'Eliminar',
  pt_BR: 'Remover',
};

export const MODAL_DELETE_NO = {
  en_US: 'No, keep the card',
  es_ES: 'No, guarda la tarjeta',
  pt_BR: 'Não, manter o cartão',
};

export const LOADING_REMOVING_CARD = {
  en_US: 'Removing the card...',
  es_ES: 'Eliminando la tarjeta...',
  pt_BR: 'Removendo o cartão...',
};

export const SUCCESS_CARD_REMOVED = {
  en_US: 'Card successfully removed.',
  es_ES: 'Tarjeta eliminada con éxito.',
  pt_BR: 'Cartão removido com sucesso.',
};
