import React, { useContext, useEffect, useState, useRef } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import creditCard from '../../assets/images/home/creditCard.svg';
import creditCardInfite from '../../assets/images/credit_card_infinite.png';
import styles from './UpgradeMe.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper.js';
import { productsAndBenefitsServices } from '../../services/index';
import ButtonLinkExternal from '../ButtonLinkExternal/ButtonLinkExternal';

const CARD_AFLUENT = 'INFINITE';

const checkInfinite = async (country, idiom) => {
  const { data } = await productsAndBenefitsServices.getProductsByCountry(
    country,
    idiom
  );
  const filterCardAfluent = data.find(product =>
    product.name.toUpperCase().includes(CARD_AFLUENT)
  );

  return filterCardAfluent;
};

const UpgradeMe = () => {
  const { translate, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const [infinite, setInfinite] = useState('');
  const isCanceled = useRef(null);

  const URL_IMAGE = infinite && isAfluent() ? creditCardInfite : creditCard;

  const styleForAfluent = infinite && isAfluent() ? styles.imgAfluent : '';

  useEffect(() => {
    isCanceled.current = false;

    async function checkInfiniteByCountry() {
      try {
        const isInfinite = await checkInfinite(country, idiomForApi());
        if (!isCanceled.current) {
          setInfinite(isInfinite);
        }
      } catch (error) {
        if (!isCanceled.current) {
          setInfinite('');
        }
      }
    }
    checkInfiniteByCountry();

    return () => {
      isCanceled.current = true;
    };
  }, [idiomForApi, country]);

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <div className={`${styles.image} ${styleForAfluent}`}>
        <img
          src={URL_IMAGE}
          title={
            infinite
              ? intl.CARD_BY_MODEL('Infinite')
              : intl.CARD_BY_MODEL('Signature')
          }
          alt=""
          data-testid="imageUpgradeMe"
        />
      </div>

      <div className={styles.content}>
        <p className={`${styles.text} ${styles[theme]}`}>
          {intl.TEXT_UPGRADE_ME}
        </p>
      </div>

      <div className={styles.upgradeBtn}>
        <ButtonLinkExternal
          type="borderGold"
          onClick={() => {
            dataLayerContentMenuTrack(
              'CTA Know more',
              'Know all Visa products'
            );
            window.open(
              intl.VISA_LINK(country),
              '_blank',
              'noopener,noreferrer'
            );
          }}
          ariaLabel={intl.BTN_UPGRADE_ME}
        >
          <span className={styles.btnText}>{intl.BTN_UPGRADE_ME}</span>
        </ButtonLinkExternal>
      </div>
    </div>
  );
};

export default UpgradeMe;
