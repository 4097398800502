import { useState, useEffect, useContext } from 'react';
import styles from './ClaimHistory.module.scss';
import { formatDate, legacyApiDateFormatWithTime } from '../../utils/date';
import { IntlContext } from '../../intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getIntlByStatus } from './intlByStatus';
import * as translations from './intl';
import { sanitize } from 'dompurify';
import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const ClaimHistory = ({ claim }) => {
  const [statuses, setStatuses] = useState([]);
  const { idiom, translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);

  const orderStatus = () => {
    const updateStatus = [];

    claim.statuses.forEach(status => {
      status.sub_statuses.forEach(subStatuses => {
        if (subStatuses.name && subStatuses.created_at) {
          const statusNameFormated = getIntlByStatus(subStatuses.name, intl);
          if (statusNameFormated) {
            updateStatus.push({
              name: statusNameFormated,
              created_at: subStatuses.created_at,
            });
          }
        }
      });
      const statusNameFormated = getIntlByStatus(status.name, intl);
      if (statusNameFormated) {
        updateStatus.push({
          name: statusNameFormated,
          created_at: status.created_at,
        });
      }
    });

    setStatuses(updateStatus);
  };

  useEffect(() => {
    orderStatus();
  }, []);

  const getStatusDayAndTime = status => {
    const dateObject = parse(
      status.created_at,
      legacyApiDateFormatWithTime,
      new Date()
    );
    const formattedDate = formatDate(
      zonedTimeToUtc(dateObject, 'UTC'),
      idiom,
      true
    );
    const [date, time] = formattedDate.split(' ');
    return [date, time];
  };

  return (
    <div className={styles.Container}>
      <ul>
        {statuses.map((status, index) => {
          const [date, time] = getStatusDayAndTime(status);

          const isFirst = index === 0;
          const isLast = statuses.length - 1 === index;

          return (
            <li
              key={status.name}
              className={`${isFirst && isLast ? styles.transparentLine : ''} ${
                styles[theme]
              }`}
            >
              <div className={styles.dateTime}>
                <p>{date}</p>
                <p>{time}</p>
              </div>
              <label
                className={`${styles.timeDot} ${styles[theme]}  ${
                  isLast ? styles.last : ''
                }`}
              ></label>
              <p
                className={styles.status}
                dangerouslySetInnerHTML={{ __html: sanitize(status.name) }}
              ></p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ClaimHistory;
