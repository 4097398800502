import React, { useContext } from 'react';
import styles from './CheckBox.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import blueAxaChecked from '../../assets/images/icon/check-box-checked-blueAXA.svg';
import afluentChecked from '../../assets/images/icon/afluent/check-box-checked.svg';
import masterChecked from '../../assets/images/icon/master/check-box-checked.svg';
import unchecked from '../../assets/images/check-box-unchecked.svg';
import Loading from '../Loading/Loading';

const CheckBox = props => {
  const {
    onClick,
    value,
    children,
    error,
    touched,
    name,
    dataTestid,
    css,
    disabled,
    isLoading,
    axa,
  } = props;
  const altImg = value
    ? `${dataTestid ? dataTestid : name}-checked`
    : `${dataTestid ? dataTestid : name}-unchecked`;
  const _onClick = () => (!disabled ? onClick(!value) : undefined);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const formikError = error && '1';

  const img = value
    ? isAfluent()
      ? afluentChecked
      : masterChecked
    : unchecked; // ternary operator nested

  const checkWithEnter = event => {
    if (event.key === 'Enter' && !disabled) {
      onClick(!value);
    }
  };

  return (
    <div className={`${styles.container} ${css}`}>
      <div
        formik-error={formikError}
        className={styles.input}
        id={name}
        aria-describedby={`error-${name}`}
        aria-invalid={error && touched}
        data-testid={dataTestid ? dataTestid : `checkbox-${name}`}
        type="checkbox"
        onClick={_onClick}
        tabIndex={0}
        onKeyDown={checkWithEnter}
      >
        {isLoading ? (
          <Loading checkBoxLoading scale="0.3" />
        ) : (
          <img src={value && axa ? blueAxaChecked : img} alt={altImg} />
        )}
        <label
          data-testid="label"
          htmlFor={name}
          className={`${value === true ? styles.checked : styles.unchecked} ${
            styles[theme]
          }`}
        >
          {children}
        </label>
      </div>

      {error && touched && (
        <label id={`error-${name}`} className={styles.errorLabel} role="alert">
          {error}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
