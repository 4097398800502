import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './MenuCards.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';

const MakeSubmenu = props => {
  const { menuId, selected, setSelected } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  switch (menuId) {
    case 'consumers':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <button
                className={`${styles.submenu} ${styles[themes]} ${
                  selected === intl.TEXT_SET_SELECTED_CREDIT
                    ? styles.submenuActive
                    : ''
                } ${selected}`}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_CREDIT)}
              >
                {intl.SUBMENU_CREDIT}
              </button>
            </li>

            <li>
              <button
                className={`${styles.submenu} ${styles[themes]} ${
                  selected === intl.TEXT_SET_SELECTED_DEBIT
                    ? styles.submenuActive
                    : ''
                } ${selected}`}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_DEBIT)}
              >
                {intl.SUBMENU_DEBIT}
              </button>
            </li>

            <li>
              <button
                className={`${styles.submenu} ${styles[themes]} ${
                  selected === intl.TEXT_SET_SELECTED_PREPAID
                    ? styles.submenuActive
                    : ''
                } ${selected}`}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_PREPAID)}
              >
                {intl.SUBMENU_PREPAID}
              </button>
            </li>
          </ul>
        </div>
      );
    case 'corporate':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <button
                className={`${styles.submenu} ${styles[themes]} ${
                  selected === intl.TEXT_SET_SELECTED_CREDIT
                    ? styles.submenuActive
                    : ''
                } ${selected}`}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_CREDIT)}
              >
                {intl.SUBMENU_CREDIT}
              </button>
            </li>

            <li>
              <button
                className={`${styles.submenu} ${styles[themes]} ${
                  selected === intl.TEXT_SET_SELECTED_DEBIT
                    ? styles.submenuActive
                    : ''
                } ${selected}`}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_DEBIT)}
              >
                {intl.SUBMENU_DEBIT}
              </button>
            </li>
            <li>
              <button
                className={`${styles.submenu} ${styles[themes]} ${
                  selected === intl.TEXT_SET_SELECTED_PREPAID
                    ? styles.submenuActive
                    : ''
                } ${selected}`}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_PREPAID)}
              >
                {intl.SUBMENU_PREPAID}
              </button>
            </li>
          </ul>
        </div>
      );
    default:
      return <></>;
  }
};

export default withRouter(MakeSubmenu);
