/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react';

import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getAdditionalCosts,
  getCovering,
  getPrice,
} from '../../../../../../utils/claimsAPI/TripUtils';

const ExpensesArea = props => {
  const { claimDetails } = props;

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const [additionalCosts, setAdditionalCosts] = useState({});
  const [insuranceCovering, setInsuranceCovering] = useState({});
  const [transportCovering, setTransportCovering] = useState({});
  const [totalAmountClaimed, setTotalAmountClaimed] = useState({});

  useEffect(() => {
    const additionalCosts =
      (claimDetails && claimDetails.additional_costs) || {};
    setAdditionalCosts(getAdditionalCosts(additionalCosts));

    const totalAmountClaimed =
      (claimDetails && claimDetails.total_amount_claimed) || {};
    setTotalAmountClaimed(totalAmountClaimed);

    const otherInsuranceCovering =
      (claimDetails && claimDetails.other_insurance_covering) || [];

    const transportCovering = getCovering(
      otherInsuranceCovering,
      'TRANSPORTATION COMPANY'
    );
    setTransportCovering(transportCovering);

    const insuranceCovering = getCovering(
      otherInsuranceCovering,
      'OTHER INSURANCE'
    );
    setInsuranceCovering(insuranceCovering);
  }, [claimDetails]);

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.TEXT_TICKET_AMOUNT}
          value={
            (additionalCosts.transport && additionalCosts.transport.price) ||
            '-'
          }
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.TEXT_ACCOMMODATION}
          value={
            (additionalCosts.accomodation &&
              additionalCosts.accomodation.price) ||
            '-'
          }
        />
      </div>
      {additionalCosts.accomodation &&
        additionalCosts.accomodation.amount_claimed && (
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.TEXT_AMOUNT_PAID_ACCOMMODATION}
              value={additionalCosts.accomodation.amount_claimed}
            />
          </div>
        )}
      {transportCovering && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue
            label={intl.TEXT_AMOUNT_PAID_TRANSPORTATION}
            value={transportCovering}
          />
        </div>
      )}
      {insuranceCovering && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue
            label={intl.TEXT_AMOUNT_OTHER_INSURANCE}
            value={insuranceCovering}
          />
        </div>
      )}
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_AMOUNT_CLAIMED}
          value={getPrice(totalAmountClaimed, '-')}
        />
      </div>
    </>
  );
};

export default ExpensesArea;
