/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import Loading from '../Loading/Loading';
import { StateContext } from '../StateContextParent/StateContextParent';
import { IntlContext } from '../../intl';
import { useHistory } from 'react-router-dom';
import { formatLinkWithIdiomAndCountry } from '../../utils/stringUtils';
import styles from './PartnerRedirect.module.scss';

const PartnerRedirect = props => {
  const { partner_name } = props.match.params;
  const { utils } = useContext(StateContext);
  const userIsLogged = utils.isUserLogged();
  const { idiom, country } = useContext(IntlContext);
  const redirectURIConciergePartner = process.env.REACT_APP_CONCIERGE_REDIRECT;
  const redirectURIPichinchaPartner =
    process.env.REACT_APP_CONCIERGE_PRICHINCHA_REDIRECT;
  const history = useHistory();

  const isConcierge = () => {
    return partner_name.toLowerCase() === 'concierge';
  };
  const isPichincha = () => {
    return partner_name.toLowerCase() === 'concierge-pichincha';
  };

  useEffect(() => {
    try {
      if (partner_name && userIsLogged) {
        const linkFormated = formatLinkWithIdiomAndCountry(
          isConcierge()
            ? redirectURIConciergePartner
            : isPichincha()
            ? redirectURIPichinchaPartner
            : '/dashboard',
          idiom,
          country
        );

        window.location.href = linkFormated;
      } else {
        history.push('/dashboard');
      }
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    </div>
  );
};

export default PartnerRedirect;
