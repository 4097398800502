/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styles from './Quote.module.scss';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import FormQuote from './FormQuote/FormQuote';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import { productsAndBenefitsServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { B2B2CContext } from '../../../components/B2B2CStateParent/B2B2CStateParent';
import ListPlans from './ListPlans/ListPlans';
import { withRouter } from 'react-router-dom';
import { CREATE_CONFIG_B2B2C } from '../CONFIG_B2B2C';
import { checkAccess } from '../utils';
import HeadingTitleAXA from '../../../components/HeadingTitleAXA/HeadingTitleAXA';
import StepClaim from '../../Claims/StepClaim/StepClaim';
import Button from '../../../components/Button/Button';
import { getQueryVariable } from '../../../utils/uri';

const Quote = ({ match, location, history }) => {
  const { params } = match;
  const { benefit_id } = params;
  const initialStep = Number(getQueryVariable('step'));
  const [step, setStep] = useState(initialStep || 1);
  const [benefitDetail, setBenefitDetail] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const CONFIG_B2B2C = CREATE_CONFIG_B2B2C(match);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { utils: utilsB2C, actions: actionsB2B2C } = useContext(B2B2CContext);
  const { utils } = useContext(StateContext);

  const email = utils.getEmail();
  const card = utils.getSelectedCard();
  const themes = getGlobalTheme();
  const plans = utilsB2C.getPlans();
  const formType = 'es_mx';
  const getbackUrl =
    location && location.state && location.state.prevPath
      ? location.state.prevPath
      : `/upsell-detail/${benefit_id}`;
  const step1 = step === 1;
  const step2 = step === 2;

  const stepNames = [
    intl.TEXT_STEP_1,
    intl.TEXT_STEP_2,
    intl.TEXT_STEP_3,
    intl.TEXT_STEP_4,
    intl.TEXT_STEP_5,
  ];

  const loadBenefitDetail = async () => {
    try {
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          utils.getBin(true),
          benefit_id,
          idiomForApi(),
          false
        );
      setBenefitDetail(response.data);
    } catch (error) {
      setBenefitDetail(null);
    }
  };

  useEffect(() => {
    loadBenefitDetail();
  }, []);

  useEffect(() => {
    checkAccess(card, CONFIG_B2B2C, benefit_id, history, email);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const nextStep = () => {
    history.push(`${CONFIG_B2B2C.quote.form.redirect}/${benefit_id}`);
  };

  return (
    <>
      <HeadingTitleAXA
        title={benefitDetail ? benefitDetail.name : ''}
        subtitle={intl.QUOTE_UPSELL_TITLE}
        description={intl.MODAL_QUOTE_IEMS}
      />

      <div className={`${styles.container} ${styles[themes]}`}>
        <SessionContainer>
          <StepClaim axa currentStep={step} stepNames={stepNames} />

          <div className={styles.content}>
            <div className={`${styles.box} ${styles[themes]}`}>
              {step1 && (
                <FormQuote
                  formType={formType}
                  backUrl={getbackUrl}
                  CONFIG_B2B2C={CONFIG_B2B2C}
                  setStep={setStep}
                />
              )}
              {step2 && (
                <>
                  <label className={`${styles.title} ${styles[themes]}`}>
                    {intl.TITLE_INCREASE_COVERAGE}
                  </label>
                  {plans &&
                    plans.ProductVISA &&
                    plans.ProductVISA.length > 0 && (
                      <ListPlans
                        formType={formType}
                        CONFIG_B2B2C={CONFIG_B2B2C}
                        setSelectedPlan={setSelectedPlan}
                        selectedPlan={selectedPlan}
                      />
                    )}
                  {(!plans || plans.isEmpty) && (
                    <div
                      className={`${styles.productNotFound}  ${styles[themes]}`}
                    >
                      {intl.PRODUCT_IS_EMPTY}
                    </div>
                  )}
                  <div className={`${styles.footer} ${styles[themes]}`}>
                    <div className={styles.divButton}>
                      <Button type="axaBlueBorder" onClick={() => setStep(1)}>
                        {intl.BTN_BACK}
                      </Button>
                    </div>
                    <div className={styles.divButton}>
                      <Button
                        type="axaBlue"
                        onClick={nextStep}
                        disabled={!selectedPlan}
                      >
                        {intl.BTN_NEXT}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </SessionContainer>
      </div>
    </>
  );
};

export default withRouter(Quote);
