import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const CameraDisabledIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M18.587 0 .01 18.132l1.413 1.38L20 1.38 18.587 0Zm-8.323 13.642-1.74 1.698c3.25 1.257 6.473-1.879 5.181-5.057l-1.739 1.697a1.966 1.966 0 0 1-1.702 1.662Zm7.727 2.954c0 .539-.448 1.453-1 1.453H6.24l-2 1.951h13.752c1.103 0 1.999-1.351 1.999-2.428V4.149L17.991 6.1v10.496ZM1.999 13.432 0 15.383V6.2C0 5.122.895 4.39 1.999 4.39h2.999V2.54c0-1.168.97-2.052 2.167-2.052h7.828l-2 1.951H7.997c-.553 0-1-.041-1 .499V4.39h4.755L9.752 6.341H3c-.553 0-1-.04-1 .499v6.592Z"
        fill={`${config_icon.primary}`}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CameraDisabledIcon;
