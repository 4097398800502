import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../BaggageDelay.module.scss';
import * as translations from './intl';
import { stringToBoolean } from '../../../../../../../../utils/stringUtils';
import sanitize from '../../../../../../../../utils/sanitize';
import RadioButtonError from '../../../../../../../../components/RadioButtonError/RadioButtonError';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const InsuranceArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;
  const valueCurrency = stringToBoolean(values.other_insurance_covering.checked)
    ? values.other_insurance_covering.currency
    : values.other_company_covering.currency;

  return (
    <div className={`row ${styles.incidentArea}`}>
      {/* Complaint against the transport service */}
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_SUBMITTED_COMPLAINT}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_SUBMITTED_COMPLAINT}
              describe={intl.DESCRIBE_PIR}
              defaultChecked={values.formal_complaint.is_submitted === 'true'}
              text={intl.TEXT_OPTION_YES}
              name="formal_complaint.is_submitted"
              dataTestid="InputFormalComplaintYes"
              value={true}
              onChange={e => {
                handleChange(e);
                setFieldValue('formal_complaint.submission_reason', '');
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_SUBMITTED_COMPLAINT}
              describe={intl.DESCRIBE_WHY}
              defaultChecked={values.formal_complaint.is_submitted === 'false'}
              text={intl.TEXT_OPTION_NO}
              name="formal_complaint.is_submitted"
              dataTestid="InputFormalComplaintNo"
              value={false}
              onChange={e => {
                handleChange(e);
                setFieldValue('formal_complaint.submission_reason', '');
              }}
            />
            <RadioButtonError
              touched={
                touched.formal_complaint &&
                touched.formal_complaint.is_submitted
              }
              error={
                errors.formal_complaint && errors.formal_complaint.is_submitted
              }
            />
          </div>
        </div>
      </div>

      <div
        className={`col-12 ${
          values.formal_complaint.is_submitted === 'true' ? '' : styles.none
        }`}
      >
        <Input
          label={intl.TEXT_PIR}
          name="formal_complaint.submission_reason"
          type="text"
          formikProps={props}
          maxLength="10"
        />
      </div>
      <div
        className={`col-12 ${
          values.formal_complaint.is_submitted === 'false' ? '' : styles.none
        }`}
        data-testid="DivComplaintReasonField"
      >
        <Input
          label={intl.TEXT_PLEASE_EXPLAIN}
          name="formal_complaint.submission_reason"
          type="text"
          formikProps={props}
          maxLength="255"
        />
      </div>

      {/* insurance that applies to the claimed benefit */}
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_INSURANCE_AREA}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_INSURANCE_AREA}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={values.other_company_covering.checked === 'true'}
              text={intl.TEXT_OPTION_YES}
              name="other_company_covering.checked"
              value={true}
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_INSURANCE_AREA}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={values.other_company_covering.checked === 'false'}
              text={intl.TEXT_OPTION_NO}
              name="other_company_covering.checked"
              value={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div
        className={`col-12 col-sm-12 col-md-6  ${
          values.other_company_covering.checked === 'true' ? '' : styles.none
        }`}
      >
        <Input
          label={intl.TEXT_INDICATE_AMOUNT}
          type="text"
          maskedMoney
          name="other_company_covering.value"
          tooltipText={intl.TEXT_DECIMAL_SYMBOL}
          value={values.other_company_covering.value}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={
            touched.other_company_covering &&
            touched.other_company_covering.value
          }
          error={
            errors.other_company_covering && errors.other_company_covering.value
          }
        />
      </div>

      <div
        className={`col-12 col-sm-12 col-md-6  ${
          stringToBoolean(values.other_company_covering.checked)
            ? ''
            : styles.none
        }`}
      >
        <Select
          dataTestid="selectCompanyCurrency"
          label={intl.LABEL_CURRENCY}
          name="other_company_covering.currency"
          value={valueCurrency}
          disabled
          onChange={handleChange}
          onBlur={handleBlur}
          touched={
            stringToBoolean(values.other_insurance_covering.checked) ||
            (touched.other_company_covering &&
              touched.other_company_covering.value)
          }
          error={
            errors.other_company_covering &&
            errors.other_company_covering.currency
          }
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
        />
      </div>
    </div>
  );
};

export default InsuranceArea;
