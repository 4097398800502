import React from 'react';
import ButtonWithIcon from '../../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';

const PassengerRemove = props => {
  const { values, setFieldValue, index, intl } = props;

  const removeAdditional = event => {
    event.preventDefault();

    const additional = [...values.additional_passengers];
    additional.splice(index, 1);
    setFieldValue('additional_passengers', additional);
  };

  const removeButton = () => {
    if (index === 0) return;
    return (
      <ButtonWithIcon
        type="blueWithoutBorder"
        dataTestid="BtnAddExpenseItem"
        onClick={removeAdditional}
        value={intl.BTN_REMOVE}
        isSVGComponent={true}
        img={<TrashIcon width={24} height={24} />}
      />
    );
  };

  return <div className="col-2">{removeButton()}</div>;
};

export default PassengerRemove;
