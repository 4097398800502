import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import Input from '../../../../../../../../components/Input/Input';
import { safeGet } from '../../../../../../../../utils/object';
import styles from '../ATM.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';
import * as translations from './intl';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const IncidentInsuranceArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              value={true}
              text={intl.TEXT_OPTION_YES}
              name="other_insurance_covering.checked"
              dataTestid="InputOtherInsuranceCoveringYes"
              onChange={handleChange}
              touched={safeGet(touched, 'other_insurance_covering.checked')}
              onBlur={handleBlur}
              error={safeGet(errors, 'other_insurance_covering.checked')}
              defaultChecked={
                values.other_insurance_covering.checked === 'true'
              }
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              value={false}
              text={intl.TEXT_OPTION_NO}
              name="other_insurance_covering.checked"
              dataTestid="InputOtherInsuranceCoveringNo"
              onChange={handleChange}
              touched={safeGet(touched, 'other_insurance_covering.checked')}
              onBlur={handleBlur}
              error={safeGet(errors, 'other_insurance_covering.checked')}
              defaultChecked={
                values.other_insurance_covering.checked === 'false'
              }
            />
          </div>
        </div>
      </div>
      {values.other_insurance_covering.checked === 'true' && (
        <>
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.OTHER_INSURANCE_AMOUNT_PAID}
              name="other_insurance_covering.amount.value"
              dataTestid="InputOtherInsuranceCoveringValue"
              type="text"
              formikProps={props}
              maskedMoney
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <Select
              label={intl.LABEL_CURRENCY}
              name="other_insurance_covering.amount.currency"
              value={values.incident.withdrawn_amount.currency}
              disabled
              dataTestid="InputOtherInsuranceCoveringCurrency"
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              formikProps={props}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default IncidentInsuranceArea;
