import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { CERTIFICATES } from '../../../utils/consts';
import InfoReimburstmentByReference from '../InfoReimburstmentByReference/InfoReimburstmentByReference';
import styles from './ListInfoReimburstmentByReference.module.scss';
import React, { useContext } from 'react';

const ListInfoReimburstmentByReference = ({
  objectTotalValueByClaim,
  objectTotalValueByCertificate,
  currencySymbol,
  currencyInitialsCode,
  type,
}) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return type === CERTIFICATES ? (
    <div
      className={`${styles[theme]}  ${styles.totalReimbursedDetails}`}
      data-testid={`TotalReimbursedDetails-${type}`}
    >
      <div className={styles.containerItensDetails}>
        {Object.entries(objectTotalValueByCertificate).map(
          ([key, value], index) => {
            return (
              <InfoReimburstmentByReference
                className={styles.itemDetails}
                key={key}
                reference={value.referenceName}
                totalSum={value.value}
                currencySymbol={currencySymbol}
                currencyInitialsCode={currencyInitialsCode}
                type={key}
              />
            );
          }
        )}
      </div>
    </div>
  ) : (
    <div
      className={`${styles[theme]}  ${styles.totalReimbursedDetails}`}
      data-testid={`TotalReimbursedDetails-${type}`}
    >
      <div className={styles.containerItensDetails}>
        {Object.entries(objectTotalValueByClaim).map(([key, value], index) => {
          return (
            <InfoReimburstmentByReference
              className={styles.itemDetails}
              key={key}
              reference={value.referenceName}
              totalSum={value.value}
              currencySymbol={currencySymbol}
              currencyInitialsCode={currencyInitialsCode}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListInfoReimburstmentByReference;
