import { useContext, useEffect, useRef } from 'react';
import { IntlContext } from '../../../intl/index';
import * as translations from './intl';
import { NotificationContext } from '../../NotificationContextParent/NotificationContextParent';
import { toast } from 'react-toastify';
import { showNotification } from '../utils';
import waitForPublicCard from '../waitForPublicCard';
import { PublicContext } from '../../PublicContextParent/PublicContextParent';

const NotificationIEMSCertificate = () => {
  const { translate, country } = useContext(IntlContext);
  const { state } = useContext(NotificationContext);
  const { productActive } = useContext(PublicContext);
  const intl = translate(translations);
  const toastId = useRef(null);
  const delayId = useRef(null);

  const isBrazil = country === 'BR';
  const isMexico = country === 'MX';

  const content = (
    <>
      <span
        role="status"
        dangerouslySetInnerHTML={{
          __html: intl.TEXT_BANNER_IEMS(productActive.product_id),
        }}
      />
    </>
  );

  useEffect(() => {
    clearTimeout(delayId.current);
    delayId.current = null;

    if (!state.toastNotification.IEMSCertificate && !isBrazil && !isMexico) {
      delayId.current = setTimeout(() => {
        showNotification(toastId, content);
      }, 2035);
    }

    return () => toast.dismiss(toastId.current);
  }, [country]);

  return null;
};

export default waitForPublicCard(NotificationIEMSCertificate);
