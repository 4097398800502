import React, { useContext } from 'react';

import moment from 'moment';
import styles from './IncidentArea.module.scss';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../../../intl/idioms';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';

const IncidentArea = props => {
  const { claimDetails } = props;
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const formatDate = date =>
    moment(date).format(idiom === pt_BR ? 'DD/MM/YYYY' : 'MM/DD/YYYY');

  const formatDateAndHour = date =>
    moment(date).format(
      idiom === pt_BR ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const date = incident && incident.date ? formatDate(incident.date) : '-';

  const description =
    incident && incident.description ? incident.description : '-';

  const programmedFlights =
    claimDetails && claimDetails.travel_information.programmed_flights[0]
      ? claimDetails.travel_information.programmed_flights[0]
      : {};

  const programmedFlights2 =
    claimDetails && claimDetails.travel_information.programmed_flights[1]
      ? claimDetails.travel_information.programmed_flights[1]
      : {};

  const departureDateAndTime =
    programmedFlights && programmedFlights.departure_time
      ? formatDateAndHour(programmedFlights.departure_time)
      : '-';

  const arrivalDateAndTime =
    programmedFlights && programmedFlights.arrival_time
      ? formatDateAndHour(programmedFlights.arrival_time)
      : '-';

  const departureProgrammedDateAndTime =
    programmedFlights2 && programmedFlights2.departure_programmed_time
      ? formatDateAndHour(programmedFlights2.departure_programmed_time)
      : '-';

  const arrivalProgrammedDateAndTime =
    programmedFlights2 && programmedFlights2.arrival_programmed_time
      ? formatDateAndHour(programmedFlights2.arrival_programmed_time)
      : '-';

  const agencyName =
    claimDetails && claimDetails.travel_information.agency_information.name
      ? claimDetails.travel_information.agency_information.name
      : '-';

  return (
    <>
      <div className="col-12" data-testid="IncidentAreaPrior">
        <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
          {intl.PRIOR_MISSED_CONNECTION}
        </h3>
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.DEPARTURE_DATE}
          value={departureDateAndTime}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.ARRIVAL_DATE} value={arrivalDateAndTime} />
      </div>
      <div className="col-12">
        <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
          {intl.MISSED_CONNECTION_PORTION}
        </h3>
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.DEPARTURE_DATE}
          value={departureProgrammedDateAndTime}
        />
      </div>
      <div className="col-sm-12 col-md-8">
        <InfoLabelValue
          label={intl.ARRIVAL_DATE}
          value={arrivalProgrammedDateAndTime}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.MISSED_CONNECTION_AIRLINE}
          value={agencyName}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.MISSED_CONNECTION_DATE} value={date} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.DESCRIBE_REASONS} value={description} />
      </div>
    </>
  );
};

export default IncidentArea;
