import { LACLinkByCode } from '../../intl/lacLinkByCode';

export const TEXT_BENEFITS = {
  en_US: 'Benefits',
  es_ES: 'Beneficios',
  pt_BR: 'Benefícios',
};

export const TEXT_OFFERS_PROMOTIONS = {
  en_US: 'Offers and promotions',
  es_ES: 'Ofertas y promociones',
  pt_BR: 'Ofertas e promoções',
};

export const TEXT_CLAIMS = {
  en_US: 'Claims',
  es_ES: 'Reclamos',
  pt_BR: 'Sinistros',
};

export const TEXT_CERTIFICATES = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Bilhetes de seguro',
};

export const TEXT_ESPECIAL_BENEFITS = {
  en_US: 'Subscriptions',
  es_ES: 'Suscripciones',
  pt_BR: 'Assinaturas',
};

export const TEXT_HELP = {
  en_US: 'Help',
  es_ES: 'Ayuda',
  pt_BR: 'Ajuda',
};

export const TEXT_AGENT_CTA = {
  en_US: 'Agente CTA',
  es_ES: 'Agente CTA',
  pt_BR: 'Agente CTA',
};

export const LINK_AGENT_CTA = {
  en_US: 'https://visa-gateway.my.site.com/zcta/home/home.jsp',
  es_ES: 'https://visa-gateway.my.site.com/zcta/home/home.jsp',
  pt_BR: 'https://visa-gateway.my.site.com/zcta/home/home.jsp',
};

export const TEXT_VISA_BRAZIL = {
  en_US: '',
  es_ES: '',
  pt_BR: 'Visa do Brasil - Representante da AIG Seguros',
};

export const LINK_VISA_BRAZIL = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'https://www.aig.com.br/content/dam/aig/lac/brazil/documents/brochure/1804-info-rep-visa.pdf',
};

export const TEXT_TERMS_SERVICES = {
  en_US: 'Terms of Service',
  es_ES: 'Términos de Servicio',
  pt_BR: 'Termos de Serviço',
};

export const LINK_TERMS_SERVICES = {
  en_US: 'https://usa.visa.com/legal.html',
  es_ES: 'https://www.visa.com.mx/legal.html',
  pt_BR: 'https://www.visa.com.br/termos-de-uso.html',
};

export const TEXT_PRIVACY_POLICY = {
  en_US: 'Privacy Policy',
  es_ES: 'Política de Privacidad',
  pt_BR: 'Política de Privacidade',
};

export const LINK_PRIVACY_POLICY = {
  en_US: 'https://visa.com/privacy',
  es_ES: 'https://visa.com/privacy',
  pt_BR: 'https://visa.com/privacy',
};

export const TEXT_PRIVACY_POLICY_AIG = {
  en_US: 'Privacy Policy - AIG Insurer',
  es_ES: 'Política de privacidad - Aseguradora AIG',
  pt_BR: 'Política de Privacidade - Seguradora AIG',
};

export const LINK_PRIVACY_POLICY_AIG = {
  en_US: 'https://www.aig.com/privacy-policy',
  es_ES: 'https://www.aig.com/privacy-policy',
  pt_BR: 'https://www.aig.com.br/privacidade',
};

export const TEXT_COOKIE_POLICY = {
  en_US: 'Cookie Preferences',
  es_ES: 'Preferencias de Cookies',
  pt_BR: 'Preferências de Cookies',
};

export const LINK_COOKIE_POLICY = {
  en_US: 'https://policy.cookiereports.com/6747c5d5-en-gb.html',
  es_ES: 'https://policy.cookiereports.com/6747c5d5-es.html',
  pt_BR: 'https://policy.cookiereports.com/6747c5d5-pt.html',
};

export const TEXT_COPYRIGHT = {
  en_US: '© Copyright 1996-2021 Visa. All rights reserved.',
  es_ES: '© Copyright 1996-2021 Visa. Todos los derechos reservados.',
  pt_BR: '© Copyright 1996-2021 Visa. Todos os direitos reservados.',
};

export const TEXT_VISA_LINK = {
  en_US: 'visa.com',
  es_ES: 'visa.com',
  pt_BR: 'visa.com',
};

export const VISA_LINK = {
  en_US: code => {
    const url = LACLinkByCode[code]
      ? LACLinkByCode[code]
      : 'https://www.visa.com';

    return url;
  },
  es_ES: code => {
    const url = LACLinkByCode[code]
      ? LACLinkByCode[code]
      : 'https://www.visa.com.mx';

    return url;
  },
  pt_BR: code => 'https://www.visa.com.br',
};

export const RIGHT_ARROW = {
  en_US: 'right arrow',
  es_ES: 'flecha hacia la derecha',
  pt_BR: 'seta para a direita',
};
