import React, { useEffect, useContext } from 'react';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from '../scheduleList.module.scss';

const setSelectedSchedule = (schedule, hour) => {
  return schedule.map(list => {
    list.selected = false;
    if (list.time === hour) {
      list.selected = true;
    }
    return list;
  });
};

const ScheduleListButtonNAL = props => {
  const { schedule, setSchedule, setFieldValue, setFieldTouched } = props;

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const getScrollPosition = schedule.find(item => {
    return item.is_available;
  });
  const getIdtoScrollPosition = (timeList, timeEnable) => {
    if (!timeEnable) return 'timeList';
    return timeList === timeEnable.time ? 'getPositionScroll' : '';
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById('getPositionScroll')) {
        const buttonTop =
          document.getElementById('getPositionScroll').offsetTop;
        const divBoxTop = document.getElementById('divBoxSchedule').offsetTop;
        document.getElementById('divBoxSchedule').scrollTop =
          buttonTop - divBoxTop;
      }
    }, 100);
  }, []);
  return (
    <>
      {schedule.map((list, index) => {
        return (
          <span key={`scheduleListKey-${index}`}>
            <button
              className={`${
                list.selected
                  ? `${styles.buttonSchedule} ${styles.scheduleSelected}`
                  : styles.buttonSchedule
              } ${styles[theme]}`}
              data-testid={`buttonScheduleList-${index}`}
              id={getIdtoScrollPosition(list.time, getScrollPosition)}
              disabled={!list.is_available}
              type="button"
              onClick={() => {
                const newListSchedule = setSelectedSchedule(
                  schedule,
                  list.time
                );
                setSchedule(newListSchedule);
                setFieldTouched('appointment.time');
                setFieldValue('appointment.time', list.time);
                setFieldValue('appointment.validTime', list.server_time);
              }}
            >
              {list.time.replace(/:[^:]*$/, '')}
            </button>
          </span>
        );
      })}
    </>
  );
};

export default ScheduleListButtonNAL;
