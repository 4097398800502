import React, { useContext, useEffect, useRef } from 'react';
import styles from './NotificationB2B2C.module.scss';
import { IntlContext } from '../../../intl';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PublicContext } from '../../PublicContextParent/PublicContextParent';
import * as translations from './intl';

const NotificationB2B2C = () => {
  const { translate, country } = useContext(IntlContext);
  const { productActive } = useContext(PublicContext);
  const toastId = useRef(null);
  const intl = translate(translations);
  const isMX = country === 'MX';

  const content = (
    <div role="status" className={styles.content}>
      <h4 className={styles.title}>
        <strong>{intl.IMPROVE_YOUR_COVERAGE}</strong>
      </h4>
      <p>{intl.DESCRIPTION_BENEFIT}</p>
      <strong>
        <u>
          <Link to={`/upsell-preview/EMC_Emergency_Medical_Assistance`}>
            {intl.READ_MORE}
          </Link>
        </u>
      </strong>
    </div>
  );

  const verifyCard = () => {
    if (productActive) {
      const cardsEnable = ['Infinite', 'Signature', 'Platinum'];
      return cardsEnable.includes(productActive.name);
    }
    return false;
  };

  const showNotification = () => {
    toastId.current = toast.info(content, {
      className: 'gold',
      delay: 5,
    });
  };

  useEffect(() => {
    if (isMX && verifyCard()) {
      if (!toast.isActive(toastId.current)) {
        showNotification();
      }
    }

    return () => toast.dismiss(toastId.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productActive]);

  return null;
};

export default NotificationB2B2C;
