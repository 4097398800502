export const MENU_ACCOUNT = {
  en_US: 'Your account',
  es_ES: 'Tu cuenta',
  pt_BR: 'Sua conta',
};
export const MENU_YOUR_CERTIFICATES = {
  en_US: 'Your certificates',
  es_ES: 'Tus certificados',
  pt_BR: 'Seus bilhetes',
};
export const MENU_YOUR_CLAIMS = {
  en_US: 'Your claims',
  es_ES: 'Tus reclamos',
  pt_BR: 'Seus sinistros',
};
export const MENU_SETTINGS = {
  en_US: 'Settings',
  es_ES: 'Configuración',
  pt_BR: 'Configuração',
};
export const MENU_HELP = {
  en_US: 'Help',
  es_ES: 'Ayuda',
  pt_BR: 'Ajuda',
};

export const MENU_NOTIFICATIONS = {
  en_US: 'Notifications',
  es_ES: 'Notificaciones',
  pt_BR: 'Notificações',
};

export const SUBMENU_SAVING = {
  en_US: `Your Savings`,
  pt_BR: `Suas Poupanças`,
  es_ES: `Tus Ahorros`,
};

export const ACCOUNT = {
  en_US: 'Account',
  es_ES: 'Cuenta',
  pt_BR: 'Conta',
};

export const CERTIFICATES = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Certificados',
};

export const CLAIMS = {
  en_US: 'Claims',
  es_ES: 'Reclamaciones',
  pt_BR: 'Sinistros',
};

export const MONETIZATION = {
  en_US: 'Monetization',
  es_ES: 'Monetización',
  pt_BR: 'Monetização',
};

export const NOTIFICATIONS = {
  en_US: 'Notifications',
  es_ES: 'Notificaciones',
  pt_BR: 'Notificações',
};

export const SETTINGS = {
  en_US: 'Settings',
  es_ES: 'Configuración',
  pt_BR: 'Configurações',
};

export const SUBMENU_VOUCHERS = {
  en_US: `Your Vouchers`,
  pt_BR: `Seus Vouchers`,
  es_ES: `Tus Vouchers`,
};
