/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext, Fragment } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { formatLinkWithIdiomAndCountry } from '../../../../utils/stringUtils';
import { certificatesServices, claimsServices } from '../../../../services';
import { StateContext } from '../../../StateContextParent/StateContextParent';
import { Link, withRouter } from 'react-router-dom';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Button from '../../../Button/Button';
import styles from './ButtonProfile.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { verifyAcessRenderButton } from '../../../../utils/verifyAccess';
import { checkAcessCross } from '../../../../utils/verifyAccessCrosselling';
import { removeAxaPrefixFromExternalReference } from '../../../../utils/B2C/string';
import WhatsappIcon from '../../../../assets/icons/WhatsappIcon';
import { ENABLE_TRIP_ASSISTANCE } from '../../../../utils/environments_variables';
import ButtonLinkExternal from '../../../ButtonLinkExternal/ButtonLinkExternal';
import { CINEPOLIS_GOLD } from '../../../../utils/benefits';
import { trip_assistance } from '../../../../utils/benefits';

const TELECONSULTATION = 'Teleconsultation';
const EXECUTIVE_TRANSPORTATION = 'Executive_Transportation';
const CONCIERGE = 'Concierge';
const ANDROID = 'android';
const IOS = 'ios';

function getDescriptionAndPdfFormated(benefit, idiom, country) {
  let pdf_url = '';
  let pdf_url_certificate = '';
  let pdf_url_claim = '';
  let redeem = '';
  for (let i = 0; i < benefit.documents.length; i++) {
    const pdf = benefit.documents[i];
    switch (pdf.name) {
      case 'pdf_url':
        pdf_url = pdf.content_url ? pdf.content_url : '';
        break;
      case 'pdf_url_certificate':
        pdf_url_certificate = pdf.content_url ? pdf.content_url : '';
        break;
      case 'pdf_url_claim':
        pdf_url_claim = pdf.content_url ? pdf.content_url : '';
        break;
      default:
        break;
    }
  }

  const link =
    benefit.redeem_info &&
    benefit.redeem_info.redeem &&
    benefit.redeem_info.redeem &&
    benefit.redeem_info.redeem.additional_buttons;

  redeem = formatLinkWithIdiomAndCountry(link, idiom, country);
  redeem =
    redeem !== '' &&
    redeem.split(';').map(linksReddem => linksReddem.split(' - '));

  return { pdf_url, pdf_url_certificate, pdf_url_claim, redeem };
}

function IsValidButtons(external_reference) {
  return (
    external_reference !== TELECONSULTATION && external_reference !== CONCIERGE
  );
}

const loadCertificateClaim = async (
  external_reference,
  setListCertificate,
  setListClaim,
  idiom,
  binOrPan,
  setLoading
) => {
  try {
    const listCertificateTypes =
      await certificatesServices.listCertificateTypesByBinNumber(
        binOrPan,
        idiom
      );
    const filteredCertificate = matchListCertificate(
      listCertificateTypes,
      external_reference
    );
    setListCertificate(filteredCertificate);
  } catch (error) {
    console.log(error);
  }

  try {
    const listClaimTypes = await claimsServices.getClaimTypes(binOrPan, idiom);
    const filteredClaim = matchListClaim(listClaimTypes, external_reference);
    setListClaim(filteredClaim);
    setLoading(false);
  } catch (error) {
    console.log(error);
  }
};

const matchListCertificate = ({ data }, external_reference) => {
  const listFiltered = data.find(res =>
    res.covered_benefits_references.includes(external_reference)
  );
  return listFiltered;
};

const matchListClaim = ({ data }, external_reference) => {
  const listFiltered = data.find(res =>
    res.external_reference.includes(external_reference)
  );
  return listFiltered;
};

const ButtonBenefits = props => {
  const {
    benefit,
    idiom,
    country,
    history,
    isDetail,
    css,
    linksinBlueBox = false,
    withTextUpsell,
    onlyUpsell,
    cardHasVMO,
  } = props;
  const [listCertificate, setListCertificate] = useState('');
  const [listClaim, setListClaim] = useState('');
  const [checkAccessUpsel, setCheckAccessUpsel] = useState(false);
  const [checkAccessCross, setCheckAccessCross] = useState(false);
  const [loading, setLoading] = useState('');

  const { utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const theme = getGlobalTheme();
  const binOrPan = utils.getBin(true);
  const buttonType = isDetail ? 'borderGold' : 'gold';
  const card = utils.getSelectedCard();
  const email = utils.getEmail();

  const intl = translate(translations);

  const verifyAccessUpsel = async () => {
    const condition = await verifyAcessRenderButton(benefit, card, email);
    setCheckAccessUpsel(condition);
  };

  const verifyAccessCross = () => {
    const condition = checkAcessCross(benefit, card, email);
    setCheckAccessCross(condition);
  };

  useEffect(() => {
    loadCertificateClaim(
      benefit.external_reference,
      setListCertificate,
      setListClaim,
      idiom,
      binOrPan,
      setLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    verifyAccessUpsel();
    verifyAccessCross();
  }, []);

  let { pdf_url_certificate, pdf_url_claim, redeem } =
    getDescriptionAndPdfFormated(benefit, idiom, country);

  return (
    <>
      {!loading && (
        <>
          <div
            className={`${styles.boxButtons} ${
              isDetail ? styles.isDetail : styles.default
            } ${css ? styles[css] : ''}`}
          >
            {!isDetail && (
              <div className={styles.wrapperButton}>
                <Button
                  id={`buttonBenefit-ViewMore-${benefit.benefit_id}`}
                  type="gold"
                  onClick={() =>
                    history.push(`/your-benefits/${benefit.benefit_id}`)
                  }
                >
                  {intl.VIEW_MORE}
                </Button>
              </div>
            )}
            {redeem &&
              !isMobile &&
              redeem.map((redeemResult, i, array) => {
                const last = i === array.length - 1;
                const penultimate = i === array.length - 2;
                const isVMO = benefit.external_reference === TELECONSULTATION;
                const isExecutiveTransportation =
                  benefit.external_reference === EXECUTIVE_TRANSPORTATION;
                const isCinepolisGold = benefit.benefit_id === CINEPOLIS_GOLD;

                // const shouldOpenSameTab =
                //   isVMO || isExecutiveTransportation || isCinepolisGold;
                const shouldOpenSameTab = isVMO || isCinepolisGold;
                const isWhatsapp = redeemResult[2]
                  ? redeemResult[2].toLowerCase() === 'whatsapp'
                  : false;

                return (
                  <Fragment key={i}>
                    <a
                      className={styles[theme]}
                      href={redeemResult[1]}
                      data-testid={`benefitDetailContentRedeem-${benefit.benefit_id}`}
                      target={shouldOpenSameTab ? undefined : '_blank'}
                      rel={
                        shouldOpenSameTab ? undefined : 'noopener noreferrer'
                      }
                      aria-label={`${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )} - ${intl.NEW_TAB}`}
                      style={{
                        textDecoration: `${
                          shouldOpenSameTab ? 'none' : 'underline'
                        }`,
                      }}
                    >
                      {linksinBlueBox ? (
                        <>
                          {` ${redeemResult[0].replace(
                            /\{{benefit_name}}/g,
                            benefit.name
                          )}`}
                        </>
                      ) : (
                        <ButtonLinkExternal
                          id={`buttonBenefit-redeem-${benefit.benefit_id}_${i}`}
                          type={
                            isWhatsapp
                              ? 'whatsapp'
                              : i === array.length - 1 && isDetail
                              ? 'borderGold'
                              : 'gold'
                          }
                          tabIndex={-1}
                          noIcon={shouldOpenSameTab}
                        >
                          {isWhatsapp && (
                            <WhatsappIcon width={20} height={20} />
                          )}
                          {` ${redeemResult[0].replace(
                            /\{{benefit_name}}/g,
                            benefit.name
                          )}`}
                        </ButtonLinkExternal>
                      )}
                    </a>
                    {linksinBlueBox && !last && !penultimate && (
                      <hr className={`${styles.divider} ${styles[theme]}`} />
                    )}
                    {linksinBlueBox && penultimate && (
                      <hr className={`${styles.divider} ${styles[theme]}`} />
                    )}
                  </Fragment>
                );
              })}

            {redeem &&
              isMobile &&
              redeem.map((redeemResult, i) => {
                const linkName = redeemResult[0].toLowerCase();
                const haveAndroid = linkName.includes(ANDROID);
                const haveIos = linkName.includes(IOS);
                const haveNoMobile = !haveAndroid && !haveIos;

                if (benefit.external_reference === 'Concierge') {
                  return (
                    <a
                      href={redeemResult[1]}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={i}
                      aria-label={`${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )} - ${intl.NEW_TAB}`}
                    >
                      <ButtonLinkExternal
                        id={`buttonBenefit-Concierge-redeem-${benefit.benefit_id}`}
                        type={buttonType}
                        style={{ textDecoration: 'underline' }}
                        tabIndex={-1}
                      >
                        {` ${redeemResult[0].replace(
                          /\{{benefit_name}}/g,
                          benefit.name
                        )}`}
                      </ButtonLinkExternal>
                    </a>
                  );
                }
                if (isAndroid && haveAndroid) {
                  return (
                    <a
                      href={redeemResult[1]}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={i}
                      style={{ textDecoration: 'underline' }}
                      aria-label={`${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )} - ${intl.NEW_TAB}`}
                    >
                      {linksinBlueBox ? (
                        <>{` ${redeemResult[0].replace(
                          /\{{benefit_name}}/g,
                          benefit.name
                        )}`}</>
                      ) : (
                        <ButtonLinkExternal
                          id={`buttonBenefit-android-redeem-${benefit.benefit_id}`}
                          type={buttonType}
                          style={{ textDecoration: 'underline' }}
                          tabIndex={-1}
                        >
                          {` ${redeemResult[0].replace(
                            /\{{benefit_name}}/g,
                            benefit.name
                          )}`}
                        </ButtonLinkExternal>
                      )}
                    </a>
                  );
                }
                if (isIOS && haveIos) {
                  return (
                    <a
                      href={redeemResult[1]}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={i}
                      aria-label={`${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )} - ${intl.NEW_TAB}`}
                    >
                      <ButtonLinkExternal
                        id={`buttonBenefit-ios-redeem-${benefit.benefit_id}`}
                        type={buttonType}
                        style={{ textDecoration: 'underline' }}
                        tabIndex={-1}
                      >
                        {` ${redeemResult[0].replace(
                          /\{{benefit_name}}/g,
                          benefit.name
                        )}`}
                      </ButtonLinkExternal>
                    </a>
                  );
                }
                if (haveNoMobile) {
                  return (
                    <a
                      href={redeemResult[1]}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={i}
                      aria-label={` ${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )} - ${intl.NEW_TAB}`}
                    >
                      <ButtonLinkExternal
                        id={`buttonBenefit-noMobile-redeem-${benefit.benefit_id}`}
                        type={buttonType}
                        style={{ textDecoration: 'underline' }}
                        tabIndex={-1}
                      >
                        {` ${redeemResult[0].replace(
                          /\{{benefit_name}}/g,
                          benefit.name
                        )}`}
                      </ButtonLinkExternal>
                    </a>
                  );
                }
              })}

            {pdf_url_certificate &&
              pdf_url_certificate !== '' &&
              listCertificate &&
              listCertificate.code &&
              IsValidButtons(benefit.external_reference) &&
              !onlyUpsell && (
                <Link
                  to={`/certificate/${listCertificate && listCertificate.code}`}
                >
                  <Button
                    id={`buttonBenefit-certificatePDF-${benefit.benefit_id}`}
                    type="gold"
                  >
                    {intl.GENERATE_CERTIFICATE}
                  </Button>
                </Link>
              )}
            {pdf_url_claim &&
              pdf_url_claim !== '' &&
              listClaim &&
              IsValidButtons(benefit.external_reference) &&
              !onlyUpsell && (
                <Link
                  to={`/claim-type/${benefit.external_reference}/generate`}
                  data-testid={`benefitDetailContentPdfClaim-${benefit.benefit_id}`}
                >
                  <Button
                    id={`buttonBenefit-claimPDF-${benefit.benefit_id}`}
                    type={buttonType}
                  >
                    {intl.GENERATE_CLAIM}
                  </Button>
                </Link>
              )}

            {benefit.external_reference ===
              'EMC_Emergency_Medical_Assistance' &&
              isDetail &&
              cardHasVMO && (
                <Link to="/your-benefits/Teleconsultation">
                  <Button
                    id={`buttonBenefit-IEMS-${benefit.benefit_id}`}
                    type={buttonType}
                  >
                    {intl.ACCESS_VISA_ONLINE_MEDIC}
                  </Button>
                </Link>
              )}

            {benefit.external_reference === 'Nurse_Advice_Line' && (
              <>
                <Link
                  className={styles[theme]}
                  to="/claim-type/NurseAdviceLine"
                  data-testid={`benefitDetailContentPdfCertificateNurseAdviceLine-${benefit.benefit_id}`}
                >
                  {linksinBlueBox ? (
                    intl.ACCESS_VISA_NAL
                  ) : (
                    <Button
                      id={`buttonBenefit-nurse-${benefit.benefit_id}`}
                      type={buttonType}
                    >
                      {intl.ACCESS_VISA_NAL}
                    </Button>
                  )}
                </Link>
                {linksinBlueBox && (
                  <hr className={`${styles.divider} ${styles[theme]}`} />
                )}
                <Link
                  className={styles[theme]}
                  to="/nurse-advice-line/previous-appointments"
                  data-testid={`benefitDetailContentNALConsultAppointment-${benefit.benefit_id}`}
                >
                  {linksinBlueBox ? (
                    intl.NAL_CONSULT_APPOINTMENT
                  ) : (
                    <Button type={buttonType}>
                      {intl.NAL_CONSULT_APPOINTMENT}
                    </Button>
                  )}
                </Link>
              </>
            )}
            {benefit.external_reference === trip_assistance &&
              ENABLE_TRIP_ASSISTANCE && (
                <>
                  <Link
                    className={styles[theme]}
                    to={`/travel-kit/create/${benefit.benefit_id}`}
                  >
                    {linksinBlueBox ? (
                      intl.CREATE_TRIP_ASSISTANCE
                    ) : (
                      <Button
                        id={`buttonBenefit-travel-kit-create-${benefit.benefit_id}`}
                        type={buttonType}
                      >
                        {intl.CREATE_TRIP_ASSISTANCE}
                      </Button>
                    )}
                  </Link>
                  {linksinBlueBox && (
                    <hr className={`${styles.divider} ${styles[theme]}`} />
                  )}
                  <Link className={styles[theme]} to="/travel-kit/">
                    {linksinBlueBox ? (
                      intl.CONSULT_TRIP_ASSISTANCE
                    ) : (
                      <Button
                        id={`buttonBenefit-my-travel-kit-${benefit.benefit_id}`}
                        type={buttonType}
                      >
                        {intl.CONSULT_TRIP_ASSISTANCE}
                      </Button>
                    )}
                  </Link>
                </>
              )}
          </div>
          {checkAccessUpsel && (
            <>
              {withTextUpsell && (
                <>
                  <h5 className={styles.upsellAreaTitle}>
                    {intl.TITLE_DESCRIPTION_UPSELL}
                  </h5>
                  <span>{intl.CONTENT_DESCRIPTION_UPSELL}</span>
                </>
              )}
              <Link
                className={`${styles[theme]} ${styles.axaButton}`}
                to={{
                  pathname: `/upsell-detail/${benefit.external_reference}`,
                  state: { prevPath: history.location.pathname },
                }}
              >
                {linksinBlueBox ? (
                  intl.UPDATE_BENEFIT
                ) : (
                  <Button
                    id={`buttonBenefit-upsell-${benefit.benefit_id}`}
                    type="axaBlue"
                  >
                    {intl.UPDATE_BENEFIT}
                  </Button>
                )}
              </Link>
            </>
          )}
          {checkAccessCross && linksinBlueBox && (
            <Link
              className={`${styles[theme]} ${styles.axaButton}`}
              to={{
                pathname: `/cross-sell-detail/${removeAxaPrefixFromExternalReference(
                  benefit.external_reference
                )}`,
                state: { prevPath: history.location.pathname },
              }}
            >
              {intl.BUTTON_PURCHASE}
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(ButtonBenefits);
