import React, { useContext, useEffect, useState } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getIncidentDate,
  getIncidentDescription,
  getTotalAmountClaimed,
} from '../../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import PurchasedItem from './PurchasedItem/PurchasedItem';

const IncidentArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const [purchasedItems, setPurchasedItems] = useState([]);
  const [incident, setIncident] = useState({});
  const [totalAmountClaimed, setTotalAmountClaimed] = useState({});

  useEffect(() => {
    const purchasedItems =
      (claimDetails && claimDetails.purchase_protection_items) || [];
    setPurchasedItems(purchasedItems);

    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const totalAmountClaimed =
      (claimDetails && claimDetails.total_amount_claimed) || {};
    setTotalAmountClaimed(totalAmountClaimed);
  }, [claimDetails]);

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DATE}
          value={getIncidentDate(incident, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_DESCRIPTION}
          value={getIncidentDescription(incident)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_AMOUNT_CLAIMED}
          value={getTotalAmountClaimed(totalAmountClaimed)}
        />
      </div>
      {purchasedItems.map((item, index) => (
        <PurchasedItem key={index} item={item} itemNumber={index + 1} />
      ))}
    </>
  );
};

export default IncidentArea;
