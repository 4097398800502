import { useContext } from 'react';
import moment from 'moment';
import RecentItem from '../RecentItem/RecentItem';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { CONFIG_CERTIFICATES } from './configCertificates';
import {
  travel_insurance,
  certificate_seguro_compra,
  billete_auto_rental_insurance,
  certificate_extended_warranty,
} from '../../utils/certificates_name';
import { pt_BR, en_US } from '../../intl/idioms';
import styles from './ListCertificate.module.scss';
import { formatNumber } from '../../utils/stringUtils';
import Button from '../Button/Button';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { formatCustomDate } from '../../utils/date';
import { getCorrectTranslateByCountry } from '../../utils/country';
import TotalReimbursed from '../TotalReimbursed/TotalReimbursed';
import { CERTIFICATES } from '../../utils/consts';
import { REACT_APP_TOTAL_REIMBURSEMENT } from '../../utils/environments_variables';

const getCountryByIdiom = (country, idiom) => {
  const findCountry = getCorrectTranslateByCountry(country);
  if (findCountry) {
    return findCountry[idiom];
  }

  return '';
};

const ListCertificates = props => {
  const {
    certificates,
    showButtonClaim,
    numberToFilter = 2,
    isToSelectCertificate = false,
    setCertificate = null,
    disableButtonSelect = false,
    showVerticalDivider = true,
    profile,
    isGenerateClaim,
    isTripAssistance = false,
    confirmTripAssistanceCertificate = null,
  } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);
  const dateFormat = idiom === en_US ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  const isBrasil = idiom === pt_BR;
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const ConfigCertificates = CONFIG_CERTIFICATES(
    intl,
    isAfluent(),
    isTripAssistance,
    idiom
  );

  const handleMakeDetails = data => {
    switch (data.certificate_type.toLowerCase()) {
      case certificate_extended_warranty:
        return (
          <div className={`${styles.showDetails} ${styles[themes]}`}>
            <div>
              <div>
                <h6>{intl.TEXT_TICKET_NUMBER}</h6>
                <h5>{data.ticket_number}</h5>
              </div>
              <div>
                <h6>{intl.TEXT_CREATION_DATE}</h6>
                <h5>
                  {formatCustomDate(
                    data.created_at,
                    'YYYY-MM-DD',
                    idiom,
                    country
                  )}
                </h5>
              </div>
              <div>
                <h6>{intl.TEXT_ORIGINAL_WARRANTY_PERIOD}</h6>
                <h5>{`${intl.TEXT_DATE_FROM} ${formatCustomDate(
                  data.valid_from,
                  'YYYY-MM-DD',
                  idiom,
                  country
                )} ${intl.TEXT_DATE_TO} ${formatCustomDate(
                  data.valid_until,
                  'YYYY-MM-DD',
                  idiom,
                  country
                )}`}</h5>
              </div>
            </div>
            {!isBrasil && (
              <div>
                <div>
                  <h6>{intl.TEXT_PURCHASE_BRAND}</h6>
                  <h5>
                    {data.appliance_details && data.appliance_details.brand
                      ? data.appliance_details.brand
                      : '-'}
                  </h5>
                </div>
                <div>
                  <h6>{intl.TEXT_PURCHASE_AMOUNT}</h6>
                  <h5>
                    {data.appliance_details &&
                    data.appliance_details.cost &&
                    data.appliance_details.cost.value
                      ? data.appliance_details.cost.value
                      : '-'}
                    {data &&
                    data.appliance_details &&
                    data.appliance_details.cost &&
                    data.appliance_details.cost.currencyValue
                      ? `(${data.appliance_details.cost.currencyValue})`
                      : ''}
                  </h5>
                </div>
                <div></div>
              </div>
            )}
          </div>
        );

      case travel_insurance:
        return (
          <div className={`${styles.showDetails} ${styles[themes]}`}>
            <div>
              <div>
                <h6>{intl.TEXT_TICKET_NUMBER}</h6>
                <h5>{data.ticket_number}</h5>
              </div>
              <div>
                <h6>{intl.TEXT_CREATION_DATE}</h6>
                <h5>
                  {formatCustomDate(
                    data.created_at,
                    'YYYY-MM-DD',
                    idiom,
                    country
                  )}
                </h5>
              </div>
              <div>
                {data.travel_details &&
                  (data.travel_details.end_date ||
                    data.travel_details.start_date) && (
                    <>
                      <h6>{intl.TEXT_TRIP_DATE}</h6>
                      {data.travel_details.end_date ? (
                        <h5>{`${intl.TEXT_DATE_FROM} ${formatCustomDate(
                          data.travel_details.start_date,
                          'YYYY-MM-DD',
                          idiom,
                          country
                        )} ${intl.TEXT_DATE_TO} ${formatCustomDate(
                          data.travel_details.end_date,
                          'YYYY-MM-DD',
                          idiom,
                          country
                        )}`}</h5>
                      ) : (
                        <h5>{`${formatCustomDate(
                          data.travel_details.start_date,
                          'YYYY-MM-DD',
                          idiom,
                          country
                        )}`}</h5>
                      )}
                    </>
                  )}
              </div>
            </div>
          </div>
        );

      case billete_auto_rental_insurance:
        return (
          <div className={`${styles.showDetails} ${styles[themes]}`}>
            {!isBrasil && (
              <>
                <div>
                  <div>
                    <h6>{intl.TEXT_TICKET_NUMBER}</h6>
                    <h5>{data.ticket_number}</h5>
                  </div>
                  <div>
                    <h6>{intl.TEXT_CREATION_DATE}</h6>
                    <h5>
                      {formatCustomDate(
                        data.created_at,
                        'YYYY-MM-DD',
                        idiom,
                        country
                      )}
                    </h5>
                  </div>
                  <div>
                    <h6>{intl.TEXT_RENTAL_DATE}:</h6>
                    <h5>{`${intl.TEXT_DATE_FROM} ${formatCustomDate(
                      data.valid_from,
                      'YYYY-MM-DD',
                      idiom,
                      country
                    )} ${intl.TEXT_DATE_TO} ${formatCustomDate(
                      data.valid_until,
                      'YYYY-MM-DD',
                      idiom,
                      country
                    )}`}</h5>
                  </div>
                </div>
                <div>
                  <div>
                    <h6>
                      {data &&
                      data.car_rental_details &&
                      data.car_rental_details.country_origin
                        ? intl.TEXT_ORIGIN_COUNTRY
                        : intl.TEXT_ORIGIN_CITY}
                    </h6>
                    <h5>
                      {data &&
                      data.car_rental_details &&
                      data.car_rental_details.country_origin
                        ? data.car_rental_details.country_origin
                        : data.car_rental_details.city_origin}
                    </h5>
                  </div>
                  <div>
                    <h6>
                      {data &&
                      data.car_rental_details &&
                      data.car_rental_details.country_destination
                        ? intl.TEXT_DESTINATION_COUNTRY
                        : intl.TEXT_DESTINATION_CITY}
                    </h6>
                    <h5>
                      {data &&
                      data.car_rental_details &&
                      data.car_rental_details.country_destination
                        ? data.car_rental_details.country_destination
                        : data.car_rental_details.city_destination}
                    </h5>
                  </div>
                  <div></div>
                </div>
              </>
            )}
          </div>
        );

      case certificate_seguro_compra:
        return (
          <div className={`${styles.showDetails} ${styles[themes]}`}>
            {!isBrasil && (
              <>
                <div>
                  <div>
                    <h6>{intl.TEXT_TICKET_NUMBER}</h6>
                    <h5>{data.ticket_number}</h5>
                  </div>
                  <div>
                    <h6>{intl.TEXT_CREATION_DATE}</h6>
                    <h5>
                      {formatCustomDate(
                        data.created_at,
                        'YYYY-MM-DD',
                        idiom,
                        country
                      )}
                    </h5>
                  </div>
                  <div>
                    <h6>{intl.TEXT_PURCHASE_DATE}</h6>
                    <h5>
                      {moment(
                        data &&
                          data.appliance_details &&
                          data.appliance_details.purchase_date
                      ).format(dateFormat)}
                    </h5>
                  </div>
                </div>
                <div>
                  <div>
                    <h6>{intl.TEXT_PURCHASE_BRAND}</h6>
                    <h5>
                      {data &&
                        data.appliance_details &&
                        data.appliance_details.brand}
                    </h5>
                  </div>
                  <div>
                    <h6>{intl.TEXT_PURCHASE_AMOUNT}</h6>
                    <h5>{`${
                      data &&
                      data.appliance_details &&
                      data.appliance_details.cost &&
                      formatNumber(
                        data.appliance_details.cost.value || 0,
                        idiom
                      )
                    }
                  (${
                    data &&
                    data.appliance_details &&
                    data.appliance_details.cost &&
                    data.appliance_details.cost.currencyValue
                  })`}</h5>
                  </div>
                  <div></div>
                </div>
              </>
            )}
          </div>
        );
    }
  };

  const getCertificateType = certificate => {
    if (
      isBrasil &&
      certificate.certificate_type.toLowerCase() ===
        certificate_extended_warranty
    ) {
      return `${intl.TEXT_PURCHASE_DATE}: ${formatCustomDate(
        certificate.appliance_details.purchase_date,
        'YYYY-MM-DD',
        idiom,
        country
      )}`;
    } else {
      switch (certificate.certificate_type.toLowerCase()) {
        case certificate_extended_warranty:
          return `${intl.TEXT_PURCHASE_DATE}: ${formatCustomDate(
            certificate.appliance_details.purchase_date,
            'YYYY-MM-DD',
            idiom,
            country
          )}`;
        case travel_insurance:
          return certificate.travel_details.destination_country
            ? getCountryByIdiom(
                certificate.travel_details.destination_country,
                idiom
              )
            : certificate.travel_details.city_destination;
        case billete_auto_rental_insurance:
          return `${intl.RENTAL_DATE} ${formatCustomDate(
            certificate.car_rental_details.rental_start_date,
            'YYYY-MM-DD',
            idiom,
            country
          )} ${intl.TO} ${formatCustomDate(
            certificate.car_rental_details.rental_end_date,
            'YYYY-MM-DD',
            idiom,
            country
          )}`;
        case certificate_seguro_compra:
          return `${intl.TEXT_PURCHASE_DATE}: ${formatCustomDate(
            certificate.appliance_details.purchase_date,
            'YYYY-MM-DD',
            idiom,
            country
          )} <br /> ${intl.TEXT_BRAND}: ${certificate.appliance_details.brand}`;
        default:
          return null;
      }
    }
  };

  const customButton = certificate_id => {
    return (
      <div className={`${styles.button} btnSelect`}>
        <Button
          disabled={disableButtonSelect}
          type="borderBlue"
          onClick={() => {
            if (isTripAssistance) {
              confirmTripAssistanceCertificate(certificate_id);
              return;
            }
            setCertificate(certificate_id);
          }}
          id="BtnSelectCertificate"
          css={styles.button}
        >
          {' '}
          {intl.TEXT_SELECT}{' '}
        </Button>
      </div>
    );
  };

  const renderCertificate = data => {
    return data
      .slice(0, numberToFilter)
      .map((certificate, index) => (
        <RecentItem
          key={index}
          benefitName={
            isTripAssistance
              ? `${intl.TRAVEL_DESTINATION} ${getCountryByIdiom(
                  certificate.travel_details.destination_country,
                  idiom
                )}`
              : ConfigCertificates[certificate.certificate_type.toLowerCase()]
                  .name
          }
          isClaim={false}
          number={certificate.ticket_number.substring(14, 23)}
          textDate={intl.TEXT_EXPIRY_DATE}
          date={certificate.valid_until}
          image={
            ConfigCertificates[certificate.certificate_type.toLowerCase()].icon
          }
          htmlContent={handleMakeDetails(certificate)}
          moreInfo={getCertificateType(certificate)}
          showButtonClaim={showButtonClaim}
          showVerticalDivider={showVerticalDivider}
          certificate_id={certificate.certificate_id}
          customButton={
            isToSelectCertificate
              ? customButton(certificate.certificate_id)
              : false
          }
          isTripAssistance={isTripAssistance}
          createdAt={certificate.created_at}
          ecardUrl={certificate.wallet_pass_url}
        />
      ));
  };

  return (
    <>
      {/* commented for refund rules review */}
      {!profile && !isGenerateClaim && REACT_APP_TOTAL_REIMBURSEMENT && (
        <TotalReimbursed
          bottom
          border
          type={CERTIFICATES}
          showArrow
          shouldShowLinkSaving
          shouldShowTooltip
        />
      )}
      {!profile &&
        !isTripAssistance &&
        certificates &&
        certificates.length > 0 && (
          <h3
            className={`${styles.subTitleRecentCertificates} ${styles[themes]}`}
          >
            {intl.SUBTITLE_YOUR_CERTIFICATES}
          </h3>
        )}
      <ul>{renderCertificate(certificates)}</ul>
    </>
  );
};

export default ListCertificates;
