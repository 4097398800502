import React, { useState, useEffect, useContext } from 'react';
import styles from './AddArea.module.scss';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import FormBank from './FormBank/FormBank';
import ButtonWithIcon from '../../../../../components/ButtonWithIcon/ButtonWithIcon';
import AddIcon from '../../../../../assets/icons/AddIcon';
import BankInfoIcon from '../../../../../assets/icons/BankInfoIcon';

const AddArea = props => {
  const [addText, setAddText] = useState('');
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const newBankForm = () => {
    actions.modal.showModal(
      props.title,
      true,
      <FormBank newBank {...props} />,
      false,
      false,
      null,
      null,
      <BankInfoIcon width={35} height={35} />,
      true,
      false,
      true
    );
  };

  const titleByWidthScreen = () => {
    if (window.screen.width > 768) {
      setAddText(intl.ADD_LONG);
    } else {
      setAddText(intl.ADD_SHORT);
    }
  };

  useEffect(() => {
    titleByWidthScreen();
    window.addEventListener('resize', titleByWidthScreen);
    return () => {
      window.removeEventListener('resize', titleByWidthScreen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.controlArea}`}>
      <ButtonWithIcon
        id="addButtonIcon"
        type="blueWithoutBorder"
        onClick={() => {
          newBankForm();
        }}
        value={addText}
        isSVGComponent={true}
        img={<AddIcon />}
      />
    </div>
  );
};

export default AddArea;
