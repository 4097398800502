import React, { useState, useContext, useEffect, useMemo } from 'react';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import { voucherService } from '../../../services';
import { isSameOrAfterDay } from '../../../utils/date';
import * as translations from './intl';
import styles from './ProfileVouchers.module.scss';
import VoucherHeader from './VoucherHeader/VoucherHeader';
import DividingLine from '../../../components/DividingLine/DividingLine';
import VoucherList from './VoucherList/VoucherList';
import Loading from '../../../components/Loading/Loading';
import Button from '../../../components/Button/Button';
import { toDate } from 'date-fns-tz';

const LOAD_MORE = 4;
const ACTIVE = 'ACTIVE';
const EXPIRED = 'EXPIRED';

const sortASC = (a, b) => new Date(b.redeemed_at) - new Date(a.redeemed_at);

const ProfileVouchers = () => {
  const { utils } = useContext(StateContext);
  const { translate, idiomForApi } = useContext(IntlContext);

  const [loading, setLoading] = useState(true);
  const [activeVouchers, setActiveVouchers] = useState([]);
  const [showActiveVouchers, setShowActiveVouchers] = useState(4);
  const [expiredVouchers, setExpiredVouchers] = useState([]);
  const [showExpiredVouchers, setShowExpiredVouchers] = useState(4);

  const activeVouchersFilteredAndSorted = useMemo(
    () => [...activeVouchers.slice(0, showActiveVouchers)],
    [activeVouchers, showActiveVouchers]
  );
  const expiredVouchersFilteredAndSorted = useMemo(
    () => [...expiredVouchers.slice(0, showExpiredVouchers)],
    [expiredVouchers, showExpiredVouchers]
  );

  const intl = translate(translations);
  const idiomAPI = idiomForApi();
  const customer_id = utils.getCn();

  const emptyMessageVouchers =
    !loading && activeVouchers.length === 0
      ? intl.TEXT_EMPTY_VOUCHERS
      : undefined;
  const emptyMessageExpiredVouchers =
    !loading && expiredVouchers.length === 0
      ? intl.TEXT_EMPTY_VOUCHERS_EXPIRED
      : undefined;

  const getVouchers = async () => {
    try {
      setLoading(true);
      const { data } = await voucherService.getVouchers(
        customer_id,
        'CINEPOLIS',
        idiomAPI
      );

      const active = [];
      const expired = [];
      data.forEach(voucher => {
        const isActive = isSameOrAfterDay(
          toDate(voucher.valid_until),
          new Date()
        );
        if (isActive) {
          active.push(voucher);
        } else {
          expired.push(voucher);
        }
      });

      const activeSortedASC = active.sort(sortASC);
      const expiredSortedASC = expired.sort(sortASC);

      setActiveVouchers(activeSortedASC);
      setExpiredVouchers(expiredSortedASC);
    } catch (error) {
      setActiveVouchers([]);
      setExpiredVouchers([]);
    }

    setLoading(false);
  };

  const loadMore = voucherType => {
    if (voucherType === ACTIVE) {
      const more = showActiveVouchers + LOAD_MORE;
      setShowActiveVouchers(more);
    } else if (voucherType === EXPIRED) {
      const more = showExpiredVouchers + LOAD_MORE;
      setShowExpiredVouchers(more);
    }
  };

  useEffect(() => {
    getVouchers();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <VoucherHeader
          id="active"
          title={intl.TTTLE_ACTIVED_VOUCHERS}
          number={loading ? undefined : activeVouchers.length}
          subtitle={emptyMessageVouchers}
          listVouchers={activeVouchers}
          setListVouchers={setActiveVouchers}
        />
        {loading ? (
          <Loading />
        ) : (
          <VoucherList vouchers={activeVouchersFilteredAndSorted} />
        )}
        <footer className={styles.footer}>
          {!loading && activeVouchers.length > showActiveVouchers && (
            <Button
              onClick={() => loadMore(ACTIVE)}
              type="blueWithoutBorder"
              style={{ width: 'fit-content' }}
            >
              {intl.TEXT_SEE_MORE}
            </Button>
          )}
        </footer>
      </div>

      <DividingLine />

      <div className={styles.subContainer}>
        <VoucherHeader
          id="past"
          title={intl.TTTLE_EXPIRED_VOUCHERS}
          number={loading ? undefined : expiredVouchers.length}
          subtitle={emptyMessageExpiredVouchers}
          listVouchers={expiredVouchers}
          setListVouchers={setExpiredVouchers}
        />
        {loading ? (
          <Loading />
        ) : (
          <VoucherList vouchers={expiredVouchersFilteredAndSorted} />
        )}
        <footer className={styles.footer}>
          {!loading && expiredVouchers.length > showExpiredVouchers && (
            <Button
              onClick={() => loadMore(EXPIRED)}
              type="blueWithoutBorder"
              style={{ width: 'fit-content' }}
            >
              {intl.TEXT_SEE_MORE}
            </Button>
          )}
        </footer>
      </div>
    </div>
  );
};

export default ProfileVouchers;
