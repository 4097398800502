import React, { useContext, useEffect, useState } from 'react';
import styles from './PrivateBenefits.module.scss';
import YourBenefits from './YourBenefits/YourBenefits';
import PrivateBenefitsBanner from '../../components/PrivateBenefitsBanner/PrivateBenefitsBanner';
import RelatedOffers from '../../components/RelatedOffers/RelatedOffers';
import UpgradeMe from '../../components/UpgradeMe/UpgradeMe';
import CrossSelling from '../../components/CrossSelling/CrossSelling';
import RelatedSpecialBenefits from '../../components/RelatedSpecialBenefits/RelatedSpecialBenefits';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import {
  ENABLE_OFFERS,
  ENABLE_SPECIAL_BENEFITS,
  HIDDEN_BENEFITS,
} from '../../utils/environments_variables';
import { checkAcessCross } from '../../utils/verifyAccessCrosselling';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../intl';
import { productsAndBenefitsServices } from '../../services';
import {
  SPECIAL_BENEFIS,
  HIDDEN,
  CATEGORY_TAG_SUBSCRIPTION,
} from '../../utils/benefitCategory';
import Footer from '../DownloadApp/Footer/Footer';
import { checkAccessDownloadApp } from '../../utils/downloadAppAccess';
import { isVDBPBenefits } from '../../utils/benefits';

const loadBenefits = async (
  binOrPan,
  idiom,
  setBenefits,
  setLoadingBenefits,
  setBenefitsSpecial
) => {
  try {
    setLoadingBenefits(true);

    const { data } = await productsAndBenefitsServices.getBenefitsByProduct(
      binOrPan,
      idiom,
      false,
      false
    );
    let benefits = [...data];

    const isVDBP = isVDBPBenefits(benefits);
    if (ENABLE_SPECIAL_BENEFITS) {
      if (isVDBP) {
        const onlySpecialBenefits = benefits.filter(benefit =>
          benefit.categories.find(category =>
            CATEGORY_TAG_SUBSCRIPTION.includes(category)
          )
        );

        setBenefitsSpecial(onlySpecialBenefits);

        benefits = benefits.filter(benefit =>
          benefit.categories.find(
            category => !CATEGORY_TAG_SUBSCRIPTION.includes(category)
          )
        );
      } else {
        const onlySpecialBenefits = benefits.filter(
          benefit => benefit.categories === SPECIAL_BENEFIS
        );

        setBenefitsSpecial(onlySpecialBenefits);

        benefits = benefits.filter(
          benefit => benefit.categories !== SPECIAL_BENEFIS
        );
      }
    }

    if (!isVDBP) {
      benefits = benefits.filter(
        benefit =>
          benefit.categories !== HIDDEN &&
          !HIDDEN_BENEFITS.includes(benefit.external_reference)
      );
    }

    setBenefits(benefits);
  } catch (error) {
    setBenefits([]);
  }

  setLoadingBenefits(false);
};

const getLinearGradient = (theme, isAfluent) => {
  if (theme === 'theme-portoBank') {
    return '#0A0047';
  }

  return isAfluent() ? '#021E4C' : '#1434CB';
};

const PrivateBenefits = props => {
  const { utils } = useContext(StateContext);
  const { idiomForApi, country } = useContext(IntlContext);
  const card = utils.getSelectedCard();
  const email = utils.getEmail();
  const binOrPan = utils.getBin(true);
  const cardSelected = utils.getSelectedCard();
  const { isAfluent, getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const [loadingBenefits, setLoadingBenefits] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const [benefitsSpecial, setBenefitsSpecial] = useState([]);

  const initialCategory =
    props && props.match && props.match.params && props.match.params.category;

  const bgColor = getLinearGradient(theme, isAfluent);

  const bgStyle = {
    background: `linear-gradient(
    to bottom,
    ${bgColor} 0%,
    ${bgColor} 420px,
    white 420px,
    white 100%`,
  };

  useEffect(() => {
    if (cardSelected && cardSelected.external_reference) {
      setBenefits([]);
      setBenefitsSpecial([]);
      loadBenefits(
        binOrPan,
        idiomForApi(),
        setBenefits,
        setLoadingBenefits,
        setBenefitsSpecial
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardSelected]);

  return (
    <div style={bgStyle} className={`${styles[theme]} ${styles.container}`}>
      <PrivateBenefitsBanner />

      <div className={styles.content}>
        <div id="mainContent" className={styles.contentBenefits}>
          <YourBenefits
            benefitList={benefits}
            isLoading={loadingBenefits}
            initialCategory={initialCategory}
          />
        </div>

        {checkAcessCross('EMC_Emergency_Medical_Assistance', card, email) && (
          <CrossSelling benefitList={benefits} />
        )}

        {checkAccessDownloadApp(country) && <Footer />}
        {ENABLE_OFFERS && <RelatedOffers />}
        {ENABLE_SPECIAL_BENEFITS && <RelatedSpecialBenefits />}
        <div className={styles.contentUpgradeMe}>
          <UpgradeMe />
        </div>
      </div>
    </div>
  );
};

export default PrivateBenefits;
