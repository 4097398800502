export const CREDIT_CARD = {
  en_US: 'Credit card',
  es_ES: 'Tarjeta de crédito',
  pt_BR: 'Cartão de crédito',
};

export const DEBIT_CARD = {
  en_US: 'Debit card',
  es_ES: 'Tarjeta de débito',
  pt_BR: 'Cartão de débito',
};

export const PREPAID_CARD = {
  en_US: 'Prepaid card',
  es_ES: 'Tarjeta prepago',
  pt_BR: 'Cartão pré-pago',
};

export const CREDIT = {
  en_US: 'Credit',
  es_ES: 'Crédito',
  pt_BR: 'Crédito',
};

export const DEBIT = {
  en_US: 'Debit',
  es_ES: 'Débito',
  pt_BR: 'Débito',
};

export const PREPAID = {
  en_US: 'Prepaid',
  es_ES: 'Prepago',
  pt_BR: 'Pré-pago',
};

export const TITLE_CARD_NAME = {
  en_US: cardName => `Visa ${cardName} Benefits`,
  es_ES: cardName => `Beneficios Visa ${cardName}`,
  pt_BR: cardName => `Benefícios Visa ${cardName}`,
};

export const PRODUCT_DESCRIPTION = {
  en_US: `Enjoy exclusive offers and promotions.`,
  es_ES: `Disfrute de promociones y ofertas exclusivas.`,
  pt_BR: `Aproveite promoções e ofertas exclusivas.`,
};

export const GET_THIS_CARD = {
  en_US: 'Get this Visa card',
  es_ES: 'Obtener esta tarjeta Visa',
  pt_BR: 'Obtenha este cartão Visa',
};

export const LINK_GET_THIS_CARD = {
  en_US: 'https://www.visa.com',
  es_ES: 'https://www.visa.com',
  pt_BR: 'https://www.visa.com.br/pague-com-visa/solicite-seu-cartao.html',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};

export const SELECTED_CARD = {
  en_US: 'Selected Card',
  es_ES: 'Tarjeta Seleccionada',
  pt_BR: 'Cartão Selecionado',
};
