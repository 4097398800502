import React, { useState, useEffect, useContext } from 'react';
import styles from './BankInformation.module.scss';
import { bankServices, userServices } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import BankInfo from './BankInfo/BankInfo';
import Loading from '../../../../components/Loading/Loading';
import AddArea from './AddArea/AddArea';
import iconDoneAfluent from '../../../../assets/images/icon/afluent/svg_icon_done_afluent.svg';
import iconDoneMaster from '../../../../assets/images/icon/master/svg_icon_done_master.svg';
import FormBank from './AddArea/FormBank/FormBank';
import { paymentInformationsInitialValues } from '../../../Claims/Generate/ClaimFillForm/Form/PaymentInformation/initialValues/index';
import { IntlContext } from '../../../../intl';
import { generalInformationFormInitialValues } from '../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/initialValues';
import * as translations from './intl';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import ModalResult from '../../../../components/ModalResult/ModalResult';
import ModalWithImage from '../../../../components/ModalWithImage/ModalWithImage';
import ButtonWithIcon from '../../../../components/ButtonWithIcon/ButtonWithIcon';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { getBanksWithoutOCT } from '../../../../utils/banks';
import {
  getPrefixFromCountry,
  countriesExceptions,
} from '../../../../utils/country';
import EditIcon from '../../../../assets/icons/EditIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import SuccessIcon from '../../../../assets/icons/SuccessIcon';
import BankInfoIcon from '../../../../assets/icons/BankInfoIcon';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';

const WIDTH_HEIGHT_ICONS = 22;

const loadData = async (
  isProfile,
  cn,
  setData,
  setLoading,
  cardCountry,
  trackEventUserAction,
  banksLoadDetail = null,
  actions = null,
  intl = null,
  changeBank = null
) => {
  setLoading(true);
  try {
    let promiseArray = [];
    let reimbursement;
    let banksDetail;
    const generalInformatioRequest = userServices.getCHInfo(cn);
    promiseArray.push(generalInformatioRequest);

    trackEventUserAction('#### (BANK) -- BANK LIST - INFO', {
      isProfile,
      banksLoadDetail,
    });

    if (isProfile || changeBank || !banksLoadDetail) {
      const bankRequest = bankServices.getBankAccounts(cn);
      promiseArray.push(bankRequest);
    }
    const responses = await Promise.all(promiseArray);
    const generalInformation = responses[0].data;

    if (isProfile || changeBank || !banksLoadDetail) {
      let banksData = getBanksWithoutOCT(responses[1].data);

      const banksDetailPromisse = banksData
        ? banksData.map(bank =>
            bankServices.getBankAccountDetails(cn, bank.bank_account_id)
          )
        : false;

      banksDetail = await Promise.all(banksDetailPromisse);
      banksDetail = banksDetail.map(bank => bank.data);
      if (changeBank) {
        banksDetail = banksDetail.filter(
          bank =>
            bank.bank.address.country ===
            countriesExceptions(getPrefixFromCountry(cardCountry).initials)
        );
      }
    } else {
      banksDetail = banksLoadDetail.filter(
        bank =>
          bank.bank.address.country ===
          countriesExceptions(getPrefixFromCountry(cardCountry).initials)
      );
    }

    reimbursement = banksDetail || false;
    reimbursement.map(bank => {
      if (bank.holder) {
        bank.holder = {};
      }
    });

    const data = { generalInformation, reimbursement };

    trackEventUserAction('#### (BANK) -- BANK LIST - LOAD DATA', data);

    setData(data);
  } catch (error) {
    trackEventUserAction('#### (BANK) -- BANK LIST - ERROR', {
      error,
      error_response: error.response,
    });
  }
  setLoading(false);
};

const BankInformation = props => {
  const { changeBank, setBankAccount, isProfile, banksDetail } = props;

  const { utils, actions } = useContext(StateContext);
  const { idiom, translate } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);

  const [data, setData] = useState({});
  const [schemas] = useState({});
  const [values] = useState({});
  const [loading, setLoading] = useState(true);

  const intl = translate(translations);
  const themes = getGlobalTheme();
  const blue = isAfluent() ? '#021E4C' : '#1434CB';

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const cardHolderData = generalInformationFormInitialValues(
    data.generalInformation,
    '',
    idiom
  );

  const changeMainBank = async indexSelected => {
    setLoading(true);
    try {
      const bank = data.reimbursement[indexSelected];

      const formatedBank = { ...bank, is_current_account: true };
      await bankServices.updateBankInfo(
        utils.getCn(),
        bank.bank_account_id,
        formatedBank
      );

      await loadData(
        isProfile,
        utils.getCn(),
        setData,
        setLoading,
        utils.getCountry(),
        trackEventUserAction,
        banksDetail,
        actions,
        intl,
        changeBank
      );
    } catch (error) {
      trackEventUserAction('#### (BANK) -- CHANGE MAIN BANK', {
        error,
        error_response: error.response,
      });
      setLoading(false);
    }
  };

  const updateBankForm = index => {
    actions.modal.showModal(
      intl.EDIT_BANK,
      true,
      <FormBank
        updateParent={updateForm.bind(null, 'reimbursement')}
        initialValues={paymentInformationsInitialValues({
          data: data.reimbursement[index],
          idiom: idiom,
          country: utils.getCountry(),
          isClaimUpdate: false,
          editBankInProfile: true,
        })}
        showIsCardHolderArea={false}
        showIntermediaryBankArea={false}
        cardHolderData={cardHolderData}
        values={values}
        isProfile
        hideOct
        refreshData={() =>
          loadData(
            isProfile,
            utils.getCn(),
            setData,
            setLoading,
            utils.getCountry(),
            trackEventUserAction,
            banksDetail
          )
        }
      />,
      false,
      false,
      null,
      null,
      <BankInfoIcon width={35} height={35} />,
      true,
      false,
      true
    );
  };

  const deleteBank = async reimbursement => {
    const deleteFunction = async () => {
      try {
        actions.modal.showLoading(intl.REMOVING_BANK);
        await bankServices.deleteBankAccount(
          utils.getCn(),
          reimbursement.bank_account_id
        );

        await loadData(
          isProfile,
          utils.getCn(),
          setData,
          setLoading,
          utils.getCountry(),
          trackEventUserAction,
          banksDetail
        );
        actions.modal.hideLoading();
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="success"
            message={intl.CONFIRM_DELETE_BANK}
          />,
          true,
          true
        );
      } catch (error) {
        trackEventUserAction('#### (BANK) -- DELETE BANK', {
          error,
          error_response: error.response,
        });
        actions.modal.hideLoading();
        actions.modal.showModal(
          false,
          true,
          <ModalInformation type="error" message={'Algo deu errado'} />,
          true,
          true
        );
      }
    };

    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.DELETE_BANK_TITLE}
        subtitle={intl.DELETE_BANK_SUBTITLE}
      >
        <ModalResult
          textConfirm={intl.BTN_REMOVE}
          clickConfirm={deleteFunction}
          textDeny={intl.TEXT_NO_KEEP}
          clickDeny={() => {
            actions.modal.closeModal();
          }}
          buttonConfirmType="borderRed"
        />
      </ModalWithImage>,
      true,
      false
    );
  };

  useEffect(() => {
    loadData(
      isProfile,
      utils.getCn(),
      setData,
      setLoading,
      utils.getCountry(),
      trackEventUserAction,
      banksDetail,
      actions,
      intl,
      changeBank
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banksDetail]);

  return (
    <div id="bank" className={styles.container}>
      <div className="row">
        <div className={`col-12 ${styles.titleArea}`}>
          <h2 className={styles[themes]}>
            {!changeBank && intl.TITLE_YOUR_BANK}
          </h2>
          <AddArea
            title={intl.ADD_BANK}
            loadedData={data}
            updateParent={updateForm.bind(null, 'reimbursement')}
            initialValues={paymentInformationsInitialValues({
              data: {},
              idiom: idiom,
              country: utils.getCountry(),
            })}
            showIsCardHolderArea={false}
            showIntermediaryBankArea={false}
            cardHolderData={cardHolderData}
            values={values}
            isProfile
            hideOct
            refreshData={() =>
              loadData(
                isProfile,
                utils.getCn(),
                setData,
                setLoading,
                utils.getCountry(),
                trackEventUserAction,
                changeBank ? null : banksDetail
              )
            }
            setBankAccount={changeBank ? setBankAccount : null}
          />
        </div>
      </div>
      <div className={styles.content}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {data &&
              data.reimbursement &&
              data.reimbursement.map((reimbursement, index) => (
                <div
                  className={`${styles.reimbursementItem} ${styles[themes]} ${
                    reimbursement.is_current_account ? styles.selected : ''
                  }`}
                  key={index}
                >
                  {reimbursement.is_current_account && (
                    <div className={`${styles.mainCard}`}>
                      <StatusLabel
                        directText={intl.MAIN_ACCOUNT.toUpperCase()}
                        directColor={blue}
                      />
                    </div>
                  )}
                  <BankInfo bank={reimbursement} />
                  <div className={styles.footer}>
                    {!changeBank && (
                      <ButtonWithIcon
                        width="auto"
                        type="blueWithoutBorder"
                        id={`editBankButton-${index}`}
                        dataTestid={`editBankButton-${index}`}
                        onClick={() => updateBankForm(index)}
                        isSVGComponent={true}
                        img={
                          <EditIcon
                            width={WIDTH_HEIGHT_ICONS}
                            height={WIDTH_HEIGHT_ICONS}
                          />
                        }
                        value={intl.BTN_EDIT}
                      />
                    )}
                    {!changeBank && (
                      <ButtonWithIcon
                        width="auto"
                        type="blueWithoutBorder"
                        id={`removeBankButton-${index}`}
                        dataTestid={`removeBankButton-${index}`}
                        onClick={() => deleteBank(reimbursement)}
                        isSVGComponent={true}
                        img={
                          <TrashIcon
                            width={WIDTH_HEIGHT_ICONS}
                            height={WIDTH_HEIGHT_ICONS}
                          />
                        }
                        value={intl.BTN_REMOVE}
                      />
                    )}
                    {!reimbursement.is_current_account && (
                      <ButtonWithIcon
                        width="auto"
                        id={`setMainAccountButton-${index}`}
                        type="blueWithoutBorder"
                        onClick={() => {
                          changeMainBank(index);
                          if (setBankAccount) {
                            setBankAccount(
                              utils.getCn(),
                              reimbursement.bank_account_id
                            );
                          }
                        }}
                        img={
                          <SuccessIcon
                            width={WIDTH_HEIGHT_ICONS}
                            height={WIDTH_HEIGHT_ICONS}
                          />
                        }
                        isSVGComponent={true}
                        value={intl.BTN_SET_MAIN}
                      />
                    )}
                  </div>
                </div>
              ))}
            {data && data.reimbursement && data.reimbursement.length === 0 && (
              <p className={`${styles.emptyMessage} ${styles[themes]}`}>
                {intl.YOU_DONT_HAVE_BANK}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BankInformation;
