export const IMPROVE_YOUR_COVERAGE = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Mejora tu cobertura al viajar',
};

export const DESCRIPTION_BENEFIT = {
  en_US: '',
  pt_BR: '',
  es_ES: `Antes de tu próximo viaje, conozca los beneficios de emergencia médica y
  la posibilidad de mejorar tu actual cobertura.`,
};

export const READ_MORE = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Conozca más',
};
