import React, { useState, useEffect, useContext } from 'react';
import DividingLine from '../../../../components/DividingLine/DividingLine';
import ListRecentClaims from '../../../../components/ListRecentClaims/ListRecentClaims';
import Loading from '../../../../components/Loading/Loading';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import OrderList from '../OrderList/OrderList';
import styles from '../ProfileClaims.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { SORT_ASC } from '../../../../components/ButtonSortList/ButtonSortList';

const ListClaimByStatus = ({
  value,
  benefits,
  intl,
  lastItem,
  loading = true,
  siteSection,
}) => {
  const [number, setNumber] = useState(4);
  const [list, setList] = useState([]);
  const subtitle = list.length > 0 ? value.subtitle : value.messageEmpty;
  const getNumber = list ? list.length : 0;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    setList(value.claims);
  }, [value.claims]);
  return (
    <div id={value.id} className={`row ${styles.status}`}>
      <div className={`col-12  ${styles.header}`}>
        <div className={styles.headerContainer}>
          <div className={styles.titleSection}>
            <TitleSectionProfile
              title={value.title}
              number={loading ? null : getNumber}
              subtitle={loading ? null : subtitle}
              loading={loading}
            />
          </div>

          {list.length > 1 && (
            <OrderList
              list={value.claims}
              setList={setList}
              initialOrder={SORT_ASC}
            />
          )}
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className={`col-12 ${styles.contentStatus}`}>
            <ul>
              {list && (
                <ListRecentClaims
                  claims={list}
                  benefits={benefits}
                  numberToFilter={number}
                  siteSection={siteSection}
                />
              )}
            </ul>
          </div>
          {list.length > 0 && list.length > number && (
            <div className={styles.showMore}>
              <span
                className={styles[themes]}
                onClick={() => setNumber(number + 4)}
              >
                {intl.TEXT_SEE_MORE}
              </span>
            </div>
          )}
        </>
      )}
      {lastItem !== value.substatus[0] && <DividingLine />}
    </div>
  );
};

export default ListClaimByStatus;
