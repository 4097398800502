import { listCountries } from '../intl/countries';
import { listCurrencies } from '../intl/currency/currencies';
import { getCookie } from './cookies';

export const getCurrencyByHomeCountry = () => {
  const homeCountry = getCookie('homeCountry');
  const country = listCountries.find(c => c.initials === homeCountry);
  return country ? country.currency : '';
};

export const getCurrencyNameByInitials = (initials, idiom) => {
  const currency = listCurrencies.find(item => item.initials === initials);
  return currency ? currency[idiom] : initials;
};

export const getCurrencyByCountry = initialsCountry => {
  const country = listCountries.find(c => c.initials === initialsCountry);
  return country ? country.currency : '';
};

export const getCurrrencySymbolByInitials = initials => {
  const country = listCurrencies.find(item => item.initials === initials);
  return country ? country.currencySymbol : '$';
};
