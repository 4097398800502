import React, { useContext } from 'react';
import styles from './Step3.module.scss';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import useScript from '../../../../hooks/useScript';
import { API_TOKENEX_ENDPOINT } from '../../../../utils/environments_variables';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import RadioButton from '../../../../components/RadioButton/RadioButton';

const Step3 = props => {
  const {
    backStep,
    submitButtonRef,
    isUserPartner,
    handleChange,
    touched,
    handleBlur,
    errors,
    stepCard,
    setStepCard,
    setFieldValue,
    setTouched,
  } = props;

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const tokenexPublicKey = process.env.REACT_APP_TOKENEX_PUBLIC_KEY;
  useScript(`${API_TOKENEX_ENDPOINT}/inpage/js/TokenEx-Lite.js`);

  const removeRequiredCharLabel = data => {
    return data && data.slice(-1) === '*'
      ? data.substring(0, data.length - 1)
      : data;
  };

  return (
    <div className={styles.stepThree}>
      <h2 className={styles[themes]}>{intl.CARD_INFORMATION}</h2>
      <p className={`${styles.info} ${styles[themes]}`}>
        {intl.TEXT_DISCLAIMER_REGISTER_STEP_3}
      </p>
      <div className={styles.form_section}>
        <div className={`row ${styles.buttons}`}>
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <RadioButton
              label={intl.TEXT_DISCLAIMER_REGISTER_STEP_3}
              defaultChecked={stepCard === 'P'}
              name="typeCard"
              text={removeRequiredCharLabel(intl.LABEL_CARD_NUMBER_16)}
              value={true}
              onChange={e => {
                setFieldValue('creditCard', '');
                setTouched({ creditCard: false });
                setStepCard('P');
              }}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <RadioButton
              label={intl.TEXT_DISCLAIMER_REGISTER_STEP_3}
              defaultChecked={stepCard === 'B'}
              name="typeCard"
              text={removeRequiredCharLabel(intl.LABEL_CARD_NUMBER_10)}
              value={false}
              onChange={e => {
                setFieldValue('creditCard', '');
                setTouched({ creditCard: false });
                setStepCard('B');
              }}
            />
          </div>
        </div>
        {stepCard === 'P' && (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <Input
                mask="maskedCreditCard16Digits"
                name="creditCard"
                type="text"
                label={intl.LABEL_CARD_NUMBER_16}
                formikProps={props}
                dataTestid="creditCard"
              />
            </div>
            {/*
          /**
           * * The Business team requested to comment the effective_end_date from the form
          * ? ISSUER:
          * https://dev.azure.com/AASolutions/americas_visa_benefits_portal/_sprints/taskboard/americas_visa_benefits_portal%20Team/americas_visa_benefits_portal/2023/Sprint23_15?workitem=713801
          <div className="col-12 col-sm-6 col-md-4 col-lg-4">
            <Input
              type="text"
              label={intl.LABEL_EXPIRATION_DATE}
              name="effective_end_date"
              formikProps={props}
              mask="maskedExpirationDate"
            />
          </div> */}
          </div>
        )}

        {stepCard === 'B' && (
          <div className="row">
            <div className="col-12 col-md-8 col-lg-6">
              <Input
                mask="maskedCreditCard"
                name="creditCard"
                type="text"
                label={intl.LABEL_CARD_NUMBER_10}
                onChange={handleChange}
                touched={touched.creditCard}
                onBlur={handleBlur}
                error={errors.creditCard}
                dataTestid="creditCard"
              />
            </div>
          </div>
        )}
      </div>
      <p className={`${styles.info3} ${styles[themes]}`}>
        {intl.TEXT_DISCLAIMER_CARDS_REGISTER_STEP_3}
      </p>
      <div className={`${styles.btnFooter} ${styles[themes]}`}>
        {!isUserPartner && (
          <div className={styles.divButton}>
            <Button type="borderGold" onClick={e => backStep(e, 2)}>
              {intl.BTN_BACK}
            </Button>
          </div>
        )}

        <div className={styles.divButton}>
          <Button
            typeHtml="submit"
            type="blue"
            dataTestid="submit"
            buttonRef={submitButtonRef}
          >
            {intl.BTN_CREATE_ACCOUNT}
          </Button>
        </div>
      </div>
      <input id="TxEncryptionKey" type="hidden" value={tokenexPublicKey} />
    </div>
  );
};

export default Step3;
