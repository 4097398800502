import { getFormatedTime } from './utils';
import { parseISO } from 'date-fns';
import * as translations from '../../../intl/travels';
import { useFlightInfo } from '../FlightInfoContext';
import { useContext } from 'react';
import { IntlContext } from '../../../intl';
import { MILES_TO_KM } from '../../../utils/units';
import styles from './index.module.scss';
import ProgressBar from '../../ProgressBar';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

interface AirportsRowProps {
  showDuration?: boolean;
  showDistance?: boolean;
  className?: string;
}

const AirportsRow = ({
  showDuration = true,
  showDistance = false,
  className,
}: AirportsRowProps) => {
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  let handledClassname = styles.container;
  if (className) handledClassname += ` ${className}`;

  const { flightInfo } = useFlightInfo();
  const {
    startAt,
    airportOrigin,
    endAt,
    airportDestination,
    progressPercent = 0,
    routeDistance = 0,
  } = flightInfo;

  const distanceKilometers = routeDistance * MILES_TO_KM;
  const remainingKilometers =
    (distanceKilometers * (100 - progressPercent)) / 100;

  const startAtDate = parseISO(startAt);
  const endAtDate = parseISO(endAt);

  const [startTime, startSuffix] = getFormatedTime(startAt, idiom);
  const [endTime, endSuffix] = getFormatedTime(endAt, idiom);

  const isOnAir = progressPercent > 0 && progressPercent < 100;
  const durationStart = isOnAir ? new Date() : startAtDate;
  const duration = (endAtDate.getTime() - durationStart.getTime()) / 1000;
  const durationHours = Math.floor(duration / 60 / 60);
  const durationMinutes = Math.floor((duration / 60) % 60);

  return (
    <div className={handledClassname}>
      <div className={styles.leftContainer}>
        <p className={`${styles.airport} ${styles[theme]}`}>{airportOrigin}</p>
        <p className={`${styles.time} ${styles[theme]}`}>
          {startTime} <small>{startSuffix}</small>
        </p>
      </div>
      <div className={styles.progressContainer}>
        <ProgressBar progress={progressPercent} />
        {showDuration === true && (
          <p className={`${styles.statusText} ${styles[theme]}`}>
            {`${intl[isOnAir ? 'REMAINING' : 'DURATION']}: `}
            {durationHours ? `${durationHours} h ` : ''}
            {durationMinutes ? `${durationMinutes} m` : ''}
          </p>
        )}
        {showDistance && (
          <div className={styles.distanceContainer}>
            <p className={`${styles.statusText} ${styles[theme]}`}>{`${
              intl.DISTANCE
            }: ${distanceKilometers.toFixed()}km`}</p>
            {isOnAir && (
              <p>{`${intl.REMAINING}: ${remainingKilometers.toFixed()}km`}</p>
            )}
          </div>
        )}
      </div>
      <div className={styles.rightContainer}>
        <p className={`${styles.airport} ${styles[theme]}`}>
          {airportDestination}
        </p>
        <p className={`${styles.time} ${styles[theme]}`}>
          {endTime} <small>{endSuffix}</small>
        </p>
      </div>
    </div>
  );
};

export default AirportsRow;
