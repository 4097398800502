import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './PeriodArea.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const PeriodArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, errors, setFieldTouched } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div>
      <div className={`row ${styles.rentalPeriodlArea}`}>
        <div className="col-12">
          <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
            {intl.TEXT_DATE_OF_TRIP}
          </h3>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Calendar
            value={values.travel_information.from}
            label={intl.TEXT_START_DATE}
            name="travel_information.from"
            onChange={handleChange}
            maxDate={new Date()}
            touched={
              touched.travel_information && touched.travel_information.from
            }
            error={errors.travel_information && errors.travel_information.from}
            setFieldTouched={setFieldTouched}
            dataTestid="inputInformationFrom"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Calendar
            value={values.travel_information.to}
            label={intl.TEXT_END_DATE}
            name="travel_information.to"
            onChange={handleChange}
            minDate={values.travel_information.from}
            maxDate={new Date()}
            touched={
              touched.travel_information && touched.travel_information.to
            }
            error={errors.travel_information && errors.travel_information.to}
            setFieldTouched={setFieldTouched}
            dataTestid="inputInformatiomTo"
          />
        </div>
      </div>
    </div>
  );
};

export default PeriodArea;
