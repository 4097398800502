export const TITLE = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Bilhetes de seguro',
};
export const SUBTITLE = {
  en_US:
    'See your recent certificates below and generate a new one from your list.',
  es_ES:
    'Revisa tus certificados recientes a continuación y genere uno nuevo de tu lista.',
  pt_BR: 'Veja seus bilhetes recentes abaixo e crie um novo em sua lista.',
};
export const TEXT_MAKE_CLAIM = {
  en_US: 'Make claims',
  es_ES: 'Hacer reclamos',
  pt_BR: 'Criar reivindicações',
};

export const TEXT_VIEW_PDF = {
  en_US: 'View PDF',
  es_ES: 'Ver PDF',
  pt_BR: 'Ver PDF',
};

export const TEXT_VIEW_ALL = {
  en_US: 'View all certificates',
  es_ES: 'Ver todos los certificados',
  pt_BR: 'Ver todos os bilhetes',
};

export const TEXT_EXPIRY_DATE = {
  en_US: 'Valid until',
  es_ES: 'Válido hasta',
  pt_BR: 'Válido até',
};

export const TITLE_AUTO_RENTAL_CERTIFICATE = {
  en_US: 'Auto rental insurance',
  pt_BR: 'Bilhete seguro para veículos de locadora',
  es_ES: 'Certificado seguro de alquiler de auto',
};
export const TITLE_EXTENDED_WARRANTY_CERTIFICATE = {
  en_US: 'Extended warranty',
  pt_BR: 'Bilhete seguro de garantia estendida original',
  es_ES: 'Garantía extendida',
};
export const TITLE_TRAVEL_INSURANCE_CERTIFICATE = {
  en_US: 'Travel assistance certificate',
  pt_BR: 'Bilhete seguro viagem',
  es_ES: 'Certificado de asistencia en viaje',
};
export const TITLE_PURCHASE_CERTIFICATE = {
  en_US: 'Certificate of purchase',
  pt_BR: 'Bilhete de proteção de compra',
  es_ES: 'Certificado de compra',
};

export const TEXT_TICKET_NUMBER = {
  en_US: 'Ticket number',
  es_ES: 'Numero de ticket',
  pt_BR: 'Número do bilhete',
};

export const TEXT_CREATION_DATE = {
  en_US: 'Creation date',
  es_ES: 'Fecha de creatíon',
  pt_BR: 'Vigência anual',
};

export const TEXT_TRIP_DATE = {
  en_US: 'Trip date',
  es_ES: 'Fecha de viaje',
  pt_BR: 'Data de início de viagem',
};

export const TEXT_DATE_FROM = {
  en_US: 'From',
  es_ES: 'Del',
  pt_BR: 'De',
};

export const TEXT_DATE_TO = {
  en_US: 'to',
  es_ES: 'al',
  pt_BR: 'até',
};

export const TEXT_PURCHASE_BRAND = {
  en_US: 'Purchase brand',
  es_ES: 'Marca',
  pt_BR: '',
};

export const TEXT_PURCHASE_AMOUNT = {
  en_US: 'Purchase amount',
  es_ES: 'Monto de la compra',
  pt_BR: '',
};

export const TEXT_ORIGINAL_WARRANTY_PERIOD = {
  en_US: 'Original warranty period',
  es_ES: 'Período de garantía original',
  pt_BR: 'Data da compra',
};

export const TEXT_RENTAL_DATE = {
  en_US: 'Rental date',
  es_ES: 'Fecha de alquiler',
  pt_BR: '',
};

export const TEXT_ORIGIN_COUNTRY_CITY = {
  en_US: 'Origin (country | city)',
  es_ES: 'Origen (país | ciudad)',
  pt_BR: '',
};

export const TEXT_DESTINATION_COUNTRY_CITY = {
  en_US: 'Destination (country | city)',
  es_ES: 'Destino (país | ciudad)',
  pt_BR: '',
};

export const TEXT_PURCHASE_DATE = {
  en_US: 'Purchase date',
  es_ES: 'Fecha de compra',
  pt_BR: 'Data da compra',
};

export const TEXT_BRAND = {
  en_US: 'Brand',
  es_ES: 'Marca',
  pt_BR: '',
};

export const RENTAL_DATE = {
  en_US: 'Rental date',
  es_ES: 'Destino',
  pt_BR: '',
};

export const TO = {
  en_US: 'To',
  es_ES: 'para',
  pt_BR: '',
};

export const TEXT_NO_HAVE_CERTIFICATE = {
  en_US:
    'You do not have any certificate created recently. Enjoy your benefits below! ',
  es_ES:
    'No ha creado ningún certificado recientemente. Disfruta de tus beneficios a continuación!',
  pt_BR:
    'Você não tem nenhum bilhete criado recentemente. Aproveite os benefícios abaixo!',
};

export const TITLE_GENERATE_PDF = {
  en_US: 'Receive the certificate PDF in your email',
  es_ES: 'Reciba el PDF del certificado en tu correo electrónico',
  pt_BR: 'Receba o PDF do certificado em seu e-mail',
};

export const SUBTITLE_GENERATE_PDF = {
  en_US: 'Do you want to receive your certificate by email?',
  es_ES: '¿Quieres recibir tu certificado por correo electrónico?',
  pt_BR: 'Deseja receber seu certificado por e-mail?',
};

export const YES_GENERATE_PDF = {
  en_US: 'Yes, I do',
  es_ES: 'Sí, quiero',
  pt_BR: 'Sim, eu quero',
};

export const NO_GENERATE_PDF = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const GENERATE_PDF_SUCCESS = {
  en_US: 'Your certificate has been successfully sent to your email.',
  es_ES: 'Tu certificado ha sido enviado con éxito a tu correo electrónico.',
  pt_BR: 'Seu bilhete foi enviado para seu e-mail com sucesso.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TEXT_OK = {
  en_US: 'Ok',
  es_ES: 'Ok',
  pt_BR: 'Ok',
};
