import { useTheme } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../../Button/Button';
import styles from './index.module.scss';
import { IconType } from 'react-icons';

interface FeedbackButtonProps {
  Icon: IconType;
  onClick?: () => void;
  disabled?: boolean;
}

const FeedbackButton = ({ Icon, onClick, disabled }: FeedbackButtonProps) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <Button
      type="whiteWhitoutBorder"
      width={32}
      height={32}
      style={{ background: 'transparent' }}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        size={24}
        className={`${styles[theme]} ${
          disabled ? styles.feedbackIconDisabled : styles.feedbackIcon
        }`}
      />
    </Button>
  );
};

export default FeedbackButton;
