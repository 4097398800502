import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import Button from '../../../../Button/Button';
import styles from './ButtonProfile.module.scss';
import { removeAxaPrefixFromExternalReference } from '../../../../../utils/B2C/string';

const ButtonBenefits = props => {
  const { benefit, history } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <div className={`${styles.boxButtons} ${styles.default}`}>
      <div className={styles.buttonItem}>
        <Button
          type="gold"
          onClick={() => history.push(`/your-benefits/${benefit.benefit_id}`)}
        >
          {intl.VIEW_MORE}
        </Button>
      </div>

      <div className={styles.buttonItem}>
        <Button
          type="gold"
          onClick={() =>
            history.push(
              `/cross-sell-detail/${removeAxaPrefixFromExternalReference(
                benefit.external_reference
              )}`
            )
          }
        >
          {intl.BUTTON_PURCHASE}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(ButtonBenefits);
