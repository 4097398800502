import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import StepClaim from '../../StepClaim/StepClaim';
import Loading from '../../../../components/Loading/Loading';
import { userServices, bankServices } from '../../../../services';
import styles from './ClaimFillForm.module.scss';
import Form from './Form/Form';
import * as translations from './intl';
import { IntlContext } from '../../../../intl/index';

const OCT = 'OCT';

const findBankWithoutOCT = banks => {
  return banks.find(bank => {
    const OCTreturn = bank.account_codes.find(
      wireCode => wireCode.wire_code_type === OCT
    );
    return OCTreturn ? false : true;
  });
};

const loadData = async (cn, setData) => {
  const generalInformatioRequest = userServices.getCHInfo(cn);
  const bankRequest = bankServices.getBankAccounts(cn);
  try {
    const responses = await Promise.all([
      generalInformatioRequest,
      bankRequest,
    ]);
    const generalInformation = responses[0].data;
    const bankInformation = responses[1].data;

    let bankDetail = false;
    const bankData = findBankWithoutOCT(bankInformation);

    if (bankData) {
      bankDetail = bankInformation.filter(async value => {
        let account = await bankServices.getBankAccountDetails(
          cn,
          value.bank_account_id
        );

        return account.data.is_current_account;
      });
    }

    bankDetail = bankDetail
      ? await bankServices.getBankAccountDetails(
          cn,
          bankDetail[0].bank_account_id
        )
      : false;

    let reimbursement = bankDetail.data || false;
    const data = { generalInformation, reimbursement };

    setData(data);
  } catch (error) {}
};

const ClaimFillForm = props => {
  const { showCertificateArea } = props;

  const [data, setData] = useState('LOADING');
  const { utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const cn = utils.getCn();
  const intl = translate(translations);

  const isLoading = data === 'LOADING';
  const isError = data === 'ERROR';
  const successLoad = !isLoading && !isError;

  const stepNames = showCertificateArea
    ? [intl.TEXT_STEP_1, intl.TEXT_STEP_2, intl.TEXT_STEP_3, intl.TEXT_STEP_4]
    : [intl.TEXT_STEP_1, intl.TEXT_STEP_3, intl.TEXT_STEP_4];

  useEffect(() => {
    loadData(cn, setData);
  }, [cn]);

  let loading = (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  );

  let error = (
    <>
      <h1>ERROR</h1>
    </>
  );

  return (
    <div>
      {isLoading && loading}
      {isError && error}
      {successLoad && (
        <>
          <StepClaim
            currentStep={showCertificateArea ? 3 : 2}
            stepNames={stepNames}
          />
          <Form {...props} loadedData={data} />
        </>
      )}
    </div>
  );
};

export default ClaimFillForm;
