export default (env, axios, errorHandler) => {
  return {
    getLoggedUserCards: async customer_id => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/policies`;
      return errorHandler(axios.get(url), 'getLoggedUserCards', {
        customer_id,
        url,
      });
    },

    getCardInfo: async (customer_id, bin_number) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/policies/${bin_number}`;
      return errorHandler(axios.get(url), 'getCardInfo', {
        customer_id,
        bin_number,
        url,
      });
    },

    getProductCardList: async () => {
      const url = '/sales/v2/travel/products?country=BR';
      return errorHandler(axios.get(url), 'getProductCardList', { url });
    },

    addCard: async (customer_id, newCard, is_preferred = true) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/policies`;
      const data = {
        external_reference: newCard.bin,
        is_preferred,
      };
      return errorHandler(axios.post(url, data), 'addCard', { url, data });
    },

    removeCard: async (customer_id, bin_number) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/policies/${bin_number}`;
      return errorHandler(axios.delete(url), 'removeCard', {
        url,
        customer_id,
        bin_number,
      });
    },

    changePreferredCard: async (
      customer_id,
      card_name,
      bin_number,
      panToken = null
    ) => {
      const panOrBin =
        panToken && panToken.length === 16 ? panToken : bin_number;
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/policies/${panOrBin}`;
      const data = {
        external_reference: bin_number,
        credit_card_token: panToken,
        name: card_name,
        is_preferred: true,
      };
      return errorHandler(axios.patch(url, data), 'changePreferredCard', {
        url,
        data,
        customer_id,
        card_name,
        bin_number,
        panToken,
      });
    },

    updateCard: async (customer_id, panOrBin, dataToUpdate) => {
      const url = `/policies/${panOrBin}`;

      const data = { is_preferred: true, ...dataToUpdate };
      return errorHandler(axios.patch(url, data), 'update card', {
        url,
        data,
        customer_id,
        panOrBin,
      });
    },
  };
};
