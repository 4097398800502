import React, { useContext } from 'react';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import moment from 'moment';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../intl/idioms';
import { formatCustomDate } from '../../../../utils/date';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const ExtendedWarrantyDetail = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const itemInformation = claimDetails && claimDetails.item_information;

  const purchaseDate =
    itemInformation && itemInformation.purchase_date
      ? formatCustomDate(
          itemInformation.purchase_date,
          'YYYY-MM-DD',
          idiom,
          country
        )
      : '-';

  const itemPurchased =
    itemInformation && itemInformation.label ? itemInformation.label : '-';

  const itemManufacturer =
    itemInformation && itemInformation.manufacturer
      ? itemInformation.manufacturer
      : '-';

  const itemModel =
    itemInformation && itemInformation.model_number
      ? itemInformation.model_number
      : '-';

  const purchasedPrice =
    itemInformation && itemInformation.price.value
      ? itemInformation.price.value
      : '-';

  const currency =
    itemInformation && itemInformation.price.currency
      ? itemInformation.price.currency
      : '-';

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <InfoLabelValue label={intl.LABEL_PURCHASE_DATE} value={purchaseDate} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_ITEM_PURCHASED}
          value={itemPurchased}
        />
      </div>

      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_MANUFACTURER}
          value={itemManufacturer}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_MODEL} value={itemModel} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_PURCHASED_PRICE}
          value={`${purchasedPrice} - ${currency}`}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} - ${reimbursedCurrency}`}
          />
        </div>
      )}
    </div>
  );
};

export default ExtendedWarrantyDetail;
