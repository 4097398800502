import React, { useContext, useEffect, useState } from 'react';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { productsAndBenefitsServices } from '../../../../services';
import styles from './Cards.module.scss';
import Card from './Card/Card';
import Loading from '../../../../components/Loading/Loading';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';

const CardsMobile = props => {
  const { clientType } = props;
  const { idiomForApi, country, translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const [productsCredit, setProductsCredit] = useState(null);
  const [productsDebit, setProductsDebit] = useState(null);
  const [productsPrePaid, setProductsPrePaid] = useState(null);
  const [loading, setLoading] = useState(true);
  const themes = getGlobalTheme();

  const getProducts = async () => {
    setLoading(true);
    try {
      const res = await productsAndBenefitsServices.getProductsByCountry(
        country,
        idiomForApi(),
        false
      );

      if (res.data.length > 0) {
        const cardsCredit = [];
        const cardsDebit = [];
        const cardsPrePaid = [];

        res.data.forEach(element => {
          if (element.client_type === clientType) {
            if (element.type === intl.TEXT_SET_SELECTED_CREDIT) {
              cardsCredit.push(element);
            } else if (element.type === intl.TEXT_SET_SELECTED_DEBIT) {
              cardsDebit.push(element);
            } else if (element.type === intl.TEXT_SET_SELECTED_PREPAID) {
              cardsPrePaid.push(element);
            }
          }
        });

        setProductsCredit(cardsCredit);
        setProductsDebit(cardsDebit);
        setProductsPrePaid(cardsPrePaid);
      }
    } catch (error) {
      setProductsCredit([]);
      setProductsDebit([]);
      setProductsPrePaid([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idiomForApi, country, clientType]);

  const mapCards = products => {
    if (products && products.length > 0) {
      return products.map((card, index) => {
        return <Card product={card} key={`card_${index}`} />;
      });
    }

    return <h2 className={styles.emptyProducts}>{intl.TEXT_EMPTY_PRODUCTS}</h2>;
  };

  return (
    <div
      className={`col-12 col-lg-9 ${styles.cards}`}
      data-testid="cardsMobile"
    >
      <div className={`row ${styles.list}`}>
        {loading && (
          <div
            className={styles.loadingContainer}
            data-testid="cardsMobileLoading"
          >
            <Loading />
          </div>
        )}
        {!loading && (
          <>
            <h3 className={`${styles.subtitleTypeCard} ${styles[themes]}`}>
              {intl.CREDIT}
            </h3>
            {mapCards(productsCredit)}
            <h3 className={`${styles.subtitleTypeCard} ${styles[themes]}`}>
              {intl.DEBIT}
            </h3>
            {mapCards(productsDebit)}
            <h3 className={`${styles.subtitleTypeCard} ${styles[themes]}`}>
              {intl.PREPAID}
            </h3>
            {mapCards(productsPrePaid)}
          </>
        )}
      </div>
    </div>
  );
};

export default CardsMobile;
