/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import ListCardStatic from '../../../../components/ListCardStatic/ListCardStatic';
import styles from './AdvancedSearch.module.scss';
import Button from '../../../../components/Button/Button';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { showModalAddCard } from '../../../../components/ModalAddCard/functionShowModal';
import { loadListCardByCertificate } from './config_advancedSearch';
import Loading from '../../../../components/Loading/Loading';

const verifyCardDeleted = (bin, binDeleted) => {
  return binDeleted.filter(value => value.external_reference === bin).length;
};

const AdvancedSearchCertificate = ({
  searchCertificate,
  setShowListCertificate,
}) => {
  const { translate } = useContext(IntlContext);
  const {
    actions,
    utils,
    state: { cards },
  } = useContext(StateContext);
  const [listAdvancedSearch, setListAdvancedSearch] = useState({});
  const [showModal, setShowModal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [binOrPan, setBinOrPan] = useState();
  const [showButtonAddCard, setShowButtonAddCard] = useState(false);
  const binDeleted = useMemo(
    () => listAdvancedSearch.cardDeleted,
    [listAdvancedSearch]
  );

  const intl = translate(translations);
  const cn = utils.getCn();

  useEffect(() => {
    async function getCardsWithCertificate() {
      setLoading(true);
      try {
        let response = await loadListCardByCertificate(cn, cards);
        setListAdvancedSearch(response);
      } catch (error) {
        setListAdvancedSearch({});
      }
      setLoading(false);
    }

    getCardsWithCertificate();
  }, []);

  useEffect(() => {
    if (binOrPan && binDeleted) {
      setShowButtonAddCard(false);
      setShowListCertificate(false);

      const isDeleted = verifyCardDeleted(binOrPan, binDeleted);

      if (isDeleted) {
        setShowButtonAddCard(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binOrPan, binDeleted]);

  const addCard = value => {
    setShowModal(value);
    showModalAddCard(actions, intl, 'Header', binOrPan, value, addCard);
  };

  return (
    <>
      <div>
        {showButtonAddCard && (
          <p className={styles.textDeletedCard}>{intl.TEXT_DELETED_CARD}</p>
        )}
      </div>
      <div>{loading && <Loading />}</div>
      <div className={styles.boxAdvancedSearch}>
        {!loading &&
          listAdvancedSearch &&
          listAdvancedSearch.data &&
          listAdvancedSearch.data.length > 0 && (
            <>
              <div className={styles.listCards}>
                <ListCardStatic
                  setBinOrPan={setBinOrPan}
                  removeFirstItem={true}
                  list={listAdvancedSearch}
                />
              </div>
              <div className={styles.searchButton}>
                <Button
                  id="advancedSearchButton"
                  onClick={() => {
                    searchCertificate(binOrPan);
                    setShowListCertificate(true);
                  }}
                >
                  {' '}
                  {intl.TEXT_BUTTON_ADVANCED_SEARCH}
                </Button>
              </div>
              {showButtonAddCard && (
                <div className={styles.searchButton}>
                  <Button
                    id="advancedSearchAddButton"
                    type="borderBlue"
                    onClick={() =>
                      showModalAddCard(
                        actions,
                        intl,
                        'Header',
                        binOrPan,
                        showModal,
                        addCard
                      )
                    }
                  >
                    {' '}
                    {intl.LABEL_ADD_SHORT}
                  </Button>
                </div>
              )}
            </>
          )}
      </div>
    </>
  );
};

export default AdvancedSearchCertificate;
