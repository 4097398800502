import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../TripCancellation.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();

  const { values, handleChange, handleBlur } = props;

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div
          className={`${styles.incidentPeriodArea}  ${styles.fontGray12} row`}
        >
          <div className="col-12">
            <h3 className={`${styles[theme]} ${styles.groupDivisor}`}>
              {intl.TEXT_INCIDENT_AREA}
            </h3>
          </div>

          <div className="col-12 col-md-4">
            <Select
              label={intl.TEXT_COUNTRY_INCIDENT}
              value={values.incident.location.country}
              name="incident.location.country"
              onChange={handleChange}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.COUNTRY_LABEL}</option>${intl.COUNTRY_OPTIONS}`
                ),
              }}
              onBlur={handleBlur}
              dataTestid="selectIncidentLocation"
            />
          </div>

          <div className="col-12 col-md-4">
            <Input
              label={intl.TEXT_STATE_INCIDENT}
              name="incident.location.state"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.incident.location.state}
              maxLength="100"
              dataTestid="inputLocationState"
            />
          </div>

          <div className="col-12 col-md-4">
            <Input
              label={intl.TEXT_CITY_INCIDENT}
              name="incident.location.locality"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.incident.location.locality}
              maxLength="100"
              dataTestid="inputLocationLocality"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentArea;
