export const QUOTE_UPSELL_TITLE = {
  en_US: 'Quote an upgrade coverage plan to your trip',
  pt_BR: 'Faça a cotação de um plano para aumento da cobertura da sua viagem',
  es_ES: 'Extiende tu cobertura de viaje con Axa',
};

export const QUOTE_CROSSELL_TITLE = {
  en_US: 'Quote a coverage plan for your trip',
  pt_BR: 'Faça a cotação de um plano de cobertura para sua viagem',
  es_ES: 'Cotiza un plan de cobertura para tu viaje',
};

export const PRODUCT_IS_EMPTY = {
  en_US: 'No coverage plans were found for your trip.',
  pt_BR: 'Nenhum plano de cobertura para sua viagem foi encontrado.',
  es_ES: 'No se encontraron planes de cobertura para tu viaje.',
};

export const TITLE_INCREASE_YOUR_TRAVEL_COVERAGE = {
  en_US: 'Plans to increase your travel coverage',
  pt_BR: 'Planos para aumentar sua cobertura de viagem',
  es_ES: 'Planes para aumentar tu cobertura de viaje',
};

export const MODAL_QUOTE_IEMS = {
  en_US: '',
  pt_BR: '',
  es_ES:
    '¡Gracias por tu interés!  Aquí comienza el proceso de cotización de cobertura adicional que se puede agregar a tu beneficio existente.',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const BTN_NEXT = {
  en_US: 'Next step',
  pt_BR: 'Próximo passo',
  es_ES: 'Próximo paso',
};

export const TITLE_INCREASE_COVERAGE = {
  en_US: 'Select a coverage increase option',
  pt_BR: 'Selecione uma opção de aumento de cobertura',
  es_ES: 'Selecciona una opción de aumento de cobertura',
};

export const TEXT_STEP_1 = {
  en_US: 'Enter the number of passengers',
  pt_BR: 'Informe a quantidade de passageiros',
  es_ES: 'Ingresa el número de pasajeros',
};

export const TEXT_STEP_2 = {
  en_US: 'Select a plan',
  pt_BR: 'Selecione um plano',
  es_ES: 'Selecciona un plan',
};

export const TEXT_STEP_3 = {
  en_US: 'Acceptance of terms and conditions',
  pt_BR: 'Aceitação dos termos e condições',
  es_ES: 'Aceptación de términos y condiciones',
};

export const TEXT_STEP_4 = {
  en_US: 'Travel information',
  pt_BR: 'Informação de viagem',
  es_ES: 'Información general',
};

export const TEXT_STEP_5 = {
  en_US: 'Payment information',
  pt_BR: 'Informação de pagamento',
  es_ES: 'Información para pago',
};
