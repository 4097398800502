import { getCountryByInitials } from '../../intl/utils';
import {
  CARD_HOLDER_VALUE,
  SPOUSE_VALUE,
  DEPENDENT_CHILD_VALUE,
  OTHER_VALUE,
} from '../consts';
import { formatCustomDate } from '../date';
import { getPhoneType } from '../formatInfoProfile';
import { toFixedOrDefault } from '../numbers';
import { formatPostalCodeBR } from '../stringUtils';

export const CORPORATE_LIABILITY_DATE_FORMAT = 'DD/MM/YYYY';

const getRelationshipIntl = (relationship, intl) => {
  const relationships = {
    [CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [DEPENDENT_CHILD_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  return relationships[relationship];
};

const formatPhone = (phone, intl) => {
  return phone
    ? `${getPhoneType(phone.phone_type, intl)}
    ${phone.international_prefix}
    ${phone.number}`
    : '-';
};

const formatPrice = (value, currency) =>
  value
    ? `${toFixedOrDefault(value, 2, '-')}${currency && ` (${currency})`}`
    : '-';

const getYesOrNoIntl = (value, intl) => (value ? intl.YES : intl.NO);

export const getCardholderRelationship = (affectedPerson, intl) =>
  affectedPerson && affectedPerson.relationship
    ? getRelationshipIntl(affectedPerson.relationship, intl)
    : '-';

export const getAffectedPersonComment = affectedPerson =>
  (affectedPerson && affectedPerson.comment) || '';

export const getName = affectedPerson =>
  affectedPerson && affectedPerson.person && affectedPerson.person.first_name
    ? affectedPerson.person.first_name
    : '-';

export const getLastName = affectedPerson =>
  affectedPerson && affectedPerson.person && affectedPerson.person.last_name
    ? affectedPerson.person.last_name
    : '-';

export const getCountry = (address, idiom) => {
  const country = address && address.country;
  return country ? getCountryByInitials(country, idiom) : '-';
};

export const getPostalCode = address => {
  const country = address && address.country;

  const postalCode = (address && address.postal_code) || '-';
  return country === 'BR' && postalCode !== '-'
    ? formatPostalCodeBR(postalCode)
    : postalCode;
};

export const getState = address => (address && address.state) || '-';

export const getCity = address => (address && address.locality) || '-';

export const getStreet = address => (address && address.street_address) || '-';

export const getSubdivision = address =>
  (address && address.subdivision) || '-';

export const getAddress = (address, idiom) => {
  if (Object.keys(address).length === 0) return '-';

  const street = getStreet(address);
  const subdivision = getSubdivision(address);
  const postalCode = getPostalCode(address);

  const addressHierarchy = [];
  const city = getCity(address);
  if (city !== '-') addressHierarchy.push(city);
  const state = getState(address);
  if (state !== '-') addressHierarchy.push(state);
  const country = getCountry(address, idiom);
  if (country !== '-') addressHierarchy.push(country);

  return `
    ${street !== '-' ? `${street} <br/>` : ''}
    ${subdivision !== '-' ? `${subdivision} <br/>` : ''}
    ${addressHierarchy.join(' - ')}
    ${postalCode !== '-' ? ` (${postalCode})` : ''}`;
};

export const getPhone = (person, intl) =>
  formatPhone(person && person.phones && person.phones[0], intl);

export const getValidAccountsNumber = info =>
  info && info.valid_account_number
    ? toFixedOrDefault(info.valid_account_number, 0)
    : '-';

export const getCreditLimit = info =>
  info && info.credit_limit
    ? formatPrice(info.credit_limit, info && info.currency)
    : '-';

export const getChargesSubmited = info =>
  info && info.charge_submitted_by_company
    ? toFixedOrDefault(info.charge_submitted_by_company, 0)
    : '-';

export const getAmountRecovered = info =>
  info && info.recovered_to_date
    ? toFixedOrDefault(info.recovered_to_date, 0)
    : '-';

export const getHadPaymentLast12Months = (info, intl) =>
  info && info.is_payment_made_within_last_12_months !== undefined
    ? getYesOrNoIntl(info.is_payment_made_within_last_12_months, intl)
    : '-';

export const getWasCardStolen = (info, intl) =>
  info && info.date_of_loss_or_stealth ? intl.YES : intl.NO;

export const getDateOfLoss = (incident, idiom) =>
  incident && incident.date_of_loss_or_stealth
    ? formatCustomDate(
        incident.date_of_loss_or_stealth,
        CORPORATE_LIABILITY_DATE_FORMAT,
        idiom
      )
    : '-';

export const getIsResultOfBankrupcy = (incident, intl) =>
  incident && incident.bankruptcy_declaration_date ? intl.YES : intl.NO;

export const getDateOfBankrupcy = (incident, idiom) =>
  incident && incident.bankruptcy_declaration_date
    ? formatCustomDate(
        incident.bankruptcy_declaration_date,
        CORPORATE_LIABILITY_DATE_FORMAT,
        idiom
      )
    : '-';

export const getIsCompanyResponsible = (incident, intl) =>
  incident && incident.is_the_company_responsible !== undefined
    ? getYesOrNoIntl(incident.is_the_company_responsible, intl)
    : '-';

export const getIsCardholderResponsible = (incident, intl) =>
  incident && incident.is_the_cardholder_responsible !== undefined
    ? getYesOrNoIntl(incident.is_the_cardholder_responsible, intl)
    : '-';

export const getAgencyName = agency => (agency && agency.name) || '-';

export const getAgencyDateOfAssignment = (agency, idiom, country) =>
  agency && agency.assignment_date
    ? formatCustomDate(
        agency.assignment_date,
        CORPORATE_LIABILITY_DATE_FORMAT,
        idiom,
        country
      )
    : '-';

export const getAgencyDateOfAffidavitReceivement = (agency, idiom, country) =>
  agency && agency.received_affidavit_date
    ? formatCustomDate(
        agency.received_affidavit_date,
        CORPORATE_LIABILITY_DATE_FORMAT,
        idiom,
        country
      )
    : '-';

export const getAgencyDateOfAccountCancellation = (agency, idiom, country) =>
  agency && agency.account_cancellation_date
    ? formatCustomDate(
        agency.account_cancellation_date,
        CORPORATE_LIABILITY_DATE_FORMAT,
        idiom,
        country
      )
    : '-';

export const getAgencyDateOfRequest = (agency, idiom, country) =>
  agency && agency.received_request_date
    ? formatCustomDate(
        agency.received_request_date,
        CORPORATE_LIABILITY_DATE_FORMAT,
        idiom,
        country
      )
    : '-';

export const getFinancialInstitution = certification =>
  (certification && certification.financial_institution) || '-';

export const getDateOfCertificationRecovery = (certification, idiom, country) =>
  certification && certification.recovery_date
    ? formatCustomDate(
        certification.recovery_date,
        CORPORATE_LIABILITY_DATE_FORMAT,
        idiom,
        country
      )
    : '-';
