import React, { useContext, useState, useEffect } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getItemName,
  getItemPurchasePrice,
} from '../../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import styles from '../BagProtection.module.scss';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [items, setItems] = useState([]);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    const items =
      (claimDetails && claimDetails.purchase_protection_items) || [];
    setItems(items);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <h4 className={`${styles.itemSeparator} ${styles[themes]}`}>
          {intl.TEXT_ITEM}
        </h4>
      </div>
      {items.map((item, index) => (
        <div key={index} className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={getItemName(item)}
            value={getItemPurchasePrice(item)}
          />
        </div>
      ))}
    </>
  );
};

export default PersonalArea;
