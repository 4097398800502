export const en_nationalities = `
<option value=""> Nationality *</option>
<option value="AF"> Afghan </option>
<option value="AL"> Albanian </option>
<option value="DZ"> Algerian </option>
<option value="US"> American </option>
<option value="AD"> Andorran </option>
<option value="AO"> Angolan </option>
<option value="AI"> Anguillian </option>
<option value="AQ"> Antarctica </option>
<option value="AG"> Antiguan and Barbudan </option>
<option value="AR"> Argentinian </option>
<option value="AM"> Armenian </option>
<option value="AW"> Aruban </option>
<option value="AC"> Ascension </option>
<option value="AU"> Australian </option>
<option value="AT"> Austrian </option>
<option value="AZ"> Azerbaijani </option>
<option value="BS"> Bahamian </option>
<option value="BH"> Bahraini </option>
<option value="BD"> Bangladeshi </option>
<option value="BB"> Barbadian </option>
<option value="LS"> Basotho </option>
<option value="BY"> Belarusian </option>
<option value="BE"> Belgian </option>
<option value="BZ"> Belizean </option>
<option value="BJ"> Beninese </option>
<option value="BM"> Bermudian </option>
<option value="BT"> Bhutanese </option>
<option value="GW"> Bissau–guinean </option>
<option value="BO"> Bolivian </option>
<option value="BA"> Bosnian </option>
<option value="BR"> Brazilian </option>
<option value="GB"> British </option>
<option value="VG"> British Virgin Islander </option>
<option value="BN"> Bruneian </option>
<option value="BG"> Bulgarian </option>
<option value="BF"> Burkinabé </option>
<option value="MM"> Burmese </option>
<option value="BI"> Burundian </option>
<option value="KH"> Cambodian </option>
<option value="CM"> Cameroonian </option>
<option value="CA"> Canadian </option>
<option value="CV"> Cape Verdean </option>
<option value="KY"> Cayman Islander </option>
<option value="CF"> Central-african </option>
<option value="TD"> Chadian </option>
<option value="CL"> Chilean </option>
<option value="CN"> Chinese </option>
<option value="CX"> Christmas Islander </option>
<option value="CC"> Cocos Islander </option>
<option value="CO"> Colombian </option>
<option value="KM"> Comoran </option>
<option value="CG"> Congolese </option>
<option value="CK"> Cook Islander </option>
<option value="CR"> Costa Rican </option>
<option value="HR"> Croatian </option>
<option value="CU"> Cuban </option>
<option value="CW"> Curacaoan </option>
<option value="CY"> Cypriot </option>
<option value="CZ"> Czech </option>
<option value="DK"> Danish </option>
<option value="DJ"> Djiboutian </option>
<option value="DM"> Dominican </option>
<option value="DO"> Dominican </option>
<option value="NL"> Dutch </option>
<option value="EC"> Ecuadorian </option>
<option value="EG"> Egyptian </option>
<option value="AE"> Emirati </option>
<option value="GQ"> Equatoguinean </option>
<option value="ER"> Eritrean </option>
<option value="EE"> Estonian </option>
<option value="ET"> Ethiopian </option>
<option value="FK"> Falkland Islanders </option>
<option value="FO"> Faroese </option>
<option value="FJ"> Fijian </option>
<option value="FI"> Finnish </option>
<option value="FR"> French </option>
<option value="TF"> French Antilles </option>
<option value="PF"> French Polynesian </option>
<option value="GA"> Gabonese </option>
<option value="GM"> Gambian </option>
<option value="GE"> Georgian </option>
<option value="DE"> German </option>
<option value="GH"> Ghanaian </option>
<option value="GI"> Gibraltarian </option>
<option value="GR"> Greek </option>
<option value="GL"> Greenlander </option>
<option value="GD"> Grenadian </option>
<option value="GP"> Guadeloupean </option>
<option value="GU"> Guamanian </option>
<option value="GT"> Guatemalan </option>
<option value="GN"> Guinean </option>
<option value="GF"> Guyanese </option>
<option value="GY"> Guyanese </option>
<option value="HT"> Haitian </option>
<option value="HN"> Honduran </option>
<option value="HK"> Hongkonger </option>
<option value="HU"> Hungarian </option>
<option value="KI"> I-kiribati </option>
<option value="IS"> Icelander </option>
<option value="IN"> Indian </option>
<option value="ID"> Indonesian </option>
<option value="IQ"> Iraqi </option>
<option value="IE"> Irish </option>
<option value="IL"> Israeli </option>
<option value="IT"> Italian </option>
<option value="CI"> Ivorian </option>
<option value="JM"> Jamaican </option>
<option value="JP"> Japanese </option>
<option value="JO"> Jordanian </option>
<option value="KZ"> Kazakh </option>
<option value="KE"> Kazakh </option>
<option value="KN"> Kittian </option>
<option value="KR"> Korean </option>
<option value="XK"> Kosovar </option>
<option value="KW"> Kwaiti </option>
<option value="KG"> Kyrgyzstani </option>
<option value="LA"> Laotian </option>
<option value="LV"> Latvian </option>
<option value="LB"> Lebanese </option>
<option value="LR"> Liberian </option>
<option value="LY"> Libyan </option>
<option value="LI"> Liechtensteiner </option>
<option value="LT"> Lithuanian </option>
<option value="LU"> Luxembourgish </option>
<option value="MO"> Macau </option>
<option value="MK"> Macedonian </option>
<option value="YT"> Mahoran </option>
<option value="MG"> Malagasy </option>
<option value="MW"> Malawian </option>
<option value="MY"> Malaysian </option>
<option value="MV"> Maldivian </option>
<option value="ML"> Malian </option>
<option value="MT"> Maltese </option>
<option value="IM"> Manx </option>
<option value="MP"> Mariana Islander </option>
<option value="MH"> Marshall Islander </option>
<option value="MQ"> Martinican </option>
<option value="MR"> Mauritanian </option>
<option value="MU"> Mauritian </option>
<option value="MX"> Mexican </option>
<option value="FM"> Micronesian </option>
<option value="UM"> Midway Islands </option>
<option value="MD"> Moldovan </option>
<option value="MC"> Monacan </option>
<option value="MN"> Mongolian </option>
<option value="MS"> Montserratian </option>
<option value="MA"> Moroccan </option>
<option value="BW"> Motswana </option>
<option value="MZ"> Mozambican </option>
<option value="NA"> Namibian </option>
<option value="NR"> Nauruan </option>
<option value="NP"> Nepali </option>
<option value="AN"> Netherlands Antillean </option>
<option value="NC"> New Caledonian </option>
<option value="NZ"> New Zealander </option>
<option value="VU"> Ni-vanuatu </option>
<option value="NI"> Nicaraguan </option>
<option value="NG"> Nigerian </option>
<option value="NE"> Nigerien </option>
<option value="NU"> Niuean </option>
<option value="NF"> Norfolk Islander </option>
<option value="NO"> Norwegian </option>
<option value="OM"> Omani </option>
<option value="PK"> Pakistanese </option>
<option value="PW"> Palauan </option>
<option value="PA"> Panamanian </option>
<option value="PG"> Papua New Guinean </option>
<option value="PY"> Paraguayan </option>
<option value="PE"> Peruvian </option>
<option value="PH"> Philippine </option>
<option value="PL"> Polish </option>
<option value="PT"> Portuguese </option>
<option value="PR"> Puerto Rican </option>
<option value="QA"> Qatari </option>
<option value="RO"> Romanian </option>
<option value="RU"> Russian </option>
<option value="RW"> Rwandan </option>
<option value="RE"> Réunionese </option>
<option value="SH"> Saint Helenian </option>
<option value="ST"> Saint Thomas and Prince </option>
<option value="PM"> Saint-Pierrais </option>
<option value="MP"> Saipanese </option>
<option value="SV"> Salvadorian </option>
<option value="SW"> Samoan </option>
<option value="AS"> Samoan </option>
<option value="SM"> San Marinan </option>
<option value="SA"> Saudi </option>
<option value="SN"> Senegalese </option>
<option value="RS"> Serbian </option>
<option value="SC"> Seychellois </option>
<option value="SL"> Sierra Leonean </option>
<option value="SG"> Singaporean </option>
<option value="SK"> Slovak </option>
<option value="SI"> Slovene </option>
<option value="SB"> Solomon Islander </option>
<option value="SO"> Somali </option>
<option value="ZA"> South African </option>
<option value="ES"> Spanish </option>
<option value="LK"> Sri Lankan </option>
<option value="LC"> St Lucian </option>
<option value="SR"> Surinamese </option>
<option value="SZ"> Swazi </option>
<option value="SE"> Swedish </option>
<option value="CH"> Swiss </option>
<option value="TW"> Taiwanese </option>
<option value="TJ"> Tajiki </option>
<option value="TZ"> Tanzanian </option>
<option value="TH"> Thai </option>
<option value="TG"> Togolese </option>
<option value="TK"> Tokelauan </option>
<option value="TO"> Tongan </option>
<option value="TT"> Trinidadian </option>
<option value="TN"> Tunisian </option>
<option value="TR"> Turkish </option>
<option value="TM"> Turkmen </option>
<option value="TC"> Turks and Caicos Islander </option>
<option value="TV"> Tuvaluan </option>
<option value="UG"> Ugandan </option>
<option value="UA"> Ukrainian </option>
<option value="UY"> Uruguayan </option>
<option value="UZ"> Uzbek </option>
<option value="VA"> Vatican City </option>
<option value="VE"> Venezuelan </option>
<option value="VC"> Vicentinian </option>
<option value="VN"> Vietnamese </option>
<option value="VI"> Virgin Islander (US) </option>
<option value="UM"> Wakean </option>
<option value="WF"> Wallis and Futuna Islander </option>
<option value="YE"> Yemeni </option>
<option value="YU"> Yugoslavian </option>
<option value="ZR"> Zairian </option>
<option value="ZM"> Zambian </option>
<option value="TZ"> Zanzibari </option>
<option value="ZW"> Zimbabwean </option>
`;
