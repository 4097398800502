import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

let from_date_view;
let to_date_view;

export const baggageLossSchema = intl =>
  Yup.object().shape({
    travel_information: Yup.lazy(value => {
      from_date_view = value.from_date;
      to_date_view = value.to_date;
      return Yup.object().shape({
        from_date: Yup.string()
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID)
          .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
            return moment(date).isSameOrBefore(new Date(), 'day');
          }),
        to_date: Yup.mixed()
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID)
          .test('match', intl.VALIDATE_DATE_VALID, function (date) {
            return moment(date).isSameOrAfter(this.parent.from_date);
          }),
        transportation_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      });
    }),
    incident: Yup.object().shape({
      discovered_at: Yup.date()
        .test('match', intl.VALIDATE_DATE_LOSS, function (discovered_at) {
          return (
            moment(discovered_at).isSameOrAfter(from_date_view) &&
            moment(discovered_at).isSameOrBefore(to_date_view)
          );
        })
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID),

      hour_discovered_at: Yup.string()
        .required(intl.VALIDATE_HOUR_VALID)
        .typeError(intl.VALIDATE_HOUR_VALID)
        .length(5, intl.VALIDATE_HOUR_VALID)
        .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
          return moment(hour, 'hh:mm').isValid();
        }),
      location: Yup.object().shape({
        country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      concerned_items: Yup.lazy(() => {
        let concerned = {};
        concerned.description = Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255);
        concerned.price = Yup.object().shape({
          value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        });

        return Yup.array().of(
          Yup.object().shape({
            ...concerned,
          })
        );
      }),
    }),
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),

    other_insurance_covering: Yup.object().shape({
      checked: Yup.boolean(),
      value: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
    }),

    additional_passengers: additionalShema(intl),

    other_company_covering: Yup.object().shape({
      checked: Yup.boolean(),
      value: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
    }),

    formal_complaint: Yup.object().shape({
      is_submitted: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      submission_reason: Yup.string().when(
        ['is_submitted'],
        {
          is: is_submitted => {
            return is_submitted === 'false' || is_submitted === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED)
            .max(255, intl.TEXT_FIELD_VALIDATION_255),
        },
        ['is_submitted']
      ),
    }),

    total_amount_claimed: Yup.lazy(value => {
      let totalClaimed = {};
      totalClaimed.value = Yup.number()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .max(3000, intl.TEXT_LIMIT_3000);
      totalClaimed.currency = Yup.string().required(intl.TEXT_FIELD_REQUIRED);

      return Yup.object().shape({
        ...totalClaimed,
      });
    }),
  });

const additionalShema = intl => {
  const additional = Yup.array().when(['hasAdditional'], {
    is: hasAdditional => {
      return hasAdditional === 'true';
    },
    then: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        birth_date: Yup.date()
          .max(new Date(), intl.VALIDATE_DATE_VALID)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        nationalities: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        comment: Yup.string().when(['relationship'], {
          is: relationship => {
            return relationship === constants.OTHER_VALUE;
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .max(255, intl.TEXT_FIELD_VALIDATION_255),
        }),
      })
    ),
  });

  return additional;
};
