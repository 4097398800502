import React, { useContext } from 'react';
import styles from './ErrorPage.module.scss';
import Button from '../Button/Button';
import { IntlContext } from '../../intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import img from '../../assets/images/Image_pageNotFound.png';
import { useHistory } from 'react-router-dom';

const ErrorPage = () => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const intl = translate(translations);
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.boxInfo}>
          <h1 className={styles[themes]}>{intl.TITLE}</h1>
          <p className={styles[themes]}>{intl.DESCRIPTION}</p>

          <div className={styles.footer}>
            <div className={styles.wrapButton}>
              <Button
                type="blue"
                onClick={() => {
                  history.push('/');
                  window.location.reload();
                }}
              >
                {intl.GO_HOMEPAGE}
              </Button>
            </div>

            <div className={styles.wrapButton}>
              <Button
                type="borderBlue"
                onClick={() => window.location.reload()}
              >
                {intl.TRY_AGAIN}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <img src={img} alt={intl.SESSION_EXPIRED} />
    </div>
  );
};

export default ErrorPage;
