import { FormikProps } from 'formik';
import { useIntl, useMemoTranslations } from '../../intl';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import Input from '../Input/Input';
import SearchSelect from '../SearchSelect';
import Select from '../Select/Select';
import styles from './index.module.scss';
import * as translations from './intl';
import { ChangeEvent, useMemo } from 'react';
import {
  countryCodeFromPhonePrefix,
  getCountryPrefixSelectOptions,
} from '../../utils/country';
import { removeNonNumeric } from '../../utils/stringUtils';
import { formatPhoneNumberByCountry } from '../../utils/phone';
import { PHONE_TYPE_LANDLINE, PHONE_TYPE_MOBILE } from '../../constants/phone';
import { safeGet } from '../../utils/object';

interface InputNames {
  phoneType?: string;
  countryCode: string;
  phoneNumber: string;
}

export interface PhoneFormProps {
  formikProps: FormikProps<any>;
  inputNames: InputNames;
  title?: string;
  disabled?: boolean;
  testIds?: InputNames;
  className?: string;
  axa?: boolean;
  onFinishEditingField?: (value: string) => void;
}

const PhoneForm = ({
  formikProps,
  inputNames,
  title,
  disabled,
  testIds = {
    countryCode: undefined,
    phoneNumber: undefined,
    phoneType: undefined,
  },
  className,
  axa,
  onFinishEditingField,
}: PhoneFormProps) => {
  const { getGlobalTheme } = useTheme();
  const themes = getGlobalTheme();

  const { idiom } = useIntl();
  const intl = useMemoTranslations(translations);

  const { setFieldValue, values, handleBlur, handleChange } = formikProps;

  const prefixOptions = useMemo(
    () =>
      getCountryPrefixSelectOptions(idiom, {
        showFlags: true,
        sort: 'ALPHABETICALLY',
      }).map((option, index) => ({
        ...option,
        value: `${option.value}_${index}`,
      })),
    [idiom]
  );

  const formatNumber = (phoneNumber?: string) => {
    const country = countryCodeFromPhonePrefix(
      safeGet(values, inputNames.countryCode)
    );

    if (country) {
      return formatPhoneNumberByCountry(
        phoneNumber ?? safeGet(values, inputNames.phoneNumber),
        country
      );
    }
    return phoneNumber;
  };

  return (
    <div
      className={`row ${styles.phoneArea}${className ? ` ${className}` : ''}`}
    >
      {!!title && (
        <div className="col-12">
          <h3 className={styles[themes]}>{title}</h3>
        </div>
      )}
      {!!inputNames.phoneType && (
        <div className="col-12 col-sm-12 col-md-3">
          <Select
            label={intl.LABEL_PHONE_TYPE}
            name={inputNames.phoneType}
            formikProps={formikProps}
            dataTestid={testIds.phoneType}
            disabled={disabled}
            axa={axa}
            onChange={event => {
              onFinishEditingField?.(event.target.value);
              handleChange(event);
            }}
          >
            <option value={PHONE_TYPE_MOBILE}>
              {intl.VALUE_PHONE_TYPE_MB}
            </option>
            <option value={PHONE_TYPE_LANDLINE}>
              {intl.VALUE_PHONE_TYPE_LN}
            </option>
          </Select>
        </div>
      )}
      <div
        className={`col-12 col-sm-12 col-md-${
          inputNames.phoneType ? '3' : '6'
        }`}
      >
        <SearchSelect
          label={intl.LABEL_PREFIX}
          name={inputNames.countryCode}
          formikProps={formikProps}
          options={prefixOptions}
          emptyMessage={intl.ERROR_COUNTRY_NOT_FOUND}
          onChange={value => {
            setFieldValue(
              inputNames.countryCode,
              `+${removeNonNumeric(value.split('_')[0])}`
            );
            onFinishEditingField?.(value);
          }}
          dataTestId={testIds.countryCode}
          tooltipText={intl.TEXT_ALERT_PREFIX}
          disabled={disabled}
          axa={axa}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          label={intl.LABEL_NUMBER}
          name={inputNames.phoneNumber}
          value={formatNumber(safeGet(values, inputNames.phoneNumber))}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const numeric = removeNonNumeric(event.target.value).substring(
              0,
              13
            );
            setFieldValue(inputNames.phoneNumber, numeric);
          }}
          type="text"
          formikProps={formikProps}
          tooltipText={intl.TOOLTIP_NUMBER}
          formControl
          dataTestid={testIds.phoneNumber}
          disabled={disabled}
          axa={axa}
          onBlur={event => {
            onFinishEditingField?.(event.target.value);
            handleBlur(event);
          }}
        />
      </div>
    </div>
  );
};

export default PhoneForm;
