import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const MicrophoneEnableIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-342.000000, -4039.000000)"
          fill={`${config_icon.primary}`}
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path d="M295,3894.437 L295,3897 L298,3897 L298,3899 L290,3899 L290,3897 L293,3897 L293,3894.437 C289.36,3894.028 286,3891 286,3887 L288,3887 C288,3895 300,3895 300,3887 L302,3887 C302,3891 298.64,3894.028 295,3894.437 L295,3894.437 Z M292,3881 L296,3881 L296,3886.393 C296,3887.389 295.319,3888.313 294.336,3888.473 C293.083,3888.677 292,3887.715 292,3886.5 L292,3881 Z M294.393,3890.481 C296.473,3890.283 298,3888.417 298,3886.328 L298,3879 L290,3879 L290,3886.5 C290,3888.839 292.007,3890.709 294.393,3890.481 L294.393,3890.481 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MicrophoneEnableIcon;
