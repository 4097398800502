import React, { useState } from 'react';
import styles from './ButtonSortList.module.scss';
import { useIntl } from '../../intl';
import * as translations from './intl';
import order_afluent from '../../assets/images/icon/afluent/icon_order_afluent.svg';
import order_master from '../../assets/images/icon/master/icon_order_master.svg';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

const ButtonSortList = ({
  initialOrder,
  actionToSortASC,
  actionToSortDESC,
}) => {
  const [sortType, setSortType] = useState(initialOrder || SORT_ASC);
  const { getGlobalTheme, isAfluent } = useTheme();
  const { translate } = useIntl();

  const intl = translate(translations);
  const themes = getGlobalTheme();
  const sort_icon = isAfluent() ? order_afluent : order_master;

  const onClick = () => {
    if (sortType === SORT_ASC) {
      actionToSortDESC();
      setSortType(SORT_DESC);
    } else {
      actionToSortASC();
      setSortType(SORT_ASC);
    }
  };

  return (
    <div className={styles.headerActions} onClick={onClick}>
      <img
        src={sort_icon}
        className={`${styles.iconList} ${styles[themes]}`}
        alt={intl.SORT_ICON}
      />
      <span className={`${styles.filterSelected} ${styles[themes]}`}>
        {sortType === SORT_ASC ? intl.TEXT_RECENT : intl.TEXT_LESS_RECENT}
      </span>
      <span className={styles.iconActions}>
        <ChevronGeneric
          rotate={sortType === SORT_ASC ? 0 : 180}
          width={20}
          height={20}
        />
      </span>
    </div>
  );
};

export default ButtonSortList;
