import React, { ReactNode, useContext } from 'react';
import { IntlContext } from '../../intl';
import ModalResult from '../ModalResult/ModalResult';
import ModalWithImage from '../ModalWithImage/ModalWithImage';
import * as translations from './intl';
import { StateContext } from '../StateContextParent/StateContextParent';
import { ButtonType } from '../Button/Button';

interface ModalInformationProps {
  message?: string;
  type?: string;
  textBtn?: string;
  clickBtn?: () => void;
  textBtnDeny?: string;
  clickBtnDeny?: () => void;
  textBtnThird?: string;
  clickBtnThird?: () => void;
  image?: string;
  subtitle?: string;
  buttonConfirmType?: ButtonType;
  buttonDenyType?: ButtonType;
  content?: ReactNode;
  contentRedirect?: ReactNode;
}

const ModalInformation = ({
  message,
  type,
  textBtn = null,
  clickBtn = null,
  textBtnDeny = null,
  clickBtnDeny = null,
  textBtnThird = null,
  clickBtnThird = null,
  image,
  subtitle = '',
  buttonConfirmType,
  buttonDenyType,
  content,
  contentRedirect,
}: ModalInformationProps) => {
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const btn = textBtn ? textBtn : intl.TEXT_OK;
  const click = clickBtn ? clickBtn : actions.modal.closeModal;
  const clickDeny = clickBtnDeny ? clickBtnDeny : actions.modal.closeModal;
  return (
    <ModalWithImage
      image={image}
      type={type}
      title={message}
      subtitle={subtitle}
      content={content}
    >
      <ModalResult
        textConfirm={btn}
        clickConfirm={click}
        textDeny={textBtnDeny}
        clickDeny={clickDeny}
        textBtnThird={textBtnThird}
        clickBtnThird={clickBtnThird}
        buttonConfirmType={buttonConfirmType}
        buttonDenyType={buttonDenyType}
        contentRedirect={contentRedirect}
      />
    </ModalWithImage>
  );
};

export default ModalInformation;
