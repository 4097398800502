import React, { useContext, useState } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import { callCepService } from '../../../../../../../../utils/postalCode';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../ATM.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const fieldsPostalCode = {
  uf: 'incident.location.state',
  localidade: 'incident.location.locality',
  logradouro: 'incident.location.street_address',
  bairro: 'incident.location.subdivision',
};

const IncidentLocation = props => {
  const [cepStatus, setCepStatus] = useState();
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;

  const isCountryBr = values.incident.location.country === 'BR';
  const cepService = cep => {
    if (cep.length === 9) values.incident.location.invalidCep = true;
    callCepService(
      cep,
      setCepStatus,
      'incident.location.invalidCep',
      setFieldValue,
      fieldsPostalCode
    );
  };

  const handleCepBlur = e => {
    handleBlur(e);
    if (isCountryBr) cepService(e.target.value);
  };

  const clearCepSearch = () => {
    setCepStatus();
  };

  let maskPostalCode = isCountryBr ? 'maskedPostalCodeBr' : 'maskedPostalCode';

  let cepError, cepWaiting;
  if (cepStatus === 'LOADING') {
    cepWaiting = intl.VALIDATING_CEP;
  } else if (cepStatus === 'INVALID') {
    cepError = intl.VALIDATE_CEP;
  } else {
    cepError =
      errors.incident &&
      errors.incident.location &&
      errors.incident.location.postal_code;
  }

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <h3 className={`${styles[theme]} ${styles.groupDivisor}`}>
          {intl.TITLE_INCIDENT_LOCATION}
        </h3>
      </div>
      <div className="col-12 col-sm-12 col-md-6  col-lg-4">
        <Select
          label={intl.COUNTRY_LABEL}
          name="incident.location.country"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.COUNTRY_LABEL}</option>${intl.COUNTRY_OPTIONS}`
            ),
          }}
          formikProps={props}
          dataTestid="inputLocationCountry"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-2">
        <Input
          value={values.incident.location.postal_code}
          label={intl.LABEL_POSTAL_CODE}
          name="incident.location.postal_code"
          type="text"
          onChange={handleChange}
          dataTestid="InputClaimAtmPostalCode"
          mask={maskPostalCode}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.postal_code
          }
          onBlur={handleCepBlur}
          error={cepError}
          bottomLabel={cepWaiting}
          onFocus={clearCepSearch}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          label={intl.LABEL_STATE}
          name="incident.location.state"
          type="text"
          formikProps={props}
          maxLength="100"
          dataTestid="inputLocationState"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          label={intl.LABEL_CITY}
          type="text"
          name="incident.location.locality"
          formikProps={props}
          maxLength="100"
          dataTestid="inputLocationLocality"
        />
      </div>
      <div className="col-12 col-lg-6">
        <Input
          label={intl.LABEL_ADDRESS}
          name="incident.location.street_address"
          type="text"
          formikProps={props}
          maxLength="255"
          dataTestid="inputLocationAddress"
        />
      </div>
      <div className="col-12 col-lg-6">
        <Input
          label={intl.LABEL_SUBDIVISION}
          name="incident.location.subdivision"
          type="text"
          formikProps={props}
          maxLength="100"
          dataTestid="inputLocationSubdivision"
        />
      </div>
    </div>
  );
};

export default IncidentLocation;
