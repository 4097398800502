import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import * as constants from '../../consts';
import styles from '../AdditionalPassengers.module.scss';
import ButtonWithIcon from '../../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';

const PassengerAdd = props => {
  const { values, setFieldValue } = props;
  const { translate } = useContext(IntlContext);
  const { isAfluent } = useContext(ThemeContext);
  const intl = translate(translations);

  const addAdditional = event => {
    event.preventDefault();
    const additional = [...values.additional_passengers];
    additional.push(constants.ADDITIONAL_OBJECT);
    setFieldValue('additional_passengers', additional);
  };
  return (
    <div className="row">
      <div className={`col-12 ${styles.add}`}>
        <ButtonWithIcon
          type="blueWithoutBorder"
          onClick={addAdditional}
          value={intl.BTN_ADD_ADDITIONAL}
          isSVGComponent={true}
          img={<AddIcon />}
        />
      </div>
    </div>
  );
};

export default PassengerAdd;
