import React, { useContext } from 'react';
import ArrowRightIcon from '../../assets/icons/ArrowRight';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';
import Button from '../Button/Button';
import * as translations from '../ALink/intl';
import { useIntl } from '../../intl';

const ButtonLinkExternal = props => {
  const { children, noIcon } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useIntl();

  const intl = translate(translations);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  const ariaLabel = props.ariaLabel
    ? `${props.ariaLabel} - ${intl.NEW_TAB}`
    : undefined;

  return (
    <Button {...props} ariaLabel={ariaLabel}>
      {children}
      {!noIcon && (
        <ArrowRightIcon
          width={22}
          height={16}
          rotate={135}
          color={config_icon.white}
        />
      )}
    </Button>
  );
};
export default ButtonLinkExternal;
