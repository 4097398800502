/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import styles from './index.module.scss';
import * as translations from './intl';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import Button from '../../../../components/Button/Button';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { cabifyService } from '../../../../services';
import { useHistory } from 'react-router-dom';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import CabifyMobile from '../../../../assets/images/cabify/Cabify_Mobile.png';
import CabifyQrCode from '../../../../assets/images/cabify/Cabify_QR.png';
import EditIcon from '../../../../assets/icons/EditIcon';
import { dataLayerAppSelectTrack } from '../../../../utils/GTM_helper';

const Form = props => {
  const { setStep } = props;
  const [isMobile, setIsMobile] = useState(false);
  const { utils, actions, state } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { user } = state;
  const history = useHistory();
  const card = utils.getSelectedCard();
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  const checkWindowWidth = () => {
    if (window.screen.width > 990) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const successModalContent = () => {
    return (
      <div className={styles.groupInline}>
        <div className={styles.appImagesContainer}>
          <a
            className={styles.imageLink}
            href={'https://apps.apple.com/app/cabify/id476087442'}
            onClick={() => dataLayerAppSelectTrack('Apple Store')}
          >
            <img
              className={styles.link}
              src={intl.IMAGE_APP_STORE}
              alt="App Store"
            />
          </a>

          <a
            className={styles.imageLink}
            href={
              'https://play.google.com/store/apps/details?id=com.cabify.rider'
            }
            onClick={() => dataLayerAppSelectTrack('Google Play')}
          >
            <img
              className={styles.link}
              src={intl.IMAGE_PLAY_STORE}
              alt="Google Play"
            />
          </a>
        </div>

        <img
          className={styles.QRcode}
          src={CabifyQrCode}
          alt="Cabify QR Code"
        />
      </div>
    );
  };

  const submitCabifyProspectCreation = async () => {
    try {
      trackEventUserAction(`#### (CABIFY) CABIFY SUBMIT FLOW`, { user, card });
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.TEXT_CREATING_USER_CABIFY
      );
      await cabifyService.createProspectInCabify();
      trackEventUserAction(
        `#### (CABIFY) SUCCSESS CREATING PROSPECT IN CABIFY`,
        {
          user,
          card,
        }
      );
      actions.loadingFullScreen.hideLoadingFullScreen();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.TITLE_CABIFY_SUCCESS}
          subtitle={intl.SUBTITLE_CABIFY_SUCCESS}
          textBtn={intl.GO_HOME}
          clickBtn={() => {
            actions.modal.closeModal();
            history.push('/dashboard');
          }}
          image={CabifyMobile}
          content={successModalContent()}
        />,
        true
      );
    } catch (error) {
      actions.loadingFullScreen.hideLoadingFullScreen();
      trackEventUserAction(`#### (CABIFY) ERROR TO CREATE PROSPECT IN CABIFY`, {
        user,
        card,
      });
      actions.modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={intl.TRY_AGAIN} />,
        true
      );
    }
  };

  return (
    <ShadowBox>
      <div className={styles.contentContainer}>
        <h2 className={styles[theme]}>{intl.TITLE_CABIFY_INFO}</h2>

        <div className={styles.personalInfocontainer}>
          <div className={styles.fieldContainer}>
            <p className={styles.label}>
              <strong>{intl.TEXT_FIRST_NAME}</strong>
              {': '} {user.data.userInfo.firstName}
            </p>
          </div>

          <div className={styles.fieldContainer}>
            <p className={styles.label}>
              <strong>{intl.TEXT_LAST_NAME}</strong>
              {': '} {user.data.userInfo.lastName}
            </p>
          </div>

          <div className={styles.fieldContainer}>
            <p className={styles.label}>
              <strong>{intl.TEXT_EMAIL}</strong>
              {': '} {user.data.userInfo.email}
            </p>
          </div>
        </div>
        <div className={styles.disclaimerContainer}>
          <h4>{intl.DISCLAIMER_CABIFY}</h4>

          <Link
            className={styles[theme]}
            to={'/profile/account#contact'}
            onClick={() => history.push('/profile/account#contact')}
          >
            {!isMobile && (
              <span className={styles.icon}>
                <EditIcon width={22} height={22} />
              </span>
            )}

            {isMobile ? intl.TEXT_EDIT_MOB : intl.TEXT_EDIT_PROFILE}
          </Link>
        </div>

        <div className={styles.footer}>
          <div className={styles.buttonArea}>
            <Button type="borderBlue" onClick={() => setStep(1)}>
              {intl.TEXT_BACK}
            </Button>
            <Button type="blue" onClick={() => submitCabifyProspectCreation()}>
              {intl.BTN_SIGN_UP}
            </Button>
          </div>
        </div>
      </div>
    </ShadowBox>
  );
};

export default Form;
