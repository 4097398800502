import React, { useContext, useState } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import * as translationsPErsonal from '../intlPersonal';
import { callCepService } from '../../../../../../../../utils/postalCode';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from './AgenciesArea.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const fieldsPostalCode = {
  uf: 'collection_agencies.address.state',
  localidade: 'collection_agencies.address.locality',
  logradouro: 'collection_agencies.address.street_address',
  bairro: 'collection_agencies.address.subdivision',
};

const AgenciesArea = props => {
  const [cepStatus, setCepStatus] = useState();
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const intlPersonal = translate(translationsPErsonal);
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const isCountryBr = values.collection_agencies.address.country === 'BR';
  const cepService = cep => {
    if (cep.length === 9) values.collection_agencies.address.invalidCep = true;
    callCepService(
      cep,
      setCepStatus,
      'collection_agencies.address.invalidCep',
      setFieldValue,
      fieldsPostalCode
    );
  };

  const handleCepBlur = e => {
    handleBlur(e);
    if (isCountryBr) cepService(e.target.value);
  };

  const clearCepSearch = () => {
    setCepStatus();
  };

  let maskPostalCode = isCountryBr ? 'maskedPostalCodeBr' : 'maskedPostalCode';

  let cepError, cepWaiting;
  if (cepStatus === 'LOADING') {
    cepWaiting = intl.VALIDATING_CEP;
  } else if (cepStatus === 'INVALID') {
    cepError = intl.VALIDATE_CEP;
  } else {
    cepError =
      errors.collection_agencies &&
      errors.collection_agencies.address &&
      errors.collection_agencies.address.postal_code;
  }

  return (
    <div className={`row ${styles.personalArea}`}>
      <div className={`col-12`}>
        <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
          {intl.COLLECTION_INFORMATION}
        </h3>
      </div>

      <div className="col-12">
        <Input
          label={intl.AGENCY_NAME}
          name="collection_agencies.name"
          type="text"
          formikProps={props}
          maxLength="100"
        />
      </div>

      <div className="col-12">
        <div
          className={`${styles.fontGray12} ${styles[themes]} ${styles.options} row`}
        >
          <div className="col-12 col-sm-12 col-md-6  col-lg-4">
            <Select
              dataTestid="selectAgenciesCountry"
              label={intlPersonal.COUNTRY_LABEL}
              name="collection_agencies.address.country"
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intlPersonal.COUNTRY_LABEL}</option>${intlPersonal.COUNTRY_OPTIONS}`
                ),
              }}
              formikProps={props}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-2">
            <Input
              value={values.collection_agencies.address.postal_code}
              label={intlPersonal.LABEL_POSTAL_CODE}
              name="collection_agencies.address.postal_code"
              type="text"
              onChange={handleChange}
              mask={maskPostalCode}
              touched={
                touched.collection_agencies &&
                touched.collection_agencies.address &&
                touched.collection_agencies.address.postal_code
              }
              onBlur={handleCepBlur}
              error={cepError}
              bottomLabel={cepWaiting}
              onFocus={clearCepSearch}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <Input
              label={intlPersonal.LABEL_STATE}
              name="collection_agencies.address.state"
              type="text"
              formikProps={props}
              maxLength="100"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <Input
              label={intlPersonal.LABEL_CITY}
              name="collection_agencies.address.locality"
              type="text"
              formikProps={props}
              maxLength="100"
            />
          </div>
          <div className="col-12 col-lg-6">
            <Input
              label={intlPersonal.LABEL_ADDRESS}
              name="collection_agencies.address.street_address"
              type="text"
              formikProps={props}
              maxLength="255"
            />
          </div>
          <div className="col-12 col-lg-6">
            <Input
              label={intlPersonal.LABEL_SUBDIVISION}
              name="collection_agencies.address.subdivision"
              type="text"
              formikProps={props}
              maxLength="100"
            />
          </div>
        </div>
      </div>

      <div className={`col-12`}>
        <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
          {intl.CONTACT_PERSON}
        </h3>
      </div>

      <div className="col-12 col-md-6">
        <Input
          label={intl.FIRST_NAME}
          name="collection_agencies.contacts.person.first_name"
          type="text"
          formikProps={props}
          maxLength="40"
        />
      </div>

      <div className="col-12 col-md-6">
        <Input
          label={intl.LAST_NAME}
          name="collection_agencies.contacts.person.last_name"
          type="text"
          formikProps={props}
          maxLength="80"
        />
      </div>
    </div>
  );
};

export default AgenciesArea;
