import React, { useContext } from 'react';
import styles from './ModalWithImage.module.scss';
import ModalGenericSuccess from '../../assets/images/modal/modalSuccess.jpg';
import ModalGenericError from '../../assets/images/modal/modalError.jpg';
import ModalCreditCard from '../../assets/images/modal/modal_image_credit_card.png';
import sanitize from '../../utils/sanitize';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import SuccessIcon from '../../assets/icons/SuccessIcon';
import ErrorIcon from '../../assets/icons/ErrorIcon';
import QuestionIcon from '../../assets/icons/QuestionIcon';
import ErrorAttentionIcon from '../../assets/icons/ErrorAttentionIcon';
import * as translations from './intl';
import { IntlContext } from '../../intl';

const ModalWithImage = ({
  type = '',
  title,
  subtitle,
  children,
  image,
  content,
}) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const matchImageType = type => {
    switch (type) {
      case 'success':
        return ModalGenericSuccess;
      case 'error':
        return ModalGenericError;
      default:
        return ModalCreditCard;
    }
  };

  const ICON_IMAGE = {
    success: (
      <SuccessIcon
        modal_success={1}
        width={40}
        height={40}
        aria-label={intl.SUCCESS}
      />
    ),
    error_credit_card: (
      <ErrorIcon
        modal_error={1}
        width={40}
        height={40}
        aria-label={intl.ERROR_CREDIT_CARD}
      />
    ),
    question: (
      <QuestionIcon width={40} height={40} aria-label={intl.QUESTION} />
    ),
    error: (
      <ErrorAttentionIcon
        modal_error={1}
        width={40}
        height={40}
        aria-label={intl.ERROR}
      />
    ),
  };

  const colorRed =
    type === 'error' || type === 'error_credit_card' ? styles.colorRed : '';

  const img = image ? image : matchImageType(type);

  return (
    <div className={styles.boxModalWithImage}>
      <div className={`row ${styles.section}`}>
        <div className={`${styles.boxWhite} col-12 col-lg-6`}>
          {type && type.length > 0 && (
            <div className={styles.icon}>{ICON_IMAGE[type]}</div>
          )}
          <div id="dialog_label" className={styles.title}>
            <h2 className={`${styles[theme]}${colorRed}`}>{title}</h2>
          </div>
          {subtitle && (
            <div className={styles.subtitle} aria-label={intl.SUBTITLE}>
              <h4
                className={styles[theme]}
                dangerouslySetInnerHTML={{
                  __html: sanitize(subtitle),
                }}
              />
              {content && content}
            </div>
          )}
          <div id="dialog_desc" className={styles.children}>
            {children}
          </div>
        </div>
        <div className={`${styles.boxGrey} col-12 col-lg-4`}>
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ModalWithImage;
