import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';
import ChevronDownIcon from './ChevronDownIcon';
import ChevronUpIcon from './ChevronUpIcon';
import ChevronRightIcon from './ChevronRightIcon';
import ChevronLeftIcon from './ChevronLeftIcon';

const ChevronGeneric = props => {
  const { rotate = 0 } = props;
  const renderChevron = {
    'rotate-180': () => <ChevronUpIcon {...props} />,
    'rotate-0': () => <ChevronDownIcon {...props} />,
    'rotate-90': () => <ChevronLeftIcon {...props} />,
    'rotate-270': () => <ChevronRightIcon {...props} />,
  };
  return <>{renderChevron[`rotate-${rotate}`]()}</>;
};

export default ChevronGeneric;
