import React, { useContext } from 'react';
import styles from './StepClaimItem.module.scss';
import dotEmpty from '../../../../assets/images/icon/svg_dot.svg';
import dotWhite from '../../../../assets/images/icon/svg_dot_white.svg';
import dotGray from '../../../../assets/images/icon/svg_dot_gray.svg';
import dotGreen from '../../../../assets/images/icon/svg_dot_green.svg';
import dotAxaBlue from '../../../../assets/images/icon/svg_dot_AxaBlue.svg';
import dotGoldAfluent from '../../../../assets/images/icon/afluent/svg_dot_gold.svg';
import dotGoldMaster from '../../../../assets/images/icon/master/svg_dot_gold.svg';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';

const getStatus = (selected, concluded) => {
  if (selected) {
    return 'SELECTED';
  } else if (concluded) {
    return 'CONCLUDED';
  } else {
    return 'EMPTY';
  }
};

const getStyle = status => {
  switch (status) {
    case 'SELECTED':
      return styles.gold;
    case 'CONCLUDED':
      return styles.light;
    default:
      return '';
  }
};

const StepClaimItem = props => {
  const { selected, concluded, children, axa, name } = props;
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const dot = {
    CONCLUDED: axa ? dotGreen : dotGreen,
    SELECTED: axa ? dotAxaBlue : isAfluent() ? dotGoldAfluent : dotGoldMaster,
    EMPTY: axa ? dotGray : dotGray,
  };

  const status = getStatus(selected, concluded);
  const style = getStyle(status);

  return (
    <div className={`${styles.itemContainer} ${axa ? styles.axaStyled : ''}`}>
      <div className={styles.item}>
        <img src={dot[status]} alt={`status-${status}`} />
        <div
          className={`${styles.label} ${styles[theme]} ${
            axa ? styles.axaLabel : ''
          }`}
        >
          <span
            className={`${styles[theme]} ${style} ${
              name ? styles.hasName : ''
            }`}
          >
            {children}
          </span>
          {name && (
            <p className={`${styles.stepName} ${styles[theme]} ${style}`}>
              {name}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepClaimItem;
