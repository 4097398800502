import { useContext, useEffect, useState } from 'react';
import { LoadingStatus } from '../../../@types/LoadingStatus';
import { TravelDetail } from '../../../@types/APIs/Travels';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { AppInsightTrackContext } from '../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { travelService } from '../../../services';
import {
  FlightDetails,
  HandledFlights,
  formatFlights,
  getFlightNumberList,
  getFlightNumbers,
  getFlights,
} from './utilsDetails';
import HorizontalHalfBlueLayout from '../../../layouts/HorizontalHalfBlueLayout';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import AccordionTravelDetails from './AccordionTravelDetails/AccordionTravelDetails';
import FlightDetailList from './FlightDetailList/FlightDetailList';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { RouteComponentProps } from 'react-router-dom';
import styles from './TravelDetails.module.scss';
import { getCountryFromPrefixTranslated } from '../../../utils/country';
import { getRouteIntl } from '../utils';

const createFlightDetails = (
  travel: TravelDetail,
  flights: HandledFlights,
  isReturn = false
) => {
  return (
    <FlightDetailList travel={travel} flights={flights} isReturn={isReturn} />
  );
};

interface RouteParams {
  travel_id: string;
  flight_number?: string;
}

const TravelDetails = ({ match }: RouteComponentProps<RouteParams>) => {
  const { travel_id, flight_number } = match.params;
  const { utils } = useContext(StateContext);
  const { translate, idiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [travelDetails, setTravelDetails] = useState<TravelDetail>(null);
  const [flights, setFlights] = useState<HandledFlights>(null);

  const [openAccordion, setOpenAccordion] = useState<'OUTBOUND' | 'RETURN'>(
    'OUTBOUND'
  );

  const customer_id = utils.getCn();
  const intl = translate(translations);

  const loadTravelDetails = async () => {
    try {
      setStatus(LoadingStatus.LOADING);

      trackEventUserAction(
        `#### Trip Assistance - Details ### Requesting Travel detail`,
        { customer_id: customer_id, travel_id }
      );

      const { data: travelData } = await travelService.getTravelDetail(
        customer_id,
        travel_id
      );

      await loadFlightDetails(travelData);
      setTravelDetails(travelData);
      setStatus(LoadingStatus.LOADED);

      trackEventUserAction(
        `#### Trip Assistance - Details ### Travel detail requested`,
        { data: travelData }
      );
    } catch (error) {
      setStatus(LoadingStatus.ERROR);
      trackEventUserAction(
        `#### Trip Assistance - Details ### Error requesting travel detail`,
        { data: error?.response?.data || error }
      );
    }
  };

  const loadFlightDetails = async (travel: TravelDetail) => {
    try {
      const flights = getFlightNumberList(travel);
      const flightDetails: FlightDetails = {};

      await Promise.allSettled(
        flights.map(async flight => {
          const flights = await getFlights(flight, trackEventUserAction);
          if (flights) flightDetails[flight] = flights;
        })
      );

      const formattedFlights = formatFlights(travel, flightDetails);
      setFlights(formattedFlights);

      trackEventUserAction(
        `#### Trip Assistance - Details ### Flights details requested`,
        { data: flightDetails }
      );
    } catch (error) {
      trackEventUserAction(
        `#### Trip Assistance - Details ### Error requesting flights`,
        { data: error?.response?.data || error }
      );
    }
  };

  useEffect(() => {
    loadTravelDetails();
  }, []);

  useEffect(() => {
    if (status === LoadingStatus.LOADED && flight_number) {
      const flightNumbers = getFlightNumbers(travelDetails);

      const isReturnFLight = flightNumbers.return.some(
        flightNumber => flightNumber === flight_number
      );
      setOpenAccordion(isReturnFLight ? 'RETURN' : 'OUTBOUND');
    }
  }, [status]);

  return (
    <HorizontalHalfBlueLayout status={status}>
      <SessionContainer>
        <HeadingTitle
          title={getRouteIntl(intl, idiom, travelDetails)}
          subtitle={intl.MY_TRIP}
          className={styles.header}
        />
        <div id="mainContent">
          <AccordionTravelDetails
            open={openAccordion === 'OUTBOUND'}
            initialFlightFocus={openAccordion === 'OUTBOUND' && flight_number}
            title={intl.OUTBOUND}
          >
            {createFlightDetails(travelDetails, flights)}
          </AccordionTravelDetails>
          {!travelDetails?.is_one_way_travel && (
            <AccordionTravelDetails
              open={openAccordion === 'RETURN'}
              initialFlightFocus={openAccordion === 'RETURN' && flight_number}
              title={intl.RETURN}
            >
              {createFlightDetails(travelDetails, flights, true)}
            </AccordionTravelDetails>
          )}
        </div>
      </SessionContainer>
    </HorizontalHalfBlueLayout>
  );
};

export default TravelDetails;
