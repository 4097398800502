import React, { useContext } from 'react';
import styles from './HeadingTitleAXA.module.scss';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import axa_logo from '../../assets/images/logo_AXA.jpeg';

const HeadingTitleAXA = props => {
  const { title, subtitle, description } = props;
  const history = useHistory();
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={`${styles[theme]} ${styles.headingTitle} ${styles.axa}`}>
      <div className={styles.upArea}>
        <div className={styles.box}>
          <h4 className={styles[theme]}>{subtitle}</h4>
        </div>
        <img src={axa_logo} alt="" className={styles.litleLogo} />
      </div>
      <div className={styles.descriptionArea}>
        <div className={styles.text}>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        <img src={axa_logo} alt="" className={styles.bigLogo} />
      </div>
    </div>
  );
};

export default HeadingTitleAXA;
