export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const LABEL_AMOUNT_TO_BE_PAYED = {
  en_US: 'Amount paid',
  pt_BR: 'Valor pago',
  es_ES: 'Valor pago',
};
