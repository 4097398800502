import React, { useContext, useState } from 'react';
import { pt_BR } from '../../../../intl/idioms';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import CheckTerms from './CheckTerms/CheckTerms';
import CertificatesArea from '../../Generate/SearchCertificates/CertificatesArea/CertificatesArea';
import styles from './styles.module.scss';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import FormSearchCertificates from '../../Generate/SearchCertificates/FormSearchCertificates/FormSearchCertificates';
import Button from '../../../../components/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';

const getPdfLink = claimDetails => {
  const pdfDocument = claimDetails.documents.find(
    document => document.name === 'pdf_url'
  );
  return pdfDocument && pdfDocument.content_url;
};

const reduceAcceptChecked = (sum, checked) => (checked ? sum + 1 : sum);

const TermsAndConditions = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { setCertificate, claimDetails, claimType, setStep } = props;
  const [acceptChecked, setAcceptChecked] = useState([]);
  const isBrazil = idiom === pt_BR;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const [incidentDate, setIncidentDate] = useState(null);
  const acceptSum = acceptChecked.reduce(reduceAcceptChecked, 0);
  const acceptedAll = isBrazil ? acceptSum === 5 : acceptSum === 4;
  const pdfLink = claimDetails && getPdfLink(claimDetails);

  const check = index => {
    const _acceptChecked = [...acceptChecked];
    _acceptChecked[index] = !_acceptChecked[index];
    setAcceptChecked(_acceptChecked);
  };

  return (
    <div id="mainContent">
      <ShadowBox>
        <div className={styles.termsArea}>
          <h2 className={styles[themes]}>{intl.WELCOME_MEDIC}</h2>
          <p className={styles[themes]}>{intl.CONDITION_ELIGIBLE}</p>
          <p className={styles[themes]}>{intl.READ_CAREFUL}</p>
          <div className={`${styles.acceptArea} ${styles[themes]}`}>
            <CheckTerms
              isBrazil={isBrazil}
              acceptChecked={acceptChecked}
              check={check}
              pdfLink={pdfLink}
              styles={styles}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.buttonArea}>
            <Button
              type="borderBlue"
              disabled={!acceptedAll}
              onClick={() => setStep('FORM')}
            >
              {intl.BTN_NEXT_STEP}
            </Button>
          </div>
        </div>
        {/*     {isBrazil ? (
          <>
            <FormSearchCertificates
              acceptChecked={!acceptedAll}
              incidentDateState={[incidentDate, setIncidentDate]}
            />

            {incidentDate && (
              <CertificatesArea
                claimType={claimType}
                isBrazil={isBrazil}
                acceptChecked={!acceptedAll}
                setCertificate={setCertificate}
                incidentDate={incidentDate}
                isTeleconsultation
              />
            )}
          </>
        ) : (
          <div className={styles.footer}>
            <div className={styles.buttonArea}>
              <Button
                type="borderBlue"
                disabled={!acceptedAll}
                onClick={() => setStep('FORM')}
              >
                {intl.BTN_NEXT_STEP}
              </Button>
            </div>
          </div>
        )} */}
      </ShadowBox>
    </div>
  );
};

export default TermsAndConditions;
