import { useContext } from 'react';
import { IntlContext } from '../../../intl';
import * as translations from '../../../intl/travels';
import { useFlightInfo } from '../FlightInfoContext';
import { getDelayText } from './utils';
import styles from './index.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const DELAY_THRESHOLD_SECONDS = 5 * 50; // 5 Min

const DelayRow = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const { flightInfo } = useFlightInfo();
  const { departureDelay, arrivalDelay, cancelled } = flightInfo;

  const isDepartureDelayed = departureDelay > DELAY_THRESHOLD_SECONDS;
  const isDepartureEarly = departureDelay < -DELAY_THRESHOLD_SECONDS;

  const isArrivalDelayed = arrivalDelay > DELAY_THRESHOLD_SECONDS;
  const isArrivalEarly = arrivalDelay < -DELAY_THRESHOLD_SECONDS;

  if (
    !isDepartureDelayed &&
    !isDepartureEarly &&
    !isArrivalDelayed &&
    !isArrivalEarly &&
    !cancelled
  ) {
    return null;
  }

  if (cancelled) {
    return (
      <div className={styles.container}>
        <p className={styles.alert}>{intl.FLIGHT_WAS_CANCELED}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {isDepartureDelayed && (
        <p className={`${styles.orange} ${styles[theme]}`}>
          {getDelayText(departureDelay, intl.DELAYED)}
        </p>
      )}
      {isDepartureEarly && (
        <p className={`${styles.green} ${styles[theme]}`}>
          {getDelayText(departureDelay, intl.EARLY)}
        </p>
      )}
      <div className={styles.spacer} />
      {isArrivalDelayed && (
        <p className={`${styles.orange} ${styles[theme]}`}>
          {getDelayText(arrivalDelay, intl.DELAYED)}
        </p>
      )}
      {isArrivalEarly && (
        <p className={`${styles.green} ${styles[theme]}`}>
          {getDelayText(arrivalDelay, intl.EARLY)}
        </p>
      )}
    </div>
  );
};

export default DelayRow;
