import React, { useState, useContext } from 'react';
import styles from './ExpansiveMenu.module.scss';
import * as translations from './intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerHelpFAQTrack } from '../../utils/GTM_helper';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import { IntlContext } from '../../intl';
import sanitize from '../../utils/sanitize';

const ExpansiveMenu = props => {
  const { children, title, id, defaultShow, filterSelectedId } = props;
  const [show, setShow] = useState(defaultShow || false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const openMenu = () => {
    setShow(true);
    const filter = filterSelectedId && filterSelectedId.split('filter')[1];
    dataLayerHelpFAQTrack(`Click ${filter}`, title);
  };

  const toggle = () => (show === false ? openMenu() : setShow(false));

  return (
    <div id={id ? id : undefined}>
      <div
        className={`${styles.header} ${styles[themes]}`}
        data-testid="container"
        onClick={toggle}
      >
        <h6
          className={styles[themes]}
          dangerouslySetInnerHTML={{ __html: sanitize(title) }}
        ></h6>
        {show === false ? (
          <ChevronGeneric
            rotate={0}
            width={30}
            height={30}
            aria-label={intl.ARROW_DOWN}
            className={styles.iconHeader}
          />
        ) : (
          <ChevronGeneric
            aria-label={intl.ARROW_UP}
            rotate={180}
            width={30}
            height={30}
          />
        )}
      </div>
      {show && (
        <div className={`${styles.questionContent} ${styles[themes]}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default ExpansiveMenu;
