import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './index.module.scss';

interface GroupDivisorProps {
  title: string;
  className?: string;
  id?: string;
}

const GroupDivisor = ({ title, className, id }: GroupDivisorProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  let handledClassName = `${styles[theme]} ${styles.groupDivisor}`;
  if (className) handledClassName += ` ${className}`;

  return (
    <h3 className={handledClassName} id={id}>
      {title}
    </h3>
  );
};

export default GroupDivisor;
