import { useState, useContext } from 'react';
import styles from './TermsAndConditions.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../intl/index';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import Button from '../../../../components/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  CLAIM_TERMS_OPENED,
  dataLayerClaimEventTrack,
} from '../../../../utils/GTM_helper';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import ALink from '../../../../components/ALink';

const getLinkDocument = tripAssistanceData => {
  const pdf = tripAssistanceData?.documents?.find(
    document => document.name === 'pdf_url'
  );

  return pdf?.content_url ? pdf.content_url : '';
};

const TermsAndConditions = props => {
  const { tripAssistanceData, setStep } = props;

  const [genericAccept, setGenericAccept] = useState(false);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const termsAndConditionsContent = () => {
    return (
      <>
        <div id="mainContent" className={styles.termsArea}>
          <h2 className={styles[theme]}>
            {intl.ACCEPTING_TERMS_AND_CONDITIONS}
          </h2>

          <div className={styles.maxCoverageArea}>
            <h3 className={styles[theme]}>
              {intl.TEXT_TERM_AND_CONDITIONS_CONTENT}
            </h3>
          </div>

          <div className={styles.pdf}>
            <PDFIcon width={42} height={54} />
            <div
              className={styles.pdfInfo}
              onClick={() =>
                dataLayerClaimEventTrack(
                  CLAIM_TERMS_OPENED,
                  tripAssistanceData.benefit_id
                )
              }
            >
              <ALink
                className={styles[theme]}
                href={getLinkDocument(tripAssistanceData)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.TERMS_AND_CONDITIONS}
              </ALink>
              <span>{intl.TRIP_ASSISTANCE}</span>
            </div>
          </div>
        </div>

        <div className={`${styles[theme]} ${styles.acceptArea}`}>
          <CheckBox
            name="accept_terms"
            value={genericAccept}
            onClick={check => setGenericAccept(check)}
          >
            {intl.ACCEPT_TERMS}
          </CheckBox>
        </div>
        <div className={styles.footer}>
          <div className={styles.buttonArea}>
            <Button
              type="blue"
              disabled={!genericAccept}
              onClick={() => setStep(2)}
            >
              {intl.BTN_NEXT_STEP}
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <ShadowBox>{termsAndConditionsContent()}</ShadowBox>
    </div>
  );
};

export default TermsAndConditions;
