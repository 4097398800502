import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './ComplementPersonalArea.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const ComplementPersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={`row`}>
      <div className={`col-12`}>
        <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
          {intl.CONTACT_PERSON}
        </h3>
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          label={intl.FIRST_NAME}
          name="contacts.person.first_name"
          type="text"
          formikProps={props}
          maxLength="40"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          label={intl.LAST_NAME}
          name="contacts.person.last_name"
          type="text"
          formikProps={props}
          maxLength="80"
        />
      </div>
    </div>
  );
};

export default ComplementPersonalArea;
