import BlueboxButtonsBenefits from './BlueboxButtonBenefits';
import BlueboxContainer from './BlueboxContainer';
import BlueboxImageLink from './BlueboxImageLink';
import BlueboxLink from './BlueboxLink';
import BlueboxNotice from './BlueboxNotice';
import BlueboxQRCode from './BlueboxQRCode';
import BlueboxSubContainer from './BlueboxSubContainer';
import BlueboxText from './BlueboxText';
import BlueboxTitle from './BlueboxTitle';

export default {
  ButtonBenefits: BlueboxButtonsBenefits,
  Container: BlueboxContainer,
  ImageLink: BlueboxImageLink,
  Link: BlueboxLink,
  Notice: BlueboxNotice,
  QRCode: BlueboxQRCode,
  SubContainer: BlueboxSubContainer,
  Text: BlueboxText,
  Title: BlueboxTitle,
};
