import React, { useContext, useState } from 'react';
import styles from '../AddCardItem/AddCardItem.module.scss';
import { StateContext } from '../../StateContextParent/StateContextParent';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { showModalAddCard } from '../../ModalAddCard/functionShowModal';
import AddIcon from '../../../assets/icons/AddIcon';
import AddCardIcon from '../../../assets/icons/AddCardIcon';

const AddCardItem = ({ setOpenSubMenu }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const [showModal, setShowModal] = useState(1);

  const changeModal = value => {
    actions.modal.closeModal();
    setShowModal(value);
    showModalAddCard(actions, intl, 'Header', false, value, changeModal);
  };

  return (
    <li>
      <button
        className={`${styles[theme]} ${styles.cardItem}`}
        onClick={() =>
          showModalAddCard(
            actions,
            intl,
            'Header',
            false,
            showModal,
            changeModal
          )
        }
        aria-label={intl.ADD_PAN}
        data-testid="btnAddNewCard"
      >
        <AddCardIcon width={50} height={50} alt="" />

        <div className={styles.bin}>
          <span>{intl.TEXT_ADD_NEW_CARD}</span>

          <AddIcon width={25} height={25} alt="" />
        </div>
      </button>
    </li>
  );
};

export default AddCardItem;
