export const MODAL_CONFIRMATION_UPDATE_BANK = {
  en_US: 'Your bank information has been changed. Do you want to save it?',
  pt_BR: 'Suas informações bancárias foram alteradas. Deseja salvá-las?',
  es_ES: 'Se ha modificado tu información bancaria. ¿Quieres guardarlo?',
};

export const MODAL_CONFIRMATION_UPDATE_BANK_SUCCESS = {
  en_US: 'Your bank information has been updated successfully.',
  pt_BR: 'Suas informações bancárias foram atualizadas com sucesso.',
  es_ES: 'Tu información bancaria se ha actualizada correctamente.',
};

export const TEXT_CH_CHANGE = {
  en_US: 'Your personal details has been changed. Do you want to save it?',
  pt_BR: 'Suas informações pessoais foram modificadas. Deseja salvá-las?',
  es_ES:
    'Tu información personal ha sido modificada. ¿Deseas guardar los cambios?',
};
export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_YES_I_DO = {
  en_US: 'Yes, I do',
  pt_BR: 'Sim, eu quero',
  es_ES: 'Sí, quiero',
};

export const SAVING_BANK_INFORMATION = {
  en_US: 'Saving bank information...',
  pt_BR: 'Salvando informações bancárias...',
  es_ES: 'Guardando información bancaria...',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const SAVING_PERSONAL_DETAILS = {
  en_US: 'Saving personal details...',
  pt_BR: 'Salvando informações pessoais...',
  es_ES: 'Guardando información personal...',
};

export const TEXT_CH_CHANGE_SUCCESS = {
  en_US: 'Your personal details has been updated successfully.',
  pt_BR: 'Suas informações pessoais foram atualizadas com sucesso.',
  es_ES: 'Tu información personal se ha actualizado con éxito.',
};

export const ERROR_CUSTOMER_UPDATE = {
  en_US: 'Error updating cardholder information. Please try again later.',
  pt_BR:
    'Erro ao atualizar as informações do titular do cartão. Por  favor, tente novamente mais tarde.',
  es_ES:
    'Error al actualizar la información del titular de la tarjeta. Por favor, inténtelo de nuevo más tarde.',
};

export const ERROR_CUSTOMER_DUPLICATED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const ERROR_TIME_OUT = {
  en_US: 'Performing this operation, a timeout happened. Please try again.',
  pt_BR:
    'Ao realizar essa operação, o tempo de processamento esgotou. Por favor, tente novamente.',
  es_ES:
    'Al realizar esta operación, ha transcurrido el tiempo de procesamiento. Por favor, intenta nuevamente.',
};

export const TEXT_BTN_CREATE_CLAIM = {
  en_US: 'Create claim',
  pt_BR: 'Abrir Sinistro',
  es_ES: 'Crear un reclamo',
};

export const TEXT_BTN_UPDATE_CLAIM = {
  en_US: 'Update claim',
  pt_BR: 'Atualizar sinistro',
  es_ES: 'Actualizar reclamo',
};

export const TITLE_CLAIM_SUCESS = {
  en_US: 'Thanks for the info!',
  pt_BR: 'Obrigado pelas informações!',
  es_ES: '¡Gracias por la información!',
};

export const UPDATED_CLAIM_SUCESS = {
  en_US: 'Congratulations! Your claim has been successfully updated.',
  pt_BR: 'Parabéns! Seu sinistro foi atualizado com sucesso.',
  es_ES: '¡Felicidades! Tu reclamo se ha actualizado correctamente.',
};

export const SUBTITLE_CLAIM_SUCESS = {
  en_US:
    'In order for us to proceed with the analysis of your claim, we ask that you attach the necessary documents.',
  pt_BR:
    'Para que possamos avançar com a análise do seu sinistro, solicitamos que anexe os documentos necessários.',
  es_ES:
    'Para que podamos proceder con el análisis de tu reclamación, te pedimos que adjuntes los documentos necesarios.',
};

export const OPTION_RECEIVE_UNAVAILABLE = {
  en_US:
    'The option to receive the payment directly on the card is momentarily unavailable. Please, select the bank account option and try again.',
  pt_BR:
    'A opção de pagamento direto no cartão de débito não esta disponível no momento. Por favor, selecione o pagamento em conta bancária e tente novamente.',
  es_ES:
    'La opción de pago directo en la tarjeta de débito esta indisponible en este momento. Por favor, selecciona el pago en cuenta bancaria e intenta nuevamente.',
};

export const ERROR_DUPLICATED_CLAIM = {
  en_US:
    'There is already a claim created. Please make the desired changes by updating it.',
  pt_BR:
    'Já existe um sinistro criado. Por favor, realize as modificações desejadas atualizando-o.',
  es_ES:
    'Ya hay un reclamo creado. Realice los cambios deseados actualizándolo.',
};

export const ERROR_NOT_COVERED_FOR_PERIOD = {
  en_US: 'The benefit is not covered for the specified period.',
  pt_BR: 'O benefício não é coberto pelo período especificado.',
  es_ES: 'El beneficio no está cubierto durante el período especificado.',
};

export const ERROR_DATE_BY_CERTIFICATE = {
  en_US:
    'You have selected an out-of-coverage ticket for this claim. Please make sure that your ticket was issued prior to the date when medical assistance started.',
  pt_BR:
    'Você selecionou um bilhete fora da cobertura para este sinistro. Verifique se o seu bilhete foi emitido antes da data de início da assistência médica.',
  es_ES:
    'Ha seleccionado un certificado fuera de cobertura para este reclamo. Asegúrate de que tu certificado haya sido emitido antes de la fecha en que comenzó la asistencia médica',
};

export const GENERATED_CLAIM_ERROR = {
  en_US: 'An error occurred while performing this operation.',
  pt_BR: 'Ocorreu um erro ao efetuar esta operação.',
  es_ES: 'Se produjo un error al realizar esta operación.',
};

export const ERROR = {
  en_US: 'Error updating your personal information. Please try again later.',
  pt_BR:
    'Erro ao atualizar suas informações pessoais. Por favor, tente novamente mais tarde.',
  es_ES:
    'Error al actualizar tu información personal. Por favor, inténtalo de nuevo más tarde.',
};

export const CLAIM_GENERAL_INFORMATION = {
  en_US: 'Cardholder details',
  pt_BR: 'Dados do titular do cartão',
  es_ES: 'Datos del titular de la tarjeta',
};

export const CLAIM_ABOUT_THE_INCIDENT = {
  en_US: 'Information about the incident',
  pt_BR: 'Informações sobre o incidente',
  es_ES: 'Información sobre el incidente',
};

export const CLAIM_PAYMENT = {
  en_US: 'Information for payment',
  pt_BR: 'Informações para pagamento',
  es_ES: 'Información para pago',
};

export const CLAIM_ADVICE_AND_CONSENT = {
  en_US: 'Legal advice and consent',
  pt_BR: 'Declaração e consentimento',
  es_ES: 'Asesoramiento legal y consentimiento',
};

export const CREATING_CLAIM = {
  en_US: 'Creating your claim...',
  pt_BR: 'Criando seu sinistro... ',
  es_ES: 'Creando tu reclamo...',
};

export const UPDATING_CLAIM = {
  en_US: 'Updating your claim...',
  pt_BR: 'Atualizando seu sinistro...',
  es_ES: 'Actualizando tu reclamo...',
};

export const ATTACH_DOCUMENT = {
  en_US: 'Attach documents',
  pt_BR: 'Anexar documentos',
  es_ES: 'Adjuntar documentos',
};

export const YOUR_CLAIMS = {
  en_US: 'Your claims',
  pt_BR: 'Seus sinistros',
  es_ES: 'Tus reclamos',
};

export const MODAL_CONFIRMATION_CREATE_BANK_SUCCESS = {
  en_US: 'Congratulations! New bank added successfully.',
  pt_BR: 'Parabéns! Novo banco adicionado com sucesso.',
  es_ES: '¡Felicidades! Nuevo banco agregado con éxito.',
};

export const MODAL_INVALID_DOCTOR_HOSPITAL_EMAIL = {
  en_US:
    'Correo eletrónico del médico / hospital responsable de la atención inválido.',
  pt_BR: 'E-mail do médico / hospital responsável pelo atendimento inválido.',
  es_ES: 'Doctor / Hospital e-mail invalid.',
};
