import { useContext, useEffect, useState } from 'react';
import BenefitsCategories from '../../../components/Sections/AllBenefits/BenefitsCategories/BenefitsCategories';
import { IntlContext } from '../../../intl';
import { ALL, getCategory } from '../../../utils/benefitCategory';
import styles from './Claims.module.scss';
import ClaimsList from './ClaimsList/ClaimsList';
import * as translations from './intl';

const Claims = props => {
  const { benefits, mergeBenefitsClaims } = props;
  const { translate } = useContext(IntlContext);
  const [benefitsFiltered, setBenefitsFiltered] = useState(mergeBenefitsClaims);
  const [splitBenefits, setSplitbenefits] = useState([]);
  const [category, setCategory] = useState(getCategory());

  const intl = translate(translations);

  const filterBenefits = value => {
    setCategory(value);

    if (value === ALL) return setBenefitsFiltered(mergeBenefitsClaims);

    const filtered =
      mergeBenefitsClaims &&
      mergeBenefitsClaims.filter(benefit => {
        return (
          benefit['info'] &&
          benefit['info'][0] &&
          benefit['info'][0].categories === value
        );
      });
    setBenefitsFiltered(filtered);
  };

  const mountSplitBenefits = () => {
    try {
      let newListBenefits = [...mergeBenefitsClaims];
      let numberColumns = 3;
      const result = new Array(
        Math.ceil(newListBenefits.length / numberColumns)
      )
        .fill()
        .map(() => newListBenefits.splice(0, numberColumns));
      setSplitbenefits(result);
    } catch (error) {
      setSplitbenefits([]);
    }
  };

  useEffect(() => {
    mountSplitBenefits();
    setCategory(ALL);
  }, [mergeBenefitsClaims, benefits]);

  useEffect(() => {
    filterBenefits(category);
  }, [category, mergeBenefitsClaims, benefits]);

  return (
    <div className={styles.claims}>
      <div className={styles.claimsHeader}>
        <div className={styles.title}>
          <h2>{intl.TITLE_CLAIM}</h2>
        </div>
        <BenefitsCategories
          filterBenefits={filterBenefits}
          selected={category}
          benefitList={benefits}
          disableConciergeFilter
        />
      </div>

      <ClaimsList
        benefits={benefitsFiltered}
        benefitsList={benefits}
        splitBenefits={splitBenefits}
      />
    </div>
  );
};

export default Claims;
