import React, { useContext, useState, useEffect } from 'react';

import { StateContext } from '../../StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import { claimsServices } from '../../../services';
import { benefitToClaimType } from '../../../pages/Claims/Generate/ClaimFillForm/Form/submit/benefitToClaimType';
import ModalCreditCard from '../../../assets/images/modal/modal_image_credit_card.png';
import Loading from '../../Loading/Loading';
import ModalWithImage from '../../ModalWithImage/ModalWithImage';
import * as translations from './intl';
import styles from './ClaimDetail.module.scss';
import PriceProtectionDetail from './PriceProtectionDetail/PriceProtectionDetail';
import PurchaseProtectionDetail from './PurchaseProtectionDetail/PurchaseProtectionDetail';
import ExtendedWarranty from './ExtendedWarrantyDetail/ExtendedWarrantyDetail';
import CarRentalDetail from './CarRentalDetail/CarRentalDetail';
import IemsDetail from './IemsDetail/IemsDetail';
import TripDelayDetail from './TripDelayDetail/TripDelayDetail';
import BaggageLossDetail from './BaggageLossDetail/BaggageLossDetail';
import TripCancellationDetail from './TripCancellationDetail/TripCancellationDetail';
import BaggageDelayDetail from './BaggageDelayDetail/BaggageDelayDetail';
import MissedConnectionDetail from './MissedConnectionDetail/MissedConnectionDetail';
import TravelAccidentInsuranceDetail from './TravelAccidentInsurance/TravelAccidentInsurance';
import InsuredJourneyAccidentalDetail from './InsuredJourneyAccidentalDetail/InsuredJourneyAccidentalDetail';
import CellphoneProtectionDetail from './CellphoneProtectionDetail/CellphoneProtectionDetail';
import BagProtectionDetail from './BagProtectionDetail/BagProtectionDetail';
import HotelBurglaryDetail from './HotelBurglary/HotelBurglaryDetail';
import ATMDetail from './ATMDetail/ATMDetail';
import CorporateLiabilityWaiverDetail from './CorporateLiabilityWaiverDetail/CorporateLiabilityWaiverDetail';
import { AppInsightTrackContext } from '../../AppInsightTrackContextParent/AppInsightTrackContextParent';

const components = {
  Price_Protection: PriceProtectionDetail,
  Purchase_Protection: PurchaseProtectionDetail,
  EMC_Emergency_Medical_Assistance: IemsDetail,
  Extended_Warranty: ExtendedWarranty,
  Trip_Delay: TripDelayDetail,
  Auto_Rental_Insurance: CarRentalDetail,
  Trip_Cancellation: TripCancellationDetail,
  Baggage_Delay: BaggageDelayDetail,
  Baggage_Loss: BaggageLossDetail,
  Missed_Connection: MissedConnectionDetail,
  Travel_Accident_Insurance: TravelAccidentInsuranceDetail,
  Injured_Journay_Accidental_D_D: InsuredJourneyAccidentalDetail,
  Cellphone_Protection: CellphoneProtectionDetail,
  Bag_Protection: BagProtectionDetail,
  Assault_Cash_Death: ATMDetail,
  Corporate_Liability_Waiver: CorporateLiabilityWaiverDetail,
  protected_vacations: TripCancellationDetail,
  Hotel_Motel_Burglary: HotelBurglaryDetail,
};

const ClaimDetails = props => {
  const { claim } = props;
  const { actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [loading, setLoading] = useState(true);
  const [claimDetails, setClaimDetails] = useState();

  const Component =
    components[claim.benefit_external_reference] || (() => <></>);

  const loadClaimDetails = async (claimId, idiom, setClaimDetails) => {
    setLoading(true);
    try {
      const response = await Promise.all([
        claimsServices.getCompleteInfoByClaimId(
          claimId,
          benefitToClaimType[claim.benefit_external_reference],
          idiom
        ),
      ]);
      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) CARREGOU AS INFORMAÇÕES RESUMIDAS`,
        { response: response[0].data, claimId: claimId, claim: claim }
      );
      setClaimDetails(response[0].data);
    } catch (err) {
      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) ERRO AO CARREGAR AS INFORMAÇÕES RESUMIDAS`,
        {
          error: err,
          error_response: err & err.response ? err.response : err,
          claim: claim,
          claimId: claimId,
        }
      );
      actions.modal.closeModal();
      actions.modal.showModal(
        false,
        true,
        <ModalWithImage
          type="error"
          title={intl.UNEXPECTED_ERROR_OCCURRED}
          image={ModalCreditCard}
        ></ModalWithImage>,
        true
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    loadClaimDetails(claim.claim_id, idiom, setClaimDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim.claim_id, idiom]);

  return (
    <div>
      <div className={styles.showDetails}>
        {loading ? (
          <div className={styles.loadingCenter}>
            <Loading />
          </div>
        ) : (
          <Component claimDetails={claimDetails} />
        )}
      </div>
    </div>
  );
};

export default ClaimDetails;
