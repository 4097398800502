import SelectSearch, {
  SelectedOption,
  SelectSearchOption,
  SelectSearchProps,
} from 'react-select-search';
import { removeRequiredCharLabel } from '../../utils/stringUtils';
import { safeGet } from '../../utils/object';
import { FormikErrors, FormikProps } from 'formik';
import styles from './index.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import Tooltip from '../Tooltip/Tooltip';

interface SearchSelectProps {
  label: string;
  name: string;
  options: SelectSearchOption[];
  emptyMessage: string;
  formikProps?: FormikProps<unknown>;
  disabled?: boolean;
  value?: string;
  error?: FormikErrors<string>;
  touched?: FormikErrors<string>;
  tooltipText?: string;
  dataTestId?: string;
  axa?: boolean;
  setFieldValue?: FormikProps<unknown>['setFieldValue'];
  setFieldTouched?: FormikProps<unknown>['setFieldTouched'];
  onChange?: (
    selectedValue: string,
    selectedOption: SelectedOption,
    optionSnapshot: SelectSearchProps
  ) => void;
}

const SearchSelect = ({
  options,
  label,
  name,
  emptyMessage,
  formikProps,
  disabled,
  value,
  error,
  touched,
  tooltipText,
  dataTestId,
  axa,
  setFieldValue,
  setFieldTouched,
  onChange,
}: SearchSelectProps) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  if (formikProps) {
    setFieldValue = setFieldValue || formikProps.setFieldValue;
    setFieldTouched = setFieldTouched || formikProps.setFieldTouched;
    error = error || safeGet(formikProps, 'errors.' + name);
    touched = touched || safeGet(formikProps, 'touched.' + name);
    value = value || safeGet(formikProps, 'values.' + name);
  }

  let inputClassName = '';
  if (error && touched) inputClassName += ` ${styles.error}`;
  if (value && !error) inputClassName += ` ${styles.value}`;

  return (
    <div className={styles.container}>
      {value && (
        <label
          className={`${styles.label} ${styles[theme]} ${
            axa ? styles.axa : ''
          }`}
        >
          {removeRequiredCharLabel(label)}
        </label>
      )}
      <SelectSearch
        id={name}
        className={{
          container: styles.inputContainer,
          value: styles.value,
          input: `${styles.input} ${styles[theme]} ${axa ? styles.axa : ''}`,
          select: `${styles.select} ${styles[theme]}`,
          options: styles.option,
          row: styles.row,
          option: `${styles.option} ${styles[theme]}`,
          group: styles.group,
          'group-header': styles.groupHeader,
          'is-selected': `${styles.isSelected} ${styles[theme]}`,
          'is-highlighted': `${styles.isHighlighted} ${styles[theme]}`,
          'is-loading': styles.isLoading,
          'is-multiple': styles.isMultiple,
          'has-focus': `${styles.hasFocus} ${styles[theme]}`,
        }}
        options={options}
        search
        placeholder={label}
        value={value}
        disabled={disabled}
        onChange={
          onChange ||
          (value => {
            setFieldValue(name, value);
          })
        }
        // @ts-ignore: onBlur exists and works
        onBlur={() => setFieldTouched(name)}
        emptyMessage={emptyMessage}
        renderValue={(valueProps, snapshot, className) => (
          <>
            {/* @ts-ignore */}
            <input
              {...valueProps}
              value={snapshot.focus ? snapshot.search : value}
              placeholder={label}
              className={`${className}${inputClassName}`}
              data-testid={dataTestId}
              autoComplete="off"
            />
            {tooltipText && (
              <Tooltip
                axa={axa}
                text={tooltipText}
                className={styles.tooltip}
              />
            )}
          </>
        )}
      />
      {error && touched && <label className={styles.errorLabel}>{error}</label>}
    </div>
  );
};

export default SearchSelect;
