import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { IntlContext } from '../../../intl';
import { productsAndBenefitsServices } from '../../../services';
import Loading from '../../../components/Loading/Loading';
import styles from './Products.module.scss';
import Cards from '../../../assets/images/cards_2.svg';
import * as translations from './intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import ArrowRightIcon from '../../../assets/icons/ArrowRight';
let sliderReference = '';

const getImage = images => {
  let findImage = images && images.find(image => image.type === 'PICTURE');
  return findImage && findImage.content_url.length > 0
    ? findImage.content_url
    : Cards;
};

const renderProducts = products => {
  return products.map(({ name, product_id, images }, index) => {
    return (
      <img
        key={product_id}
        className={styles.boxImg}
        onClick={() => {
          sliderReference.slickGoTo(index);
        }}
        title={name}
        src={getImage(images)}
        alt={name}
        loading="lazy"
      />
    );
  });
};

const getIndexSelectedProduct = (products, productActive) => {
  const index = products.findIndex(
    product => product.product_id === productActive.product_id
  );
  return index === -1 ? 0 : index;
};

const Products = props => {
  const { productActive, setProductActive } = props;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [indexSelected, setIndexSelected] = useState(0);
  const { idiom, country, translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const loadProducts = async () => {
    try {
      setLoading(true);

      const { data } = await productsAndBenefitsServices.getProductsByCountry(
        country,
        idiomForApi()
      );
      const cardFilter = data.filter(card => card.is_default === 1);
      setProducts(cardFilter);

      const findProduct = cardFilter.find(
        product => product.product_id === productActive.product_id
      );
      if (!(productActive && productActive.product_id) || !findProduct)
        setProductActive(cardFilter[0] || {});

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setProductActive({});
    }
  };

  const renderCards = renderProducts(products);

  useEffect(() => {
    const index =
      productActive && productActive.product_id
        ? getIndexSelectedProduct(products, productActive)
        : 0;
    setIndexSelected(index);
  }, [products, productActive]);

  useEffect(() => {
    loadProducts();
  }, [country, idiom]); // eslint-disable-line react-hooks/exhaustive-deps
  const settings = {
    initialSlide: indexSelected,
    className: styles.slider,
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 2.2,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],

    beforeChange: (current, next) => {
      setProductActive(products[next]);
    },
    // eslint-disable-next-line react/display-name
    customPaging: index => (
      <div className={styles.boxDots}>
        <button
          aria-label={intl.PAGINATION_DOTS(products[index].name)}
          className={`${styles[theme]} ${styles.dots}`}
          onClick={() => {
            sliderReference.slickGoTo(index);
          }}
        ></button>
      </div>
    ),
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' '}
          <Loading />
        </div>
      ) : (
        <div className={`${styles.boxSlider} container-fluid`}>
          <div className="row">
            <div className={`${styles.boxTitleCard} col-12 col-lg-5`}>
              <span className={`${styles.subTitleCard} ${styles[theme]}`}>
                {intl.TEXT_FIND_THE_BENEFITS}
              </span>
              <h2 className={`${styles.titleCard} ${styles[theme]}`}>
                {`Visa ${productActive && productActive.name}`}
              </h2>

              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`/benefits/${productActive && productActive.product_id}`}
                className={styles[theme]}
              >
                {intl.TEXT_SEE_BENEFITS}
                <span className={styles.icon}>
                  <ArrowRightIcon width={20} height={20} />
                </span>
              </Link>
            </div>

            <div
              className={`col-lg-7  ${styles.boxCards} ${styles[theme]} col-12`}
            >
              <Slider {...settings} ref={slider => (sliderReference = slider)}>
                {renderCards}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Products;
