import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './MenuProfileMobile.module.scss';
import Submenu from '../MenuProfile/Submenu';
import NavHashLink from '../NavHashLink/NavHashLink';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import {
  NOTIFICATIONS_ENABLE,
  REACT_APP_TOTAL_REIMBURSEMENT,
} from '../../utils/environments_variables';
import CircledUserIcon from '../../assets/icons/CircledUserIcon';
import { StateContext } from '../StateContextParent/StateContextParent';
import CertificateIcon from '../../assets/icons/CertificatesIcon';
import ClaimsIcon from '../../assets/icons/ClaimsIcon';
import NotificationsIcon from '../../assets/icons/NotificationsIcon';
import SettingsIcon from '../../assets/icons/SettingsIcons';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import TotalReimbursedIcon from '../../assets/icons/TotalReimbursedIcon';
import VoucherIcon from '../../assets/icons/VoucherIcon';

const MenuProfileMobile = props => {
  const { hiddenMenu, customer, hasCinepolisGold } = props;
  const [menuId, setMenuId] = useState('');
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { utils } = useContext(StateContext);
  const { gender } = utils.getCustomerInfo();

  return (
    <div className="showOnMobile">
      {!hiddenMenu && (
        <div className={`${styles.menuMobileList} ${styles[themes]}`}>
          <ul>
            <li className={styles[themes]}>
              <NavHashLink
                to="/profile/account#contact"
                activeClassName={styles.active}
                onClick={() => setMenuId('account')}
                data-testid="account"
                className={styles[themes]}
              >
                <span className={styles.icon}>
                  <CircledUserIcon
                    gender={gender}
                    width={30}
                    height={30}
                    aria-label={intl.ACCOUNT}
                  />
                </span>{' '}
                {intl.MENU_ACCOUNT}
              </NavHashLink>
              <span className={styles.iconChevron}>
                <ChevronGeneric rotate={270} width={20} height={20} alt="" />
              </span>
            </li>

            <li className={styles[themes]}>
              <NavHashLink
                to="/profile/certificates#active"
                activeClassName={styles.active}
                onClick={() => setMenuId('certificates')}
                data-testid="certificates"
                className={styles[themes]}
              >
                <span className={styles.icon}>
                  <CertificateIcon
                    width={30}
                    height={30}
                    aria-label={intl.CERTIFICATES}
                  />
                </span>
                {intl.MENU_YOUR_CERTIFICATES}
              </NavHashLink>
              <span className={styles.iconChevron}>
                <ChevronGeneric rotate={270} width={20} height={20} alt="" />
              </span>
            </li>

            <li className={styles[themes]}>
              <NavHashLink
                to="/profile/claims#pending"
                activeClassName={styles.active}
                onClick={() => setMenuId('claims')}
                data-testid="claims"
                className={styles[themes]}
              >
                <span className={styles.icon}>
                  <ClaimsIcon width={30} height={30} aria-label={intl.CLAIMS} />
                </span>{' '}
                {intl.MENU_YOUR_CLAIMS}
              </NavHashLink>
              <span className={styles.iconChevron}>
                <ChevronGeneric rotate={270} width={20} height={20} alt="" />
              </span>
            </li>
            {REACT_APP_TOTAL_REIMBURSEMENT && (
              <li className={styles[themes]}>
                <NavHashLink
                  to="/profile/monetization#certificates"
                  activeClassName={styles.active}
                  onClick={() => setMenuId('monetization')}
                  data-testid="monetization"
                  className={styles[themes]}
                >
                  <span className={styles.icon}>
                    <TotalReimbursedIcon
                      width={30}
                      height={30}
                      aria-label={intl.MONETIZATION}
                    />
                  </span>
                  {intl.SUBMENU_SAVING}
                </NavHashLink>
                <span className={styles.iconChevron}>
                  <ChevronGeneric rotate={270} width={20} height={20} alt="" />
                </span>
              </li>
            )}

            {hasCinepolisGold && (
              <li className={styles[themes]}>
                <NavHashLink
                  to="/profile/vouchers#active"
                  activeClassName={styles.active}
                  onClick={() => setMenuId('vouchers')}
                  className={styles[themes]}
                >
                  <span className={styles.icon}>
                    <VoucherIcon width={30} height={30} />
                  </span>
                  {intl.SUBMENU_VOUCHERS}
                </NavHashLink>
                <span className={styles.iconChevron}>
                  <ChevronGeneric rotate={270} width={20} height={20} alt="" />
                </span>
              </li>
            )}

            {NOTIFICATIONS_ENABLE && (
              <li className={styles[themes]}>
                <NavHashLink
                  to="/profile/notifications#all"
                  activeClassName={styles.active}
                  onClick={() => setMenuId('notifications')}
                  data-testid="notifications"
                  className={styles[themes]}
                >
                  <span className={styles.icon}>
                    <NotificationsIcon
                      width={30}
                      height={30}
                      aria-label={intl.NOTIFICATIONS}
                    />
                  </span>
                  {intl.MENU_NOTIFICATIONS}
                </NavHashLink>
                <span className={styles.iconChevron}>
                  <ChevronGeneric rotate={270} width={20} height={20} alt="" />
                </span>
              </li>
            )}

            <li className={styles[themes]}>
              <NavHashLink
                to="/profile/settings"
                activeClassName={styles.active}
                onClick={() => setMenuId('settings')}
                data-testid="settings"
                className={styles[themes]}
              >
                <span className={styles.icon}>
                  <SettingsIcon
                    width={30}
                    height={30}
                    aria-label={intl.SETTINGS}
                  />
                </span>
                {intl.MENU_SETTINGS}
              </NavHashLink>

              <span className={styles.iconChevron}>
                <ChevronGeneric rotate={270} width={20} height={20} alt="" />
              </span>
            </li>
          </ul>
        </div>
      )}
      {hiddenMenu && (
        <div id="submenu" data-testid="submenu" className={styles.submenu}>
          <Submenu
            menuId={menuId}
            showVoiceIdLink={customer && !!customer.external_voice_profile_id}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(MenuProfileMobile);
