import React, { useContext, useState, useEffect } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../components/Input/Input';
import Select from '../../../../../../../components/Select/Select';
import { callCepService } from '../../../../../../../utils/postalCode';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './AddressArea.module.scss';
import sanitize from '../../../../../../../utils/sanitize';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../utils/environments_variables';

const AddressArea = props => {
  const { translate } = useContext(IntlContext);
  const [cepStatus, setCepStatus] = useState();
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    disableProspectFields,
    isBrazil,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const fieldsPostalCode = {
    uf: 'address.state',
    localidade: 'address.locality',
    logradouro: 'address.street_address',
    bairro: 'address.subdivision',
  };

  const isTravelOrExtendedWarranty =
    props.isTravelCertificate || props.isExtendedWarrantyCertificate;

  const cepService = async cep => {
    const cepValue = cep.replace('-', '');
    if (cep.length === 9 || cepValue.length === 8)
      values.address.invalidCep = true;
    await callCepService(
      cep,
      setCepStatus,
      'address.invalidCep',
      setFieldValue,
      fieldsPostalCode
    );
  };
  useEffect(() => {
    if (
      values.address &&
      values.address.country === 'BR' &&
      values.address.postal_code
    ) {
      cepService(values.address.postal_code);
      setFieldTouched(`address.postal_code`, true);
    }
  }, [values.benefitiaryIsNotCardHolder]); //eslint-disable-line

  const handleCepBlur = e => {
    handleBlur(e);
    if (values.address.country === 'BR') cepService(e.target.value);
  };

  const clearCepSearch = () => {
    setCepStatus();
  };

  let maskPostalCode =
    values.address.country === 'BR'
      ? 'maskedPostalCodeBr'
      : ALPHANUMERIC_COUNTRY_LIST.includes(values.address.country)
      ? 'maskedPostalCodeAlphanumeric'
      : 'maskedPostalCode';

  let cepError, cepWaiting;
  if (cepStatus === 'LOADING') {
    cepWaiting = intl.VALIDATING_CEP;
  } else if (cepStatus === 'INVALID') {
    cepError = intl.VALIDATE_CEP;
  } else {
    cepError = errors.address && errors.address.postal_code;
  }

  return (
    <div className={`row ${styles.section}`}>
      <div className="col-12">
        <h3 className={`${styles[theme]} ${styles.groupDivisor}`}>
          {intl.TEXT_ADDRESS}
        </h3>
      </div>
      <div className="col-12 col-sm-12 col-md-6  col-lg-4">
        <Select
          label={intl.COUNTRY_LABEL}
          value={values.address.country}
          name="address.country"
          dataTestid="InputClaimCHCountry"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.COUNTRY_LABEL}</option>${intl.COUNTRY_OPTIONS}`
            ),
          }}
          touched={touched.address && touched.address.country}
          onBlur={handleBlur}
          error={errors.address && errors.address.country}
          disabled={disableProspectFields}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-2">
        <Input
          value={values.address.postal_code}
          label={intl.LABEL_POSTAL_CODE}
          name="address.postal_code"
          type="text"
          onChange={handleChange}
          dataTestid="InputClaimCHPostalCode"
          mask={maskPostalCode}
          touched={touched.address && touched.address.postal_code}
          onBlur={handleCepBlur}
          error={cepError}
          bottomLabel={cepWaiting}
          onFocus={clearCepSearch}
          disabled={disableProspectFields}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          value={values.address.state}
          label={intl.LABEL_STATE}
          name="address.state"
          type="text"
          dataTestid="InputClaimCHState"
          onChange={handleChange}
          touched={touched.address && touched.address.state}
          onBlur={handleBlur}
          error={errors.address && errors.address.state}
          maxLength="100"
          disabled={disableProspectFields}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          value={values.address.locality}
          label={intl.LABEL_CITY}
          name="address.locality"
          type="text"
          dataTestid="InputClaimCHLocality"
          onChange={handleChange}
          touched={touched.address && touched.address.locality}
          onBlur={handleBlur}
          error={errors.address && errors.address.locality}
          maxLength="30"
          tooltipText={intl.TOOLTIP_CITY}
          disabled={disableProspectFields}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Input
          value={values.address.street_address}
          label={intl.LABEL_ADDRESS}
          name="address.street_address"
          type="text"
          dataTestid="InputClaimCHAddress"
          onChange={handleChange}
          touched={touched.address && touched.address.street_address}
          onBlur={handleBlur}
          error={errors.address && errors.address.street_address}
          maxLength="255"
          disabled={disableProspectFields}
        />
      </div>
      <div className="col-12 col-lg-6">
        <Input
          value={values.address.subdivision}
          label={
            isBrazil && isTravelOrExtendedWarranty && props.isCertificate
              ? intl.LABEL_SUBDIVISION + '*'
              : intl.LABEL_SUBDIVISION
          }
          name="address.subdivision"
          dataTestid="InputClaimCHSubdivision"
          type="text"
          onChange={handleChange}
          touched={touched.address && touched.address.subdivision}
          onBlur={handleBlur}
          error={errors.address && errors.address.subdivision}
          maxLength="100"
          disabled={disableProspectFields}
        />
      </div>
    </div>
  );
};

export default AddressArea;
