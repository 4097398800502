import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './ViewAllBenefits.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ArrowRightIcon from '../../assets/icons/ArrowRight';
import * as translations from './intl';
import { IntlContext } from '../../intl';

const ViewAllBenefits = ({
  text,
  link,
  isNatural = false,
  disablePadding = false,
}) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  return (
    <div
      className={`${
        isNatural ? styles.naturalContainerLink : styles.containerLink
      } ${disablePadding ? styles.disablePadding : ''}`}
    >
      <Link className={`${styles.link} ${styles[themes]}`} to={link}>
        <span className={styles[themes]}>{text}</span>

        <ArrowRightIcon width={20} height={20} aria-label={intl.IMAGE_ARROW} />
      </Link>
    </div>
  );
};

export default ViewAllBenefits;
