export const STATUS_REVIEW = {
  en_US: 'UNDER REVIEW',
  es_ES: 'EN REVISIÓN',
  pt_BR: 'EM REVISÃO',
};
export const STATUS_REVIEW_UNDERWRITER = {
  en_US: 'UNDER REVIEW BY UNDERWRITER',
  es_ES: 'EN REVISIÓN POR LA SEGURADORA',
  pt_BR: 'EM REVISÃO PELA SEGURADORA',
};
export const STATUS_UPLOADED = {
  en_US: 'UPLOADED',
  es_ES: 'SUBIDO',
  pt_BR: 'ENVIADO',
};
export const STATUS_COMPLETED = {
  en_US: 'CONCLUDED',
  es_ES: 'CONCLUIDO',
  pt_BR: 'CONCLUÍDO',
};
export const STATUS_CLOSED = {
  en_US: 'CLOSED',
  es_ES: 'CERRADO',
  pt_BR: 'FECHADO',
};
export const STATUS_CLOSED_DUPLICATED = {
  en_US: 'CLOSED (duplicated)',
  es_ES: 'CERRADO (duplicado)',
  pt_BR: 'FECHADO (duplicado)',
};
export const STATUS_REJECTED = {
  en_US: 'REJECTED',
  es_ES: 'RECHAZADO',
  pt_BR: 'REJEITADO',
};
export const STATUS_PENDING = {
  en_US: 'PENDING',
  es_ES: 'PENDIENTE',
  pt_BR: 'PENDENTE',
};

export const STATUS_PENDING_DOCUMENTATION = {
  en_US: 'PENDING DOCUMENTATION',
  es_ES: 'DOCUMENTACIÓN PENDIENTE',
  pt_BR: 'DOCUMENTAÇÃO PENDENTE',
};

export const TEXT_PENDIND = {
  en_US: 'PENDING',
  pt_BR: 'PENDENTE',
  es_ES: 'PENDIENTE',
};

export const TEXT_CANCELED = {
  en_US: 'CANCELED',
  pt_BR: 'CANCELADO',
  es_ES: 'CANCELADO',
};

export const TEXT_DONE = {
  en_US: 'DONE',
  pt_BR: 'REALIZADO',
  es_ES: 'HECHO',
};

export const TEXT_NOT_DONE = {
  en_US: 'NOT DONE',
  pt_BR: 'NÃO REALIZADO',
  es_ES: 'NO HECHO',
};

export const TEXT_CREATED = {
  en_US: 'UNDER REVIEW ',
  pt_BR: 'EM ANÁLISE',
  es_ES: 'EN ANÁLISIS',
};

export const TEXT_ONGOING = {
  en_US: 'ACTIVE',
  pt_BR: 'ATIVO',
  es_ES: 'ACTIVO',
};

export const TEXT_EXPIRED = {
  en_US: 'EXPIRED',
  pt_BR: 'EXPIRADO',
  es_ES: 'EXPIRADO',
};

export const TEXT_CANCELLED = {
  en_US: 'CANCELED',
  pt_BR: 'CANCELADO',
  es_ES: 'CANCELADO',
};

export const PAYMENT_REFUSED = {
  en_US: 'NOT APPROVED',
  pt_BR: 'NÃO APROVADO',
  es_ES: 'NO APROBADO',
};

export const SUSPENDED = {
  en_US: 'PENDING APPROVAL',
  pt_BR: 'APROVAÇÃO PENDENTE',
  es_ES: 'APROBACIÓN PENDIENTE',
};

export const STATUS_APPROVED = {
  en_US: 'APPROVED',
  es_ES: 'APROBADO ',
  pt_BR: 'APROVADO',
};

export const STATUS_DELAYED = {
  en_US: 'DELAYED',
  pt_BR: 'ATRASADO',
  es_ES: 'RETRASADO',
};

export const STATUS_ON_TIME = {
  en_US: 'ON TIME',
  pt_BR: 'NO HORÁRIO',
  es_ES: 'A TIEMPO',
};

export const WAITING_FOR_TRAVEL = {
  en_US: 'WAITING FOR THE TRIP',
  pt_BR: 'AGUARDANDO A VIAGEM',
  es_ES: 'ESPERANDO EL VIAJE',
};
