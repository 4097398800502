import React, { useState, useContext } from 'react';
import styles from './InputNumber.module.scss';
import { safeGet } from '../../utils/object';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { removeRequiredCharLabel } from '../../utils/stringUtils';
import MaskedInput from 'react-text-mask';

const InputNumber = props => {
  let {
    label,
    name,
    value,
    error,
    onChange,
    onBlur,
    touched,
    formikProps,
    onFocus,
    axa,
    decrease,
    increase,
    disableMin,
    disableMax,
  } = props;
  const [focusInElement, setFocusInElement] = useState(false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  if (formikProps) {
    onChange = formikProps.handleChange;
    onBlur = formikProps.handleBlur;
    error = safeGet(formikProps, 'errors.' + name);
    touched = safeGet(formikProps, 'touched.' + name);
    value = safeGet(formikProps, 'values.' + name);
  }

  let className = `form-control ${styles.Input}`;
  if (error && touched) className += ' ' + styles.error;
  if (value && !error) className += ' ' + styles.value;

  let labelClassName = `${styles.label} ${styles[themes]}`;
  const errorLabelClassName = `${styles.errorLabel}`;

  const formikError = error && '1';

  return (
    <div className={styles.Input}>
      <label
        className={`${labelClassName} ${styles[themes]} ${
          axa ? styles.axa : ''
        }`}
      >
        {removeRequiredCharLabel(label)}
      </label>

      <button
        className={`${styles.buttonChange} ${styles.minor} ${styles[themes]}`}
        onClick={decrease}
        type="button"
        disabled={disableMin}
      >
        -
      </button>

      <MaskedInput
        mask={[/[0-9]/, /[0-9]/]}
        formik-error={formikError}
        className={`${className} ${styles[themes]}`}
        autoComplete="off"
        type="text"
        placeholder={label}
        name={name}
        value={value}
        showMask={false}
        guide={false}
        onChange={onChange}
        onFocus={event => {
          setFocusInElement(true);
          onFocus && onFocus(event);
        }}
        onBlur={event => {
          setFocusInElement(false);
          onBlur && onBlur(event);
        }}
      />
      <button
        className={`${styles.buttonChange} ${styles.plus} ${styles[themes]}`}
        onClick={increase}
        type="button"
        disabled={disableMax}
      >
        +
      </button>
      {error && touched && (
        <label className={errorLabelClassName}>{error}</label>
      )}
    </div>
  );
};

export default InputNumber;
