import React, { useContext } from 'react';
import DownloadIcon from '../../../../../../../assets/icons/DownloadIcon';
import ModalResult from '../../../../../../../components/ModalResult/ModalResult';
import ModalWithImage from '../../../../../../../components/ModalWithImage/ModalWithImage';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../../intl';
import styles from './FileList.module.scss';
import * as translation from './intl';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import CloseIcon from '../../../../../../../assets/icons/CloseIcon';
import { AppInsightTrackContext } from '../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const handleDownload = async (file, actions, intl, trackEventUserAction) => {
  try {
    var link = document.createElement('a');
    trackEventUserAction(
      '#### VMO ### FileList - Iniciando download de arquivo'
    );
    link.href = 'data:application/octet-stream;base64,' + file.file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    trackEventUserAction('#### VMO ### FileList - download realizado');
  } catch (error) {
    trackEventUserAction(
      '#### VMO ### FileList - Erro ao baixar arquivo',
      error
    );
    actions.alert.showError(intl.UNEXPECTED_ERROR_OCCURRED);
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage type="error" title={intl.UNEXPECTED_ERROR_OCCURRED}>
        <ModalResult
          textConfirm={intl.TEXT_OK}
          clickConfirm={() => {
            actions.modal.closeModal();
          }}
        />
      </ModalWithImage>,
      true
    );
  }
};

const FileList = ({ files, label, removeButton, handleRemove }) => {
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const theme = getGlobalTheme();
  const intl = translate(translation);
  return (
    <div>
      <>
        <div className={styles.title}>{label}</div>
        {files.map((fileObject, index) => (
          <div className={styles.containerDownloadItem} key={fileObject.id}>
            <div
              className={styles.cursorPointer}
              onClick={() =>
                handleDownload(fileObject, actions, intl, trackEventUserAction)
              }
              data-testid="downloadButton"
            >
              <DownloadIcon />
            </div>
            <p>{fileObject.name}</p>
            {removeButton && (
              <span
                className={`${styles[theme]} ${styles.closeIcon} ${styles.cursorPointer}`}
                onClick={() => handleRemove(fileObject.id)}
                data-testid="removeButton"
              >
                <CloseIcon width={20} height={20} />
              </span>
            )}
          </div>
        ))}
      </>
    </div>
  );
};

export default FileList;
