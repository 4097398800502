export default (env, axios, errorHandler) => ({
  createEnroll: async (policy_id, data, idiom = 'es-ES') => {
    const url =
      env.REACT_APP_JWE_BENEFITS_EXTENSION.toString() === 'true'
        ? `/internal/specific/visagateway/v2/policies/${policy_id}/benefit_extensions`
        : `/api/specific/visagateway/sales/travel/v1/policies/${policy_id}/benefit_extensions`;
    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(
      axios.post(url, data, { headers }),
      'postCreateEnroll',
      {
        url,
        data,
      }
    );
  },
  getBenefitExtensions: async policy_id => {
    const url =
      env.REACT_APP_JWE_BENEFITS_EXTENSION.toString() === 'true'
        ? `/internal/specific/visagateway/v2/policies/${policy_id}/benefit_extensions`
        : `/api/specific/visagateway/sales/travel/v1/policies/${policy_id}/benefit_extensions`;
    return errorHandler(axios.get(url), 'getBenefitExtensions', {
      url,
    });
  },
});
