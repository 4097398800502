import React, { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  getAmountRecovered,
  getChargesSubmited,
  getCreditLimit,
  getDateOfLoss,
  getHadPaymentLast12Months,
  getValidAccountsNumber,
  getWasCardStolen,
} from '../../../../../../utils/claimsAPI/CorporateLiabilityUtils';
import styles from '../CorporateLiabilityWaiver.module.scss';
import * as translations from './intl';

const FinancialArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [financialInfo, setFinancialInfo] = useState({});
  const [incident, setIncident] = useState({});

  useEffect(() => {
    const financialInfo =
      (claimDetails && claimDetails.financial_information) || {};
    setFinancialInfo(financialInfo);

    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <h4 className={`${styles.itemSeparator} ${styles[themes]}`}>
          {intl.TEXT_FINANCIAL_INFORMATION}
        </h4>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3">
        <InfoLabelValue
          label={intl.TEXT_VALID_ACCOUNTS}
          value={getValidAccountsNumber(financialInfo)}
        />
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3">
        <InfoLabelValue
          label={intl.CARDHOLDER_CREDIT_LIMIT}
          value={getCreditLimit(financialInfo)}
        />
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3">
        <InfoLabelValue
          label={intl.TEXT_CHANGES_SUBMITTED}
          value={getChargesSubmited(financialInfo)}
        />
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3">
        <InfoLabelValue
          label={intl.AMOUNT_RECOVERED}
          value={getAmountRecovered(financialInfo)}
        />
      </div>
      <div className="col-sm-12">
        <InfoLabelValue
          label={intl.QUESTION_PAYMENT_WITHIN_LAST_12_MONTHS}
          value={getHadPaymentLast12Months(financialInfo, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.QUESTION_LOST_OR_STOLEN_CARD}
          value={getWasCardStolen(incident, intl)}
        />
      </div>
      {incident && incident.date_of_loss_or_stealth && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.DATE_REPORTED}
            value={getDateOfLoss(incident, idiom)}
          />
        </div>
      )}
    </>
  );
};

export default FinancialArea;
