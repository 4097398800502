import React, { useContext } from 'react';
import { IntlContext } from '../../../intl';
import { claimsServices } from '../../../services';
import Button from '../../Button/Button';
import ModalInformation from '../../ModalInformation/ModalInformation';
import { StateContext } from '../../StateContextParent/StateContextParent';
import * as translations from './intl';
import moment from 'moment';
import { isWeekend, eachDayOfInterval, isSameDay, isAfter } from 'date-fns';

//mm-dd
const holidaysToBeConsidered = ['12-24', '12-25', '12-31', '01-01', '05-01'];

function countBusinessDays(startDate, endDate) {
  if (!isAfter(endDate, startDate)) {
    return 0;
  }
  const allDates = eachDayOfInterval({ start: startDate, end: endDate });
  allDates.shift();
  const businessDays = allDates.filter(date => {
    const isHoliday = holidaysToBeConsidered.find(holiday => {
      const holidayDate = new Date(`${date.getFullYear()}-${holiday}T00:00:00`);
      return isSameDay(date, holidayDate);
    });
    return !isWeekend(date) && !isHoliday;
  });
  return businessDays.length;
}

const PaymentProofButton = props => {
  const { number, claimId, claimApprovedDate } = props;
  const { actions } = useContext(StateContext);
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const isWithin15DaysOfApproval =
    countBusinessDays(new Date(claimApprovedDate), new Date()) <= 15;

  const formatDate = idiom === 'en_US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  const requestProofPayment = async () => {
    try {
      actions.modal.showLoading(intl.SENDING_YOUR_PROOF_PAYMENT);
      await claimsServices.requestProofOfPayment(claimId);
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="success"
          message={intl.TEXT_PROOF_PAYMENT_SUCCESS}
          subtitle={intl.SUBTITLE_PROOF_PAYMENT_SUCCESS}
        />,
        true,
        false
      );
    } catch (error) {
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true,
        false
      );
    }
  };

  const openModalPaymentProofRequest = () => {
    return actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="question"
        message={
          isWithin15DaysOfApproval
            ? intl.TITLE_WITHIN_15_DAYS(
                moment(claimApprovedDate).format(formatDate)
              )
            : intl.TITLE_AFTER_15_DAYS(number)
        }
        subtitle={
          isWithin15DaysOfApproval
            ? intl.SUBTITLE_WITHIN_15_DAYS
            : intl.SUBTITLE_AFTER_15_DAYS
        }
        textBtn={
          isWithin15DaysOfApproval ? intl.TEXT_OK : intl.TEXT_YES_PROOF_PAYMENT
        }
        textBtnDeny={
          isWithin15DaysOfApproval ? null : intl.TEXT_NO_PROOF_PAYMENT
        }
        clickBtn={() =>
          isWithin15DaysOfApproval
            ? actions.modal.closeModal()
            : requestProofPayment()
        }
      />,
      true,
      false
    );
  };

  return (
    <Button
      type="blueWithoutBorder"
      onClick={() => openModalPaymentProofRequest()}
      id={`view-${number}`}
    >
      {intl.TEXT_REQUEST_PROOF_PAYMENT_BUTTON}
    </Button>
  );
};

export default PaymentProofButton;
