import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import * as translationsOCT from './PaymentInformation/OCTFlow/OCTForm/intl';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import styles from './Form.module.scss';
import AcordeonSession from '../../../../../components/AcordeonSession/AcordeonSession';
import PaymentInformation from './PaymentInformation/PaymentInformation';
import { paymentInformationsInitialValues } from './PaymentInformation/initialValues';
import { getRefsCreateClaim, getRefsUpdateClaim } from './getRefs';
import { checkErrorsOnForm, checkErrorsOnSession } from './checkErrorsOnForm';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import { generalInformationFormInitialValues } from './GeneralInformation/initialValues';
import IncidentInformation from './IncidentInformation/IncidentInformation';
import { submitAClaim } from './submit';
import LegalAdviceAndConsent from './LegalAdviceAndConsent/LegalAdviceAndConsent';
import { checkGeneralInfoChange } from './submit/checkGeneralInfoChange';
import { checkBankInfoChange } from './submit/checkBankInfoChange';
import {
  dataLayerClaimEventTrack,
  CLAIM_COMPLETED_STEP,
  CLAIM_FAILED,
} from '../../../../../utils/GTM_helper';
import { trackFieldErrorElementToGTM } from '../../../../../utils/elements';
import { isAllowedClaimReimbursmentUpdate } from '../../../../../utils/claims_name';
import { NotificationContext } from '../../../../../components/NotificationContextParent/NotificationContextParent';
import { AppInsightTrackContext } from '../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { TYPE_BANK, TYPE_OCT } from './PaymentInformation/OCTFlow/oct-const';
import { checkOCTInfoChange } from './PaymentInformation/OCTFlow/OCTForm/OCTSubmit/OCTSubmit';

const Form = props => {
  const {
    claimType,
    loadedData,
    certificate,
    setStep,
    previousPage,
    showCertificateArea,
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [verifiedGeneralInformation, setVerifiedGeneralInformation] =
    useState(false);
  const [schemas] = useState({});
  const [values] = useState({});
  const [disabledReimbursement, setDisabledReimbursement] = useState(false);
  const [hideReimbursement, setHideReimbursement] = useState(false);
  const [prefix, setPrefix] = useState('');
  const { translate, idiom, idiomForApi, country } = useContext(IntlContext);
  const { utils, actions } = useContext(StateContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const intlOCT = translate(translationsOCT);
  const [initalValueCompare, setInitialValueCompare] = useState('');
  const [bankInfo, setBankInfo] = useState();
  const [paymentId, setPaymentId] = useState('');
  const [listAccordeon, setListAccordeon] = useState({
    policy: true,
    incident: false,
    reimbursement: false,
    legal: false,
  });
  const [thirdPartyPayment, setThirdPartyPayment] = useState(false);
  const [bankType, setBankType] = useState(TYPE_BANK);
  const history = useHistory();

  const setStatusAccordeon = selected => {
    const newListAccordeon = { ...listAccordeon };
    Object.keys(newListAccordeon).map(key => {
      return (newListAccordeon[key] = false);
    });
    setListAccordeon({
      ...newListAccordeon,
      [selected]: !listAccordeon[selected],
    });
  };

  const cn = utils.getCn();
  const bin = utils.getBin();
  const selectedCard = utils.getSelectedCard();
  const isClaimUpdate = loadedData && loadedData.completeClaim ? true : false;

  // Reimbursment update needs ref (IMES refs create)
  // TODO melhorar
  let submitRefs =
    isClaimUpdate && !isAllowedClaimReimbursmentUpdate(claimType)
      ? getRefsUpdateClaim(useRef)
      : getRefsCreateClaim(useRef);
  let accordeonRefs =
    isClaimUpdate && !isAllowedClaimReimbursmentUpdate(claimType)
      ? getRefsUpdateClaim(useRef)
      : getRefsCreateClaim(useRef);

  const certificate_id = certificate ? certificate : null;

  const initialValues = {
    policy: generalInformationFormInitialValues(
      loadedData.generalInformation,
      selectedCard,
      idiom,
      false
    ),
    reimbursement: paymentInformationsInitialValues({
      data: loadedData.reimbursement,
      idiom: idiom,
      country: utils.getCountry(),
      isClaimUpdate: isClaimUpdate,
      claimType: claimType,
    }),
  };

  const textBtnNext = () => {
    if (isClaimUpdate) return intl.TEXT_BTN_UPDATE_CLAIM;

    return intl.TEXT_BTN_CREATE_CLAIM;
  };

  const submit = async () => {
    trackEventUserAction('#### (CLAIM) CLICOU NO SUBMIT ####', values);
    delete values.policy.address.invalidCep;

    if (
      values.incident &&
      values.incident.collection_agencies &&
      values.incident.collection_agencies.address &&
      values.incident.collection_agencies.address.invalidCep !== undefined
    ) {
      delete values.incident.collection_agencies.address.invalidCep;
    }

    trackEventUserAction(
      '#### (CLAIM) CLICOU NO SUBMIT -- REMOVEU O INVALIDCEP ####',
      values
    );

    const areFormsOk = await checkErrorsOnForm(
      submitRefs,
      accordeonRefs,
      schemas,
      values,
      listAccordeon,
      hideReimbursement,
      trackEventUserAction
    );
    trackEventUserAction(
      '#### (CLAIM) CLICOU NO SUBMIT -- VALIDOU FORM ####',
      areFormsOk ? true : false
    );

    if (areFormsOk) {
      const _props = {
        claimType,
        values,
        cn,
        bin,
        selectedCard,
        setSubmitting,
        actions,
        initialValues,
        intl,
        intlOCT,
        idiom,
        history: props.history,
        idiomForApi: idiomForApi(),
        certificate_id,
        completeClaim: loadedData.completeClaim,
        initalValueCompare,
        setInitialValueCompare,
        verifiedGeneralInformation,
        notificationActions,
        disabledReimbursement,
        hideReimbursement,
        thirdPartyPayment,
        trackEventUserAction,
        bankType,
        paymentId,
        setPaymentId,
      };
      trackEventUserAction(
        '#### (CLAIM) CLICOU NO SUBMIT -- FORM ESTÁ VALIDADO',
        _props
      );
      submitAClaim(_props, isClaimUpdate);
    }
  };

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const openTargetAcordeon = async (
    currentAcordeon,
    targetAcordeon,
    validation = true
  ) => {
    const checkErrorOnSession = await checkErrorsOnSession(
      accordeonRefs,
      currentAcordeon,
      targetAcordeon,
      schemas,
      values,
      validation,
      submitRefs
    );

    if (checkErrorOnSession) {
      dataLayerClaimEventTrack(
        CLAIM_COMPLETED_STEP,
        claimType,
        getStepbyAcordeon(currentAcordeon)
      );
    } else {
      trackFieldErrorElementToGTM(
        CLAIM_FAILED,
        claimType,
        getStepbyAcordeon(currentAcordeon)
      );
    }

    return checkErrorOnSession;
  };

  const getStepbyAcordeon = acordeon => {
    let step = 2;
    switch (acordeon) {
      case 'policy':
        step = 2;
        break;

      case 'incident':
        step = 3;
        break;

      case 'reimbursement':
        step = 4;
        break;

      case 'legal':
        step = 5;
        break;

      default:
        step = 2;
        break;
    }
    return step;
  };

  const getNextPolicy = () => {
    return 'incident';
  };

  const nextAccordionIncident = hideReimbursement ? 'legal' : 'reimbursement';
  const backAccordionIncident = hideReimbursement
    ? 'incident'
    : 'reimbursement';

  useEffect(() => {
    setInitialValueCompare(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="mainContent" className={styles.formContainer}>
      <AcordeonSession
        startsOpened
        title={intl.CLAIM_GENERAL_INFORMATION}
        openButtonRef={accordeonRefs.policy}
        accordeon="policy"
        status={listAccordeon.policy}
        setStatus={() => setStatusAccordeon('policy')}
        next={async () => {
          const response = await openTargetAcordeon(
            'policy',
            getNextPolicy(),
            true
          );
          if (response) {
            await checkGeneralInfoChange({
              initalValueCompare,
              values,
              actions,
              intl,
              cn,
              setInitialValueCompare,
              trackEventUserAction,
            });
            setVerifiedGeneralInformation(true);
          }
        }}
        back={() => {
          if (previousPage === '/update') {
            history.goBack();
            return;
          }

          setStep(showCertificateArea ? 'SEARCH_CERTIFICATES' : 'TERMS');
        }}
      >
        <GeneralInformation
          claimType={claimType}
          loadedData={loadedData.generalInformation}
          policySubmitRef={submitRefs.policy}
          updateParent={updateForm.bind(null, 'policy')}
          initialValues={initialValues.policy}
          prefixOptions={{ prefix, setPrefix }}
        />
      </AcordeonSession>
      <AcordeonSession
        id="incident"
        title={intl.CLAIM_ABOUT_THE_INCIDENT}
        openButtonRef={accordeonRefs.incident}
        accordeon="incident"
        status={listAccordeon.incident}
        setStatus={() => setStatusAccordeon('incident')}
        next={() => openTargetAcordeon('incident', nextAccordionIncident, true)}
        back={() => openTargetAcordeon('incident', 'policy', false)}
      >
        <IncidentInformation
          claimType={claimType}
          loadedData={loadedData.completeClaim}
          parentValues={values}
          updateParent={updateForm.bind(null, 'incident')}
          incidentSubmitRef={submitRefs.incident}
          prefixOptions={{ prefix, setPrefix }}
          certificate={certificate}
          setDisabledReimbursement={setDisabledReimbursement}
          setHideReimbursement={setHideReimbursement}
        />
      </AcordeonSession>
      <AcordeonSession
        id="reimbursement"
        title={intl.CLAIM_PAYMENT}
        openButtonRef={accordeonRefs.reimbursement}
        accordeon="reimbursement"
        status={listAccordeon.reimbursement}
        setStatus={() => setStatusAccordeon('reimbursement')}
        next={async () => {
          const response = await openTargetAcordeon(
            'reimbursement',
            'legal',
            true
          );
          if (bankType == TYPE_BANK) {
            if (
              response &&
              !isClaimUpdate &&
              !thirdPartyPayment &&
              !disabledReimbursement
            ) {
              await checkBankInfoChange(trackEventUserAction, {
                initalValueCompare,
                values,
                actions,
                intl,
                cn,
                idiom,
                setInitialValueCompare,
                initialValues,
                bankInfo,
              });
            }
          } else if (bankType == TYPE_OCT) {
            if (
              response &&
              !isClaimUpdate &&
              !thirdPartyPayment &&
              !disabledReimbursement
            ) {
              await checkOCTInfoChange(
                values.reimbursement,
                actions,
                intlOCT,
                trackEventUserAction,
                paymentId,
                setPaymentId
              );
            }
          }
        }}
        back={() => openTargetAcordeon('reimbursement', 'incident', false)}
        notOpen={
          (isClaimUpdate && !isAllowedClaimReimbursmentUpdate(claimType)) ||
          hideReimbursement
        }
      >
        {!hideReimbursement && (
          <PaymentInformation
            claimType={claimType}
            loadedData={loadedData.reimbursement}
            updateParent={updateForm.bind(null, 'reimbursement')}
            reimbursementSubmitRef={submitRefs.reimbursement}
            initialValues={initialValues.reimbursement}
            cn={cn}
            parentValues={values}
            setBankInfo={setBankInfo}
            thirdPartyPayment={thirdPartyPayment}
            setThirdPartyPayment={setThirdPartyPayment}
            setDisabledReimbursement={setDisabledReimbursement}
            setBankType={setBankType}
            bankType={bankType}
            country={country}
          />
        )}
      </AcordeonSession>
      <AcordeonSession
        id="legal"
        title={intl.CLAIM_ADVICE_AND_CONSENT}
        openButtonRef={accordeonRefs.legal}
        accordeon="legal"
        status={listAccordeon.legal}
        setStatus={() => setStatusAccordeon('legal')}
        txtBtnNext={textBtnNext()}
        next={submit}
        back={() => openTargetAcordeon('legal', backAccordionIncident, false)}
      >
        <LegalAdviceAndConsent
          claimType={claimType}
          parentValues={values}
          loadedData={loadedData.legalConsents}
          updateParent={updateForm.bind(null, 'legal_consents')}
          legalSubmitRef={submitRefs.legal}
        />
      </AcordeonSession>
    </div>
  );
};

export default withRouter(Form);
