import React, { useContext } from 'react';
import styles from './PageNotFound.module.scss';
import img from '../../assets/images/Image_pageNotFound.png';
import Button from '../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { showModalAddCard } from '../../components/ModalAddCard/functionShowModal';

const PageNotFound = props => {
  const { insertNewCard } = props;
  const history = useHistory();
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { state, actions } = useContext(StateContext);
  const isLogged = state.user.logged;

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.boxInfo}>
          <h1 className={styles[themes]}>
            {insertNewCard ? intl.TEXT_INSERT_PAN : intl.TITLE_PAGE_NOT_FOUND}
          </h1>
          <p className={styles[themes]}>
            {insertNewCard ? intl.CLICK_HERE : intl.DESCRIPTION_PAGE_NOT_FOUND}
          </p>
          <div className={styles.footer}>
            <div className={styles.wrapButton}>
              <Button
                type="borderBlue"
                onClick={() =>
                  isLogged ? history.push('/dashboard') : history.push('/')
                }
              >
                {intl.GO_HOMEPAGE}
              </Button>
            </div>
            {insertNewCard && (
              <div className={styles.wrapButton}>
                <Button
                  type="borderBlue"
                  onClick={() => showModalAddCard(actions, intl)}
                >
                  {intl.GO_ACTIVE_CARD}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <img src={img} alt={intl.SESSION_EXPIRED} />
    </div>
  );
};

export default PageNotFound;
