import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import * as constants from '../../consts';
import styles from '../ExpenseArea.module.scss';
import ButtonWithIcon from '../../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import plus_master from '../../../../../../../../../assets/images/icon/master/icon_plus_master.svg';
import plus_afluent from '../../../../../../../../../assets/images/icon/afluent/icon_plus_afluent.svg';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';

const ExpenseItemAdd = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { isAfluent } = useContext(ThemeContext);
  const { values, setFieldValue } = props;

  const iconPlus = isAfluent() ? plus_afluent : plus_master;

  const addExpense = event => {
    event.preventDefault();

    let newObject = constants.EXPENSE_OBJECT;
    newObject.price.currency = values.expense_currency;
    newObject.price.value = '';

    const expense = [...values.expenses];
    expense.push(newObject);
    setFieldValue('expenses', expense);
  };
  return (
    <div className="row">
      <div className={`col-12 ${styles.add}`}>
        <ButtonWithIcon
          type="blueWithoutBorder"
          dataTestid="BtnAddExpenseItem"
          onClick={addExpense}
          value={intl.BTN_ADD_EXPENSE}
          isSVGComponent={true}
          img={<AddIcon />}
        />
      </div>
    </div>
  );
};

export default ExpenseItemAdd;
