import React, { useContext, useEffect } from 'react';
import styles from './LegalExpensesList.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../../../../../intl';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import ButtonWithIcon from '../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import plus_master from '../../../../../../../../assets/images/icon/master/icon_plus_master.svg';
import plus_afluent from '../../../../../../../../assets/images/icon/afluent/icon_plus_afluent.svg';
import iconTrashMaster from '../../../../../../../../assets/images/icon/master/icon-trash.svg';
import iconTrashAfluent from '../../../../../../../../assets/images/icon/afluent/icon-trash.svg';
import ExpensesItem from './ExpensesItem/ExpensesItem';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import { sanitize } from 'dompurify';
import { safeGet } from '../../../../../../../../utils/object';
import TrashIcon from '../../../../../../../../assets/icons/TrashIcon';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';

const LegalExpensesList = props => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    handleChange,
    errors,
    touched,
    handleBlur,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const iconPlus = isAfluent() ? plus_afluent : plus_master;
  const iconRemove = isAfluent() ? iconTrashAfluent : iconTrashMaster;

  const addProduct = event => {
    if (event) event.preventDefault();
    const listProducts = [...values.incident.expenses];
    const newProduct = {
      amount: {
        value: '',
        currency: values.incident.expenses[0].amount.currency || '',
      },
      type: 'ANY_OTHER_REASON',
      description: '',
    };
    listProducts.push(newProduct);
    setFieldValue('incident.expenses', listProducts);
  };
  const removeProduct = (event, index) => {
    event.preventDefault();

    const cloneProducts = [...values.incident.expenses];
    cloneProducts.splice(index, 1);
    setFieldValue('incident.expenses', cloneProducts);
    const newTotal =
      values.total_amount_claimed.value -
      values.incident.expenses[index].amount.value;
    setFieldValue('total_amount_claimed.value', newTotal.toFixed(2));
  };

  useEffect(() => {
    setFieldValue('amount_estimation_damage.value', totalExpenses());
    setFieldTouched('amount_estimation_damage.value', true);
    setFieldValue(
      'amount_estimation_damage.currency',
      values.incident.expenses[0].amount.currency
    );
    setFieldTouched('amount_estimation_damage.currency', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.incident.expenses[0].amount]);

  const totalExpenses = () => {
    let total = 0;
    for (let i = 0; i < values.incident.expenses.length; i++) {
      if (Number(values.incident.expenses[i].amount.value) > 0)
        total += Number(values.incident.expenses[i].amount.value);
    }

    return total.toFixed(2);
  };

  return (
    <div className={`${styles.marginBottom}`}>
      <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
        {intl.TITLE_LEGAL_EXPENSES}
      </h3>
      <div className={styles.buttonArea}>
        <ButtonWithIcon
          type="blueWithoutBorder"
          onClick={addProduct}
          value={intl.BTN_ADD}
          isSVGComponent={true}
          img={<AddIcon />}
        />
      </div>
      {values.incident.expenses.map((product, index) => (
        <div className="row" key={index}>
          <div className="col-12">
            <h4 className={`${styles.titleItemPassenger} ${styles[themes]}`}>
              {intl.TEXT_ITEM}
              {index + 1}
            </h4>
          </div>
          <ExpensesItem {...props} index={index} />
          <div className={`col-2 ${styles.footer}`}>
            {index !== 0 && (
              <ButtonWithIcon
                type="blueWithoutBorder"
                dataTestid={`buttonRemove-${index}`}
                onClick={event => removeProduct(event, index)}
                value={intl.BTN_REMOVE}
                isSVGComponent={true}
                img={<TrashIcon width={24} height={24} />}
              />
            )}
          </div>
        </div>
      ))}
      <div className="row">
        <div className={`col-12 col-sm-12 col-md-6`}>
          <Input
            label={intl.TEXT_TOTAL_AMOUNT}
            type="text"
            disabled
            name="amount_estimation_damage.value"
            tooltipText={intl.TEXT_DECIMAL_SYMBOL}
            value={totalExpenses()}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={
              touched.amount_estimation_damage &&
              touched.amount_estimation_damage.value
            }
            error={
              errors.amount_estimation_damage &&
              errors.amount_estimation_damage.value
            }
          />
        </div>
        <div className={`col-12 col-sm-12 col-md-6`}>
          <Select
            label={intl.LABEL_CURRENCY}
            value={values.incident.expenses[0].amount.currency}
            name="amount_estimation_damage.currency"
            disabled
            onChange={handleChange}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
              ),
            }}
            setFieldTouched={setFieldTouched}
            onBlur={handleBlur}
            error={safeGet(errors, 'amount_estimation_damage.currency')}
          />
        </div>
      </div>
    </div>
  );
};
export default LegalExpensesList;
