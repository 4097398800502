import React from 'react';

import styles from '../Logo.module.scss';

const logoSize = {
  BIGGER: styles.bigger,
  SMALL: styles.small,
  MEDIUM: styles.medium,
};

const LogoABCBank = ({ size = 'BIGGER' }) => {
  return (
    <div className={logoSize[size]}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1602.000000 422.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,422.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            fill="#333c3c"
            d="M13690 2130 l0 -1120 165 0 165 0 0 218 0 217 175 175 c96 96 176
172 178 170 3 -3 129 -179 281 -393 l277 -387 179 0 c99 0 180 3 180 8 -1 4
-160 225 -355 492 -195 266 -355 487 -355 490 1 3 142 149 314 325 l314 320
-181 3 -181 2 -406 -405 c-223 -223 -409 -403 -413 -399 -4 4 -7 322 -7 706
l0 698 -165 0 -165 0 0 -1120z"
          />
          <path
            fill="#00008f"
            d="M6400 3231 c-289 -31 -513 -126 -683 -290 -91 -87 -146 -166 -206
-292 -86 -181 -118 -369 -108 -629 10 -263 64 -448 180 -622 224 -337 627
-477 1172 -407 117 15 382 85 407 108 7 6 38 476 38 579 l0 53 -45 -3 -46 -3
-54 -118 c-108 -236 -222 -401 -312 -454 -120 -70 -321 -70 -462 0 -120 60
-204 227 -247 492 -25 152 -31 687 -10 865 27 237 75 374 165 472 75 82 189
128 318 128 134 0 219 -34 294 -118 50 -55 176 -263 241 -394 l42 -88 48 0 48
0 0 43 c0 23 -7 155 -15 292 -8 138 -15 266 -15 286 l0 36 -77 17 c-190 41
-517 63 -673 47z"
          />
          <path
            fill="#333c3c"
            d="M7640 2110 l0 -1100 380 0 c210 0 424 5 478 11 435 47 672 269 672
629 0 272 -133 436 -414 510 -47 13 -83 27 -80 31 3 5 11 9 17 9 29 1 132 52
182 90 114 88 175 222 175 384 0 212 -84 353 -264 441 -158 78 -303 95 -793
95 l-353 0 0 -1100z m831 806 c154 -33 230 -102 250 -225 18 -111 -15 -223
-83 -287 -83 -79 -178 -102 -460 -111 l-208 -6 0 321 0 322 218 0 c148 0 238
-5 283 -14z m93 -915 c105 -28 173 -67 217 -125 46 -61 59 -104 59 -203 0
-157 -54 -252 -180 -316 -114 -57 -178 -67 -452 -67 l-238 0 0 371 0 371 258
-5 c221 -4 268 -7 336 -26z"
          />
          <path
            fill="#00008f"
            d="M2229 3173 c-6 -16 -158 -437 -339 -938 -181 -500 -338 -932 -350
-960 -42 -97 -80 -131 -181 -161 l-49 -15 0 -44 0 -45 330 0 330 0 0 44 0 45
-57 16 c-32 8 -75 23 -96 32 -87 38 -88 122 -3 381 l57 172 313 0 314 0 56
-162 c75 -215 90 -277 76 -328 -13 -48 -39 -67 -123 -90 l-62 -17 -3 -47 -3
-47 1153 4 c1260 4 1218 2 1390 63 239 85 360 231 389 468 20 161 -18 314
-100 408 -44 51 -131 106 -220 141 -83 32 -268 77 -318 77 -63 0 -32 17 79 44
215 51 365 149 423 278 24 52 30 80 33 162 13 302 -133 454 -501 523 -66 12
-205 16 -714 20 l-633 5 0 -46 c0 -49 -5 -45 90 -66 30 -7 67 -19 81 -26 55
-28 54 -5 54 -959 0 -954 1 -931 -54 -959 -14 -8 -52 -19 -83 -26 -50 -11 -63
-11 -102 4 -53 20 -94 64 -129 137 -13 30 -181 478 -373 997 l-348 942 -158 0
-158 0 -11 -27z m2316 -135 c64 -21 120 -84 145 -165 27 -87 38 -279 21 -379
-35 -202 -114 -262 -358 -272 l-133 -5 0 411 0 412 23 5 c57 12 78 13 167 10
52 -2 113 -9 135 -17z m-2355 -431 c0 -2 58 -176 130 -385 l129 -382 -264 0
c-146 0 -265 3 -265 8 -1 18 256 762 263 762 4 0 7 -2 7 -3z m2375 -531 c94
-29 159 -97 191 -200 22 -67 31 -330 15 -421 -17 -98 -49 -173 -93 -221 -58
-63 -109 -79 -248 -79 -135 0 -176 16 -197 75 -8 25 -12 157 -12 454 l-1 419
148 -6 c90 -3 166 -11 197 -21z"
          />
          <path
            fill="#333c3c"
            d="M10220 2676 c-96 -17 -210 -49 -290 -82 -84 -34 -257 -121 -264 -133
-5 -7 110 -221 118 -221 2 0 52 25 112 55 60 30 143 65 185 79 255 85 501 45
597 -96 37 -55 59 -113 68 -175 l6 -50 -93 -7 c-233 -17 -536 -75 -684 -131
-256 -96 -386 -254 -385 -470 1 -210 126 -372 341 -440 239 -75 499 -18 776
172 46 31 68 41 73 33 4 -7 11 -44 14 -84 13 -127 -2 -116 152 -116 l134 0 0
555 c0 635 -1 650 -75 801 -73 150 -191 244 -367 295 -94 26 -309 34 -418 15z
m530 -1028 l-1 -193 -43 -33 c-80 -60 -202 -128 -272 -151 -97 -33 -243 -40
-330 -16 -125 34 -184 103 -184 215 0 33 6 76 14 94 37 89 166 167 346 210
126 30 316 58 438 64 l32 2 0 -192z"
          />
          <path
            fill="#333c3c"
            d="M12414 2675 c-72 -16 -193 -69 -260 -116 -27 -18 -83 -64 -126 -101
-42 -37 -79 -68 -81 -68 -3 0 -10 59 -17 130 l-12 130 -139 0 -139 0 0 -820 0
-820 170 0 170 0 0 569 0 570 75 68 c150 136 252 183 394 183 43 0 100 -7 127
-15 96 -28 168 -115 193 -233 7 -36 11 -232 11 -597 l0 -545 171 0 170 0 -4
598 c-3 530 -6 605 -22 667 -70 279 -247 416 -535 414 -47 0 -113 -7 -146 -14z"
          />
        </g>
      </svg>
    </div>
  );
};

export default LogoABCBank;
