import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import styles from '../ATM.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';

import * as translations from './intl';
const IncidentAmountArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;

  const setCurrency = currency => {
    setFieldValue('incident.withdrawn_amount.currency', currency);
    setFieldTouched('incident.withdrawn_amount.currency', true);

    setFieldValue('incident.stolen_amount.currency', currency);
    setFieldTouched('incident.stolen_amount.currency', true);

    setFieldValue('other_insurance_covering.amount.currency', currency);
    setFieldTouched('other_insurance_covering.amount.currency', true);
  };

  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.fontGray12} ${styles.options} row`}>
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.LABEL_WITHDRAWN_AMOUNT}
              name="incident.withdrawn_amount.value"
              type="text"
              formikProps={props}
              maskedMoney
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              dataTestid="InputWithDrawnAmountValue"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <Select
              dataTestid="comboWithDrawnAmountCurrency"
              label={intl.LABEL_CURRENCY}
              name="incident.withdrawn_amount.currency"
              value={values.incident.withdrawn_amount.currency}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              onChange={e => {
                setFieldValue(
                  'incident.stolen_amount.currency',
                  e.target.value
                );
                setFieldValue(
                  'other_insurance_covering.amount.currency',
                  e.target.value
                );
                handleChange(e);
              }}
              onBlur={handleBlur}
              touched={
                touched.incident &&
                touched.incident.withdrawn_amount &&
                touched.incident.withdrawn_amount.currency
              }
              error={
                errors.incident &&
                errors.incident.withdrawn_amount &&
                errors.incident.withdrawn_amount.currency
              }
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.LABEL_STOLEN_AMOUNT}
              name="incident.stolen_amount.value"
              type="text"
              formikProps={props}
              maskedMoney
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              dataTestid="InputStoleAmountValue"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <Select
              label={intl.LABEL_CURRENCY}
              name="incident.stolen_amount.currency"
              value={values.incident.withdrawn_amount.currency}
              disabled
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              formikProps={props}
              dataTestid="comboStoleAmountCurrency"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentAmountArea;
