/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import styles from './Header.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import ModalWithImage from '../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../components/ModalResult/ModalResult';
import ModalCreditCard from '../../../../assets/images/modal/modal_image_credit_card.png';

import ArchiveIcon from '../../../../assets/icons/ArchiveIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import UnarchiveIcon from '../../../../assets/icons/UnarchiveIcon';

const Header = ({
  title,
  subtitle,
  qtdNotifications,
  id,
  loading,
  data,
  limit,
  archiveSelected,
  setNotifications,
  deleteAll,
}) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const [checkbox, setCheckbox] = useState(false);
  const theme = getGlobalTheme();

  const isArchived = id === 'archived';
  const textType = isArchived
    ? intl.TEXT_UNARCHIVE_SELECTED
    : intl.TEXT_ARCHIVE_SELECTED;

  const existsNotificationChecked =
    data && data.find(notification => notification.isChecked === true);

  const openModalDelete = () => {
    return actions.modal.showModal(
      false,
      false,
      <ModalWithImage
        type="error"
        title={intl.TEXT_DELETE}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={intl.BTN_DELETE_MODAL}
          textDeny={intl.BTN_CONFIRM_MODAL}
          clickConfirm={() => {
            actions.modal.closeModal();
            deleteAll(data);
          }}
          clickDeny={() => actions.modal.closeModal()}
        />
      </ModalWithImage>,
      true,
      true
    );
  };

  const selectAllNotifications = status => {
    let notications = [...data];
    notications.forEach((item, index) => {
      if (index <= limit - 1 || limit > notications.length) {
        item.isChecked = status;
      }
    });
    setNotifications(notications);
  };

  const checkConditionSelectAll = () => {
    let count = 0;
    for (let index = 0; index < limit && index < data.length; index++) {
      if (!data[index].isChecked) {
        setCheckbox(false);
        return;
      } else {
        count++;
      }
    }

    if (count === limit || count === data.length) {
      setCheckbox(true);
    }
  };

  useEffect(() => {
    if (data && data.length > 0 && limit) {
      checkConditionSelectAll();
    }
  }, [data, limit]);

  return (
    <>
      <div
        className={styles.headerContainer}
        id={id}
        data-testid="profileNotificationHeader"
      >
        <div className={styles.HeaderInfo}>
          <TitleSectionProfile
            title={title}
            number={qtdNotifications}
            subtitle={subtitle}
            loading={loading}
          />
        </div>
        {data && data.length > 0 && (
          <div className={styles.headerActions}>
            <div>
              <span className={styles[theme]}>
                <CheckBox
                  css={styles[theme]}
                  name={`selectAll-${id}`}
                  value={checkbox}
                  onClick={() => {
                    selectAllNotifications(!checkbox);
                    setCheckbox(!checkbox);
                  }}
                  dataTestid="selectAll"
                >
                  {intl.TEXT_SELECT_ALL}
                </CheckBox>
              </span>
            </div>
            <div>
              <span
                className={`${styles[theme]} ${styles.right}`}
                onClick={
                  existsNotificationChecked
                    ? () => {
                        archiveSelected(data, !isArchived);
                        setCheckbox(false);
                      }
                    : undefined
                }
              >
                {isArchived ? (
                  <UnarchiveIcon
                    aria-label={intl.UNARCHIVE}
                    width={22}
                    height={22}
                    disabled={!existsNotificationChecked}
                  />
                ) : (
                  <ArchiveIcon
                    aria-label={intl.ARCHIVE}
                    width={22}
                    height={22}
                    disabled={!existsNotificationChecked}
                  />
                )}

                <span
                  className={
                    existsNotificationChecked
                      ? `${styles.textBlue} ${styles[theme]}`
                      : `${styles.textGray}`
                  }
                >
                  {textType}
                </span>
              </span>
              {isArchived && (
                <span
                  className={`${styles[theme]} ${styles.right} ${styles.trash}`}
                  onClick={
                    existsNotificationChecked
                      ? () => openModalDelete()
                      : undefined
                  }
                >
                  <TrashIcon
                    width={22}
                    height={22}
                    disabled={!existsNotificationChecked}
                  />
                  <span
                    className={
                      existsNotificationChecked
                        ? `${styles.textBlue} ${styles[theme]}`
                        : ''
                    }
                  >
                    {intl.BTN_DELETE}
                  </span>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
