export const BANK_ACCOUNT = {
  en_US: 'Bank account',
  pt_BR: 'Bank account',
  es_ES: 'Cuenta bancaria',
};

export const BANK_ACCOUNT_INFO = {
  en_US: '(Receive the payment within 15 days)',
  pt_BR: 'Bank account',
  es_ES: '(Reciba el pago en hasta 15 días, despues de aprobado el reclamo)',
};

export const DIRECT_PAYMENT_OCT = {
  en_US: 'Direct payment on <strong>Visa Debit</strong>',
  pt_BR: 'Pago diretamento no cartão de <strong>Débito Visa</strong>',
  es_ES: 'Pago directo en la tarjeta de <strong>Débito Visa</strong>',
};

export const DIRECT_PAYMENT_OCT_INFO = {
  en_US: 'Card (Receive the payment within 1 bussiness day)',
  pt_BR: '',
  es_ES:
    '(Recibe el pago en hasta <strong>1 día hábil</strong>, después de aprobado el reclamo. )',
};

export const PAYMENT_OCT_TOOLTIP = {
  en_US: `• Make sure your debit card is active.`,
  pt_BR: `• Garanta que seu cartão de débito esteja ativo.`,
  es_ES: `• Asegúrate de que tu tarjeta de débito esté activa.`,
};
