import { getCountryByInitials } from '../../intl/utils';
import { formatCustomDate } from '../../utils/date';
import { toFixedOrDefault } from '../../utils/numbers';
export const TRIP_DATE_FORMAT = 'YYYY-MM-DD';

export const getPrice = (price, defaultLabel) => {
  const value = price.value ? toFixedOrDefault(price.value, 2, '') : '';
  const currency = price.currency ? price.currency : '';

  return value ? `${value}${currency && ` (${currency})`}` : defaultLabel;
};

export const getIncidentDescription = tripInformation =>
  tripInformation.reason ? tripInformation.reason : '-';

export const getTripPeriod = (tripInformation, idiom, country) => {
  const planedTrip = tripInformation.planed_trip || {};

  const departureDate = planedTrip.departure_date
    ? formatCustomDate(
        planedTrip.departure_date,
        TRIP_DATE_FORMAT,
        idiom,
        country
      )
    : '';

  const arrivalDate = planedTrip.arrival_date
    ? formatCustomDate(
        planedTrip.arrival_date,
        TRIP_DATE_FORMAT,
        idiom,
        country
      )
    : '';

  const tripPeriod =
    departureDate || arrivalDate ? `${departureDate} - ${arrivalDate}` : '-';

  return tripPeriod;
};

export const getIncidentStartDate = (incident, idiom, country) =>
  incident.start_date
    ? formatCustomDate(incident.start_date, TRIP_DATE_FORMAT, idiom, country)
    : '-';

export const getCovering = (covering, description) => {
  const foundCovering = covering.find(
    item => item && item.description === description
  );

  const amount =
    foundCovering && foundCovering.amount ? foundCovering.amount : {};

  return getPrice(amount, '');
};

export const getCancellationDate = (tripInfo, idiom, country) =>
  tripInfo.interruption_date
    ? formatCustomDate(
        tripInfo.interruption_date,
        TRIP_DATE_FORMAT,
        idiom,
        country
      )
    : '-';

export const getFlightNumber = agency => (agency.name ? agency.name : '-');

export const getIncidentPlace = (incident, idiom) => {
  const location = incident.location ? incident.location : {};

  const placesHierarchy = [];
  if (location.country)
    placesHierarchy.push(getCountryByInitials(location.country, idiom));
  if (location.state) placesHierarchy.push(location.state);
  if (location.locality) placesHierarchy.push(location.locality);

  return placesHierarchy.length > 0 ? placesHierarchy.join(' - ') : '-';
};

const getAdditionalCost = additionalCost => ({
  price: getPrice(additionalCost.price || {}, ''),
  amount_claimed: getPrice(additionalCost.amount_claimed || {}, ''),
});

export const getAdditionalCosts = costs => {
  let accomodationCosts, transportCosts;
  if (costs[0] && costs[0].label && costs[0].label === 'TRANSPORT') {
    transportCosts = costs[0];
    accomodationCosts = costs[1];
  } else {
    transportCosts = costs[1];
    accomodationCosts = costs[0];
  }

  return {
    accomodation: getAdditionalCost(accomodationCosts || {}),
    transport: getAdditionalCost(transportCosts || {}),
  };
};
