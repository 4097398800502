import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './TopBannerMarketing.module.scss';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import BANNER_DESKTOP_PT_AFFLUENT from '../../assets/images/marketing/top_banner_download_mob/desktop/affluent/banner-pt.png';
import BANNER_DESKTOP_SPA_AFFLUENT from '../../assets/images/marketing/top_banner_download_mob/desktop/affluent/banner-es.png';
import BANNER_DESKTOP_ENG_AFFLUENT from '../../assets/images/marketing/top_banner_download_mob/desktop/affluent/banner-eng.png';
import BANNER_DESKTOP_PT_MASTER from '../../assets/images/marketing/top_banner_download_mob/desktop/master/banner-pt.png';
import BANNER_DESKTOP_SPA_MASTER from '../../assets/images/marketing/top_banner_download_mob/desktop/master/banner-es.png';
import BANNER_DESKTOP_ENG_MASTER from '../../assets/images/marketing/top_banner_download_mob/desktop/master/banner-eng.png';
import BANNER_MOBILE_PT_AFFLUENT from '../../assets/images/marketing/top_banner_download_mob/mobile/affluent/banner-pt.png';
import BANNER_MOBILE_SPA_AFFLUENT from '../../assets/images/marketing/top_banner_download_mob/mobile/affluent/banner-es.png';
import BANNER_MOBILE_ENG_AFFLUENT from '../../assets/images/marketing/top_banner_download_mob/mobile/affluent/banner-eng.png';
import BANNER_MOBILE_PT_MASTER from '../../assets/images/marketing/top_banner_download_mob/mobile/master/banner-pt.png';
import BANNER_MOBILE_SPA_MASTER from '../../assets/images/marketing/top_banner_download_mob/mobile/master/banner-es.png';
import BANNER_MOBILE_ENG_MASTER from '../../assets/images/marketing/top_banner_download_mob/mobile/master/banner-eng.png';
import CloseIcon from '../../assets/icons/CloseIcon';

const redirectDownloadPage = history => {
  history.push('/download-app');
};

const TopBannerMarketing = props => {
  const { history, setShowTopBannerMarkertingBanner } = props;
  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const [deviceType, setDeviceType] = useState('desktop');

  const checkWindowWidth = () => {
    if (window.screen.width > 600 && window.innerWidth > 600) {
      setDeviceType('desktop');
    } else {
      setDeviceType('mobile');
    }
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  const top_banner = {
    'theme-afluent': {
      desktop: {
        pt_BR: BANNER_DESKTOP_PT_AFFLUENT,
        es_ES: BANNER_DESKTOP_SPA_AFFLUENT,
        en_US: BANNER_DESKTOP_ENG_AFFLUENT,
      },
      mobile: {
        pt_BR: BANNER_MOBILE_PT_AFFLUENT,
        es_ES: BANNER_MOBILE_SPA_AFFLUENT,
        en_US: BANNER_MOBILE_ENG_AFFLUENT,
      },
    },
    'theme-master': {
      desktop: {
        pt_BR: BANNER_DESKTOP_PT_MASTER,
        es_ES: BANNER_DESKTOP_SPA_MASTER,
        en_US: BANNER_DESKTOP_ENG_MASTER,
      },
      mobile: {
        pt_BR: BANNER_MOBILE_PT_MASTER,
        es_ES: BANNER_MOBILE_SPA_MASTER,
        en_US: BANNER_MOBILE_ENG_MASTER,
      },
    },
  };

  return (
    <div className={`${styles[theme]} ${styles.boxBannerMarketing}`}>
      <div
        className={styles.closeIcon}
        onClick={() => setShowTopBannerMarkertingBanner(false)}
      >
        <CloseIcon width={25} height={25} />
      </div>
      <img
        onClick={() => redirectDownloadPage(history)}
        alt={intl.ALT_IMAGE_SCAN_ME}
        src={top_banner[theme][deviceType][idiom]}
      />
    </div>
  );
};

export default withRouter(TopBannerMarketing);
