import React, { useContext } from 'react';
import styles from './StepCircle.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import iconDoneMaster from '../../../assets/images/icon/svg_circleDone_master.svg';
import iconDoneAfluent from '../../../assets/images/icon/svg_circleDone_afluent.svg';

import step1Master from '../../../assets/images/icon/master/step1_master.png';
import step2Master from '../../../assets/images/icon/master/step2_master.png';
import step3Master from '../../../assets/images/icon/master/step3_master.png';
import step4Master from '../../../assets/images/icon/master/step4_master.png';

import step1Afluent from '../../../assets/images/icon/afluent/step1_afluent.png';
import step2Afluent from '../../../assets/images/icon/afluent/step2_afluent.png';
import step3Afluent from '../../../assets/images/icon/afluent/step3_afluent.png';
import step4Afluent from '../../../assets/images/icon/afluent/step4_afluent.png';

const StepCircle = ({ number, concluded }) => {
  const { isAfluent } = useContext(ThemeContext);

  const getCorrectImage = () => {
    switch (number) {
      case 1:
        return isAfluent() ? step1Afluent : step1Master;
      case 2:
        return isAfluent() ? step2Afluent : step2Master;
      case 3:
        return isAfluent() ? step3Afluent : step3Master;
      case 4:
        return isAfluent() ? step4Afluent : step4Master;
      default:
        return isAfluent() ? step1Afluent : step1Master;
    }
  };

  const iconDone = isAfluent() ? iconDoneAfluent : iconDoneMaster;

  return (
    <>
      {concluded ? (
        <img className={styles.imgStep} src={iconDone} alt="" />
      ) : (
        <img className={styles.imgStep} src={getCorrectImage()} alt="" />
      )}
    </>
  );
};

export default StepCircle;
