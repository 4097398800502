import React, { ReactNode, useContext } from 'react';
import styles from './index.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import LoadingError from '../../components/LoadingError/LoadingError';
import Loading from '../../components/Loading/Loading';
import { LoadingStatus } from '../../@types/LoadingStatus';

interface HorizontalHalfBlueLayoutProps {
  children?: ReactNode;
  status?: LoadingStatus;
}

const HorizontalHalfBlueLayout = ({
  children,
  status,
}: HorizontalHalfBlueLayoutProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      {status === 'LOADING' && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
      {status === 'ERROR' && (
        <div className={styles.loadingContainer}>
          <LoadingError color="#fff" />
        </div>
      )}
      {(!status || status === 'LOADED') && children}
    </div>
  );
};

export default HorizontalHalfBlueLayout;
