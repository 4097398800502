import { useContext } from 'react';
import styles from './LoadingDots.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

export interface LoadingDotsProps {
  blue?: boolean;
}

const LoadingDots = ({ blue }: LoadingDotsProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  let className = styles.loading;

  if (blue) className += ' ' + styles.blue;

  return <span role="loading" className={`${className} ${styles[themes]}`} />;
};

export default LoadingDots;
