import React, { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as TravelAccidentUtils from '../../../../../../utils/claimsAPI/TravelAccidentUtils';
import styles from '../TravelAccidentInsurance.module.scss';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';

const CardArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [cardInfo, setCardInfo] = useState({});

  useEffect(() => {
    const cardInfo =
      (claimDetails &&
        claimDetails.policy &&
        claimDetails.policy.credit_card &&
        claimDetails.policy.credit_card.additional_card_intormation) ||
      {};
    setCardInfo(cardInfo);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <h4 className={`${styles.itemSeparator} ${styles[themes]}`}>
          {intl.LABEL_CARD_STATUS}
        </h4>
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.CARD_STATUS_AT_INCIDENT_DATE}
          value={TravelAccidentUtils.getCardStatus(cardInfo, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.CARD_OPENING_DATE}
          value={TravelAccidentUtils.getCardOpeningDate(cardInfo, idiom)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.CARD_EXPIRATION_DATE}
          value={TravelAccidentUtils.getCardExpirationDate(cardInfo, idiom)}
        />
      </div>
    </>
  );
};

export default CardArea;
