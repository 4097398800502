import React, { useContext, useEffect, useState } from 'react';
import styles from './AddressArea.module.scss';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../components/Input/Input';
import { callCepService } from '../../../../../../utils/postalCode';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import Select from '../../../../../../components/Select/Select';
import { sanitize } from 'dompurify';

const AddressArea = props => {
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    saveInSession,
  } = props;
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();

  const [cepStatus, setCepStatus] = useState();

  const fieldsPostalCode = {
    uf: 'MPH.address.state',
    localidade: 'MPH.address.locality',
    logradouro: 'MPH.address.street_address',
    bairro: 'MPH.address.subdivision',
  };

  const cepService = async cep => {
    const cepValue = cep.replace('-', '');
    if (cep.length === 9 || cepValue.length === 8)
      values.MPH.address.InvalidCep = true;
    await callCepService(
      cep,
      setCepStatus,
      'MPH.address.InvalidCep',
      setFieldValue,
      fieldsPostalCode
    );
  };

  let cepError, cepWaiting;
  if (cepStatus === 'LOADING') {
    cepWaiting = intl.VALIDATING_CEP;
  } else if (cepStatus === 'INVALID') {
    cepError = intl.VALIDATE_CEP;
  } else {
    cepError =
      errors.MPH && errors.MPH.address && errors.MPH.address.postal_code;
  }

  useEffect(() => {
    if (
      values.MPH.address &&
      values.MPH.address.country === 'BR' &&
      values.MPH.address.postal_code
    ) {
      cepService(values.MPH.address.postal_code);
      setFieldTouched('MPH.address.postal_code', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldTouched, values.MPH.address.postal_code]);

  return (
    <div className="row">
      <div className="col-12">
        <h3 className={`${styles[theme]} ${styles.groupDivisor}`}>
          {intl.TEXT_ADDRESS}
        </h3>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <Select
          label={intl.COUNTRY_LABEL}
          value={values.MPH.address.country}
          name="MPH.address.country"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.COUNTRY_LABEL}</option>${intl.COUNTRY_OPTIONS}`
            ),
          }}
          touched={
            touched.MPH && touched.MPH.address && touched.MPH.address.country
          }
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={errors.MPH && errors.MPH.address && errors.MPH.address.country}
          axa
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-2">
        <Input
          value={values.MPH.address.postal_code}
          label={intl.LABEL_POSTAL_CODE}
          name="MPH.address.postal_code"
          onChange={handleChange}
          touched={
            touched.MPH &&
            touched.MPH.address &&
            touched.MPH.address.postal_code
          }
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={cepError}
          bottomLabel={cepWaiting}
          onFocus={() => setCepStatus()}
          mask={
            values.MPH.address.country === 'BR'
              ? 'maskedPostalCodeBr'
              : 'maskedPostalCode'
          }
          axa
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          value={values.MPH.address.state}
          label={intl.LABEL_STATE}
          name="MPH.address.state"
          type="text"
          onChange={handleChange}
          touched={
            touched.MPH && touched.MPH.address && touched.MPH.address.state
          }
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={errors.MPH && errors.MPH.address && errors.MPH.address.state}
          maxLength="100"
          axa
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          value={values.MPH.address.locality}
          label={intl.LABEL_CITY}
          name="MPH.address.locality"
          type="text"
          onChange={handleChange}
          touched={
            touched.MPH && touched.MPH.address && touched.MPH.address.locality
          }
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={
            errors.MPH && errors.MPH.address && errors.MPH.address.locality
          }
          maxLength="100"
          axa
        />
      </div>
      <div className="col-12 col-lg-6">
        <Input
          value={values.MPH.address.street_address}
          label={intl.TEXT_ADDRESS}
          name="MPH.address.street_address"
          type="text"
          onChange={handleChange}
          touched={
            touched.MPH &&
            touched.MPH.address &&
            touched.MPH.address.street_address
          }
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={
            errors.MPH &&
            errors.MPH.address &&
            errors.MPH.address.street_address
          }
          maxLength="255"
          axa
        />
      </div>
      <div className="col-12 col-lg-6">
        <Input
          value={values.MPH.address.subdivision}
          label={intl.LABEL_SUBDIVISION}
          name="MPH.address.subdivision"
          type="text"
          onChange={handleChange}
          touched={
            touched.MPH &&
            touched.MPH.address &&
            touched.MPH.address.subdivision
          }
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={
            errors.MPH && errors.MPH.address && errors.MPH.address.subdivision
          }
          maxLength="100"
          axa
        />
      </div>
    </div>
  );
};

export default AddressArea;
