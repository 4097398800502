import { useContext } from 'react';
import { useMemoTranslations } from '../../../intl';
import * as translations from '../../../intl/travels';
import { Flight } from '../../../@types/APIs/AEROAPI/Flight';
import { getFlightStatus } from '../../../pages/TripAssistance/TravelDetails/utilsDetails';
import { getRouteStatus, getStartDate, getStatusIntl } from './utils';
import { differenceInHours } from 'date-fns';
import styles from './index.module.scss';
import TravelIcon from '../../../assets/icons/TravelIcon';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import StatusLabel from '../../StatusLabel/StatusLabel';

interface StatusHeaderProps {
  flight: Flight;
  title?: string;
  displayRouteStatus?: boolean;
  displayIcon?: boolean;
}

const StatusHeader = ({
  flight,
  title,
  displayRouteStatus = true,
  displayIcon = true,
}: StatusHeaderProps) => {
  const intl = useMemoTranslations(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const flightStatus = getFlightStatus(flight);

  const routeStatus = getRouteStatus(flight);

  const startDate = getStartDate(flight);
  const displayStatus = startDate
    ? differenceInHours(startDate, new Date()) < 1
    : false;

  return (
    <div className={styles.flightHeader}>
      <div className={styles.leftContainer}>
        {displayIcon && <TravelIcon width={48} height={48} />}
        <div className={styles.textContainer}>
          <p className={`${styles.text} ${styles[theme]}`}>
            {title || `${intl.FLIGHT} ${flight?.ident_iata || flight?.ident}`}
          </p>
          {flightStatus !== 'CANCELLED' && displayRouteStatus && (
            <p
              className={`${styles.text} ${
                routeStatus !== 'SCHEDULED' ? styles.green : undefined
              } ${styles[theme]}`}
            >
              {getStatusIntl(routeStatus, intl)}
            </p>
          )}
        </div>
      </div>
      <div className={styles.rightContainer}>
        {flight && (
          <p className={`${styles.realTimeDataDisclaimer} ${styles[theme]}`}>
            {intl.REAL_TIME_AVAILABLE}
          </p>
        )}
        {displayStatus && (
          <StatusLabel status={flightStatus} flight className={styles.status} />
        )}
      </div>
    </div>
  );
};

export default StatusHeader;
