import { AnchorHTMLAttributes, ReactNode } from 'react';
import styles from './index.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { useIntl } from '../../intl';

interface ALinkProps {
  href: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  className?: string;
  children?: ReactNode;
  ariaLabel?: string;
  onClick?: any;
}

const ALink = ({
  href,
  target = '_blank',
  children,
  className,
  ariaLabel,
  onClick,
}: ALinkProps) => {
  const { getGlobalTheme } = useTheme();
  const { translate } = useIntl();

  const theme = getGlobalTheme();
  const intl = translate(translations);
  const isExternal = String(target).toLowerCase() === '_blank';
  const _ariaLabel = `${ariaLabel || children} ${
    isExternal ? `- ${intl.NEW_TAB}` : ''
  }`;

  return (
    <a
      className={`${styles.link} ${styles[theme]}${
        className ? ` ${className}` : ''
      }`}
      style={{
        textDecoration: isExternal ? 'underline' : 'none',
      }}
      href={href}
      target={target}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      aria-label={_ariaLabel}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default ALink;
