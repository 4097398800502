import React, { useState, useContext } from 'react';
import styles from './BenefitsItem.module.scss';
import Button from '../../../../Button/Button';
import Loading from '../../../../Loading/Loading';
import { productsAndBenefitsServices } from '../../../../../services/index';
import { IntlContext } from '../../../../../intl/index';
import ButtonLogin from '../../../../ButtonLogin/ButtonLogin';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import { Link } from 'react-router-dom';
import * as translations from '../intl';
import { dataLayerContentMenuTrack } from '../../../../../utils/GTM_helper';
import {
  getAlignBackgroundByURL,
  getValignBackgroundByURL,
} from '../../../../../utils/stringUtils';

const AFLUENT = 'theme-afluent';

const BenefitItem = props => {
  const { product, value, index, priority, images } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState(null);
  const { idiomForApi, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const idiom_API = idiomForApi();
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const bgcolorByTheme = theme === AFLUENT ? ' #021E4C' : '#1434CB';
  const backgroundColor = index % 2 === 0 ? bgcolorByTheme : '#FFFFFF';
  const textColor = index % 2 === 0 ? '#FFFFFF' : '#5c5c5c';

  const imageAPI =
    value &&
    value.images &&
    value.images.find(image => image.type === 'PICTURE');
  const backGround = imageAPI ? imageAPI.content_url : bgcolorByTheme;
  const benefit = value.external_reference.replace(/_/g, ' ');
  const selectBenefit = async () => {
    setShowDetails(!showDetails);
    dataLayerContentMenuTrack(`Click on card ${benefit}`, 'Benefits');

    if (details === null) {
      try {
        const response =
          await productsAndBenefitsServices.getBenefitsDetailByProduct(
            product,
            value.benefit_id,
            idiom_API
          );
        setDetails(response.data);
      } catch (error) {
        setDetails(null);
        setShowDetails(false);
      }
    }
  };
  return (
    <div
      className={`${styles.card}  ${
        priority ? styles.priorityHeight : styles.commonHeight
      }`}
      onClick={selectBenefit}
    >
      {showDetails ? (
        <div
          className={styles.bodyDetails}
          style={{ backgroundColor: backgroundColor }}
        >
          <img src={images[index]} alt="" />
          <div className={`${styles[theme]} ${styles.content}`}>
            <p style={{ color: textColor }} className={styles.name}>
              {value.name}
            </p>
            {details === null ? (
              <div className={styles.loadingContainer}>
                <Loading />
              </div>
            ) : (
              <span
                style={{ color: textColor }}
                className={styles.short_description}
                dangerouslySetInnerHTML={{ __html: details.short_description }}
              />
            )}

            <div className={styles.footer}>
              <ButtonLogin
                id={`buttonLogin-${value.benefit_id}`}
                redirect={`/your-benefits/${value.benefit_id}`}
                type={`${
                  index % 2 === 0 ? 'goldWithoutBorder' : 'blueWithoutBorder'
                }`}
                height="25px"
                track={() => {
                  dataLayerContentMenuTrack(
                    `Click on card ${benefit} - CTA Log in`,
                    'Benefits'
                  );
                }}
              />
              <hr />
              <Link
                to={`/benefits/${product}/${value.benefit_id}`}
                onClick={event => {
                  dataLayerContentMenuTrack(
                    `Click on card ${benefit} - CTA View more`,
                    'Benefits'
                  );
                  event.stopPropagation();
                }}
              >
                <Button
                  id={`buttonViewMore-${value.benefit_id}`}
                  type={`${
                    index % 2 === 0 ? 'goldWithoutBorder' : 'blueWithoutBorder'
                  }`}
                  height="25px"
                >
                  {intl.VIEW_MORE}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={`${styles.wrapper} ${styles[theme]}`}>
            <div
              className={`${styles.bodyImg} ${styles[theme]}`}
              style={
                imageAPI
                  ? {
                      backgroundImage: `url(${backGround})`,
                      backgroundPosition: `
                  ${
                    imageAPI &&
                    imageAPI.content_url &&
                    getAlignBackgroundByURL(imageAPI.content_url)
                  } ${
                        imageAPI &&
                        imageAPI.content_url &&
                        getValignBackgroundByURL(imageAPI.content_url)
                      }
                `,
                    }
                  : { backgroundColor: `${backGround}` }
              }
            >
              {imageAPI && imageAPI.content_url && (
                <div className={styles.blackCoverItem}></div>
              )}
              <h3>{value.name}</h3>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BenefitItem;
