import { useContext, useEffect, useState } from 'react';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../intl';
import { userServices } from '../../../../services';
import * as translations from './intl';

import CardHolderProfile from './CardHolderInfo/CardHolderProfile';
import styles from './ContactDetails.module.scss';
import ButtonWithIcon from '../../../../components/ButtonWithIcon/ButtonWithIcon';
import ModalEditCardHolder from './ModalEditCardHolder/ModalEditCardHolder';
import Loading from '../../../../components/Loading/Loading';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import EditIcon from '../../../../assets/icons/EditIcon';

const loadContactDetails = async (cn, setUserInfo, setLoading) => {
  try {
    const { data } = await userServices.getCHInfo(cn);
    setUserInfo(data);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

const ContactDetails = () => {
  const { utils, state, actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const [userInfo, setUserInfo] = useState('');
  const [loading, setLoading] = useState(true);
  const intl = translate(translations);
  const cn = utils.getCn();
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    loadContactDetails(cn, setUserInfo, setLoading);
  }, [cn, state.user.data.userInfo]);

  const editCardHolder = () => {
    actions.modal.showModal(
      intl.TEXT_EDIT_CONTACT_DETAILS,
      true,
      <ModalEditCardHolder userInfo={userInfo} />,
      false,
      false,
      null,
      null,
      null,
      true
    );
  };

  return (
    <div id="contact" className={styles.boxContactDetails}>
      <h2 className={`${styles.titleContactDetails} ${styles[themes]}`}>
        {intl.TEXT_CONTACT_DETAILS}
      </h2>
      {loading && <Loading />}
      {!loading && (
        <>
          <CardHolderProfile userInfo={userInfo} />
          <div className={styles.boxButtons}>
            <ButtonWithIcon
              width="auto"
              type="blueWithoutBorder"
              id="edit-contat-button"
              onClick={editCardHolder}
              isSVGComponent={true}
              img={<EditIcon width={22} height={22} />}
              value={intl.BTN_EDIT}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContactDetails;
