import React, { useContext, useMemo, useState } from 'react';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import { generalInformationSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import AddressArea from './AddressArea/AddressArea';
import EmailArea from './EmailArea/EmailArea';
import PhoneArea from './PhoneArea/PhoneArea';
import styles from './GeneralInformation.module.scss';
import ProfessionalArea from './ProfessionalArea/ProfessionalArea';
import RegistrationsArea from './RegistrationsArea/RegistrationsArea';
import BankArea from './BankArea/BankArea';
import IsCardHolderArea from './IsCardHolderArea/IsCardHolderArea';
import ProductArea from './ProductArea/ProductArea';
import { pt_BR } from '../../../../../../intl/idioms';
import IssuanceArea from './IssuanceArea/IssuanceArea';
import NotesArea from './NotesArea/NotesArea';
import { isIssuerDomain } from '../../../../../../utils/banks';

const GeneralInformation = props => {
  const { translate, idiom } = useContext(IntlContext);
  const [isCardHolder, setIsCardHolder] = useState(true);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const {
    initialValues,
    updateParent,
    policySubmitRef,
    showIsCardHolderArea,
    isCertificate,
    productName,
    showIsIssuanceArea,
    isEditProfile,
    changeCardHolder,
    prefixOptions,
    isTelemedicinaBR,
    isExtendedWarrantyCertificate,
    isTravelCertificate,
  } = props;

  const disableProspectFields = isIssuerDomain() && isEditProfile;

  const isBrazil = idiom === pt_BR;
  const card = utils.getSelectedCard();

  const validationSchema = useMemo(
    () =>
      generalInformationSchema(
        intl,
        isCertificate,
        isBrazil,
        isEditProfile,
        card,
        isExtendedWarrantyCertificate,
        isTravelCertificate
      ),
    [card, intl, isBrazil, isCertificate, isEditProfile]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      enableReinitialize
    >
      {props => {
        const { values, handleSubmit, resetForm, errors } = props;
        updateParent(values, validationSchema);
        return (
          <form
            onSubmit={handleSubmit}
            data-testid="FullFormCliamGeneralInformation"
          >
            <button
              ref={policySubmitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>
            {isCertificate && isExtendedWarrantyCertificate && <NotesArea />}

            <div className={styles.generalInformation}>
              {showIsCardHolderArea && (
                <IsCardHolderArea
                  {...props}
                  resetForm={resetForm}
                  initialValues={initialValues}
                  changeCardHolder={changeCardHolder}
                  isCertificate={isCertificate}
                  setIsCardHolder={setIsCardHolder}
                />
              )}

              {productName && <ProductArea productName={productName} />}
              {showIsIssuanceArea && (
                <IssuanceArea {...props} resetForm={resetForm} />
              )}
              <PersonalArea
                {...props}
                disableProspectFields={disableProspectFields}
              />
              <RegistrationsArea
                {...props}
                isCertificate={isCertificate}
                isTelemedicinaBR={isTelemedicinaBR}
                isCardHolder={isCardHolder}
                disableProspectFields={disableProspectFields}
              />
              <PhoneArea
                {...props}
                prefixOptions={prefixOptions}
                disableProspectFields={disableProspectFields}
              />
              <AddressArea
                {...props}
                isExtendedWarrantyCertificate={isExtendedWarrantyCertificate}
                isTravelCertificate={isTravelCertificate}
                isBrazil={isBrazil}
                isCertificate={isCertificate}
                disableProspectFields={disableProspectFields}
              />
              <EmailArea {...props} />

              {(isTelemedicinaBR || !isCertificate) && (
                <ProfessionalArea {...props} />
              )}
              {!isCertificate && !isEditProfile && (
                <BankArea {...props} initialValues={initialValues} />
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default GeneralInformation;
