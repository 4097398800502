import React, { useContext, useEffect } from 'react';
import styles from './ProductList.module.scss';
import * as translations from '../intl';
import { IntlContext } from '../../../../../../../../intl';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import ProductItem from './ProductItem/ProductItem';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import ButtonWithIcon from '../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import plus_master from '../../../../../../../../assets/images/icon/master/icon_plus_master.svg';
import plus_afluent from '../../../../../../../../assets/images/icon/afluent/icon_plus_afluent.svg';
import iconTrashMaster from '../../../../../../../../assets/images/icon/master/icon-trash.svg';
import iconTrashAfluent from '../../../../../../../../assets/images/icon/afluent/icon-trash.svg';
import TrashIcon from '../../../../../../../../assets/icons/TrashIcon';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';

const ProductsList = props => {
  const { values, setFieldValue, setFieldTouched } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const iconPlus = isAfluent() ? plus_afluent : plus_master;

  const addProduct = event => {
    if (event) event.preventDefault();
    const listProducts = [...values.incident.concerned_items];
    const newProduct = {
      description: '',
      purchase_date: '',
      price: {
        value: '',
        currency: values.incident.concerned_items[0].price.currency || '',
      },
      other_insurance_covering: {
        is_internal: false,
        amount: {
          value: '',
          currency: values.incident.concerned_items[0].price.currency || '',
        },
      },
    };
    listProducts.push(newProduct);
    setFieldValue('incident.concerned_items', listProducts);
  };

  const removeProduct = (event, index) => {
    event.preventDefault();
    const cloneProducts = [...values.incident.concerned_items];
    cloneProducts.splice(index, 1);
    setFieldValue('incident.concerned_items', cloneProducts);
    const newTotal =
      values.total_amount_claimed.value -
      values.incident.concerned_items[index].price.value;
    setFieldValue('total_amount_claimed.value', newTotal.toFixed(2));
  };

  const setCurrency = currency => {
    setFieldValue('incident.concerned_items[0].price.currency', currency);
    setFieldTouched('incident.concerned_items[0].price.currency', true);
  };
  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`col-12 ${styles.marginBottom}`}>
      <div className={styles.add}>
        <ButtonWithIcon
          type="blueWithoutBorder"
          onClick={addProduct}
          value={intl.BTN_ADD}
          isSVGComponent={true}
          img={<AddIcon />}
        />
      </div>
      {values.incident.concerned_items.map((product, index) => (
        <div className="row" key={index}>
          <div className="col-12">
            <h3 className={`${styles[themes]} ${styles.groupDivisor}`}>
              {intl.TEXT_ITEM(index + 1)}
            </h3>
          </div>
          <ProductItem {...props} index={index} />
          <div className={`col-2 ${styles.footer}`}>
            {index !== 0 && (
              <ButtonWithIcon
                type="blueWithoutBorder"
                dataTestid={`buttonRemove-${index}`}
                onClick={event => {
                  removeProduct(event, index);
                }}
                value={intl.BTN_REMOVE}
                isSVGComponent={true}
                img={<TrashIcon width={24} height={24} />}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default ProductsList;
