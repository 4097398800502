/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';
import MenuNavigation from '../MenuNavigation/MenuNavigation';
import styles from './Header.module.scss';
import MenuCountryList from '../MenuCountryList/MenuCountryList';
import MenuMobile from '../MenuMobile/MenuMobile';
import ButtonLogin from '../ButtonLogin/ButtonLogin';
import { StateContext } from '../StateContextParent/StateContextParent';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ButtonProfile from '../ButtonProfile/ButtonProfile';
import ListCards from '../ListCards/ListCards';
import { getHeaderConfigByTheme, HEADER_PRIVATE } from './headerConfig';
import BellNotificationTemporary from '../BellNotificationTemporary/BellNotificationTemporary';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper';
import BellNotification from '../BellNotification/BellNotification';
import { NOTIFICATIONS_ENABLE } from '../../utils/environments_variables';
import LinkMainContent from '../LinkMainContent/LinkMainContent';
import { isDomainByIssuer, PORTOBANK } from '../../utils/banks';

const Header = props => {
  const { state } = useContext(StateContext);
  const isLogged = state.user.logged;
  const loadedCards = state.cards.data.length > 0;
  const { headerType, emptyHeader = false } = props;
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [styleHeader, setStyleHeader] = useState(headerType);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const isIssuerDomain = isDomainByIssuer(PORTOBANK);

  const listenerScroll = () => {
    setScroll(window.pageYOffset > 40);
  };

  useEffect(() => {
    setStyleHeader(headerType);
    window.addEventListener('scroll', listenerScroll);

    return () => {
      window.removeEventListener('scroll', listenerScroll);
    };
  }, []);

  useEffect(() => {
    setStyleHeader(headerType);
  }, [headerType]);

  useEffect(() => {
    if (scroll) {
      const config = getHeaderConfigByTheme(theme);
      setStyleHeader(config[HEADER_PRIVATE]);
    } else {
      setStyleHeader(headerType);
    }
  }, [scroll, styleHeader]);

  return (
    <header
      className={`${styles.positionRelative} ${
        styleHeader.backgroundColor ? styles.headerOnScroll : ''
      }`}
    >
      <div className={`${styles.header} ${styles[theme]}`}>
        <LinkMainContent />
        <div className={styles.headerLeft}>
          <Link to="/" onClick={() => dataLayerContentMenuTrack('Visa Logo')}>
            <Logo color={styleHeader.colorLogo} size={styleHeader.logoSize} />
          </Link>
          {isLogged && loadedCards && !isIssuerDomain && <ListCards />}
        </div>
        <div className={styles.headerRight}>
          {!emptyHeader && (
            <>
              <MenuNavigation
                color={styleHeader.colorText}
                isLogged={isLogged}
                colorSearch={styleHeader.searchIcon}
              />
              {!isLogged && (
                <>
                  <MenuCountryList color={styleHeader.colorText} />
                  <div className={styles.buttonArea}>
                    <ButtonLogin
                      type="gold"
                      width="120px"
                      height="40px"
                      dataTestid="HeaderButtonLogin"
                      id="HeaderButtonLogin"
                    />
                  </div>
                  <MenuMobile
                    setShowMenuMobile={setShowMenuMobile}
                    showMenuMobile={showMenuMobile}
                    isLogged={isLogged}
                  />
                </>
              )}
            </>
          )}

          {isLogged && (
            <>
              {NOTIFICATIONS_ENABLE ? (
                <BellNotification />
              ) : (
                <BellNotificationTemporary />
              )}
              <ButtonProfile user={state.user.data.userInfo.firstName} />
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
