import React, { useContext } from 'react';
import CloseIcon from '../../../../../../../assets/icons/CloseIcon';
import PDFIcon from '../../../../../../../assets/icons/PDFIcon';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './FileItem.module.scss';

const FileItem = props => {
  const { file, removeFile } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={`${styles.fileContainer}`}>
      <div className={`${styles.infoContainer}`}>
        <PDFIcon width={28} height={40} />
        <span className={`${styles.name} ${styles[theme]}`}>{file.name}</span>
      </div>
      <span
        className={`${styles[theme]} ${styles.closeIcon}`}
        data-testid="removeIcon"
        onClick={removeFile}
      >
        <CloseIcon width={20} height={20} />
      </span>
    </div>
  );
};

export default FileItem;
