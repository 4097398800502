import { Dispatch, SetStateAction, useContext, useState } from 'react';
import styles from './ModalCertificateInformation.module.scss';
import { useHistory } from 'react-router-dom';
import { FullTravelInsuranceCertificateDetail } from '../../../../../../../@types/APIs/Certificate';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../../../../intl';
import InfoLabelValue from '../../../../../../../components/InfoLabelValue/InfoLabelValue';
import * as translations from './intl';
import GroupDivisor from '../../../../../../../components/GroupDivisor';
import PassengersTripAssistance from './PassengersTripAssistance/PassengersTripAssistance';
import Button from '../../../../../../../components/Button/Button';
import CheckBox from '../../../../../../../components/CheckBox/CheckBox';
import { formatDateFromString } from '../../../../../../../utils/date';
import { getCountryFromPrefixTranslated } from '../../../../../../../utils/country';
import { travel_insurance } from '../../../../../../../utils/certificates_name';
import { stringToBoolean } from '../../../../../../../utils/stringUtils';

interface ModalCertificateInformationProps {
  certificate: FullTravelInsuranceCertificateDetail;
  setStep: (step: number) => void;
  setCertificate: Dispatch<
    SetStateAction<FullTravelInsuranceCertificateDetail>
  >;
  actions: any;
}

const ModalCertificateInformation = ({
  certificate,
  setStep,
  setCertificate,
  actions,
}: ModalCertificateInformationProps) => {
  const [checkBoxConfirm, setCheckBoxConfirm] = useState(false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const history = useHistory();

  const { travel_information } = certificate;
  const { country_destination, country_origin, from, to, is_one_way_travel } =
    travel_information;

  return (
    <>
      <GroupDivisor title={intl.TRAVEL} className={styles.divider} />
      <div className={`${styles.container} ${styles[theme]}`}>
        <div className={styles.leftContainer}>
          <div className={styles.airportContainer}>
            <InfoLabelValue
              label={intl.OUTBOUND}
              value={getCountryFromPrefixTranslated(country_origin, idiom)}
            />
            <InfoLabelValue
              label={intl.OUTBOUND_DATE}
              value={formatDateFromString(from, idiom)}
            />
          </div>
        </div>
        <div className={`${styles.airportContainer} ${styles.rightContainer}`}>
          <InfoLabelValue
            label={intl.RETURN}
            value={getCountryFromPrefixTranslated(country_destination, idiom)}
          />
          {!stringToBoolean(is_one_way_travel) && (
            <InfoLabelValue
              label={intl.RETURN_DATE}
              value={formatDateFromString(to, idiom)}
            />
          )}
        </div>
      </div>
      <GroupDivisor title={intl.NUMBER_PERSON} className={styles.divider} />
      <PassengersTripAssistance certificate={certificate} />

      <div className={`${styles[theme]} ${styles.checkboxConfirmCertificate}`}>
        <CheckBox
          name="accept_terms"
          value={checkBoxConfirm}
          onClick={check => setCheckBoxConfirm(check)}
        >
          {intl.LABEL_INFORMATION_IS_CORRECT}
        </CheckBox>
      </div>

      <div className={`row`}>
        <div className={`col-12  ${styles.footerBtns}`}>
          <div className={styles.btnModalConfirmation}>
            <Button
              disabled={!checkBoxConfirm}
              type="borderBlue"
              onClick={() => {
                setStep(3);
                setCertificate(certificate);
                actions.modal.closeModal();
              }}
              width="180px"
              id="BtnConfirmationCertificate"
              dataTestid={`data-testid-BtnConfirmationCertificate`}
            >
              {intl.TEXT_CONFIRM}
            </Button>
          </div>
          <div className={styles.btnModalConfirmation}>
            <Button
              type="blueWithoutBorder"
              onClick={() => {
                history.push(`/certificate/${travel_insurance}`);
                actions.modal.closeModal();
              }}
              width="220px"
              id="BtnConfirmationCertificate"
              dataTestid={`data-testid-BtnConfirmationCertificate`}
            >
              {intl.LABEL_CREATE_CERTIFICATE}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCertificateInformation;
