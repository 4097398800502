import React, { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as TravelAccidentUtils from '../../../../../../utils/claimsAPI/TravelAccidentUtils';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);
  const [affectedPerson, setAffectedPerson] = useState({});

  useEffect(() => {
    const affectedPerson = (claimDetails && claimDetails.affected_person) || {};
    setAffectedPerson(affectedPerson);
  }, [claimDetails]);

  const isAccidentalDeath = claimDetails && claimDetails.is_accidental_death;
  const isMarried =
    affectedPerson &&
    affectedPerson.family_situation &&
    affectedPerson.family_situation.marital_status === 'MARRIED';

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.ACCIDENT_REASON}
          value={TravelAccidentUtils.getAccidentReason(isAccidentalDeath, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={TravelAccidentUtils.getCardholderRelationship(
            affectedPerson,
            intl
          )}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_NAME}
          value={TravelAccidentUtils.getAffectedPersonName(affectedPerson)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={TravelAccidentUtils.getAffectedPersonLastName(affectedPerson)}
        />
      </div>
      {affectedPerson.relationship === TravelAccidentUtils.OTHER_VALUE && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue
            label={intl.LABEL_RELATIONSHIP}
            value={TravelAccidentUtils.getAffectedPersonComment(affectedPerson)}
          />
        </div>
      )}
      {affectedPerson.relationship !==
        TravelAccidentUtils.CARD_HOLDER_VALUE && (
        <>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_NUMBER}
              value={TravelAccidentUtils.getAffectedPersonPhone(
                affectedPerson,
                intl
              )}
            />
          </div>
          {!isAccidentalDeath && (
            <>
              <div className="col-sm-12 col-md-3">
                <InfoLabelValue
                  label={intl.LABEL_OCCUPATION}
                  value={TravelAccidentUtils.getAffectedPersonOccupation(
                    affectedPerson
                  )}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <InfoLabelValue
                  label={intl.LABEL_EMAIL}
                  value={TravelAccidentUtils.getAffectedPersonEmail(
                    affectedPerson
                  )}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <InfoLabelValue
                  label={intl.LABEL_NATIONALITY}
                  value={TravelAccidentUtils.getAffectedPersonNationality(
                    affectedPerson,
                    idiom
                  )}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <InfoLabelValue
                  label={intl.LABEL_REGISTRATION_TYPE}
                  value={TravelAccidentUtils.getAffectedPersonRegistration(
                    affectedPerson,
                    idiom
                  )}
                />
              </div>
            </>
          )}
        </>
      )}
      {!isAccidentalDeath && (
        <>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_MARITAL_STATUS}
              value={TravelAccidentUtils.getAffectedPersonMaritalStatus(
                affectedPerson,
                intl
              )}
            />
          </div>
          {isMarried && (
            <div className="col-sm-12 col-md-3">
              <InfoLabelValue
                label={intl.LABEL_DATE_LIVE_TOGETHER}
                value={TravelAccidentUtils.getAffectedPersonLivedTogetherSince(
                  affectedPerson,
                  idiom,
                  country
                )}
              />
            </div>
          )}
        </>
      )}
      {affectedPerson.relationship !==
        TravelAccidentUtils.CARD_HOLDER_VALUE && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_ADDRESS}
            value={TravelAccidentUtils.getAffectedPersonAddress(
              affectedPerson,
              idiom
            )}
          />
        </div>
      )}
    </>
  );
};

export default PersonalArea;
