import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const SearchIcon = props => {
  const { color = null } = props;
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg width={22} height={23} viewBox="0 0 22 23" fill="none" {...props}>
      <g clipPath="url(#clip0_4967_6007)">
        <path
          d="M8.25033 1.31663C4.15156 1.31663 0.816992 4.65119 0.816992 8.74996C0.816992 12.8487 4.15156 16.1833 8.25033 16.1833C12.3491 16.1833 15.6837 12.8487 15.6837 8.74996C15.6837 4.65119 12.3491 1.31663 8.25033 1.31663ZM8.25033 14.15C5.27268 14.15 2.85033 11.7276 2.85033 8.74996C2.85033 5.77231 5.27268 3.34996 8.25033 3.34996C11.228 3.34996 13.6503 5.77231 13.6503 8.74996C13.6503 11.7276 11.228 14.15 8.25033 14.15Z"
          fill={color ? color : config_icon.secondary}
          stroke={color ? color : config_icon.secondary}
          strokeWidth={0.2}
        />
        <path
          d="M8.25 4.98325H8.15V5.08325V6.91659V7.01659H8.25C9.20588 7.01659 9.98333 7.79404 9.98333 8.74992V8.84992H10.0833H11.9167H12.0167V8.74992C12.0167 6.67292 10.327 4.98325 8.25 4.98325Z"
          fill={color ? color : config_icon.secondary}
          stroke={color ? color : config_icon.secondary}
          strokeWidth={0.2}
        />
        <path
          d="M15.4148 14.4763L15.3367 14.3982L15.2667 14.4836C14.8828 14.9525 14.4533 15.382 13.9845 15.7658L13.899 15.8358L13.9771 15.9139L20.3653 22.3021L20.436 22.3728L20.5067 22.3021L21.8029 21.0058L21.8736 20.9351L21.8029 20.8644L15.4148 14.4763Z"
          fill={color ? color : config_icon.secondary}
          stroke={color ? color : config_icon.secondary}
          strokeWidth={0.2}
        />
      </g>
      <defs>
        <clipPath id="clip0_4967_6007">
          <rect
            width={22}
            height={22}
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
