import React, { useContext } from 'react';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import moment from 'moment';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../intl/idioms';
import { getCountryByInitials } from '../../../../intl/utils';
import { formatCustomDate } from '../../../../utils/date';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const CarRentalDetail = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const incident = claimDetails && claimDetails.incident;

  const carRental = claimDetails && claimDetails.car_rental;

  const firstName =
    incident && incident.driver.allOf.first_name
      ? incident.driver.allOf.first_name
      : '-';

  const lastName =
    incident && incident.driver.allOf.last_name
      ? incident.driver.allOf.last_name
      : '-';

  const fullDriverName = `${firstName} ${lastName}`;

  const agencyName =
    carRental && carRental.agency.name ? carRental.agency.name : '-';

  const incidentPlace =
    incident && incident.location.street_address
      ? incident.location.street_address
      : '-';

  const incidentCity =
    incident && incident.location.locality ? incident.location.locality : '-';

  const incidentState =
    incident && incident.location.state ? incident.location.state : '-';

  const incidentCountry =
    incident && incident.location.country
      ? getCountryByInitials(incident.location.country, idiom)
      : '-';

  const fullIncidentLocation = `${incidentPlace} <br /> ${incidentCity} ${incidentState} ${incidentCountry}`;

  const incidentDate =
    incident && incident.date
      ? formatCustomDate(incident.date, 'YYYY-MM-DD', idiom, country)
      : '-';

  const rentalPeriodFrom =
    carRental && carRental.from
      ? formatCustomDate(carRental.from, 'YYYY-MM-DD', idiom, country)
      : '-';

  const rentalPeriodTo =
    carRental && carRental.to
      ? formatCustomDate(carRental.to, 'YYYY-MM-DD', idiom, country)
      : '-';

  const fullRentalPeriod = `${rentalPeriodFrom} - ${rentalPeriodTo}`;

  const amountEstimationDamage =
    claimDetails && claimDetails.amount_estimation_damage.value
      ? claimDetails.amount_estimation_damage.value
      : '-';

  const amountEstimationDamageCurrency =
    claimDetails && claimDetails.amount_estimation_damage.currency
      ? claimDetails.amount_estimation_damage.currency
      : '-';

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <InfoLabelValue label={intl.LABEL_DRIVER_NAME} value={fullDriverName} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_AGENCY_NAME} value={agencyName} />
      </div>

      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_RENTAL_PERIOD}
          value={fullRentalPeriod}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_INCIDENT_DATE} value={incidentDate} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_LOCATION}
          value={fullIncidentLocation}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_AMOUNT_CLAIMED}
          value={`${amountEstimationDamage} - ${amountEstimationDamageCurrency}`}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} - ${reimbursedCurrency}`}
          />
        </div>
      )}
    </div>
  );
};

export default CarRentalDetail;
