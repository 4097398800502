import React from 'react';
import {
  claimsServices,
  reimbursmentService,
} from '../../../../../../services';
import { checkGeneralInfoChange } from './checkGeneralInfoChange/index';
import { checkBankInfoChange } from './checkBankInfoChange/index';
import {
  formatDataPostClaim,
  formatDataPostReimbursement,
  formatDataToUpdate,
} from './formatData';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import { formatReimbursementToNewAPIV2 } from './reimbursement/formatNewApi';
import {
  dataLayerClaimEventTrack,
  CLAIM_CREATED,
  CLAIM_COMPLETED_STEP,
  CLAIM_SITE_SECTION,
  CLAIM_DOCUMENT_ATTACHED_INTENTION,
  dataLayerClaimDocumentEventTrack,
} from '../../../../../../utils/GTM_helper';
import { isAllowedClaimReimbursmentUpdate } from '../../../../../../utils/claims_name';
import { NOTIFICATIONS_ENABLE } from '../../../../../../utils/environments_variables';
import { TYPE_BANK, TYPE_OCT } from '../PaymentInformation/OCTFlow/oct-const';
import { checkOCTInfoChange } from '../PaymentInformation/OCTFlow/OCTForm/OCTSubmit/OCTSubmit';

let case_id = '';

const submit = async props => {
  const {
    idiomForApi,
    initialValues,
    disabledReimbursement,
    hideReimbursement,
    trackEventUserAction,
  } = props;
  const { claimData, claimType } = formatDataPostClaim(props);
  trackEventUserAction(
    '#### (CLAIM) CLICOU NO SUBMIT -- FORMATOU DADOS DO INCIDENT CLAIM',
    { claimData, claimType }
  );
  const claimResponse = await claimsServices.postClaim(
    claimType,
    claimData,
    idiomForApi
  );
  let reimbursement;
  if (!props.values.reimbursement.paymentIdOCT) {
    reimbursement = formatDataPostReimbursement(props);
  }

  trackEventUserAction(
    '#### (CLAIM) CLICOU NO SUBMIT -- FORMATOU DADOS DO REIMBURSEMENT',
    reimbursement
  );
  case_id = claimResponse.data.case_id;
  if (!disabledReimbursement && !hideReimbursement) {
    trackEventUserAction(
      '#### (CLAIM) CLICOU NO SUBMIT -- VAI ENVIAR REIMBURSEMENT',
      reimbursement
    );
    const newReimbursement = formatReimbursementToNewAPIV2(
      reimbursement,
      props.idiom,
      props.values
    );

    await reimbursmentService.postReimbursmentV2(
      claimResponse.data.case_id,
      newReimbursement
    );
  }
};

const update = async props => {
  const {
    idiomForApi,
    completeClaim,
    disabledReimbursement,
    hideReimbursement,
    trackEventUserAction,
  } = props;

  const { claimData, claimType, reimbursement, claimTypeOriginal } =
    formatDataToUpdate(props);
  trackEventUserAction(
    '#### (CLAIM) CLICOU NO SUBMIT -- FORMATOU DADOS DO INCIDENT CLAIM - UPDATE',
    { claimData, claimType, reimbursement }
  );
  await claimsServices.updateByClaimId(
    completeClaim.claim_id,
    claimType,
    claimData,
    idiomForApi
  );

  if (
    isAllowedClaimReimbursmentUpdate(claimTypeOriginal) &&
    !disabledReimbursement &&
    !hideReimbursement
  ) {
    const newReimbursement = formatReimbursementToNewAPIV2(
      reimbursement,
      props.idiom
    );

    trackEventUserAction(
      '#### (CLAIM) CLICOU NO SUBMIT -- FORMATOU DADOS DO REIMBURSEMENT - UPDATE',
      { newReimbursement }
    );

    if (completeClaim.reimbursement_id !== null) {
      await reimbursmentService.updateReimbursmentV2(
        completeClaim.claim_id,
        completeClaim.reimbursement_id,
        newReimbursement,
        idiomForApi
      );
    } else {
      await reimbursmentService.postReimbursmentV2(
        completeClaim.claim_id,
        newReimbursement
      );
    }
  } else {
    if (completeClaim.reimbursement_id !== null) {
      await reimbursmentService.deleteReimbursmentV2(
        completeClaim.claim_id,
        completeClaim.reimbursement_id
      );
    }
  }

  case_id = completeClaim.claim_id;
};

const showModalError = (actions, msg) => {
  return actions.modal.showModal(
    false,
    false,
    <ModalInformation type="error" message={msg} />,
    true,
    false
  );
};

const redirectToUpload = (actions, history, case_id, claimType) => {
  dataLayerClaimDocumentEventTrack(
    CLAIM_DOCUMENT_ATTACHED_INTENTION,
    claimType || '',
    CLAIM_SITE_SECTION
  );
  history.push(
    `/claim-type/${case_id}/upload?siteSection=${CLAIM_SITE_SECTION}`
  );
  actions.modal.closeModal();
};

export const submitAClaim = async (props, isClaimUpdate) => {
  const {
    setSubmitting,
    actions,
    history,
    intl,
    intlOCT,
    verifiedGeneralInformation,
    claimType,
    notificationActions,
    thirdPartyPayment,
    hideReimbursement,
    disabledReimbursement,
    trackEventUserAction,
    bankType,
    paymentId,
    setPaymentId,
  } = props;
  setSubmitting(true);
  try {
    trackEventUserAction(
      '#### (CLAIM) CLICOU NO SUBMIT -- ENTROU NO SUBMIT APÓS VALIDAÇÃO',
      props
    );
    if (!verifiedGeneralInformation) {
      await checkGeneralInfoChange(props);
    }
    if (bankType == TYPE_BANK) {
      if (
        !props.values.reimbursement.BankAccount.bank.bankInfoChange &&
        !thirdPartyPayment &&
        !disabledReimbursement &&
        !hideReimbursement &&
        !isClaimUpdate
      ) {
        await checkBankInfoChange(trackEventUserAction, props);
      }
    } else if (bankType == TYPE_OCT) {
      const id = await checkOCTInfoChange(
        props.values.reimbursement,
        actions,
        intlOCT,
        trackEventUserAction,
        paymentId,
        setPaymentId
      );

      props.values.reimbursement.paymentIdOCT =
        props.values.reimbursement.paymentIdOCT || paymentId || id;

      if (!props.values.reimbursement.paymentIdOCT) {
        trackEventUserAction(
          '#### (CLAIM) OCT WITHOUT PAYMENT ID -- ERROR',
          props
        );
        throw new Error('OCT without payment ID');
      }
    }

    trackEventUserAction(
      '#### (CLAIM) CLICOU NO SUBMIT -- VERIFICOU MUDANÇAS GENERAL/BANK INFO/',
      props
    );

    const isUpdate = history.location.pathname.includes('update');
    actions.loadingFullScreen.showLoadingFullScreen(
      isUpdate ? intl.UPDATING_CLAIM : intl.CREATING_CLAIM
    );

    if (isClaimUpdate) {
      trackEventUserAction(
        '#### (CLAIM) CLICOU NO SUBMIT -- FAZENDO O UPDATE DA CLAIM',
        props
      );
      await update(props);
      trackEventUserAction(
        '#### (CLAIM) CLICOU NO SUBMIT -- UPDATE REALIZADO COM SUCESSO',
        props
      );
      actions.loadingFullScreen.hideLoadingFullScreen();
    } else {
      trackEventUserAction(
        '#### (CLAIM) CLICOU NO SUBMIT -- CRIANDO A CLAIM',
        props
      );
      await submit(props);
      trackEventUserAction(
        '#### (CLAIM) CLICOU NO SUBMIT -- CLAIM CRIADA',
        props
      );
      if (NOTIFICATIONS_ENABLE) {
        notificationActions.bellNotification.updateBellNotification();
      }

      actions.loadingFullScreen.hideLoadingFullScreen();
    }

    if (case_id) {
      trackEventUserAction(
        '#### (CLAIM) CLICOU NO SUBMIT -- REDIRECIONANDO PARA UPLOAD OU PROFILE/CLAIMS',
        case_id
      );
      dataLayerClaimEventTrack(CLAIM_COMPLETED_STEP, claimType, 5);
      dataLayerClaimEventTrack(CLAIM_CREATED, claimType);
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.TITLE_CLAIM_SUCESS}
          subtitle={intl.SUBTITLE_CLAIM_SUCESS}
          textBtn={intl.ATTACH_DOCUMENT}
          clickBtn={() =>
            redirectToUpload(actions, history, case_id, claimType)
          }
          textBtnDeny={intl.YOUR_CLAIMS}
          clickBtnDeny={() => {
            actions.modal.closeModal();
            history.push('/profile/claims');
          }}
        />,
        true,
        false
      );
    } else {
      trackEventUserAction(
        '#### (CLAIM) CLICOU NO SUBMIT -- REDIRECIONANDO PROFILE/CLAIMS',
        props
      );
      history.push('/profile/claims');
    }
  } catch (error) {
    trackEventUserAction(
      '#### (CLAIM) CLICOU NO SUBMIT -- ERRO NA CRIAÇÃO/UPDATE CLAIM',
      {
        error,
        error_response: error.response,
        message: error.message,
        stack: JSON.stringify(error.stack),
      }
    );
    actions.loadingFullScreen.hideLoadingFullScreen();
    if (error) {
      if (error.message === 'notCreateClaim') {
        showModalError(actions, intl.OPTION_RECEIVE_UNAVAILABLE);
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ===
          'You have selected an out-of-coverage ticket for this claim. Please make sure that your ticket was issued prior to the start of your trip.'
      ) {
        showModalError(actions, intl.ERROR_DATE_BY_CERTIFICATE);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ===
          'The customer is trying to open a duplicated claim'
      ) {
        showModalError(actions, intl.ERROR_DUPLICATED_CLAIM);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ===
          'The benefit is not covered for the specified period.'
      ) {
        showModalError(actions, intl.ERROR_NOT_COVERED_FOR_PERIOD);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ===
          'Invalid request body. iems_claim_information.refering_physician.email is not valid'
      ) {
        showModalError(actions, intl.MODAL_INVALID_DOCTOR_HOSPITAL_EMAIL);
      } else {
        showModalError(actions, intl.GENERATED_CLAIM_ERROR);
      }
    } else {
      showModalError(actions, intl.GENERATED_CLAIM_ERROR);
    }
  }
  setSubmitting(false);
};
