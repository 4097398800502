import { useContext } from 'react';
import styles from './Loading.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

export interface LoadingProps {
  scale?: number;
  text?: string;
  color?: string;
  checkBoxLoading?: boolean | string;
}

const getColorStyle = color => {
  switch (color) {
    case 'gold':
      return styles.ldsSpinnerGold;
    case 'blue':
      return styles.ldsSpinnerBlue;
    case 'blueAXA':
      return styles.ldsSpinnerBlueAXA;
    default:
      return styles.ldsSpinnerGold;
  }
};

const Loading = ({
  scale = 1,
  text = '',
  color,
  checkBoxLoading,
}: LoadingProps) => {
  const colorSpinner = getColorStyle(color);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <>
      <div
        className={`${checkBoxLoading && styles.checkBoxLoading} ${
          styles.ldsSpinner
        } ${colorSpinner}`}
        style={{ transform: `scale(${scale})` }}
        role="loading"
      >
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
      </div>
      {text && text.length > 0 && (
        <div className={styles.loadingText}>
          <h2 className={styles[themes]}>{text}</h2>
        </div>
      )}
    </>
  );
};

export default Loading;
