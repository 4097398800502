import React, { useContext } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import styles from './Header.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import ICON_ORDER_MASTER from '../../../../assets/images/icon/master/icon_order.svg';
import ICON_ORDER_AFLUENT from '../../../../assets/images/icon/afluent/icon_order.svg';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import ChevronGeneric from '../../../../assets/icons/ChevronGeneric';

const Header = ({
  title,
  subtitle,
  qtdCertificates,
  filterSelected,
  handleOrderCertificates,
  orderAsc,
  orderAscPast,
  orderType,
  id,
  loading,
  messageEmpty,
}) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <>
      {orderType === 'recent' && (
        <div className={styles.headerContainer} id={id}>
          <div className={styles.HeaderInfo}>
            <TitleSectionProfile
              title={title}
              number={qtdCertificates}
              subtitle={qtdCertificates > 0 ? subtitle : messageEmpty}
              loading={loading}
            />
          </div>
          {qtdCertificates > 0 && (
            <div
              className={styles.headerActions}
              onClick={handleOrderCertificates}
            >
              <img
                src={isAfluent() ? ICON_ORDER_AFLUENT : ICON_ORDER_MASTER}
                className={styles.iconList}
                alt={intl.SORT_ICON}
              />
              <span
                className={`${
                  filterSelected === 'all' && styles.filterSelected
                } ${styles[theme]}`}
              >
                {orderAsc === 'recent'
                  ? intl.TEXT_RECENT
                  : intl.TEXT_LESS_RECENT}
              </span>
              <span className={styles.iconActions}>
                <ChevronGeneric
                  rotate={orderAsc === 'recent' ? 0 : 180}
                  width={20}
                  height={20}
                />
              </span>
            </div>
          )}
        </div>
      )}
      {orderType === 'past' && (
        <div className={styles.headerContainer} id={id}>
          <div className={styles.HeaderInfo}>
            <TitleSectionProfile
              title={title}
              number={qtdCertificates}
              subtitle={qtdCertificates > 0 ? subtitle : messageEmpty}
              loading={loading}
            />
          </div>
          {qtdCertificates > 0 && (
            <div
              className={styles.headerActions}
              onClick={handleOrderCertificates}
            >
              <img
                src={isAfluent() ? ICON_ORDER_AFLUENT : ICON_ORDER_MASTER}
                className={`${styles[theme]} ${styles.iconList}`}
                alt={intl.SORT_ICON}
              />
              <span
                className={`${
                  filterSelected === 'all' && styles.filterSelected
                }  ${styles[theme]}`}
              >
                {orderAscPast === 'recent'
                  ? intl.TEXT_RECENT
                  : intl.TEXT_LESS_RECENT}
              </span>
              <span className={styles.iconActions}>
                <ChevronGeneric
                  rotate={orderAscPast === 'recent' ? 0 : 180}
                  width={20}
                  height={20}
                />
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
