export const ENABLE_OFFERS =
  process.env.REACT_APP_OFFERS_ENABLE.toString() === 'true';

export const ENABLE_SPECIAL_BENEFITS =
  process.env.REACT_APP_SPECIAL_BENEFITS_ENABLE.toString() === 'true';

export const ENABLE_CROSS_SELLING =
  process.env.REACT_APP_ENABLE_CROSS_SELLING.toString() === 'true';

export const APPEAL_ENABLE =
  process.env.REACT_APP_APPEAL_ENABLE.toString() === 'true';

export const REQUEST_CERTIFICATE_IEMS =
  process.env.REACT_APP_REQUEST_CERTIFICATE_IEMS.toString() === 'true';

export const REQUEST_CERTIFICATE_IEMS_WHITELIST =
  process.env.REACT_APP_REQUEST_CERTIFICATE_IEMS_WHITELIST.split(',');

export const NOTIFICATIONS_ENABLE =
  process.env.REACT_APP_NOTIFICATIONS_ENABLE.toString() === 'true';

const LIST_COUNTRIES_ENABLE_TO_USE_PAN =
  process.env.REACT_APP_COUNTRIES_ENABLE_TO_USE_PAN &&
  process.env.REACT_APP_COUNTRIES_ENABLE_TO_USE_PAN.split(',');

export const ALPHANUMERIC_COUNTRY_LIST =
  process.env.REACT_APP_CEP_ALPHANUMERIC_COUNTRY_LIST &&
  process.env.REACT_APP_CEP_ALPHANUMERIC_COUNTRY_LIST.split(',');

export const isCoutryEnableToUsePAN = country =>
  LIST_COUNTRIES_ENABLE_TO_USE_PAN.indexOf(country) >= 0;

export const ENABLE_UPSELLING =
  process.env.REACT_APP_ENABLE_UPSELLING.toString() === 'true';

export const B2B2C_TEST_WHITELIST =
  process.env.REACT_APP_B2B2C_TEST_WHITELIST.split(',');

export const REACT_APP_JWE_CUSTOMER_API =
  process.env.REACT_APP_JWE_CUSTOMER_API.toString() === 'true';

export const REACT_APP_JWE_BENEFITS_EXTENSION =
  process.env.REACT_APP_JWE_BENEFITS_EXTENSION.toString() === 'true';

export const REACT_APP_CHATBOT_UST =
  process.env.REACT_APP_CHATBOT_UST.toString() === 'true';

export const REACT_APP_UPLOAD_CONCLUDE_ENABLE =
  process.env.REACT_APP_UPLOAD_CONCLUDE_ENABLE.toString() === 'true';

export const REACT_APP_TOTAL_REIMBURSEMENT =
  process.env.REACT_APP_TOTAL_REIMBURSEMENT.toString() === 'true';

export const LIST_CLAIMS_API_ENABLE_TO_USE_MLE =
  process.env.REACT_APP_JWE_CLAIMS_API_LIST &&
  process.env.REACT_APP_JWE_CLAIMS_API_LIST.split(',');

export const ENABLE_DOWNLOAD_APP_PAGE =
  process.env.REACT_APP_DOWNLOAD.toString() === 'true';

export const SHOW_MESSENGER =
  process.env.REACT_APP_MESSENGER_CHAT.toString() === 'true';

export const ENABLE_TRIP_ASSISTANCE =
  process.env.REACT_APP_ENABLE_TRIP_ASSISTANCE.toString() === 'true';

export const API_TOKENEX_ENDPOINT =
  process.env.REACT_APP_TOKENEX_API_ENDPOINT.toString();

export const ALWAYS_ADD_PAN =
  process.env.REACT_APP_ALWAYS_ADD_PAN.toString() === 'true';

export const HIDDEN_BENEFITS = process.env.REACT_APP_HIDDEN_BENEFITS.split(',');

export const COUNTRY_WITHOUT_ACCESS_APP =
  process.env.REACT_APP_COUNTRY_WITHOUT_ACCESS_APP.split(',');

export const ENABLE_CLAIM_PROOF_OF_PAYMENT =
  process.env.REACT_APP_ENABLE_CLAIM_PROOF_OF_PAYMENT.toString() === 'true';

export const ENABLE_NOTIFICATION_TO_PRICE_PROTECTION =
  process.env.REACT_APP_ENABLE_NOTIFICATION_TO_PRICE_PROTECTION.toString() ===
  'true';

export const ENABLE_NOTIFICATION_TO_VACACIONES_PROTEGIDAS =
  process.env.REACT_APP_ENABLE_NOTIFICATION_TO_VACACIONES_PROTEGIDAS.toString() ===
  'true';

export const ENABLE_NOTIFICATION_TO_AIRPORT_COMPANION =
  process.env.REACT_APP_ENABLE_NOTIFICATION_VAC.toString() === 'true';

export const ENABLE_UUP =
  process.env.REACT_APP_ENABLE_UUP.toString() === 'true';

export const SHOW_ALERT_CHATBOT =
  process.env.REACT_APP_CHATBOT_SHOW_TEXT_ALERT.toString() === 'true';

export const REACT_APP_DATE_MONETIZATION_GO_LIVE =
  process.env.REACT_APP_DATE_MONETIZATION_GO_LIVE.toString();

export const REACT_APP_YOURPASS_LINK =
  process.env.REACT_APP_YOURPASS_LINK.toString();

export const ENABLE_FAQ_API =
  process.env.REACT_APP_ENABLE_FAQ_API.toString() === 'true';

export const ENABLE_EXPECTED_DOCUMENTS_API =
  process.env.REACT_APP_ENABLE_EXPECTED_DOCUMENTS_API.toString() === 'true';

export const ENABLE_OCT =
  process.env.REACT_APP_OCT_ENABLE.toString() === 'true';

export const RESTRICTION_OCT_BY_COUNTRY =
  process.env.REACT_APP_RESTRICTION_OCT_BY_COUNTRY &&
  process.env.REACT_APP_RESTRICTION_OCT_BY_COUNTRY.split(',');

export const ENABLE_RED_BAR_MESSAGE =
  process.env.REACT_APP_ENABLE_RED_BAR_MESSAGE.toString() === 'true';

export const REACT_APP_DATE_TERMS_AND_CONDITIONS_CINEPOLIS_GO_LIVE =
  process.env.REACT_APP_DATE_TERMS_AND_CONDITIONS_CINEPOLIS_GO_LIVE.toString();

export const ENABLE_CLAIM_APPROVED_VALUE =
  process.env.REACT_APP_ENABLE_CLAIM_APPROVED_VALUE.toString() === 'true';

export const ENABLED_LEGACY_LINKS =
  process.env.REACT_APP_ENABLED_LEGACY_LINKS.toString() === 'true';

export const ENABLE_DISLIKE_FAQ_API =
  process.env.REACT_APP_DISLIKE_FAQ_API.toString() === 'true';

export const REACT_APP_MINUTES_TO_SHOW_NPS =
  process.env.REACT_APP_MINUTES_TO_SHOW_NPS.toString();
