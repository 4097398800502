import { TEXT_ADD_YOUR_CARD_PAN, TEXT_16_DIGITS } from '../ModalAddCard/intl';

export { TEXT_ADD_YOUR_CARD_PAN, TEXT_16_DIGITS };

export const TEXT_WE_ARE_WORKING = {
  en_US:
    'We are working to offer you a more personalized and complete experience, for that we need more information of your Visa Card ',
  pt_BR:
    'Estamos trabalhando para oferecer uma experiência mais personalizada e completa, para isso precisamos de mais informações do seu cartão Visa ',
  es_ES:
    'Estamos trabajando para ofrecerte una experiencia más personalizada y completa, para eso necesitamos más información de tu tarjeta Visa ',
};

export const TEXT_PLEASE_ENTER_16_DIGITS = {
  en_US:
    'Please enter the 16 digits and the expiration date of your Visa card:',
  pt_BR:
    'Por favor ingresse os 16 dígitos e a data de expiração do seu cartão Visa:',
  es_ES:
    'Por favor ingresa los 16 digitos y la fecha de expiración de tu tarjeta Visa:',
};

export const TEXT_FORMAT_INVALID = {
  en_US: 'Invalid format, please check and try again.',
  pt_BR: 'Formato inválido, por favor verifique.',
  es_ES: 'El formato no es válido, por favor verifica.',
};

export const TEXT_NOT_VISA_PRODUCT = {
  en_US:
    'The informed card does not correspond to a Visa product. Please check and try again.',
  pt_BR:
    'Os dígitos informados não correspondem a um cartão Visa. Por favor verifique e tente novamente.',
  es_ES:
    'Los 16 digitos ingresados no corresponden a una tarjea Visa. Por favor verifica e intenta nuevamente.',
};

export const TEXT_NOT_CORRESPOND_REGISTERED_CARD = {
  en_US:
    'The 16 digits entered do not correspond to the previously registered card. Please verify the digits.',
  pt_BR:
    'Os 16 dígitos informados não correspondem ao cartão que deseja atualizar. Por favor verifique novamente.',
  es_ES:
    'Os 16 digitos ingresados no corresponden a la tarjeta que tú quieres actualizar. Por favor verifica e intenta nuevamente.',
};

export const TEXT_UPDATE_BUTTON = {
  en_US: 'Conclude',
  pt_BR: 'Concluir',
  es_ES: 'Concluir',
};

export const TEXT_BIN_UPDATE_SUCCESS = {
  en_US: 'Your card has been successfully updated!',
  pt_BR: 'Seu cartão foi atualizado com sucesso!',
  es_ES: 'Tu tarjeta ha sido actualizada con éxito!',
};

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const ALREADY_USED_ERROR_OCCURRED = {
  en_US:
    'Please make sure you informed the 16-digit number correctly (this number is unique).',
  pt_BR:
    'Por favor, certifique-se de que informou o número de 16 dígitos corretamente (essa numeração é única).',
  es_ES:
    'Por favor, asegúrate de haber informado correctamente el número de 16 dígitos (este número es único).',
};

export const LENGTH_16_DIGITS = {
  en_US: 'The card numbers must be have 16 digits.',
  pt_BR: 'Os números do cartão devem ter 16 dígitos.',
  es_ES: 'Los números de la tarjeta deben tener 16 dígitos.',
};

export const TEXT_ENJOY_BENEFITS = {
  en_US: 'Your card is up to date, enjoy the benefits.',
  pt_BR:
    'O cartão informado já está atualizado. Disfrute dos seus benefícios! ',
  es_ES: 'Tu tarjeta ha sido actualizada con éxito!',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar ',
  es_ES: 'Volver',
};

export const TEXT_WARNING_VISA = {
  en_US:
    'Visa will not make any additional charges to your card, the information provided will be used for validation purposes. Only Visa cards issued in Latin America and the Caribbean are eligible.',
  pt_BR:
    'A Visa não efetuará nenhuma cobrança adicional ao seu cartão, a informação fornecida é apenas para validação. Apenas os cartões Visa emitidos na América Latina e no Caribe são elegíveis.',
  es_ES:
    'Visa no hará ningún cargo adicional a tu tarjeta, la información brindada será usada con fines de validación. Solo son elegibles las tarjetas Visa emitidas en América Latina y el Caribe.',
};

export const TEXT_INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_MORE_INFO = {
  en_US: 'More information of your Visa Card',
  es_ES: 'Más información de tu Tarjeta Visa',
  pt_BR: 'Precisamos de mais dados do seu cartão Visa',
};

export const TEXT_BIN_NUMBER = {
  en_US: 'We are working to offer you a better and complete experience.',
  es_ES:
    'Estamos trabajando para ofrecerte una experiencia mejor y más completa.',
  pt_BR:
    'Agora que você já nos informou os 10 primeiros dígitos do seu cartão Visa e validamos suas informações, precisamos que nos informe os 16 números do seu cartão Visa, assim podemos te oferecer uma experiência completa:',
};

export const TEXT_YOUR_CARD = {
  en_US: 'Additional information',
  es_ES: 'Información adicional',
  pt_BR: 'Informação adicional',
};

export const TEXT_SUBTITLE = {
  en_US:
    'Visa will not make any additional charges to your card, the information provided will be used for validation purposes.',
  pt_BR:
    'A Visa não efetuará nenhuma cobrança adicional ao seu cartão, a informação fornecida é apenas para validação.',
  es_ES:
    'Visa no hará ningún cargo adicional a tu tarjeta, la información brindada será usada con fines de validación. ',
};

export const LABEL_10_DIGITS = {
  en_US: 'Credit card number (first 10 digits)',
  es_ES: 'Número de tarjeta de crédito (primeros 10 dígitos)',
  pt_BR: 'Número do cartão de crédito (primeiros 10 dígitos)',
};

export const LABEL_16_DIGITS = {
  en_US: 'Credit card number (16 digits) *',
  es_ES: 'Número de tarjeta de crédito (16 dígitos) *',
  pt_BR: 'Número do cartão de crédito (16 dígitos) *',
};

export const LABEL_EXPIRATION_DATE = {
  en_US: 'Expiration date (MM/YY) *',
  es_ES: 'Fecha de caducidad (MM/AA) *',
  pt_BR: 'Data de validade (MM/AA) *',
};

export const TEXT_SUCCESS = {
  en_US:
    'Congratulations! Your card has been successfully saved. Get ready for a better and complete experience.',
  pt_BR:
    'Parabéns! Os dados do seu cartão foram salvos com sucesso. Prepare-se para uma experiência melhor e mais completa.',
  es_ES:
    '¡Felicidades! Los datos de tu tarjeta se han guardado correctamente. Prepárate para una experiencia mejor y más completa.',
};

export const OK = {
  en_US: 'OK',
  pt_BR: 'OK',
  es_ES: 'OK',
};

export const SAVING_YOUR_CARD = {
  en_US: 'Saving your card...',
  pt_BR: 'Salvando os dados do seu cartão...',
  es_ES: 'Guardando los datos de tu tarjeta ...',
};

export const MAKE_SURE_CREDIT_NUMBER_IS_CORRECT = {
  en_US: 'Please make sure your Visa card number is correct (16 digits).',
  pt_BR:
    'Por gentileza, certifique-se de que o número do cartão Visa está correto (16 dígitos).',
  es_ES:
    'Asegúrate de que el número de tu tarjeta Visa sea correcto (16 dígitos).',
};

export const DO_YOU_WANT_SAVE_CREDIT_CARD_NUMBER = {
  en_US:
    'Be aware that you can associate this 16-digit numbering only once. Do you want to save your Visa card number now? (16 digits)',
  pt_BR:
    'Esteja ciente de que você poderá associar essa numeração de 16 dígitos somente uma vez. Deseja salvar o número do cartão Visa agora? (16 dígitos)',
  es_ES:
    'Tenga en cuenta que puedes asociar esta numeración de 16 dígitos solo una vez. ¿Quiere guardar tu número de tarjeta de Visa ahora? (16 dígitos)',
};

export const CONFIRM_SAVE_CREDIT_CARD_NUMBER = {
  en_US: 'Yes, I do',
  pt_BR: 'Sim, eu quero',
  es_ES: 'Sí, quiero',
};

export const NOT_SAVE_CREDIT_CARD_NUMBER = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TOKEN_IS_ALREADY_ASSOCIATED = {
  en_US:
    'The credit card number (16 digits) is already associated with this other card.',
  pt_BR:
    'O número do cartão de crédito (16 dígitos) já está associado a este outro cartão.',
  es_ES:
    'El número de la tarjeta de crédito (16 dígitos) ya está asociado con esta otra tarjeta.',
};

export const TOKEN_IS_ALREADY_ASSOCIATED_SUBTITLE = {
  en_US: (bin, name, issuer_name) =>
    `${name} - ${bin} <br /> ${issuer_name} <br /> <br /> Be aware that you can only associate this 16-digit numbering once.`,
  es_ES: (bin, name, issuer_name) =>
    `${name} - ${bin} <br /> ${issuer_name} <br /> <br /> Tenga en cuenta que solo puede asociar esta numeración de 16 dígitos una vez.`,
  pt_BR: (bin, name, issuer_name) =>
    `${name} - ${bin} <br /> ${issuer_name} <br /> <br /> Esteja ciente de que você poderá associar essa numeração de 16 dígitos somente uma vez.`,
};

export const CHANGING_CARD = {
  en_US: card => `Setting your card (${card}) as the main one...`,
  es_ES: card => `Estableciendo tu tarjeta (${card}) como principal...`,
  pt_BR: card => `Definindo seu cartão (${card}) como principal...`,
};

export const TEXT_CONGRATULATIONS = {
  en_US: card => `Congratulations! Your card (${card}) is now your main card.`,
  es_ES: card =>
    `¡Felicidades! Tu tarjeta (${card}) es ahora tu tarjeta principal.`,
  pt_BR: card => `Parabéns! Seu cartão (${card}) agora é o principal.`,
};

export const TEXT_OTHER_CARD = {
  en_US: 'Go to the other card',
  es_ES: 'Ir a la otra tarjeta',
  pt_BR: 'Ir para o outro cartão',
};

export const TRY_AGAIN = {
  en_US: 'Try again',
  es_ES: 'Intentar nuevamente',
  pt_BR: 'Tentar novamente',
};

export const TEXT_ADD_NEW_CARD = {
  en_US: 'Add a new card',
  es_ES: 'Agregar una nueva tarjeta',
  pt_BR: 'Adicionar um novo cartão',
};
