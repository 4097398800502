export const TEXT_CH_CHANGE = {
  en_US: 'Cardholder information has been changed. Do you want to save them?',
  pt_BR: 'A informação do titular do cartão foi alterada. Você quer salvá-los?',
  es_ES:
    'La información del titular de la tarjeta ha sido modificada. ¿Deseas guardar los cambios?',
};

export const TEXT_DEPENDENT_CHANGE = {
  en_US: 'Beneficiary information has been changed. Do you want to save them?',
  pt_BR: 'A informação do beneficiário foi alterada. Você quer salvá-los?',
  es_ES: 'La información beneficiario ha sido modificada. ¿Quieres salvarlos?',
};

export const TEXT_ERROR = {
  en_US: 'An error occurred while performing this operation.',
  pt_BR: 'Ocorreu um erro ao efetuar esta operação.',
  es_ES: 'Se ha producido un error al realizar esta operación.',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const BTN_CONTINUE = {
  en_US: 'Continue',
  pt_BR: 'Continuar',
  es_ES: 'Continúe',
};

export const TITLE_CARD_HOLDER_INFORMATION = {
  en_US: 'Appointment information',
  es_ES: 'Información de cita',
  pt_BR: 'Informações do agendamento',
};

export const TITLE_APPOINTMENT_INFORMATION = {
  en_US: 'Appointment information',
  es_ES: 'Información de agendamento',
  pt_BR: 'Informações de Agendamento',
};

export const TITLE_WHO_NEEDS_ASSISTANCE = {
  en_US: 'Identify by checking who needs assistance',
  pt_BR: 'Identifique quem é o paciente',
  es_ES: 'Identifique marcando quien es el que necesita asistencia',
};

export const TITLE_DEPENDENTS = {
  en_US: 'Your beneficiaries',
  pt_BR: 'Seus beneficiários',
  es_ES: 'Tus beneficiarios',
};

export const CARDHOLDER = {
  en_US: 'Cardholder',
  pt_BR: 'Titular do cartão',
  es_ES: 'Titular de la tarjeta',
};

export const DEPENDENT = {
  en_US: 'Beneficiary',
  pt_BR: 'Beneficiário',
  es_ES: 'Beneficiario',
};

export const TEXT_SELECT_PATIENT = {
  en_US: 'Select the patient',
  pt_BR: 'Selecione o paciente',
  es_ES: 'Selecciona el paciente',
};

export const NUMBER_DEPENDENTS_EXCEEDED = {
  en_US:
    'The number of beneficiaries associated with the cardholder has exceeded the limit.',
  pt_BR: 'O número de beneficiários associados ao titular excedeu o limite.',
  es_ES:
    'El número de beneficiarios asociados con el titular ha excedido el límite.',
};

export const ERROR_CUSTOMER_DUPLICATED = {
  pt_BR: 'O CPF informado já é utilizado por outro titular.',
};

export const ERROR_TIME_OUT = {
  en_US: 'Performing this operation, a timeout happened. Please try again.',
  pt_BR:
    'Ao realizar essa operação, o tempo de processamento esgotou. Por favor, tente novamente.',
  es_ES:
    'Al realizar esta operación, ha transcurrido el tiempo de procesamiento. Por favor, intenta nuevamente.',
};

export const ERROR_CUSTOMER_UPDATE = {
  en_US: 'Error updating profile information. Please try again later.',
  pt_BR:
    'Erro ao atualizar as informações do perfil. Por  favor, tente novamente mais tarde.',
  es_ES:
    'Error al actualizar la información del perfil. Por favor, inténtalo de nuevo más tarde.',
};

/* LANDING PAGE */
export const LANDING_PAGE_TITLE = {
  en_US: 'Get your high-tech healthcare on the go.',
  pt_BR: 'Obtenha assistência médica de alta tecnologia em qualquer lugar.',
  es_ES: 'Obtén tu atención médica de alta tecnología sobre la marcha.',
};

export const LANDING_PAGE_SUBTITLE = {
  en_US: 'This functionality is available only to mobile.',
  pt_BR: 'Essa funcionalidade está disponível apenas para celular.',
  es_ES: 'Esta funcionalidad solo está disponible para dispositivos móviles.',
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TITLE_SENDING_YOUR_APPOINTMENT = {
  es_ES: 'Tu cita está siendo programada...',
  en_US: 'Your appointment is being scheduled...',
  pt_BR: 'Seu agendamento está sendo marcado...',
};

export const TITLE_SUCCESS_REQUEST = {
  es_ES: '¡Tu cita está programada!',
  en_US: 'Your appointment is booked!',
  pt_BR: 'Seu agendamento está marcado!',
};
export const TITLE_UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TITLE_ERROR_FILESIZE = {
  en_US: 'Total files size larger than allowed (maximum size 1.5MB).',
  pt_BR:
    'Tamanho total dos arquivos maior que o permitido (tamanho máximo 1.5MB).',
  es_ES:
    'Tamaño total de archivos mayor que el permitido (tamaño máximo 1.5MB).',
};

export const MESSAGE_NOT_AVAILABLE_DATE_TIME = {
  es_ES:
    'La fecha y hora seleccionadas ya no están disponibles para la cita. Por favor, elija una nueva fecha de cita y vuelva a intentarlo.',
  en_US:
    'The selected date and time are not available for appointment anymore. Please choose a new appointment date and try again.',
  pt_BR:
    'A data e hora selecionadas não estão mais disponíveis no momento. Por favor, escolha uma nova data para o agendamento e tente novamente.',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const INVALID_TIME = {
  en_US: 'Invalid time.',
  pt_BR: 'Hora inválida.',
  es_ES: 'Hora inválida.',
};

export const TEXT_ERROR_TWO_DIGITS = {
  en_US: 'Must be exactly 2 digits.',
  pt_BR: 'Deve ter exatamente 2 dígitos.',
  es_ES: 'Debe tener exactamente 2 dígitos.',
};
