import React, { useContext, useState } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import * as translationsPErsonal from '../intlPersonal';
import { callCepService } from '../../../../../../../../utils/postalCode';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from './PersonalArea.module.scss';
import * as constants from '../consts';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const fieldsPostalCode = {
  uf: 'affected_person.address.state',
  localidade: 'affected_person.address.locality',
  logradouro: 'affected_person.address.street_address',
  bairro: 'affected_person.address.subdivision',
};

const PersonalArea = props => {
  const [cepStatus, setCepStatus] = useState();
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const intlPersonal = translate(translationsPErsonal);
  const {
    parentValues,
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const setEmptyPersonalInputFields = () => {
    setFieldValue('affected_person.comment', '');
    setFieldValue('affected_person.person.first_name', '');
    setFieldValue('affected_person.person.last_name', '');
  };
  const setCardHolderNameLastName = () => {
    setFieldValue(
      'affected_person.person.first_name',
      parentValues.policy.person.first_name
    );
    setFieldValue(
      'affected_person.person.last_name',
      parentValues.policy.person.last_name
    );
  };

  const changeRelationship = event => {
    setEmptyPersonalInputFields();
    if (event.target.value === constants.CARD_HOLDER_VALUE) {
      setCardHolderNameLastName();
    }
    handleChange(event);
  };

  const isCountryBr = values.affected_person.address.country === 'BR';
  const cepService = cep => {
    if (cep.length === 9) values.affected_person.address.invalidCep = true;
    callCepService(
      cep,
      setCepStatus,
      'affected_person.address.invalidCep',
      setFieldValue,
      fieldsPostalCode
    );
  };

  const handleCepBlur = e => {
    handleBlur(e);
    if (isCountryBr) cepService(e.target.value);
  };

  const clearCepSearch = () => {
    setCepStatus();
  };

  let maskPostalCode = isCountryBr ? 'maskedPostalCodeBr' : 'maskedPostalCode';

  let cepError, cepWaiting;
  if (cepStatus === 'LOADING') {
    cepWaiting = intl.VALIDATING_CEP;
  } else if (cepStatus === 'INVALID') {
    cepError = intl.VALIDATE_CEP;
  } else {
    cepError =
      errors.affected_person &&
      errors.affected_person.address &&
      errors.affected_person.address.postal_code;
  }

  return (
    <div className={`${styles.personalArea}`}>
      <label className={`${styles.labelTitle} ${styles[theme]}`}>
        {intl.PLEASE_REFER_COMPLETE_DESCRIPTION}
      </label>
      <div className="row">
        <div className="col-12 col-md-6">
          <Select
            label={intl.PERSON_AFFECTED}
            name="affected_person.relationship"
            value={values.affected_person.relationship}
            onBlur={handleBlur}
            dataTestid="selectAffectedPersonRelationship"
            touched={
              touched.affected_person && touched.affected_person.relationship
            }
            error={
              errors.affected_person && errors.affected_person.relationship
            }
            onChange={changeRelationship}
          >
            <option value={constants.CARD_HOLDER_VALUE}>{intl.TEXT_CH}</option>
            <option value={constants.SPOUSE_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_SPOUSE}
            </option>
            <option value={constants.DEPENDENT_VALUE}>
              {intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD}
            </option>
            <option value={constants.OTHER_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_OTHER}
            </option>
          </Select>
        </div>

        <div
          className={`col-12 col-md-6 ${
            values.affected_person.relationship !== constants.OTHER_VALUE
              ? styles.none
              : ''
          }`}
          role="contentRelationshipComment"
        >
          <Input
            label={intl.TEXT_OTHER}
            name="affected_person.comment"
            dataTestid="InputRelationshipComment"
            type="text"
            disabled={
              values.affected_person.relationship !== constants.OTHER_VALUE
            }
            formikProps={props}
            maxLength="255"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 col-md-6">
          <Input
            label={intl.LABEL_NAME_AFFECTED}
            name="affected_person.person.first_name"
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            formikProps={props}
            maxLength="40"
          />
        </div>
        <div className="col-12 col-sm-10 col-md-6">
          <Input
            label={intl.LABEL_LAST_NAME_AFFECTED}
            name="affected_person.person.last_name"
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            formikProps={props}
            maxLength="80"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h3 className={`${styles[theme]} ${styles.groupDivisor}`}>
            {intl.TEXT_ADDRESS}
          </h3>
        </div>
        <div className="col-12 col-sm-12 col-md-6  col-lg-4">
          <Select
            dataTestid="selectAffectedPersonAddressCountry"
            label={intlPersonal.COUNTRY_LABEL}
            name="affected_person.address.country"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intlPersonal.COUNTRY_LABEL}</option>${intlPersonal.COUNTRY_OPTIONS}`
              ),
            }}
            formikProps={props}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-2">
          <Input
            value={values.affected_person.address.postal_code}
            label={intlPersonal.LABEL_POSTAL_CODE}
            name="affected_person.address.postal_code"
            type="text"
            onChange={handleChange}
            dataTestid="InputClaimInjurPostalCode"
            mask={maskPostalCode}
            touched={
              touched.affected_person &&
              touched.affected_person.address &&
              touched.affected_person.address.postal_code
            }
            onBlur={handleCepBlur}
            error={cepError}
            bottomLabel={cepWaiting}
            onFocus={clearCepSearch}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
          <Input
            label={intlPersonal.LABEL_STATE}
            name="affected_person.address.state"
            type="text"
            formikProps={props}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
          <Input
            label={intlPersonal.LABEL_CITY}
            name="affected_person.address.locality"
            type="text"
            formikProps={props}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-lg-6">
          <Input
            label={intlPersonal.LABEL_ADDRESS}
            name="affected_person.address.street_address"
            type="text"
            formikProps={props}
            maxLength="255"
          />
        </div>
        <div className="col-12 col-lg-6">
          <Input
            label={intlPersonal.LABEL_SUBDIVISION}
            name="affected_person.address.subdivision"
            type="text"
            formikProps={props}
            maxLength="100"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalArea;
