import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import Button, { ButtonType } from '../Button/Button';
import styles from './Bluebox.module.scss';

interface BlueboxLinkProps {
  label: string;
  onClick: VoidFunction;
  border?: boolean;
  margin?: boolean;
  type?: ButtonType;
  id?: string;
  icon: any;
}

const BlueboxLink = ({
  label,
  onClick,
  border = true,
  margin = true,
  type,
  id,
  icon,
}: BlueboxLinkProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const CSSnoBorder = `${styles.buttonLink}`;
  const CSSMargin = `${styles.marginTop}`;
  const CSSicon = `${styles.iconMargin}`;

  return (
    <Button
      type={type || 'borderGold'}
      css={`
        ${styles[theme]}
        ${border ? '' : CSSnoBorder}
        ${margin ? CSSMargin : ''}
        ${icon ? CSSicon : ''}
      `}
      onClick={onClick}
      width={undefined}
      height={undefined}
      disabled={undefined}
      style={undefined}
      dataTestid={undefined}
      id={id}
      buttonRef={undefined}
      autoFocus={undefined}
    >
      {icon}
      {label}
    </Button>
  );
};

export default BlueboxLink;
