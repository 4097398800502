import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ArrowLeftIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 17 17"
      style={{
        enableBackground: 'new 0 0 17 17',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="m7 14.2 1.4-1.4-3.3-3.3h10.6v-2H5.1l3.3-3.3L7 2.8 1.3 8.5z"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
