import React, { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { paymentInformationsSchema } from '../validationSchema/index';

import { pt_BR } from '../../../../../../../intl/idioms';
import RelationshipArea from '../RelationshipArea/RelationshipArea';
import BankAreaBR from '../BankArea/BankAreaBR/BankAreaBR';
import BankArea from '../BankArea/BankArea';
import AccountHolderArea from '../AccountHolderArea/AccountHolderArea';
import Button from '../../../../../../../components/Button/Button';
import styles from './BankAccount.module.scss';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import BankInformation from '../../../../../../Profile/ProfileAccount/BankInformation/BankInformation';
import { paymentInformationsInitialValues } from '../initialValues';
import { bankServices } from '../../../../../../../services';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import iconProcess_master from '../../../../../../../assets/images/icon/svg_icon_process_master.svg';
import iconProcess_afluent from '../../../../../../../assets/images/icon/svg_icon_process_afluent.svg';
import { getBanksWithoutOCT } from '../../../../../../../utils/banks';
import InProcess from '../../../../../../../assets/icons/InProcess';
import { AppInsightTrackContext } from '../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const CARDHOLDER = 'Cardholder';
const PRICE_PROTECTION = 'Price_Protection';
const IEMS = 'EMC_Emergency_Medical_Assistance';

const BankAccount = props => {
  const {
    updateParent,
    reimbursementSubmitRef,
    initialValues,
    isProfile,
    intl,
    idiom,
    submitInProfile,
    setBankInfo,
    claimType,
    showIntermediaryBankArea = claimType === IEMS && idiom !== pt_BR
      ? false
      : true,
    thirdPartyPayment,
    setThirdPartyPayment,
    setDisabledReimbursement,
  } = props;
  const { actions, utils } = useContext(StateContext);
  const { isAfluent } = useContext(ThemeContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [bankChange, setBankChange] = useState(false);
  const [banksDetail, setBanksDetail] = useState([]);
  const isCanceled = useRef(null);

  const iconProcess = isAfluent() ? iconProcess_afluent : iconProcess_master;
  const cn = utils.getCn();
  const isPriceProtection = claimType === PRICE_PROTECTION;
  const isBrazil = isProfile
    ? initialValues.BankAccount &&
      initialValues.BankAccount.bank &&
      initialValues.BankAccount.bank.address &&
      initialValues.BankAccount.bank.address.country === 'BR'
    : idiom === pt_BR;

  const validationSchema = useMemo(
    () =>
      paymentInformationsSchema(intl, isBrazil, claimType, thirdPartyPayment),
    [idiom, intl, claimType, thirdPartyPayment]
  );

  const getAccounts = async () => {
    try {
      let getBankAccounts = await bankServices.getBankAccounts(cn);
      getBankAccounts = getBanksWithoutOCT(getBankAccounts.data);

      const banksDetailPromisse = getBankAccounts.map(bank =>
        bankServices.getBankAccountDetails(cn, bank.bank_account_id)
      );
      let banksDetail = await Promise.all(banksDetailPromisse);
      banksDetail = banksDetail.map(bank => bank.data);
      let bankCheckCountry;
      if (utils.getCountry() === 'BRAZIL') {
        bankCheckCountry = banksDetail.filter(
          bank => bank.bank.address.country === 'BR'
        );
      } else {
        bankCheckCountry = banksDetail.filter(
          bank => bank.bank.address.country !== 'BR'
        );
      }
      trackEventUserAction(
        '#### (BANK) -- LIST BANKS FILTERED - BUTTON BANK CHANGE',
        bankCheckCountry
      );
      if (bankCheckCountry && bankCheckCountry.length > 0) {
        if (!isCanceled.current) {
          setBanksDetail(bankCheckCountry);
          setBankChange(true);
        }
      }
    } catch (error) {
      if (!isCanceled.current) {
        setBanksDetail([]);
        setBankChange(false);
      }
    }
  };

  useEffect(() => {
    isCanceled.current = false;
    getAccounts();

    return () => {
      isCanceled.current = true;
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={isProfile ? submitInProfile : () => {}}
    >
      {props => {
        const { values, handleSubmit, setValues } = props;
        const _props = {
          ...props,
          showIntermediaryBankArea,
          claimType,
          setThirdPartyPayment,
          thirdPartyPayment,
          setDisabledReimbursement,
          isProfile,
        };

        const setBankAccount = async (cn, accountId) => {
          try {
            actions.modal.closeModal();
            actions.loadingFullScreen.showLoadingFullScreen(
              intl.TEXT_LOADING_BANK_INFORMATION
            );
            const account = await bankServices.getBankAccountDetails(
              cn,
              accountId
            );
            let bankObject = paymentInformationsInitialValues({
              data: account.data,
              idiom: idiom,
              country: utils.getCountry(),
              bankAccountHolder: values.BankAccountHolder,
            });

            // add values to bankChanges to compare
            bankObject.BankChange = {
              BankAccount: bankObject.BankAccount,
              BankAccountHolder: bankObject.BankAccountHolder,
            };

            // set flag bankInfoChange
            bankObject.BankAccount.bank.bankInfoChange = true;
            setValues(bankObject);
            setBankInfo(bankObject);

            trackEventUserAction(
              '#### (BANK) -- BANK OBJECT - SET BANK',
              bankObject
            );

            actions.loadingFullScreen.hideLoadingFullScreen();
          } catch (e) {
            actions.loadingFullScreen.hideLoadingFullScreen();
          }
        };

        const changeBankAccount = () => {
          actions.modal.showModal(
            intl.TEXT_CHANGE_BANK,
            true,
            <BankInformation
              changeBank={true}
              setBankAccount={setBankAccount}
              setBankInfo={setBankInfo}
              banksDetail={banksDetail}
            />,
            false,
            false,
            null,
            null,
            iconProcess
          );
        };

        const changeBank = !isProfile ? (
          bankChange && (
            <div
              className={`${styles.btnChangeBank} ${
                isPriceProtection ? styles.noMargin : ''
              }`}
            >
              <Button
                type="blueWithoutBorder"
                onClick={event => {
                  event.preventDefault();
                  changeBankAccount();
                }}
              >
                <span className={styles.icon}>
                  <InProcess width={24} height={24} />
                </span>

                {intl.TEXT_CHANGE_BANK}
              </Button>
            </div>
          )
        ) : (
          <></>
        );

        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit}>
            <button
              ref={reimbursementSubmitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>

            {!isProfile ? <RelationshipArea {..._props} /> : ''}
            {!thirdPartyPayment && (
              <>
                {values.BankAccountHolder.relationship === CARDHOLDER &&
                  isBrazil && (
                    <>
                      {changeBank}
                      <BankAreaBR
                        validateCep={true}
                        {..._props}
                        isBrazil={true}
                      />
                    </>
                  )}
                {values.BankAccountHolder.relationship !== CARDHOLDER &&
                  isBrazil && (
                    <>
                      <AccountHolderArea
                        {..._props}
                        validateCep={true}
                        isBrazil={true}
                      />
                      {changeBank}
                      <BankAreaBR {..._props} />
                    </>
                  )}
                {values.BankAccountHolder.relationship === CARDHOLDER &&
                  !isBrazil && (
                    <>
                      {changeBank}
                      <BankArea {..._props} />
                    </>
                  )}
                {values.BankAccountHolder.relationship !== CARDHOLDER &&
                  !isBrazil && (
                    <>
                      <AccountHolderArea {..._props} />
                      {changeBank}
                      <BankArea {..._props} />
                    </>
                  )}{' '}
              </>
            )}

            {isProfile && (
              <div className={`${styles.footer} ${styles.section}`}>
                <Button type="borderBlue" id="borderBlue-saveBank">
                  {intl.BTN_SAVE}
                </Button>
                <Button
                  type="blueWithoutBorder"
                  onClick={event => {
                    event.preventDefault();
                    actions.modal.closeModal();
                  }}
                >
                  {intl.BTN_CANCEL}
                </Button>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default BankAccount;
