import React, { useContext } from 'react';
import styles from '../ExpenseArea.module.scss';
import { set } from 'lodash';
import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../components/Select/Select';
import * as constants from '../../consts';
import ExpenseContentRemove from './ExpenseItemRemove';
import sanitize from '../../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const ExpenseItem = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const {
    values,
    index,
    handleChange,
    handleBlur,
    _touched,
    _errors,
    setValues,
  } = props;

  const sumExpenses = e => {
    const _values = { ...values };
    const expenses = JSON.parse(JSON.stringify(values.expenses));

    _values.expenses = expenses;
    set(_values, e.target.name, e.target.value);

    const total = _values.expenses.reduce((total, amount) => {
      const value = parseFloat(amount.price.value);
      if (isNaN(value)) return total;
      return total + value;
    }, 0);
    set(_values, 'amount_estimation_damage.value', total);
    setValues(_values);
  };

  return (
    <div className={styles.expenseItem} key={`expenseItem-${index + 1}`}>
      <div className="row">
        <div className="col-12">
          <h4 className={styles[theme]}>
            {intl.SUBTITLE_AREA} #{index + 1}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4">
          <Select
            dataTestid={`selectTypeExpense-${index}`}
            label={intl.LABEL_TYPE}
            value={values.expenses[index].type}
            name={`expenses[${index}].type`}
            onChange={handleChange}
            touched={_touched && _touched.type}
            onBlur={handleBlur}
            error={_errors && _errors.type}
          >
            <option value="">{intl.LABEL_TYPE}</option>
            <option value={constants.EXPENSE_MEDICAL}>
              {intl.OPTION_VALUE_MEDICAL}
            </option>
            <option value={constants.EXPENSE_MEDICINE}>
              {intl.OPTION_VALUE_MEDICINE}
            </option>
            <option value={constants.EXPENSE_DENTAL}>
              {intl.OPTION_VALUE_DENTAL}
            </option>
            <option value={constants.EXPENSE_TRANSPORT}>
              {intl.OPTION_VALUE_TRANSPORT}
            </option>
            <option value={constants.EXPENSE_ACCOMMODATION}>
              {intl.OPTION_VALUE_ACCOMMODATION}
            </option>
            <option value={constants.EXPENSE_OTHERS}>
              {intl.OPTION_VALUE_OTHERS}
            </option>
          </Select>
        </div>
        <div className={`col-12 col-sm-12 col-md-4`}>
          <Input
            value={values.expenses[index].price.value}
            name={`expenses[${index}].price.value`}
            dataTestid={`InputExpensePriceValue-${index}`}
            label={intl.LABEL_AMOUNT}
            type="text"
            maskedMoney
            onChange={sumExpenses}
            onBlur={handleBlur}
            touched={_touched && _touched.price && _touched.price.value}
            error={_errors && _errors.price && _errors.price.value}
          />
        </div>
        <div className={`col-12 col-sm-12 col-md-4`}>
          <Select
            label={intl.LABEL_FORM_PAYMENT}
            value={values.expenses[index].payment_mode}
            name={`expenses[${index}].payment_mode`}
            dataTestid={`SelectExpenseFormPayment-${index}`}
            onChange={handleChange}
            dangerouslySetInnerHTML={{
              __html:
                sanitize(`<option value="">${intl.LABEL_FORM_PAYMENT}</option>
              <option value="CREDIT_CARD">${intl.PAYMENT_CREDIT_CARD}</option>
              <option value="DEBIT_CARD">${intl.PAYMENT_DEBIT_CARD}</option>
              <option value="ELECTRONIC_TRANSFER">${intl.PAYMENT_ELECTRONIC_TRANSFER}</option>
              <option value="VIRTUAL_CREDIT_CARD">${intl.PAYMENT_VIRTUAL_CREDIT_CARD}</option>
              <option value="CASH">${intl.PAYMENT_CASH}</option>
              <option value="CHEQUE">${intl.PAYMENT_CHECK}</option>`),
            }}
            touched={_touched && _touched.payment_mode}
            onBlur={handleBlur}
            error={_errors && _errors.payment_mode}
          />
        </div>
      </div>
      <div className={`row ${styles.footer}`}>
        <ExpenseContentRemove {...props} intl={intl} />
      </div>
    </div>
  );
};

export default ExpenseItem;
