import { useContext } from 'react';
import styles from './HeadingTitle.module.scss';
import { useHistory } from 'react-router-dom';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import { StateContext } from '../StateContextParent/StateContextParent';
import ModalInformation from '../ModalInformation/ModalInformation';

const HeadingTitle = props => {
  const {
    backLink,
    title,
    subtitle,
    noBack,
    showModalExit,
    className = '',
  } = props;
  const { translate } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const intl = translate(translations);
  const history = useHistory();
  const isCertificate = history.location.pathname.includes('certificate');
  const isUpdate = history.location.pathname.includes('update');
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const defaultSubtitle = isCertificate
    ? intl.SUBTITLE_GENERATE_CERTIFICATE
    : isUpdate
    ? intl.SUBTITLE_UPDATE_CLAIM
    : intl.SUBTITLE_MAKE_CLAIM;

  const subtitleUsed = subtitle ? subtitle : defaultSubtitle;

  const openModalExit = backLink => {
    actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="question"
        message={intl.EXIT_TITLE}
        subtitle={intl.CHANGES_NOT_SAVED_MESSAGE}
        textBtn={intl.BUTTON_CONTINUE}
        textBtnDeny={intl.BUTTON_CLOSE}
        clickBtn={() => {
          backLink ? history.push(backLink) : history.goBack();
          actions.modal.closeModal();
        }}
        clickBtnDeny={() => {
          actions.modal.closeModal();
        }}
      />,
      false,
      false
    );
  };

  const handleClickArrowIcon = backLink => {
    backLink ? history.push(backLink) : history.goBack();
  };

  return (
    <div className={`${styles[theme]} ${styles.headingTitle} ${className}`}>
      <div className={styles.boxText}>
        <div className={styles.box}>
          {!noBack && (
            <>
              <div
                data-testid="linkContainer"
                onClick={() => {
                  showModalExit
                    ? openModalExit(backLink)
                    : handleClickArrowIcon(backLink);
                }}
                className={styles.link}
              >
                <span className={styles.arrowIcon}>
                  <ChevronGeneric rotate={90} width={20} height={20} />
                </span>
              </div>
            </>
          )}
          <h4 className={styles[theme]}>{subtitleUsed}</h4>
        </div>
        <h1 id="stepped_flow_title">{title}</h1>
      </div>
    </div>
  );
};

export default HeadingTitle;
