export const TEXT_RECENT = {
  en_US: 'Most recent',
  es_ES: 'Más reciente',
  pt_BR: 'Mais recente',
};

export const TEXT_LESS_RECENT = {
  en_US: 'Less recent',
  es_ES: 'Menos reciente',
  pt_BR: 'Menos recente',
};

export const TEXT_TRAVEL = {
  en_US: 'Travel',
  es_ES: 'Viaje',
  pt_BR: 'Viagem',
};

export const TEXT_SHOPPING = {
  en_US: 'Shopping',
  es_ES: 'Compras',
  pt_BR: 'Compras',
};

export const SORT_ICON = {
  en_US: 'Sort Icon',
  es_ES: 'Ícono de Ordenamiento',
  pt_BR: 'Ícone de Classificação',
};
