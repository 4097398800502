import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../intl';
import styles from './ItemsArea.module.scss';
import ItemProtection from './ItemProtection/ItemProtection';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import ButtonWithIcon from '../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import plus_master from '../../../../../../../../assets/images/icon/master/icon_plus_master.svg';
import plus_afluent from '../../../../../../../../assets/images/icon/afluent/icon_plus_afluent.svg';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';

const ItemsArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, touched, errors, setFieldValue } = props;
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const iconPlus = isAfluent() ? plus_afluent : plus_master;

  const itemList = values.purchase_protection_items.map((item, index) => {
    const _errors =
      errors &&
      errors.purchase_protection_items &&
      errors.purchase_protection_items[index]
        ? errors.purchase_protection_items[index]
        : {};
    const _touched =
      touched &&
      touched.purchase_protection_items &&
      touched.purchase_protection_items[index]
        ? touched.purchase_protection_items[index]
        : {};
    return (
      <ItemProtection
        key={`BagProtection_item-${index}`}
        _touched={_touched}
        _errors={_errors}
        index={index}
        {...props}
      />
    );
  });

  const addItem = event => {
    event.preventDefault();

    let newObject = {
      label: '',
      price: {
        value: '',
        currency:
          values && values.amount_claimed && values.amount_claimed.currency,
      },
    };
    const items = [...values.purchase_protection_items];
    items.push(newObject);
    setFieldValue('purchase_protection_items', items);
  };

  return (
    <div className="row">
      <div className={`col-12 ${styles.itemArea}`}>
        <h3 className={`${styles.labelTitle} ${styles[themes]}`}>
          {intl.TITLE_DETAILED_LIST}
        </h3>
        <p className={`${styles.labelInfo} ${styles[themes]}`}>
          {intl.DESCRIPTION_DETAILED_LIST}
        </p>

        <div className="row">
          <div className={`col-12 ${styles.buttonArea}`}>
            <ButtonWithIcon
              type="blueWithoutBorder"
              onClick={addItem}
              value={intl.BTN_ADD}
              isSVGComponent={true}
              img={<AddIcon aria-label={intl.ICON_ADD_ITEM} />}
            />
          </div>
        </div>
        {itemList}
      </div>
    </div>
  );
};

export default ItemsArea;
