/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Formik } from 'formik';
import * as yup from 'yup';
import { tokenexService } from '../../../services';
import styles from './InputTokenexIframe.module.scss';
import Button from '../../../components/Button/Button';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import Loading from '../../../components/Loading/Loading';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import Input from '../../../components/Input/Input';
import { TIMESTAMP, CONFIG_TOKENEX } from './tokenexConfig';
import ModalWithImage from '../../ModalWithImage/ModalWithImage';
import ModalResult from '../../ModalResult/ModalResult';
import ModalCreditCard from '../../../assets/images/modal/modal_image_credit_card.png';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import ModalInformation from '../../ModalInformation/ModalInformation';
import { cardsServices } from '../../../services';
import { NotificationContext } from '../../NotificationContextParent/NotificationContextParent';
import { showModalAddCard } from '../../ModalAddCard/functionShowModal';
import { AppInsightTrackContext } from '../../AppInsightTrackContextParent/AppInsightTrackContextParent';

const changePreferredCard = async (
  actions,
  infoCard,
  intl,
  mask_external_reference,
  props,
  noReload,
  notificationActions
) => {
  try {
    actions.modal.showLoading(
      intl.CHANGING_CARD(`${mask_external_reference} - ${infoCard.name}`)
    );
    const { cn, name, external_reference } = infoCard;
    await cardsServices.changePreferredCard(cn, name, external_reference);

    await actions.cards.changeSelectedCardIndex(external_reference);
    notificationActions.toastNotification.setResetAppereanceToast();
    actions.modal.hideLoading();
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="success"
        title={intl.TEXT_CONGRATULATIONS(
          `${mask_external_reference} - ${infoCard.name}`
        )}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={'Ok'}
          clickConfirm={() => {
            if (noReload) {
              actions.modal.closeModal();
            } else {
              props.history.push('/dashboard');
              window.location.reload();
            }
          }}
        />
      </ModalWithImage>,
      true,
      true
    );
  } catch (error) {
    actions.modal.hideLoading();
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.UNEXPECTED_ERROR_OCCURRED}
        image={ModalCreditCard}
      ></ModalWithImage>,
      true
    );
  }
};

const openModalSuccess = (actions, intl) => {
  return actions.modal.showModal(
    false,
    true,
    <ModalWithImage
      type="success"
      title={intl.TEXT_SUCCESS}
      image={ModalCreditCard}
    >
      <ModalResult
        textConfirm={intl.OK}
        clickConfirm={() => actions.modal.closeModal()}
      />
    </ModalWithImage>,
    false,
    true
  );
};

const modalConfirmTokenize = (actions, intl, iframeToken) => {
  return actions.modal.showModal(
    false,
    false,

    <ModalInformation
      type="question"
      message={intl.MAKE_SURE_CREDIT_NUMBER_IS_CORRECT}
      image={ModalCreditCard}
      subtitle={intl.DO_YOU_WANT_SAVE_CREDIT_CARD_NUMBER}
      textBtn={intl.CONFIRM_SAVE_CREDIT_CARD_NUMBER}
      textBtnDeny={intl.NOT_SAVE_CREDIT_CARD_NUMBER}
      clickBtn={() => {
        iframeToken.tokenize();
        actions.modal.closeModal();
      }}
    ></ModalInformation>,
    true,
    false
  );
};

const modalDuplicatedCreditCard = (
  actions,
  intl,
  card,
  cn,
  props,
  notificationActions
) => {
  const userBin = card.external_reference.replace(
    new RegExp('(\\d{4})(\\d{4})(\\d{2})', 'g'),
    '$1.****.$3**.****'
  );

  const infoCard = {
    cn: cn,
    name: card.name,
    external_reference: card.external_reference,
  };
  return actions.modal.showModal(
    false,
    false,

    <ModalInformation
      type="error"
      message={intl.TOKEN_IS_ALREADY_ASSOCIATED}
      image={ModalCreditCard}
      textBtn={intl.TEXT_OTHER_CARD}
      subtitle={intl.TOKEN_IS_ALREADY_ASSOCIATED_SUBTITLE(
        userBin,
        card.name,
        card.issuer_name
      )}
      textBtnDeny={intl.TRY_AGAIN}
      clickBtn={() => {
        changePreferredCard(
          actions,
          infoCard,
          intl,
          userBin,
          props,
          false,
          notificationActions
        );
      }}
    ></ModalInformation>,
    true,
    false,
    true
  );
};

const resetCardsSessionStorage = async (actions, history, bin, intl) => {
  try {
    await actions.cards.reloadLoggedUserCards();
    openModalSuccess(actions, intl);
  } catch (error) {
    console.log(error);
  }
};

const getAuthentication = async () => {
  const { data } = await tokenexService.getAuthenticationKey(TIMESTAMP);
  return data;
};

const createIframeTokenex = async (idiom, isAfluent, trackEventUserAction) => {
  trackEventUserAction('#### (PAN) O IFRAME SERÁ CONFIGURADO', true);
  const authenticationKey = await getAuthentication();
  trackEventUserAction('#### (PAN) O AUTHENTICATION KEY FOI GERADO', {
    authenticationKey,
  });
  const iframeConfig = CONFIG_TOKENEX(
    authenticationKey,
    idiom,
    isAfluent,
    trackEventUserAction
  );
  const iframe = new window.TokenEx.Iframe('tokenExIframeDiv', iframeConfig);
  trackEventUserAction('#### (PAN) INSTANCIANDO O IFRAME', {
    iframe,
  });
  return iframe;
};

const updateBinWithTokenex = async (
  token,
  { effective_end_date },
  infoUser,
  actions,
  history,
  intl,
  setTokenPan,
  iframe,
  setLoadingToken,
  userCards,
  trackEventUserAction
) => {
  try {
    trackEventUserAction('#### (PAN) PAN SERÁ ATUALIZADO', true);
    setLoadingToken('LOADING');
    const formatEffectiveEndDate = effective_end_date.split('/');
    const addDayEffectiveEndDate = effective_end_date
      ? `20${formatEffectiveEndDate[1]}-${formatEffectiveEndDate[0]}-01`
      : null;
    const response = await tokenexService.updateBinWithTokenex(
      infoUser,
      addDayEffectiveEndDate,
      token
    );
    setLoadingToken(false);
    resetCardsSessionStorage(
      actions,
      history,
      infoUser.external_reference,
      intl
    );
    trackEventUserAction('#### (PAN) PAN ATUALIZADO', response);
  } catch (error) {
    trackEventUserAction('#### (PAN) ERRO AO ATUALIZAR PAN', {
      error,
      error_response: error.response,
      cards: userCards,
    });
    if (
      error.response &&
      error.response.data &&
      error.response.data.error_description === 'Duplicated Credit Card Token'
    ) {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.ALREADY_USED_ERROR_OCCURRED}
        />,
        true,
        false
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description === 'Invalid credit card token'
    ) {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={intl.LENGTH_16_DIGITS} />,
        true,
        false
      );
    } else {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true,
        false
      );
    }
    setTokenPan('');
    iframe.reset();
    setLoadingToken(false);
  }
};

const callEventTokenize = (
  data,
  effective_end_date,
  setTokenPan,
  tokenPAN,
  infoUser,
  actions,
  history,
  intl,
  iframeToken,
  setLoadingToken,
  userCards,
  trackEventUserAction
) => {
  if (tokenPAN.length > 1) return;
  const token = data.token;
  setTokenPan(token);
  updateBinWithTokenex(
    token,
    effective_end_date,
    infoUser,
    actions,
    history,
    intl,
    setTokenPan,
    iframeToken,
    setLoadingToken,
    userCards,
    trackEventUserAction
  );
};

const loadingEventsTokenex = (
  iframe,
  infoUser,
  effective_end_date,
  intl,
  setTokenPan,
  tokenPAN,
  setMessageTokenError,
  messageTokenError,
  actions,
  history,
  setLoadingToken,
  userCards,
  setTokenStatus,
  trackEventUserAction
) => {
  iframe.on('validate', function (data) {
    callEventValidate(
      data,
      iframe,
      intl,
      infoUser.external_reference,
      setMessageTokenError,
      messageTokenError,
      setLoadingToken,
      actions,
      setTokenStatus,
      trackEventUserAction
    );
  });
};

const callEventValidate = (
  data,
  iframe,
  intl,
  bin,
  setMessageTokenError,
  messageTokenError,
  setLoadingToken,
  actions,
  setTokenStatus,
  trackEventUserAction
) => {
  setTokenStatus(false);
  trackEventUserAction('#### (PAN) PAN VAI SER VALIDADO', true);
  if (data && !data.isValid) {
    setLoadingToken(false);
    setMessageTokenError(intl.TEXT_FORMAT_INVALID);
  } else if (data && data.cardType && data.cardType !== 'visa') {
    setLoadingToken(false);
    setMessageTokenError(intl.TEXT_NOT_VISA_PRODUCT);
  } else if (data && data.firstSix && !bin.includes(data.firstSix)) {
    setLoadingToken(false);
    setMessageTokenError(intl.TEXT_NOT_CORRESPOND_REGISTERED_CARD);
    return;
  } else if (data && data.isValid) {
    setMessageTokenError('');
    setLoadingToken(false);
    setTokenStatus(true);
  }
  trackEventUserAction('#### (PAN) VALIDADO COM ÊXITO', true);
};

const validateExpirationDate = date => {
  const dateToday = moment();
  const splitExpirationDate = date.split('/');
  const monthExpirationDate = splitExpirationDate[0];
  const yearExpirationDate = splitExpirationDate[1];

  const createExpirationDate = moment(
    `${monthExpirationDate}-1-20${yearExpirationDate}`,
    'MM-DD-YYYY'
  );

  if (parseInt(monthExpirationDate) === 0 || parseInt(monthExpirationDate) > 12)
    return false;

  return moment(createExpirationDate).isSameOrAfter(dateToday);
};

const InputTokenexIframe = props => {
  const { bin, card, history } = props;
  const { actions, utils, state } = useContext(StateContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const { translate, idiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const [iframeToken, setIframeToken] = useState(null);
  const [tokenPAN, setTokenPan] = useState('');
  const [valuesForm, setValuesForm] = useState('');
  const [tokenStatus, setTokenStatus] = useState(false);
  const [messageTokenError, setMessageTokenError] = useState('');
  const cn = utils.getCn();
  const userCards = state.cards;
  const [loading, setLoading] = useState(true);
  const [statusLoadingToken, setLoadingToken] = useState(false);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const infoUser = {
    customer_id: cn,
    policy_id: card.policy_id,
    external_reference: bin,
  };
  const countriesDontRequireExpirationDate = ['BRAZIL'];
  const country = utils.getCountry();
  const showExpirationDate =
    !countriesDontRequireExpirationDate.includes(country);

  const initialValues = {
    effective_end_date: '',
  };
  const validation = yup.object().shape({
    effective_end_date: yup
      .string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .min(5, intl.TEXT_FIELD_REQUIRED)
      .test('valid-expiration-date', intl.TEXT_INVALID_DATE, function (value) {
        if (value && value.length === 5) {
          return validateExpirationDate(value);
        }
        return false;
      }),
  });

  const _onsubmit = async values => {
    try {
      setValuesForm(values);
      modalConfirmTokenize(actions, intl, iframeToken);
      trackEventUserAction('#### (PAN) SUBMIT DO FORMULÁRIO', true);
      setMessageTokenError('');
      loadingEventsTokenex(
        iframeToken,
        infoUser,
        values,
        intl,
        setTokenPan,
        tokenPAN,
        setMessageTokenError,
        messageTokenError,
        actions,
        history,
        setLoadingToken,
        userCards,
        setTokenStatus,
        trackEventUserAction
      );
    } catch (error) {
      trackEventUserAction('#### (PAN) ERROR NO SUBMIT DO FORMULÁRIO', error);
    }
  };

  useEffect(() => {
    setLoading(true);
    let iframe;
    async function createAndLoadingTokenex() {
      try {
        trackEventUserAction('#### (PAN) CRIANDO FRAME TOKENEX', true);
        iframe = await createIframeTokenex(
          idiom,
          isAfluent(),
          trackEventUserAction
        );
        iframe.load();
        setIframeToken(iframe);
        setLoading(false);
        trackEventUserAction('#### (PAN) FRAME TOKENEX CRIADO', iframe);
      } catch (error) {
        if (error && error.message) {
          trackEventUserAction('#### ERROR BOUNDARY ####', {
            error,
            message: error.message,
            stack: JSON.stringify(error.stack),
          });
        }

        trackEventUserAction('#### (PAN) ERROR FRAME TOKENEX', {
          error,
          response: error.response,
        });
      }
    }
    createAndLoadingTokenex();

    return () => {
      trackEventUserAction('#### (PAN) ENTROU NO RETURN DO USEEFFECT', iframe);
      iframe.remove();
    };
  }, []);

  useEffect(() => {
    if (!loading && messageTokenError.length > 0) {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={messageTokenError} />,
        true,
        false
      );
    }
  }, [messageTokenError]);

  useEffect(() => {
    if (tokenStatus) {
      iframeToken.on('tokenize', function (data) {
        const getBinWithTokenDuplicated =
          userCards &&
          userCards.data.find(
            card =>
              card.credit_card_token && card.credit_card_token === data.token
          );
        if (getBinWithTokenDuplicated) {
          modalDuplicatedCreditCard(
            actions,
            intl,
            getBinWithTokenDuplicated,
            cn,
            props,
            notificationActions
          );
          return;
        }
        callEventTokenize(
          data,
          valuesForm,
          setTokenPan,
          tokenPAN,
          infoUser,
          actions,
          history,
          intl,
          iframeToken,
          setLoadingToken,
          userCards,
          trackEventUserAction
        );
      });
    }
  }, [tokenStatus]);

  useEffect(() => {
    if (statusLoadingToken) {
      actions.loadingFullScreen.showLoadingFullScreen(intl.SAVING_YOUR_CARD);
    } else {
      actions.loadingFullScreen.hideLoadingFullScreen();
    }
  }, [statusLoadingToken]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={showExpirationDate ? validation : undefined}
      onSubmit={_onsubmit}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div className={`${styles.tokenex}`}>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                  <Input
                    disabled
                    label={intl.LABEL_10_DIGITS}
                    value={card && card.external_reference}
                  />
                </div>
              </div>

              <div className={`${styles.boxForm} row`}>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div
                    id="tokenExIframeDiv"
                    className={styles.tokenexIframe}
                  ></div>
                </div>
                {showExpirationDate && (
                  <div className={`col-12 col-sm-6 col-md-4 col-lg-4`}>
                    <Input
                      dataTestid="inputExpirationDate"
                      errorLeftAlign
                      labelFlex
                      label={intl.LABEL_EXPIRATION_DATE}
                      name="effective_end_date"
                      error={errors.effective_end_date}
                      touched={touched.effective_end_date}
                      value={values.effective_end_date}
                      mask="maskedExpirationDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )}
              </div>

              <p>{intl.TEXT_WARNING_VISA}</p>

              {loading && (
                <h2 className={styles.loading}>
                  <Loading />
                </h2>
              )}
              {!loading && (
                <div className={`${styles.btnFooter} ${styles[themes]}`}>
                  <div className={styles.divButton}>
                    <Button
                      type="borderGold"
                      onClick={event => {
                        event.preventDefault();
                        showModalAddCard(actions, intl);
                      }}
                    >
                      {intl.TEXT_ADD_NEW_CARD}
                    </Button>
                  </div>
                  <div className={styles.divButton}>
                    <Button
                      dataTestid="ButtonSubmitUpdateTokenex"
                      type="borderBlue"
                      typeHtml="submit"
                    >
                      {intl.TEXT_UPDATE_BUTTON}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default withRouter(InputTokenexIframe);
