export const TITLE = {
  en_US: 'Claims',
  es_ES: 'Reclamos',
  pt_BR: 'Sinistros',
};

export const SUBTITLE = {
  en_US: 'See your recent claims below or submit a new claim.',
  es_ES:
    'Consulta tus reclamos recientes a continuación o genera un nuevo reclamo.',
  pt_BR:
    'Por favor, veja seus sinistros recentes abaixo ou abra um novo sinistro se precisar.',
};

export const SUBTITLE_YOUR_CLAIMS = {
  en_US: 'My recent claims',
  es_ES: 'Mis reclamos recientes',
  pt_BR: 'Meus sinistros recentes',
};

export const STATUS_PENDING = {
  en_US: 'Pending',
  es_ES: 'Pendiente',
  pt_BR: 'Pendente',
};

export const STATUS_REVIEW = {
  en_US: 'UPLOADED',
  es_ES: 'SUBIDO',
  pt_BR: 'ENVIADO',
};

export const STATUS_COMPLETE = {
  en_US: 'Completed',
  es_ES: 'Terminado',
  pt_BR: 'Concluído',
};

export const STATUS_REJECTED = {
  en_US: 'Rejected',
  es_ES: 'Rechazado',
  pt_BR: 'Rejeitado',
};

export const STATUS_CLOSED = {
  en_US: 'Closed',
  es_ES: 'Cerrado',
  pt_BR: 'Fechado',
};

export const TEXT_ALL_CLAIMS = {
  en_US: 'View all claims',
  es_ES: 'Ver todos los reclamos',
  pt_BR: 'Ver todos os sinistros',
};

export const TEXT_CREATED = {
  en_US: 'Creation date: ',
  es_ES: 'Fecha de creación: ',
  pt_BR: 'Data de criação: ',
};

export const TEXT_NO_HAVE_CLAIM = {
  en_US: 'You do not have any claim created recently.',
  es_ES: 'No ha creado ningún reclamo recientemente.',
  pt_BR: 'Você não tem nenhum sinistro criado recentemente.',
};
