/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { loadData } from './loadData';
import CertificateFormFill from './CertificateFillForm/CertificateFormFill';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import Loading from '../../../components/Loading/Loading';
import LoadingError from '../../../components/LoadingError/LoadingError';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import { certificatesServices } from '../../../services';
import { pt_BR } from '../../../intl/idioms';
import ModalInformation from '../../../components/ModalInformation/ModalInformation';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import styles from './GenerateCertificate.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import {
  dataLayerCertificateEventTrack,
  CERTIFICATE_STARTED,
} from '../../../utils/GTM_helper';

const getCertificateInfo = (type, lists) => {
  const certificate = lists.filter(item => {
    return item.code === type;
  });
  return certificate[0];
};

const checkCertificateEligibility = async (
  binPan,
  idiom,
  certificateType,
  setCertificateEligibility
) => {
  try {
    const list = (
      await certificatesServices.listCertificateTypesByBinNumber(binPan, idiom)
    ).data;

    const certificateFiltered = list.find(
      certificate => certificate.code === certificateType
    );
    if (!certificateFiltered) setCertificateEligibility('ERROR');
  } catch (error) {
    setCertificateEligibility('ERROR');
  }
};

export const getCertficatesTypeByBin = async (
  binPan,
  idiom,
  type,
  setBenefits
) => {
  const list = (
    await certificatesServices.listCertificateTypesByBinNumber(binPan, idiom)
  ).data;

  const certificateInfo = getCertificateInfo(type, list);
  setBenefits(certificateInfo);
};

export const redirect = (history, modal) => {
  modal.showModal(false, false, <ModalInformation type="error" />, true, false);
  history.push('/certificates/generate-certificates');
};

const callServices = async (
  binOrPan,
  idiom,
  certificateType,
  setBenefits,
  actions,
  history
) => {
  try {
    await getCertficatesTypeByBin(
      binOrPan,
      idiom,
      certificateType,
      setBenefits
    );
  } catch (e) {
    redirect(history, actions.modal);
  }
};

const loadAllInformations = async (
  cn,
  certificateType,
  setData,
  binOrPan,
  idiom,
  setBenefits,
  actions,
  setLoading,
  history
) => {
  setLoading(true);
  await Promise.all([
    loadData(cn, certificateType, setData),
    callServices(
      binOrPan,
      idiom,
      certificateType,
      setBenefits,
      actions,
      history
    ),
  ]);
  setLoading(false);
};

const checkExistingValidCertificateBR = async (
  cn,
  binOrPan,
  idiomForApi,
  certificateType,
  actions,
  intl,
  history
) => {
  const userCertificates = await certificatesServices.getCertificatesByBin(
    cn,
    binOrPan,
    idiomForApi()
  );

  userCertificates &&
    userCertificates.data.filter(item => {
      if (
        item.certificate_type.toLowerCase() === certificateType &&
        moment(item.valid_until).isSameOrAfter(moment().format('YYYY-MM-DD'))
      ) {
        return actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.TEXT_ERROR_DUPLICATED_CARDHOLDER}
            subtitle={intl.SUBTITLE_ERROR_DUPLICATED_CARDHOLDER}
            textBtn={intl.TEXT_SEE_MY_CERTIFICATES}
            buttonConfirmType="borderBlue"
            clickBtn={() => {
              actions.modal.closeModal();
              history.push('/profile/certificates');
            }}
            textBtnDeny={intl.TEXT_CREATE_CERTIFICATE}
            clickBtnDeny={() => {
              actions.modal.closeModal();
            }}
          />,
          true
        );
      }
    });
};

const GenerateCertificate = props => {
  const { history } = props;
  const { certificateType } = props.match.params;

  const [data, setData] = useState('LOADING');
  const [benefits, setBenefits] = useState('LOADING');
  const [loading, setLoading] = useState('LOADING');
  const [certificateEligibility, setCertificateEligibility] =
    useState('LOADING');

  const { utils, actions } = useContext(StateContext);
  const { translate, idiom, idiomForApi } = useContext(IntlContext);

  const intl = translate(translations);
  const cn = utils.getCn();
  const binOrPan = utils.getBin(true);
  const bin = utils.getBin();
  const selectedCard = utils.getSelectedCard();
  const isBrazil = idiom === pt_BR;

  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const denyCertificatePtBr = [
    'billete_auto_rental_insurance',
    'certificate_seguro_compra',
  ];

  if (idiom === pt_BR && denyCertificatePtBr.indexOf(certificateType) >= 0) {
    history.push('/certificates/generate-certificates');
  }

  useEffect(() => {
    checkCertificateEligibility(
      binOrPan,
      idiom,
      certificateType,
      setCertificateEligibility
    );
  }, [certificateType]);

  useEffect(() => {
    dataLayerCertificateEventTrack(CERTIFICATE_STARTED, certificateType);
    loadAllInformations(
      cn,
      certificateType,
      setData,
      binOrPan,
      idiom,
      setBenefits,
      actions,
      setLoading,
      history
    );
  }, [idiom]); // eslint-disable-line

  useEffect(() => {
    if (isBrazil) {
      checkExistingValidCertificateBR(
        cn,
        binOrPan,
        idiomForApi,
        certificateType,
        actions,
        intl,
        history
      );
    }
  }, [binOrPan, cn, idiomForApi]);

  const isLoading = loading;
  const isError = data === 'ERROR' || certificateEligibility === 'ERROR';
  const successLoadData = !isLoading && !isError;

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
      {isError && (
        <div className={styles.loadingContainer}>
          <LoadingError color="#fff" message={intl.PRODUCT_NOT_COVERAGE} />
        </div>
      )}
      {successLoadData && (
        <SessionContainer>
          <div className={styles.titleArea}>
            <HeadingTitle
              title={benefits && benefits.name}
              backLink="/certificates/previous-certificates"
            />
          </div>
          <CertificateFormFill
            loadedData={data}
            certificateType={certificateType}
            cn={cn}
            bin={bin}
            binOrPan={binOrPan}
            actions={actions}
            idiom={idiom}
            selectedCard={selectedCard}
            intl={intl}
            idiomForApi={idiomForApi}
          />
        </SessionContainer>
      )}
    </div>
  );
};

export default withRouter(GenerateCertificate);
