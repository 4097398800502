import React, { useContext, Fragment, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './Footer.module.scss';
import { filterByPosition } from './data';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import {
  ENABLE_OFFERS,
  ENABLE_SPECIAL_BENEFITS,
} from '../../utils/environments_variables';
import sanitize from '../../utils/sanitize';
import ArrowRightIcon from '../../assets/icons/ArrowRight';
import Logo from '../Logo/Logo';
import ALink from '../ALink/index';

const Footer = () => {
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const blue = isAfluent() ? '#021E4C' : '#1434CB';
  return (
    <footer className={styles.footer}>
      <div className={`${styles.separator} ${styles[themes]}`}></div>
      <div className="row">
        <div
          className={`col-md-2 col-sm-12 order-2 order-md-1 order-lg-1 order-xl-1 order-sm-2 ${styles.logoFooter}`}
        >
          <Link to="/">
            <Logo color={blue} size="MEDIUM" />
          </Link>
        </div>
        <div className="col-md-8 col-sm-12 order-md-2 order-sm-1">
          <div className="row">
            <div className={`col-md-4 col-sm-12 ${styles.firstSm}`}>
              <ul className={styles.navLeft}>
                {filterByPosition(intl, 'left', idiom).map((data, index) => {
                  if (data.href === '/offers' && !ENABLE_OFFERS) {
                    return <Fragment key={data.id}></Fragment>;
                  }
                  if (
                    data.href === '/subscription-benefits' &&
                    !ENABLE_SPECIAL_BENEFITS
                  ) {
                    return <Fragment key={data.id}></Fragment>;
                  }
                  return (
                    <li key={`footer-link-left-${data.id}`}>
                      <Link className={styles[themes]} to={sanitize(data.href)}>
                        {sanitize(data.text)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={`col-md-8 col-sm-12 ${styles.firstSm}`}>
              <ul className={styles.navRight}>
                {filterByPosition(intl, 'right', idiom).map((data, index) => {
                  return (
                    <li key={`footer-link-right-${index}`}>
                      <ALink
                        className={styles[themes]}
                        href={sanitize(data.href)}
                        target={data.target}
                      >
                        {sanitize(data.text)}
                      </ALink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-12 order-3">
          <ALink
            className={`${styles.visa} ${styles[themes]}`}
            target="_blank"
            href={intl.VISA_LINK(country)}
            ariaLabel={intl.TEXT_VISA_LINK}
          >
            {intl.TEXT_VISA_LINK}
            <span className={styles.icon}>
              <ArrowRightIcon width={20} height={20} alt="" />
            </span>
          </ALink>
        </div>
        <div className="col-12 order-md-4 order-4">
          <span className={`${styles.copyright} ${styles[themes]}`}>
            {' '}
            {intl.TEXT_COPYRIGHT}{' '}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
