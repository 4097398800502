import { useContext } from 'react';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './StatusLabel.module.scss';

const statusConfigDocumentProfile = intl => ({
  REVIEW: {
    text: intl.STATUS_UPLOADED,
    color: '#1434CB',
  },
  ACCEPTED: {
    text: intl.STATUS_APPROVED,
    color: '#30D167',
  },
  REJECTED: {
    text: intl.STATUS_REJECTED,
    color: '#EB5757',
  },
  PENDING: {
    text: intl.STATUS_PENDING,
    color: '#FF8F28',
  },
  ACCEPTED_AUTOMATICALLY: {
    text: intl.STATUS_APPROVED,
    color: '#30D167',
  },
  ACCEPTED_MANUALLY: {
    text: intl.STATUS_APPROVED,
    color: '#30D167',
  },
  MANUAL_VERIFICATION: {
    text: intl.STATUS_UPLOADED,
    color: '#1434CB',
  },
  ERROR: {
    text: intl.STATUS_UPLOADED,
    color: '#1434CB',
  },
  DISCARDED: {
    text: intl.STATUS_REJECTED,
    color: '#EB5757',
  },
});

const statusConfigDocument = intl => ({
  REVIEW: {
    text: intl.STATUS_UPLOADED,
    color: '#1434CB',
  },
  COMPLETED: {
    text: intl.STATUS_COMPLETED,
    color: '#30D167',
  },
  CLOSED: {
    text: intl.STATUS_CLOSED,
    color: '#192236',
  },
  REJECTED: {
    text: intl.STATUS_REJECTED,
    color: '#EB5757',
  },
  PENDING_DOCUMENTATION: {
    text: intl.STATUS_PENDING_DOCUMENTATION,
    color: '#FF8F28',
  },
  PENDING: {
    text: intl.STATUS_PENDING,
    color: '#FF8F28',
  },
  ACCEPTED: {
    text: intl.STATUS_APPROVED,
    color: '#30D167',
  },
  ACCEPTED_AUTOMATICALLY: {
    text: intl.STATUS_APPROVED,
    color: '#30D167',
  },
  ACCEPTED_MANUALLY: {
    text: intl.STATUS_APPROVED,
    color: '#30D167',
  },
  MANUAL_VERIFICATION: {
    text: intl.STATUS_PENDING,
    color: '#FF8F28',
  },
  ERROR: {
    text: intl.STATUS_PENDING,
    color: '#FF8F28',
  },
  DISCARDED: {
    text: intl.STATUS_REJECTED,
    color: '#EB5757',
  },
});

const statusConfig = intl => ({
  REVIEW: {
    text: intl.STATUS_REVIEW,
    color: '#5c5c5c',
  },
  REVIEW_BY_UNDERWRITER: {
    text: intl.STATUS_REVIEW_UNDERWRITER,
    color: '#5c5c5c',
  },
  COMPLETED: {
    text: intl.STATUS_COMPLETED,
    color: '#30D167',
  },
  CLOSED: {
    text: intl.STATUS_CLOSED,
    color: '#192236',
  },
  CLOSED_DUPLICATED: {
    text: intl.STATUS_CLOSED_DUPLICATED,
    color: '#192236',
  },
  REJECTED: {
    text: intl.STATUS_REJECTED,
    color: '#EB5757',
  },
  PENDING_DOCUMENTATION: {
    text: intl.STATUS_PENDING_DOCUMENTATION,
    color: '#FF8F28',
  },
  PENDING: {
    text: intl.STATUS_PENDING,
    color: '#FF8F28',
  },
  CREATED: {
    text: intl.TEXT_CREATED,
    color: '#FF8F28',
  },
  ONGOING: {
    text: intl.TEXT_ONGOING,
    color: '#30D167',
  },
  EXPIRED: {
    text: intl.TEXT_EXPIRED,
    color: '#EB5757',
  },
  CANCELLED: {
    text: intl.TEXT_CANCELLED,
    color: '#192236',
  },
  WAITING_FOR_PAYMENT: {
    text: intl.TEXT_CREATED,
    color: '#FF8F28',
  },
  PAYMENT_REFUSED: {
    text: intl.PAYMENT_REFUSED,
    color: '#EB5757',
  },
  SUSPENDED: {
    text: intl.SUSPENDED,
    color: '#5c5c5c',
  },
  WAITING_FOR_TRAVEL: {
    text: intl.WAITING_FOR_TRAVEL,
    color: '#1434CB',
  },
});

const statusAppointments = intl => ({
  CANCELED: {
    text: intl.TEXT_CANCELED,
    color: '#EB5757',
  },
  DONE: {
    text: intl.TEXT_DONE,
    color: '#30D167',
  },
  NOT_DONE: {
    text: intl.TEXT_NOT_DONE,
    color: '#192236',
  },
  PENDING: {
    text: intl.TEXT_PENDIND,
    color: '#5c5c5c',
  },
});

const statusMonetization = intl => ({
  EXPIRED: {
    text: intl.TEXT_EXPIRED,
    color: '#EB5757',
  },
  ACTIVE: {
    text: intl.TEXT_ONGOING,
    color: '#30D167',
  },
  CLOSED: {
    text: intl.STATUS_CLOSED,
    color: '#192236',
  },
});

const statusFlight = intl => ({
  DELAYED: {
    text: intl.STATUS_DELAYED,
    color: '#F7A105',
  },
  ON_TIME: {
    text: intl.STATUS_ON_TIME,
    color: '#40996B',
  },
  CANCELLED: {
    text: intl.TEXT_CANCELLED,
    color: '#D65168',
  },
});

const typeConfig = (
  intl,
  document,
  appointments,
  documentProfile,
  monetization,
  flight
) => {
  if (document) {
    return statusConfigDocument(intl);
  } else if (appointments) {
    return statusAppointments(intl);
  } else if (documentProfile) {
    return statusConfigDocumentProfile(intl);
  } else if (monetization) {
    return statusMonetization(intl);
  } else if (flight) {
    return statusFlight(intl);
  } else {
    return statusConfig(intl);
  }
};

export interface StatusLabelProps {
  status?: string;
  document?: boolean;
  appointments?: boolean;
  documentProfile?: boolean;
  monetization?: boolean;
  flight?: boolean;
  className?: string;
  directText?: string;
  directColor?: string;
}

const StatusLabel = (props: StatusLabelProps) => {
  const {
    status,
    document,
    appointments,
    documentProfile,
    monetization,
    flight,
    className,
    directText,
    directColor,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const config = typeConfig(
    intl,
    document,
    appointments,
    documentProfile,
    monetization,
    flight
  );

  if (config[status] === undefined && (!directColor || !directText)) {
    return <></>;
  }

  return (
    <div
      className={
        className ? `${styles.container} ${className}` : styles.container
      }
      style={{ backgroundColor: directColor || config[status].color }}
    >
      <span>{directText || config[status].text}</span>
    </div>
  );
};

export default StatusLabel;
