import React, { useContext, useEffect, useState } from 'react';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import styles from './Certificates.module.scss';
import RecentCreated from '../RecentCreated/RecentCreated';
import { certificatesServices, claimsServices } from '../../../../services';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import TotalReimbursed from '../../../../components/TotalReimbursed/TotalReimbursed';
import { CERTIFICATES } from '../../../../utils/consts';
import { dataLayerContentMenuTrack } from '../../../../utils/GTM_helper';
import { REACT_APP_TOTAL_REIMBURSEMENT } from '../../../../utils/environments_variables';
import CertificateIcon from '../../../../assets/icons/CertificatesIcon';
import BenefitsIcon from '../../../../assets/icons/BenefitsIcon';
import Loading from '../../../../components/Loading/Loading';
import Generate from '../Generate/Generate';
import CertificatePlus from '../../../../assets/icons/CertificatesPlusIcon';

const getDistinctClaims = claims => {
  if (!claims) return 0;
  let uniqueClaims = [];

  claims.map(claim => {
    if (uniqueClaims.indexOf(claim.benefit_external_reference) === -1) {
      uniqueClaims.push(claim.benefit_external_reference);
    }
  });

  return uniqueClaims.length;
};

const Certificates = ({ benefits }) => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const [generatedCertificates, setGeneratedCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const { utils } = useContext(StateContext);
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const theme = getGlobalTheme();
  const [distinctClaims, setDistinctClaims] = useState(0);

  const getGenerated = async () => {
    try {
      setLoading(true);
      const { data: certificateData } =
        await certificatesServices.getCertificatesByBin(
          cn,
          binOrPan,
          idiomForApi()
        );
      const { data: dataClaim } = await claimsServices.getClaims(
        cn,
        binOrPan,
        idiomForApi()
      );
      setGeneratedCertificates(certificateData);
      const distinctClaims = getDistinctClaims(dataClaim);
      setDistinctClaims(distinctClaims);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDistinctClaims(0);
    }
  };

  useEffect(() => {
    getGenerated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binOrPan, cn]);

  return (
    <div className={`col-lg-6 col-md-12 ${styles.certificates}`}>
      <div className="row no-gutters">
        <div className={`${styles.counter} ${styles[theme]}`}>
          <div className={styles.right}>
            <span className={styles.icon}>
              <CertificateIcon width={30} height={30} />
            </span>
          </div>
          <div className={styles.left}>
            {loading ? (
              <Loading />
            ) : (
              <span className={`${styles.length} ${styles[theme]}`}>
                {generatedCertificates.length}
              </span>
            )}
          </div>
          <div className={styles.center}>
            <h6 className={styles[theme]}>
              {intl.TEXT_CERTIFICATES_GENERATED}
            </h6>
            <Link
              onClick={() =>
                dataLayerContentMenuTrack(
                  'CTA See my certificates',
                  'Check history'
                )
              }
              to="/profile/certificates#active"
              className={styles[theme]}
            >
              {intl.TEXT_VIEW_YOUR_CERTIFICATE}
            </Link>
          </div>
        </div>
        <div className={`${styles.counter} ${styles.marginLeft}`}>
          <div className={styles.right}>
            <span className={styles.icon}>
              <BenefitsIcon width={30} height={30} />
            </span>
          </div>
          <div className={styles.left}>
            {loading ? (
              <Loading />
            ) : REACT_APP_TOTAL_REIMBURSEMENT ? (
              <span className={`${styles.length} ${styles[theme]}`}>
                {benefits.length}
              </span>
            ) : (
              <span className={`${styles.length} ${styles[theme]}`}>
                {distinctClaims}
              </span>
            )}
          </div>
          <div className={styles.center}>
            {REACT_APP_TOTAL_REIMBURSEMENT ? (
              <h6 className={styles[theme]}>{intl.TEXT_BENEFITS_OFFERED}</h6>
            ) : (
              <h6 className={styles[theme]}>{intl.TEXT_BENEFITS_ENJOYED}</h6>
            )}
            <Link
              onClick={() =>
                dataLayerContentMenuTrack(
                  'CTA See my benefits',
                  'Check history'
                )
              }
              to="/benefits"
              className={styles[theme]}
            >
              {intl.TEXT_VIEW_BENEFITS}
            </Link>
          </div>
        </div>
      </div>
      {/* commented for refund rules review */}
      {REACT_APP_TOTAL_REIMBURSEMENT && (
        <TotalReimbursed
          type={CERTIFICATES}
          certificateData={generatedCertificates}
          shouldShowLinkToProfile
          shouldShowTooltip
          isDashboard
        />
      )}
      <div className={`row no-gutters`}>
        {REACT_APP_TOTAL_REIMBURSEMENT && (
          <div className={`col-lg-12 col-md-12 ${styles.certificates}`}>
            <Generate
              blue
              icon={
                <CertificatePlus
                  width={30}
                  height={30}
                  aria-label={intl.ADD_CERTIFICATE}
                  data-testid="addCertificate"
                />
              }
              title={intl.TEXT_GENERATE_CERTIFICATE}
              url="/certificates"
              GTM_id="CTA Generate a certificate"
              type="certificate"
              lightBackground={false}
            />
          </div>
        )}
        <RecentCreated
          data={generatedCertificates}
          title={intl.TEXT_RECENT_CERTIFICATES}
          type="certificate"
        />
      </div>
    </div>
  );
};

export default Certificates;
