import TooltipIcon from '../../assets/icons/TooltipIcon';
import styles from './HighlightNote.module.scss';

const HighLightNote = ({ note }) => {
  return (
    <div className={styles.highlightContainer}>
      <aside className={styles.iconArea}>
        <TooltipIcon color="#fff" width={20} height={20} />
      </aside>
      <span className={styles.highlightNote}>{note}</span>
    </div>
  );
};

export default HighLightNote;
