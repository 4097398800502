import React, { useContext, useEffect, useState } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getIncidentDate,
  getIncidentDescription,
  getAmountClaimed,
  getOtherInsuranceCovering,
} from '../../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { safeGet } from '../../../../../../utils/object';

const IncidentArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const [incident, setIncident] = useState({});
  const [items, setItems] = useState([]);
  const [covering, setCovering] = useState({});

  useEffect(() => {
    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const items =
      (claimDetails && claimDetails.purchase_protection_items) || [];
    setItems(items);

    const covering =
      safeGet(
        claimDetails,
        'purchase_protection_items[0].other_insurance_covering[0]'
      ) || {};
    setCovering(covering);
  }, [claimDetails]);

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DATE}
          value={getIncidentDate(incident, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_REIMBURSED}
          value={getAmountClaimed(items)}
        />
      </div>
      {covering && covering.amount && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue
            label={intl.LABEL_AMOUNT_PAID}
            value={getOtherInsuranceCovering(covering)}
          />
        </div>
      )}
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DESCRIPTION}
          value={getIncidentDescription(incident)}
        />
      </div>
    </>
  );
};

export default IncidentArea;
