/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from 'react';
import { IntlContext } from '../../../intl/index';
import * as translations from './intl';
import { pt_BR } from '../../../intl/idioms';
import { NotificationContext } from '../../NotificationContextParent/NotificationContextParent';
import { toast } from 'react-toastify';
import { showNotification, updateNotification } from '../utils';
import waitForPublicCard from '../waitForPublicCard';
import { StateContext } from '../../StateContextParent/StateContextParent';

const NotificationIEMS = () => {
  const { translate, country, idiom } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const { actions, state } = useContext(NotificationContext);
  const intl = translate(translations);
  const isBrazil = idiom === pt_BR;
  const toastId = useRef(null);
  const delayId = useRef(null);
  const isMX = utils.getCountry() === 'MEXICO' || country === 'MX';

  const contentMX = (
    <>
      <span
        role="status"
        dangerouslySetInnerHTML={{ __html: intl.TEXT_BANNER_IEMS_MX }}
      />
    </>
  );

  useEffect(() => {
    clearTimeout(delayId.current);
    delayId.current = null;
    if (!isBrazil && !state.toastNotification.IEMS && isMX) {
      delayId.current = setTimeout(() => {
        showNotification(toastId, contentMX);
      }, 2025);
      actions.toastNotification.setOnlyOnceAppereanceToast('IEMS');
    } else {
      if (delayId.current) {
      }
    }
    return () => toast.dismiss(toastId.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return null;
};

export default waitForPublicCard(NotificationIEMS);
