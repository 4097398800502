import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router';
import { pt_BR } from '../../../../intl/idioms';
import StepClaim from '../../StepClaim/StepClaim';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import CheckTerms from './CheckTerms/CheckTerms';
import styles from './styles.module.scss';
import { IntlContext } from '../../../../intl';
import Button from '../../../../components/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import ALink from '../../../../components/ALink';

const getPdfLink = claimDetails => {
  const pdfDocument = claimDetails.documents.find(
    document => document.name === 'pdf_url'
  );
  return pdfDocument && pdfDocument.content_url;
};

const reduceAcceptChecked = (sum, checked) => (checked ? sum + 1 : sum);

const TermsAndConditions = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { claimDetails, setAcceptAllChecked } = props;
  const [acceptChecked, setAcceptChecked] = useState([]);
  const isBrazil = idiom === pt_BR;
  const check = index => {
    const _acceptChecked = [...acceptChecked];
    _acceptChecked[index] = !_acceptChecked[index];
    setAcceptChecked(_acceptChecked);
  };
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const acceptSum = acceptChecked.reduce(reduceAcceptChecked, 0);
  const acceptedAll = acceptSum === 3;
  const pdfLink = claimDetails && getPdfLink(claimDetails);
  setAcceptAllChecked(acceptedAll);

  const stepNames = [intl.TEXT_STEP_1, intl.TEXT_STEP_2];

  return (
    <div>
      <StepClaim currentStep={1} stepNames={stepNames} />
      <ShadowBox>
        <div id="mainContent" className={styles.termsArea}>
          <h2 className={styles[theme]}>
            {intl.ACCEPTING_TERMS_AND_CONDITIONS}
          </h2>
          <div className={styles.pdf}>
            <PDFIcon width={42} height={54} />
            <div className={styles.pdfInfo}>
              <ALink
                className={styles[theme]}
                href={pdfLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.TERMS_AND_CONDITIONS}
              </ALink>
              <span>{intl.TEXT_IEMS}</span>
            </div>
          </div>
        </div>
        <div>
          <CheckTerms
            isBrazil={isBrazil}
            acceptChecked={acceptChecked}
            check={check}
            pdfLink={pdfLink}
            styles={styles}
          />

          <div className={styles.wrappButton}>
            <Button
              disabled={!acceptedAll}
              value={intl.BTN_NEXT}
              onClick={() => props.setShowForm(true)}
              type="borderBlue"
              id="borderBlue-acceptTermsAndConditions"
            >
              {intl.BTN_NEXT}
            </Button>
          </div>
        </div>
      </ShadowBox>
    </div>
  );
};

export default withRouter(TermsAndConditions);
