import React from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';

const scrollWithOffset = (el, subtract) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = subtract ? -(subtract + 90) : -90;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const NavHashLink = props => {
  const { subtract } = props;
  return (
    <Link {...props} scroll={el => scrollWithOffset(el, subtract)}>
      {props.children}
    </Link>
  );
};

export default NavHashLink;
