import { useState, useContext } from 'react';
import styles from './index.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../intl/index';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import Button from '../../../../components/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import sanitize from '../../../../utils/sanitize';
import { REACT_APP_DATE_TERMS_AND_CONDITIONS_CINEPOLIS_GO_LIVE } from '../../../../utils/environments_variables';

const TermsAndConditions = props => {
  const { setStep, pdfLink } = props;

  const [genericAccept, setGenericAccept] = useState(false);
  const [genericAccept2, setGenericAccept2] = useState(false);
  const [genericAccept3, setGenericAccept3] = useState(false);
  const { translate, idiom, country } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const intl = translate(translations);
  const theme = getGlobalTheme();

  const paragraphMessage = intl.PARAGRAPH_MESSAGE(
    REACT_APP_DATE_TERMS_AND_CONDITIONS_CINEPOLIS_GO_LIVE,
    idiom,
    country
  );

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <ShadowBox>
        <div className={styles.termsArea}>
          <h2 className={styles[theme]}>
            {intl.ACCEPTING_TERMS_AND_CONDITIONS}
          </h2>

          <div className={styles.pdf}>
            <PDFIcon width={42} height={54} />
            <div className={styles.pdfInfo}>
              <a
                className={styles[theme]}
                href={pdfLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.TERMS_AND_CONDITIONS}
              </a>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(intl.CINEPOLIS),
                }}
              ></span>
            </div>
          </div>
        </div>

        <div className={styles.paragraphArea}>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(paragraphMessage),
            }}
          ></span>
        </div>

        <div className={`${styles[theme]} ${styles.acceptArea}`}>
          <CheckBox
            name="accept_vouchers"
            value={genericAccept}
            onClick={check => setGenericAccept(check)}
          >
            {intl.ACCEPT_TERMS_VOUCHERS_PER_YEAR}
          </CheckBox>
          <CheckBox
            name="accept_inform_card_expiration"
            value={genericAccept2}
            onClick={check => setGenericAccept2(check)}
          >
            {intl.ACCEPT_TERMS_INFORM_CARD_EXPIRATION}
          </CheckBox>
          <CheckBox
            name="accept_terms"
            value={genericAccept3}
            onClick={check => setGenericAccept3(check)}
          >
            {intl.ACCEPT_TERMS}
          </CheckBox>
        </div>

        <div className={styles.footer}>
          <div className={styles.buttonArea}>
            <Button
              type="blue"
              disabled={!genericAccept || !genericAccept2 || !genericAccept3}
              onClick={() => setStep(2)}
            >
              {intl.BTN_NEXT_STEP}
            </Button>
          </div>
        </div>
      </ShadowBox>
    </div>
  );
};

export default TermsAndConditions;
