import React, { useEffect, useState, useContext } from 'react';

import styles from './ClaimsList.module.scss';
import { IntlContext } from '../../../../intl';
import * as translations from '../intl';
import {
  TRAVEL_BENEFITS,
  PURCHASE_PROTECTION,
  LIFESTYLE,
  CONCIERGE,
  getIconByTheme,
} from '../../../../utils/benefitCategory';
import ListCardBenefits from '../../../../components/ListCardBenefits/ListCardBenefits';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';

const getCategoryName = (category, intl) => {
  let name = '';

  switch (category) {
    case TRAVEL_BENEFITS:
      name = intl.TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION;
      break;
    case PURCHASE_PROTECTION:
      name = intl.TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION;
      break;
    case LIFESTYLE:
      name = intl.TITLE_BENEFITS_CATEGORY_LIFESTYLE;
      break;
    case CONCIERGE:
      name = intl.TITLE_BENEFITS_CATEGORY_CONCIERGE;
      break;
    default:
      break;
  }

  return name;
};

const ClaimsList = props => {
  const { benefits, benefitsList } = props;
  const { translate, idiom } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [newBenefitList, setNewBenefitList] = useState([]);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const binOrPan = utils.getBin(true);

  const htmlContent = intl => {
    return (
      <>
        <p>{intl.TEXT_DESCRIPTION}</p>
        <ul className={styles[theme]}>
          <li className={styles[theme]}>{intl.TEXT_INFO_INCIDENT}</li>
          <li className={styles[theme]}>{intl.TEXT_PERSONAL_INFO}</li>
        </ul>
      </>
    );
  };

  useEffect(() => {
    try {
      const list =
        benefits &&
        benefits.map(benefit => {
          return {
            ...benefit,
            image:
              benefit['info'][0] &&
              getIconByTheme(benefit['info'][0].images, isAfluent()),
            category: getCategoryName(
              benefit['info'][0] && benefit['info'][0].categories,
              intl
            ),
            description: htmlContent(intl),
          };
        });
      setNewBenefitList(list);
      trackEventUserAction(
        '#### (CLAIMS LIST) FORMATANDO BENEFICIOS/CLAIMS',
        list
      );
    } catch (error) {
      setNewBenefitList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefits, idiom]);

  return (
    <div className={`${styles.claimsList} row`}>
      <div className={styles.claimListBox}>
        {newBenefitList.length > 0 ? (
          <ListCardBenefits
            benefitToSplit={newBenefitList}
            benefitsList={benefitsList}
            type={'claim'}
            productBinOrPan={binOrPan}
          />
        ) : (
          <div className={styles.center}>
            <h2 className={`${styles.emptyMessage} ${styles[theme]}`}>
              {intl.NO_CLAIM_TYPE_AVAILABLE}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimsList;
