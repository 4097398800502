import React, { useState, useEffect, useContext } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';
import { userServices } from '../services';
import { StateContext } from '../components/StateContextParent/StateContextParent';
import LayoutRoutePrivate from '../components/LayoutRoutePrivate/LayoutRoutePrivate';
import { Redirect, useHistory } from 'react-router-dom';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';
import PartnerRedirect from '../components/PartnerRedirect/PartnerRedirect';
import EmptyLayoutHalfBlue from '../layouts/EmptyLayoutHalfBlue/EmptyLayoutHalfBlue';
import UpdateBinTokenex from '../components/UpdateBinTokenex/UpdateBinTokenex';
import { IntlContext } from '../intl';

import Dashboard from '../pages/Dashboard/Dashboard';
import Generate from '../pages/Claims/Generate/Generate';
import Upload from '../pages/Claims/Upload/Upload';
import GenerateCertificates from '../pages/Generate/Certificates/Certificates';
import PrivateBenefits from '../pages/PrivateBenefits/PrivateBenefits';
import AllCertificates from '../pages/AllCertificates/AllCertificates';
import GenerateCertificate from '../pages/Certificates/Generate/GenerateCertificate';
import PrivateClaims from '../pages/PrivateClaims/PrivateClaims';
import Profile from '../pages/Profile/Profile';
import Teleconsultation from '../pages/Claims/Teleconsultation/Teleconsultation';
import Update from '../pages/Claims/Update/Update';
import Appeal from '../pages/Claims/Appeal/Appeal';
import VisaOnlineMedic from '../pages/Claims/Teleconsultation/VisaOnlineMedic/VisaOnlineMedic';
import SpecialBenefits from '../pages/SpecialBenefits/SpecialBenefits';
import SpecialBenefitsDetail from '../pages/SpecialBenefitsDetail/SpecialBenefitsDetail';
import BenefitsDetail from '../pages/BenefitsDetail/BenefitsDetail';
import NurseLine from '../pages/Claims/NurseLine/NurseLine';
import PreviousAppointmentsNAL from '../pages/Claims/NurseLine/PreviousAppointments/PreviousAppointmentsNAL';
import Partner from '../pages/Partner/Partner';
import Quote from '../pages/B2B2C/Quote/Quote';
import Download from '../pages/Download/Download';
import QuoteDetail from '../pages/B2B2C/QuoteDetail/QuoteDetail';
import B2B2C from '../pages/B2B2C/index';
import DownloadApp from '../pages/DownloadApp/DownloadApp';
import TravelKitMarketing from '../pages/DownloadApp/TravelKitMarketing/TravelKitMarketing';
import FlightDetails from '../pages/TripAssistance/FlightDetails';
import TravelDetails from '../pages/TripAssistance/TravelDetails/TravelDetails';
import ListingTripAssistance from '../pages/TripAssistance/Listing';
import RealTimeFlightTracking from '../pages/TripAssistance/RealTimeTracking';
import {
  ENABLE_DOWNLOAD_APP_PAGE,
  ENABLE_TRIP_ASSISTANCE,
  ENABLE_UUP,
} from '../utils/environments_variables';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import { en_US, es_ES, pt_BR } from '../intl/idioms';
import { AppInsightTrackContext } from '../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import * as translationsUpdateEmail from '../utils/verifyUpdateEmail/intl';
import * as translationsPersonalDocuments from '../utils/personalDocuments/intl';
import { verifyUpdateEmail } from '../utils/verifyUpdateEmail/verifyUpdateEmail';
import { verifyPersonalDocuments } from '../utils/personalDocuments/verifyPersonalDocuments';
import VideoCall from '../pages/Claims/Teleconsultation/VideoCall/VideoCall';
import ExecutiveTransportationForm from '../pages/BenefitsFormFlow/ExecutiveTransportationForm/ExecutiveTransportationForm';
import CinepolisPassForm from '../pages/BenefitsFormFlow/CinepolisPassForm/CinepolisPassForm';

export const IsLogged = props => {
  const { state, utils, actions } = useContext(StateContext);
  const { translate, setCountryByAuth0, country } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [user, setUser] = useState('NOT_LOGGED');
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const intlUpdateEmail = translate(translationsUpdateEmail);
  const intlPersonalDocuments = translate(translationsPersonalDocuments);

  const getUserInfoLogged = async () => {
    try {
      if (!utils.isUserLogged()) {
        await userServices.getUserInfo();
      }
    } catch (error) {
      setUser('NOT_LOGGED');
      sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
      setTimeout(() => {
        history.push('/login');
      }, 1000);
    }
  };

  const setIdiomByAuth0 = async () => {
    const COUNTRY_US = 'BS';
    const COUNTRY_ES = 'MX';
    const COUNTRY_PT = 'BR';

    try {
      let idiomFromAuth0 = {};
      if (!utils.isUserLogged()) {
        const { data } = await userServices.getUserInfo();
        idiomFromAuth0 = data['https://axa-partners.com/locale'];
      } else {
        idiomFromAuth0 = utils.getUserInfo();
      }

      if (idiomFromAuth0 === pt_BR) {
        setCountryByAuth0(COUNTRY_PT);
      } else if (idiomFromAuth0 === es_ES) {
        setCountryByAuth0(COUNTRY_ES);
      } else if (idiomFromAuth0 === en_US) {
        setCountryByAuth0(COUNTRY_US);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!state.user.logged) {
      getUserInfoLogged();
    }
    if (state.user.logged) {
      if (ENABLE_UUP) {
        const path_upload = window.location.pathname.includes('upload');
        if (!path_upload && !state.cards.data.length == 0) {
          verifyPersonalDocuments(
            utils,
            actions,
            intlPersonalDocuments,
            history,
            country
          );
        }
      }
      verifyUpdateEmail(utils, actions, intlUpdateEmail, trackEventUserAction);
      if (state.cards.data.length === 0) {
        setIdiomByAuth0();
      }
    }
    let redirect = sessionStorage.getItem('redirectAfterLogin');
    if (redirect) {
      setRedirect(redirect);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user.logged, redirect]);

  if (state.user.logged && state.cards.data.length > 0) {
    if (redirect) {
      sessionStorage.removeItem('redirectAfterLogin');
      return <Redirect to={redirect} />;
    }
    return props.children;
  }

  return user === 'NOT_LOGGED' ? <></> : props.children;
};

export const PrivateRoutesBinNotActived = [
  <LayoutRoutePrivate
    path="/dashboard"
    key="/dashboard"
    component={Dashboard}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/your-cards"
    key="/your-cards"
    component={Dashboard}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/certificate/:certificateType"
    key="/certificate/:certificateType"
  >
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/claim-type/Teleconsultation/generate"
    key="/claim-type/Teleconsultation/generate"
    component={Teleconsultation}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claim-type/:claimType/generate"
    key="/claim-type/:claimType/generate"
    component={Generate}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claim-type/:claim_id/upload"
    key="/claim-type/:claim_id/upload"
    component={Upload}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/claim-type/:claim_type/:claim_id/update"
    key="/claim-type/:claim_type/:claim_id/update"
    component={Update}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/profile"
    key="/profile"
    component={Profile}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/Certificates/:external_reference"
    key="/Certificates/:external_reference"
    exact={true}
  >
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate path="/your-benefits" key="/your-benefits" exact={true}>
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/your-benefits/:benefit_id"
    key="/your-benefits/:benefit_id"
    exact={true}
  >
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/your-subscription-benefits"
    key="/your-subscription-benefits"
    exact={true}
  >
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/your-subscription-benefits/:benefit_id"
    key="/your-subscription-benefits/:benefit_id"
    exact={true}
  >
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate path="/certificates" key="/certificates">
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/claims"
    key="/claims"
    component={PrivateClaims}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/visa-online-medic/previous-appointments"
    key="/visa-online-medic/previous-appointments"
    component={VisaOnlineMedic}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/visa-online-medic/Teleconsultation/VideoCall"
    key="/visa-online-medic/Teleconsultation/VideoCall"
    component={VideoCall}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    exact={true}
    path="/update/bin"
    key="/update/bin"
    IsLogged={IsLogged}
    component={UpdateBinTokenex}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claim-type/NurseAdviceLine"
    key="/claim-type/NurseAdviceLine"
    component={NurseLine}
    layout={MainLayout}
    IsLogged={IsLogged}
  ></LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/nurse-advice-line/previous-appointments"
    key="/nurse-advice-line/previous-appointments"
    component={PreviousAppointmentsNAL}
    IsLogged={IsLogged}
    layout={MainLayout}
  ></LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/claim-type/:claim_type/:claim_id/appeal"
    key="/claim-type/:claim_type/:claim_id/appeal"
    component={Appeal}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate path="/benefits/TravelKit" key="/benefits/TravelKit">
    <Redirect to="/PageNotFound-insertPan" />
  </LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/partner/:partner_name/eligibility"
    key="/partner/:partner_name/eligibility"
    component={Partner}
    layout={EmptyLayout}
    IsLogged={IsLogged}
  />,
  <LayoutRoutePrivate
    path="/partner/:partner_name"
    key="/partner/:partner_name"
    component={PartnerRedirect}
    IsLogged={IsLogged}
    layout={EmptyLayout}
  />,
  <LayoutRoutePrivate
    path={'/claim-type/Teleconsultation'}
    key="/claim-type/Teleconsultation"
    component={BenefitsDetail}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/upsell-benefit/:benefit_id"
    key="/upsell-benefit/:benefit_id"
    component={B2B2C}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/cross-sell-benefit/:benefit_id"
    key="/cross-sell-benefit/:benefit_id"
    component={Quote}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/view_certificate/:file_id"
    key="/view_certificate/:file_id"
    component={Download}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
];

export const PrivateRoutes = [
  <LayoutRoutePrivate
    path="/dashboard"
    key="/dashboard"
    component={Dashboard}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/your-cards"
    key="/your-cards"
    component={Dashboard}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/certificate/:certificateType"
    key="/certificate/:certificateType"
    component={GenerateCertificate}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claim-type/Teleconsultation/generate"
    key="/claim-type/Teleconsultation/generate"
    component={Teleconsultation}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claim-type/:claimType/generate"
    key="/claim-type/:claimType/generate"
    component={Generate}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claim-type/:claim_id/upload"
    key="/claim-type/:claim_id/upload"
    component={Upload}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/claim-type/:claim_type/:claim_id/update"
    key="/claim-type/:claim_type/:claim_id/update"
    component={Update}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/profile"
    key="/profile"
    component={Profile}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/Certificates/:external_reference"
    key="/Certificates/:external_reference"
    component={GenerateCertificates}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/your-benefits"
    key="/your-benefits"
    component={PrivateBenefits}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/your-benefits/category/:category"
    key="/your-benefits/category/:category"
    component={PrivateBenefits}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/your-benefits/:benefit_id"
    key="/your-benefits/:benefit_id"
    component={BenefitsDetail}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/upsell-detail/:benefit_id"
    key="/upsell-detail/:benefit_id"
    component={QuoteDetail}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/cross-sell-detail/:benefit_id"
    key="/cross-sell-detail/:benefit_id"
    component={QuoteDetail}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/your-subscription-benefits"
    key="/your-subscription-benefits"
    component={SpecialBenefits}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/your-subscription-benefits/:benefit_id"
    key="/your-subscription-benefits/:benefit_id"
    component={SpecialBenefitsDetail}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/certificates"
    key="/certificates"
    component={AllCertificates}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claims"
    key="/claims"
    component={PrivateClaims}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/visa-online-medic/previous-appointments"
    key="/visa-online-medic/previous-appointments"
    component={VisaOnlineMedic}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/visa-online-medic/Teleconsultation/VideoCall"
    key="/visa-online-medic/Teleconsultation/VideoCall"
    component={VideoCall}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    exact={true}
    path="/update/bin"
    key="/update/bin"
    IsLogged={IsLogged}
    component={UpdateBinTokenex}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/claim-type/NurseAdviceLine"
    key="/claim-type/NurseAdviceLine"
    component={NurseLine}
    layout={MainLayout}
    IsLogged={IsLogged}
  ></LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/nurse-advice-line/previous-appointments"
    key="/nurse-advice-line/previous-appointments"
    component={PreviousAppointmentsNAL}
    IsLogged={IsLogged}
    layout={MainLayout}
  ></LayoutRoutePrivate>,
  <LayoutRoutePrivate
    path="/claim-type/:claim_type/:claim_id/appeal"
    key="/claim-type/:claim_type/:claim_id/appeal"
    component={Appeal}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/partner/:partner_name/eligibility"
    key="/partner/:partner_name/eligibility"
    component={Partner}
    layout={EmptyLayout}
    IsLogged={IsLogged}
  />,
  <LayoutRoutePrivate
    path="/partner/:partner_name"
    key="/partner/:partner_name"
    component={PartnerRedirect}
    IsLogged={IsLogged}
    layout={EmptyLayout}
  />,
  <LayoutRoutePrivate
    path={'/claim-type/Teleconsultation'}
    key="/claim-type/Teleconsultation"
    component={BenefitsDetail}
    IsLogged={IsLogged}
    layout={MainLayout}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/upsell-benefit/:benefit_id"
    key="/upsell-benefit/:benefit_id"
    component={B2B2C}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/cross-sell-benefit/:benefit_id"
    key="/cross-sell-benefit/:benefit_id"
    component={B2B2C}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/view_certificate/:file_id"
    key="/view_certificate/:file_id"
    component={Download}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/download-your-app/travel-kit"
    key="/download-your-app/travel-kit"
    component={ENABLE_DOWNLOAD_APP_PAGE ? TravelKitMarketing : PageNotFound}
    IsLogged={IsLogged}
    layout={ENABLE_DOWNLOAD_APP_PAGE ? MainLayout : EmptyLayoutHalfBlue}
  />,
  <LayoutRoutePrivate
    path="/download-your-app"
    key="/download-your-app"
    component={ENABLE_DOWNLOAD_APP_PAGE ? DownloadApp : PageNotFound}
    IsLogged={IsLogged}
    layout={ENABLE_DOWNLOAD_APP_PAGE ? MainLayout : EmptyLayoutHalfBlue}
  />,
  <LayoutRoutePrivate
    path="/travel-kit"
    key="/travel-kit"
    component={ENABLE_TRIP_ASSISTANCE ? ListingTripAssistance : PageNotFound}
    IsLogged={IsLogged}
    layout={ENABLE_TRIP_ASSISTANCE ? MainLayout : EmptyLayoutHalfBlue}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/travel-kit/create/certificate/:certificate_id"
    key="/travel-kit/create/certificate/:certificate_id"
    component={ENABLE_TRIP_ASSISTANCE ? FlightDetails : PageNotFound}
    IsLogged={IsLogged}
    layout={ENABLE_TRIP_ASSISTANCE ? MainLayout : EmptyLayoutHalfBlue}
    exact={true}
  />,
  <LayoutRoutePrivate
    path="/travel-kit/create/:benefit_id/:certificate_id?"
    key="/travel-kit/create/:benefit_id/:certificate_id?"
    component={ENABLE_TRIP_ASSISTANCE ? FlightDetails : PageNotFound}
    IsLogged={IsLogged}
    layout={ENABLE_TRIP_ASSISTANCE ? MainLayout : EmptyLayoutHalfBlue}
  />,
  <LayoutRoutePrivate
    path="/travel-kit/details/:travel_id/:flight_number?"
    key="/travel-kit/details/:travel_id/:flight_number?"
    component={ENABLE_TRIP_ASSISTANCE ? TravelDetails : PageNotFound}
    IsLogged={IsLogged}
    layout={ENABLE_TRIP_ASSISTANCE ? MainLayout : EmptyLayoutHalfBlue}
  />,
  <LayoutRoutePrivate
    path="/executive-transportation/register"
    key="/executive-transportation/register"
    component={ExecutiveTransportationForm}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
  <LayoutRoutePrivate
    path="/2x1_cinepolis/redeem"
    key="/2x1_cinepolis/redeem"
    component={CinepolisPassForm}
    IsLogged={IsLogged}
    layout={MainLayout}
  />,
];
