export const TEXT_ERROR_VALIDATING_CREDIT_TOKEN = {
  en_US:
    'An error occurred while validating your card number. Please try again.',
  pt_BR:
    'Ocorreu um erro ao validar seu número de cartão. Por favor tente novamente.',
  es_ES:
    'Se ha producido un error al validar tu número de tarjeta. Por favor, inténtalo de nuevo.',
};

export const TEXT_ERROR_CREDIT_CARD_NOT_ELIGIBLE = {
  en_US: 'Your credit card is not eligible to enjoy Visa Benefits.',
  pt_BR:
    'Seu cartão de crédito não é elegível para aproveitar os benefícios Visa.',
  es_ES:
    'Su tarjeta de crédito no es elegible para disfrutar de los beneficios de Visa.',
};

export const TEXT_ERROR_VCES_TEMPORARY_DOWNTIME = {
  en_US:
    'Oops, the service that validates your credit card is temporarily downtime. Please try again.',
  pt_BR:
    'Ops, o serviço que valida seu cartão de crédito está temporariamente fora do ar. Por favor tente novamente.',
  es_ES:
    '¡Ups! El servicio que valida su tarjeta de crédito está temporalmente fuera de línea. Por favor, inténtalo de nuevo.',
};

export const TEXT_ERROR_VCES_FORBIDDEN = {
  en_US: 'Oops. Unfortunately your card could not be added at this time.',
  pt_BR:
    'Opa. Infelizmente, não foi possível adicionar seu cartão neste momento.',
  es_ES:
    '¡Ups! Desafortunadamente, no pudimos agregar tu tarjeta en este momento.',
};
