import React, { useContext } from 'react';
import styles from './BenefitItem.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { withRouter } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { PublicContext } from '../../../../components/PublicContextParent/PublicContextParent';
import { dataLayerBenefitTrack } from '../../../../utils/GTM_helper';
import {
  getAlignBackgroundByURL,
  getValignBackgroundByURL,
} from '../../../../utils/stringUtils';

const BenefitItem = props => {
  const { specialBenefit, itemCarousel } = props;
  const {
    external_reference,
    images,
    short_description,
    name,
    categories,
    redeem_info,
  } = specialBenefit;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { translate } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const { productActive } = useContext(PublicContext);
  const intl = translate(translations);

  const stylesCarousel = itemCarousel ? styles.itemCarousel : '';

  const picture =
    images && images.find && images.find(image => image.type === 'PICTURE');
  const pictureMobile =
    images &&
    images.find &&
    images.find(image => image.type === 'PICTURE_MOBILE');
  const background = pictureMobile || picture;

  let icon =
    images && images.find && images.find(image => image.type === 'ICON');
  icon = icon ? icon.content_url.split(';') : [null, null];
  const { product_id } = productActive;

  const redirectBenefitSpecial = () => {
    dataLayerBenefitTrack(name, 'Benefits card', categories);

    if (utils.isUserLogged()) {
      props.history.push(`/your-subscription-benefits/${external_reference}`);
    } else {
      props.history.push(`/subscription-benefits/${external_reference}`);
    }
  };

  return (
    <div className={`${styles.itemBenefit} ${stylesCarousel} itemBenefit`}>
      <div
        className={styles.imageArea}
        data-testid="imageArea"
        style={{
          backgroundImage: `url(${background && background.content_url})`,
          // backgroundPosition: `${
          //   background &&
          //   background.content_url &&
          //   getAlignBackgroundByURL(background.content_url)
          // } ${
          //   background &&
          //   background.content_url &&
          //   getValignBackgroundByURL(background.content_url)
          // }`,
        }}
      ></div>
      <div className={`${styles.short_description} ${styles[theme]}`}>
        <p className={styles[theme]}>{name}</p>
        <span className={styles[theme]}>
          {short_description
            ? short_description
            : redeem_info &&
              redeem_info.bluebox &&
              redeem_info.bluebox.title &&
              redeem_info.bluebox.title}
        </span>
      </div>

      <Button
        type="borderBlue"
        width="60%"
        onClick={redirectBenefitSpecial}
        id={`Get-${name}`}
      >
        {intl.TEXT_BUTTON}
      </Button>
    </div>
  );
};

export default withRouter(BenefitItem);
