/* eslint-disable no-console */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import styles from './VisaOnlineMedic.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { loadData } from '../TeleconsultationFormFill/loadData';
import {
  amosConsultationsService,
  amosVideocallsService,
} from '../../../../services';
import {
  error,
  loading,
} from '../TeleconsultationFormFill/TeleconsultationFormFill';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import ConsultationCategoriesFilter from './ConsultationCategoriesFilter/ConsultationCategoriesFilter';
import {
  ConsultationVideoType,
  ConsultationPhoneType,
} from '../../../../utils/consultationCategory';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import ConsultationItem from './ConsultationItem/ConsultationItem';
import ButtonWithIcon from '../../../../components/ButtonWithIcon/ButtonWithIcon';
import AddIcon from '../../../../assets/icons/AddIcon';
import { useHistory } from 'react-router-dom';

const setFilterByPathname = () => {
  const filter = new URLSearchParams(window.location.search).get('filter');
  if ([ConsultationVideoType, ConsultationPhoneType].includes(filter)) {
    return filter;
  } else {
    return ConsultationVideoType;
  }
};

const VisaOnlineMedic = () => {
  const { translate } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const intl = translate(translations);
  const cn = utils.getCn();
  const history = useHistory();

  const [data, setData] = useState('LOADING');
  const [videoCalls, setVideoCalls] = useState();
  const [phoneCalls, setPhoneCalls] = useState();
  const initialSelected = useMemo(setFilterByPathname, []);
  const [selected, setSelected] = useState(initialSelected);

  const isLoading = data === 'LOADING';
  const isError = data === 'ERROR';

  const loadVideoCallsList = useCallback(
    async cardHolder => {
      try {
        const { data } =
          await amosVideocallsService.getAppointmentListVideoCalls(cardHolder);

        data.data.videocalls.reverse();
        setVideoCalls(data.data);

        trackEventUserAction(
          '#### VMO ### Appointments - Chamadas por vídeo carregadas',
          { data: data.data }
        );
      } catch (error) {
        trackEventUserAction(
          '#### VMO ### Appointments - Erro ao carregar chamadas por vídeo',
          error
        );
        console.log(error);
      }
    },
    [trackEventUserAction]
  );

  const loadPhoneCallsList = useCallback(
    async cardHolder => {
      try {
        const { data } =
          await amosConsultationsService.getAppointmentListPhoneCalls(
            cardHolder
          );
        setPhoneCalls(data.data);

        trackEventUserAction(
          '#### VMO ### Appointments - Chamadas telefonicas carregadas',
          { data: data.data }
        );
      } catch (error) {
        trackEventUserAction(
          '#### VMO ### Appointments - Erro ao carregar chamadas telefonicas',
          error
        );
        console.log(error);
      }
    },
    [trackEventUserAction]
  );

  const mappedData = consultationList => {
    return (
      (consultationList && consultationList.length) > 0 &&
      consultationList.map(value => {
        return (
          <ConsultationItem
            key={`ConsultationItem-${value.id}`}
            data={value}
            appointmentType={selected}
            customer={data.cardHolder}
          />
        );
      })
    );
  };

  useEffect(() => {
    loadData(cn, setData);
  }, [cn]);

  useEffect(() => {
    if (data && data.cardHolder) {
      Promise.all([
        loadVideoCallsList(data.cardHolder),
        loadPhoneCallsList(data.cardHolder),
      ]).catch(error => console.log(error));
    }
  }, [data, loadVideoCallsList, loadPhoneCallsList]);

  return (
    <div>
      {isError && error}
      {
        <div className={`${styles.container} ${styles[themes]}`}>
          <h1
            data-testid="title"
            className={`${styles.title} ${styles[themes]}`}
          >
            {intl.TITLE_APPOINTMENTS}
          </h1>
          {isLoading || !videoCalls || !phoneCalls ? (
            loading
          ) : (
            <>
              <span className={`${styles.subtitle} ${styles[themes]}`}>
                {(phoneCalls &&
                  phoneCalls.consultations &&
                  phoneCalls.consultations.length > 0) ||
                (videoCalls &&
                  videoCalls.videocalls &&
                  videoCalls.videocalls.length > 0)
                  ? intl.SUBTITLE
                  : intl.TEXT_APPONTMENT_EMPTY_LIST}
              </span>

              <div id="mainContent" className={styles.appointmentsArea}>
                <div className={styles.subHeader}>
                  {((phoneCalls &&
                    phoneCalls.consultations &&
                    phoneCalls.consultations.length > 0) ||
                    (videoCalls &&
                      videoCalls.videocalls &&
                      videoCalls.videocalls.length > 0)) && (
                    <ConsultationCategoriesFilter
                      numberOfPhoneCalls={
                        phoneCalls &&
                        phoneCalls.consultations &&
                        phoneCalls.consultations.length
                      }
                      numberOfVideoCalls={
                        videoCalls &&
                        videoCalls.videocalls &&
                        videoCalls.videocalls.length
                      }
                      setSelected={setSelected}
                      selected={selected}
                    />
                  )}

                  <ButtonWithIcon
                    type="whiteWhitoutBorder"
                    onClick={() => {
                      history.push('/claim-type/Teleconsultation/generate');
                    }}
                    value={intl.BUTTON_REQUEST_APPOINTMENT}
                    isSVGComponent={true}
                    img={<AddIcon />}
                    textAlign="right"
                  />
                </div>

                <div
                  className={` ${styles.previousAppointments} ${styles[themes]}`}
                >
                  {selected === ConsultationVideoType
                    ? mappedData(
                        videoCalls && videoCalls.videocalls
                          ? videoCalls.videocalls
                          : []
                      )
                    : mappedData(
                        phoneCalls && phoneCalls.consultations
                          ? phoneCalls.consultations
                          : []
                      )}
                </div>
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
};

export default VisaOnlineMedic;
