import React, { useContext, useMemo } from 'react';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { formInitialValues } from './initialValues';
import { validationMissedSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';
import PeriodArea from './PeriodArea/PeriodArea';
import IndemityArea from './IndemnityArea/IndemnityArea';
import AddicionalPassagers from './AddicionalPassagers/AddicionalPassagers';
import ReimbursementArea from './ReimbursementArea/ReimbursementArea';

const MissedConnection = props => {
  const { translate, idiom } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const validationSchema = useMemo(() => validationMissedSchema(intl), [intl]);
  const initialValues = useMemo(
    () => formInitialValues(parentValues.policy, idiom, loadedData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idiom, parentValues.policy]
  );
  const card = utils.getSelectedCard();
  const showIndemnityArea =
    card.name === 'Infinite' ||
    card.name === 'Business Infinite' ||
    card.name === 'Corporate Infinite';
  const isCorporate = card.name === 'Corporate Infinite';

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              {showIndemnityArea && <IndemityArea cardName={card.name} />}
              <PersonalArea parentValues={parentValues} {...props} />
              <PeriodArea {...props} />
              <IncidentArea {...props} />
              <AddicionalPassagers {...props} />
              <QuestionsArea {...props} />
              <ReimbursementArea {...props} isCorporate={isCorporate} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default MissedConnection;
