import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import { Travel } from '../../../../@types/APIs/Travels';
import styles from './TripAssistanceCardItem.module.scss';
import travelCertificateSvgMaster from '../../../../assets/images/certificates/Visa_Icons_certificate-viaje-master.svg';
import travelCertificateSvgAfluent from '../../../../assets/images/certificates/Visa_Icons_certificate-viaje-afluent.svg';
import Button from '../../../../components/Button/Button';
import TravelIcon from '../../../../assets/icons/TravelIcon';
import { getCountryFromPrefixTranslated } from '../../../../utils/country';
import { getTravelDates } from '../../utils';
import * as translations from './intl';
interface TripAssistanceCardItem {
  travel: Travel;
}

const TripAssistanceCardItem = ({ travel }: TripAssistanceCardItem) => {
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();
  const image = isAfluent()
    ? travelCertificateSvgAfluent
    : travelCertificateSvgMaster;
  const history = useHistory();

  const buttonViewMore = () => {
    return (
      <div className={`${styles.button} btnSelect`}>
        <Button
          type="borderGold"
          id="btnViewMoreTripAssistance"
          css={styles.button}
          onClick={() =>
            history.push(`/travel-kit/details/${travel.travel_id}`)
          }
          dataTestid={`data-testid-btnViewMoreTripAssistance`}
        >
          {intl.TEXT_VIEW_MORE}{' '}
        </Button>
      </div>
    );
  };
  return (
    <li
      className={`${styles.recentClaimsItem} ${styles[themes]}
  `}
    >
      <div className={styles.left}>
        <div className={`${styles.icon} ${styles[themes]}`}>
          <TravelIcon width={40} height={40} />
        </div>
        <div className={`${styles.text} ${styles[themes]}`}>
          <span>
            <strong>
              {intl.TITLE_ORIGIN_TO_DESTINATION(
                getCountryFromPrefixTranslated(travel.country_origin, idiom),
                getCountryFromPrefixTranslated(
                  travel.country_destination,
                  idiom
                )
              )}
            </strong>
          </span>
        </div>
      </div>
      <div className={styles.center}>
        <span>
          <strong>{intl.LABEL_DATE}</strong>
        </span>
        <span>{getTravelDates(travel, idiom)}</span>
      </div>
      <div className={styles.right}>{buttonViewMore()}</div>
    </li>
  );
};

export default TripAssistanceCardItem;
