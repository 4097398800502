import { useContext, useEffect, useState, useRef } from 'react';
import { endOfDay, parse, isAfter } from 'date-fns';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './TotalReimbursed.module.scss';
import Loading from '../Loading/Loading';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { inArray } from '../../utils/array';
import { CERTIFICATES, CLAIMS, STATUS_CLOSED } from '../../utils/consts';
import {
  certificatesServices,
  claimsServices,
  productsAndBenefitsServices,
  reimbursmentService,
} from '../../services';
import Tooltip from '../Tooltip/Tooltip';
import TotalReimbursedIcon from '../../assets/icons/TotalReimbursedIcon';
import { StateContext } from '../StateContextParent/StateContextParent';
import { getCurrrencySymbolByInitials } from '../../utils/currency';
import ArrowRightIcon from '../../assets/icons/ArrowRight';
import { Link } from 'react-router-dom';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import { matchClaimsAndBenefits } from '../../pages/PrivateClaims/config_claim';
import ListInfoReimburstmentByReference from './ListInfoReimburstmentByReference/ListInfoReimburstmentByReference';
import { CONFIG_CERTIFICATES } from '../ListCertificates/configCertificates';
import { REACT_APP_DATE_MONETIZATION_GO_LIVE } from '../../utils/environments_variables';
import ALink from '../ALink';

const claimsStatus = [STATUS_CLOSED];

const TotalReimbursed = props => {
  const {
    type,
    border,
    bottom,
    certificateData,
    claimData,
    showArrow,
    shouldShowLinkSaving,
    shouldShowLinkToProfile,
    shouldShowTooltip,
    returnAllClaims,
    returnAllCertificate,
    setTotalMonetization,
    isDashboard,
  } = props;

  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const { translate, idiomForApi, idiom, country } = useContext(IntlContext);
  const { utils } = useContext(StateContext);

  const [total, setTotal] = useState(() => parseFloat(0).toFixed(2));
  const [totalTravel, setTotalTravel] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [objectTotalValueByClaim, setObjectTotalValueByClaim] = useState({});
  const [objectTotalValueByCertificate, setObjectTotalValueByCertificate] =
    useState({});
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [currencyInitialsCode, setCurrencyInitialsCode] = useState('');
  const [currencySymbolCerfitificate, setCurrencySymbolCerfitificate] =
    useState('');
  const [
    currencyInitialsCodeCerfitificate,
    setCurrencyInitialsCodeCerfitificate,
  ] = useState('');
  const [loadingTotal, setLoadingTotal] = useState(true);

  const isCanceled = useRef(null);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const title = type === CERTIFICATES ? intl.TEXT_CERTIFICATE : intl.TEXT_CLAIM;
  const subtitle =
    type === CERTIFICATES
      ? intl.TEXT_CERTIFICATE_SUBTITLE
      : intl.TEXT_CLAIM_SUBTITLE;
  const tooltip =
    type === CERTIFICATES
      ? intl.TEXT_TOOLTIP_REIMBURSED_WHEN_DATE_CERTIFICATE(
          REACT_APP_DATE_MONETIZATION_GO_LIVE,
          idiom,
          country
        )
      : intl.TEXT_TOOLTIP_REIMBURSED_WHEN_DATE_CLAIM(
          REACT_APP_DATE_MONETIZATION_GO_LIVE,
          idiom,
          country
        );
  const classBorder = border ? styles.border : '';
  const classBottom = bottom ? styles.marginBottom2 : '';
  const cn = utils.getCn();
  const binOrPan = utils.getBin(true);

  const getCurrencySymbolCertificate = data => {
    if (data.length > 0) {
      const currencyInitialsCode = data[0].currency;
      const currencySymbol = getCurrrencySymbolByInitials(currencyInitialsCode);
      return {
        currencySymbol,
        currencyInitialsCode,
      };
    }

    return {
      currencySymbol: '',
      currencyInitialsCode: '',
    };
  };

  const getCurrencySymbol = claimData => {
    if (claimData.length > 0) {
      const currencyInitialsCode = claimData[0].currency;
      const currencySymbol = getCurrrencySymbolByInitials(currencyInitialsCode);
      return {
        currencySymbol,
        currencyInitialsCode,
      };
    }

    return {
      currencySymbol: '',
      currencyInitialsCode: '',
    };
  };

  const ConfigCertificates = CONFIG_CERTIFICATES(intl, isAfluent());

  const getClaimData = async (claimData, listBenefits) => {
    const getInfoBenefit = benefit_external_reference => {
      return (
        listBenefits &&
        listBenefits.filter(benefit => {
          return benefit.external_reference === benefit_external_reference;
        })
      );
    };

    const promises = claimData
      .filter(currentClaimData =>
        inArray(claimsStatus, currentClaimData.status)
      )
      .map(async currentClaimData => {
        const reimbustment =
          await reimbursmentService.getAllReimbursmentClaimV2(
            currentClaimData.claim_id
          );

        const infoBenefit = getInfoBenefit(
          currentClaimData.benefit_external_reference
        );

        if (
          reimbustment &&
          reimbustment.data &&
          reimbustment.data[0] &&
          reimbustment.data[0].total_amount_reimbursed
        ) {
          return {
            type: currentClaimData.benefit_external_reference,
            value: Number(reimbustment.data[0].total_amount_reimbursed.value),
            currency: reimbustment.data[0].total_amount_reimbursed.currency,
            info: {
              date: currentClaimData.created_at.split(' ').shift() || '',
              country: '',
              value: Number(reimbustment.data[0].total_amount_reimbursed.value),
              ticket_number: currentClaimData.claim_number,
              status: currentClaimData.status,
              referenceName:
                infoBenefit && infoBenefit[0] && infoBenefit[0].name,
            },
            images:
              infoBenefit && infoBenefit[0] && infoBenefit[0].images
                ? infoBenefit[0].images
                : [],
            currencyInitialsCode: getCurrrencySymbolByInitials(
              reimbustment.data[0].total_amount_reimbursed.currency
            ),
            checked: false,
            expand: false,
          };
        } else {
          return {
            type: currentClaimData.benefit_external_reference,
            value: 0,
            currency: '',
            info: {
              date: currentClaimData.created_at || '',
              country: '',
              value: 0,
              ticket_number: currentClaimData.claim_number,
              status: currentClaimData.status,
              referenceName: infoBenefit[0] && infoBenefit[0].name,
            },
            images:
              infoBenefit && infoBenefit[0] && infoBenefit[0].images
                ? infoBenefit[0].images
                : [],
            currencyInitialsCode: '',
            checked: false,
            expand: false,
          };
        }
      });

    const result = await Promise.all(promises);

    return result;
  };

  const getTotalSumClaims = claimData => {
    const totalClaim = claimData.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );

    return totalClaim;
  };

  const getObjectTotalValueByClaim = claimData => {
    const totalByClaim = claimData
      .filter(data => data.value > 0)
      .reduce((accumulator, currentValue) => {
        const actual = accumulator;

        actual[currentValue.type] = actual[currentValue.type]
          ? {
              value: actual[currentValue.type].value + currentValue.value,
              items: [...actual[currentValue.type].items, currentValue.info],
              referenceName: currentValue.info.referenceName,
              images: currentValue.images,
            }
          : {
              value: currentValue.value,
              items: [currentValue.info],
              referenceName: currentValue.info.referenceName,
              images: currentValue.images,
            };

        return actual;
      }, {});

    return totalByClaim;
  };

  const getTotalClaims = async claimData => {
    try {
      const benefits = await getBenefits();

      if (!claimData) {
        const { data } = await claimsServices.getClaims(
          cn,
          binOrPan,
          idiomForApi()
        );
        claimData = data;
      }
      const listBenefits = await matchBenefitsAndCreatedClaims(
        claimData,
        benefits
      );

      const claimDataRes = await getClaimData(claimData, listBenefits);
      const totalSumClaims = getTotalSumClaims(claimDataRes);
      const currencyInfo = getCurrencySymbol(claimDataRes);
      const objectTotalValueByClaimData =
        getObjectTotalValueByClaim(claimDataRes);
      setObjectTotalValueByClaim(objectTotalValueByClaimData);
      const { currencySymbol, currencyInitialsCode } = currencyInfo;
      setTotal(parseFloat(totalSumClaims).toFixed(2));
      if (setTotalMonetization && type === CLAIMS)
        setTotalMonetization(parseFloat(totalSumClaims).toFixed(2));
      setCurrencySymbol(currencySymbol);
      setCurrencyInitialsCode(currencyInitialsCode);
      if (returnAllClaims)
        returnAllClaims(claimDataRes.filter(i => i.value > 0));
    } catch (error) {
      if (!isCanceled.current) {
        setTotal(parseFloat(0).toFixed(2));
        if (setTotalMonetization && type === CLAIMS)
          setTotalMonetization(parseFloat(0).toFixed(2));
        if (returnAllClaims) returnAllClaims([]);
      }
    }
  };

  const getObjectTotalValueByCertificate = certificateData => {
    const totalByCertificate = certificateData
      .filter(data => data.value > 0)
      .reduce((accumulator, currentValue) => {
        const actual = accumulator;

        actual[currentValue.type] = actual[currentValue.type]
          ? {
              value: actual[currentValue.type].value + currentValue.value,
              currency: currentValue.currency,
              items: [...actual[currentValue.type].items, currentValue.info],
              referenceName: currentValue.info.referenceName,
              images: currentValue.images,
            }
          : {
              value: currentValue.value,
              currency: currentValue.currency,
              items: [currentValue.info],
              referenceName: currentValue.info.referenceName,
              images: currentValue.images,
            };

        return actual;
      }, {});

    return totalByCertificate;
  };

  const getTotalCertificate = async certificateData => {
    try {
      if (!certificateData) {
        const { data } = await certificatesServices.getCertificatesByBin(
          cn,
          binOrPan,
          idiomForApi()
        );
        certificateData = data;
      }

      const today = endOfDay(new Date());

      const certificateInfoCollection = certificateData
        .map(certificate => {
          if (certificate.certificate_type === 'Certificate_Seguro_Compra') {
            return;
          } else {
            let value = { value: 0, type: '', currency: '' };
            const dateCertificate = parse(
              certificate.valid_until,
              'yyyy-MM-dd',
              new Date()
            );

            if (certificate.certificate_type === 'Travel_Insurance') {
              value =
                certificate.travel_details &&
                certificate.travel_details.cost &&
                certificate.travel_details.cost.value
                  ? {
                      value: certificate.travel_details.cost.value,
                      type: certificate.certificate_type,
                      currency: certificate.travel_details.cost.currency,
                      info: {
                        date: certificate.created_at || '',
                        country:
                          certificate.travel_details.destination_country || '',
                        value: certificate.travel_details.cost.value,
                        ticket_number: certificate.ticket_number,
                        status: isAfter(today, dateCertificate)
                          ? 'Expired'
                          : 'Active',
                        referenceName:
                          ConfigCertificates[
                            certificate.certificate_type.toLowerCase()
                          ].name,
                      },
                      images:
                        ConfigCertificates[
                          certificate.certificate_type.toLowerCase()
                        ],
                      currencyInitialsCode: getCurrrencySymbolByInitials(
                        certificate.travel_details.cost.currency
                      ),
                      checked: false,
                      expand: false,
                    }
                  : {
                      value: 0,
                      type: certificate.certificate_type,
                      currency: certificate.travel_details.cost.currency,
                      info: {},
                      images: '',
                      currencyInitialsCode: getCurrrencySymbolByInitials(
                        certificate.travel_details.cost.currency
                      ),
                      checked: false,
                      expand: false,
                    };
            }

            if (
              certificate.certificate_type === 'Billete_Auto_Rental_Insurance'
            ) {
              value =
                certificate.car_rental_details &&
                certificate.car_rental_details.cost &&
                certificate.car_rental_details.cost.value
                  ? {
                      value: certificate.car_rental_details.cost.value,
                      type: certificate.certificate_type,
                      currency: certificate.car_rental_details.cost.currency,
                      info: {
                        date: certificate.valid_from || '',
                        country:
                          certificate.car_rental_details.country_destination ||
                          '',
                        value: certificate.car_rental_details.cost.value,
                        ticket_number: certificate.ticket_number,
                        status: isAfter(today, dateCertificate)
                          ? 'Expired'
                          : 'Active',
                        referenceName:
                          ConfigCertificates[
                            certificate.certificate_type.toLowerCase()
                          ].name,
                      },
                      images:
                        ConfigCertificates[
                          certificate.certificate_type.toLowerCase()
                        ],
                      currencyInitialsCode: getCurrrencySymbolByInitials(
                        certificate.car_rental_details.cost.currency
                      ),
                      checked: false,
                      expand: false,
                    }
                  : {
                      value: 0,
                      type: certificate.certificate_type,
                      currency: certificate.car_rental_details.cost.currency,
                      info: {},
                      images: '',
                      currencyInitialsCode: getCurrrencySymbolByInitials(
                        certificate.car_rental_details.cost.currency
                      ),
                      checked: false,
                      expand: false,
                    };
            }

            if (
              certificate.certificate_type === 'Certificate_Extended_Warranty'
            ) {
              value =
                certificate.appliance_details &&
                certificate.appliance_details.cost &&
                certificate.appliance_details.cost.value
                  ? {
                      value: certificate.appliance_details.cost.value,
                      type: certificate.certificate_type,
                      currency: certificate.appliance_details.cost.currency,
                      info: {
                        date: certificate.created_at || '',
                        brand: certificate.appliance_details.brand || '',
                        value: certificate.appliance_details.cost.value,
                        ticket_number: certificate.ticket_number,
                        status: isAfter(today, dateCertificate)
                          ? 'Expired'
                          : 'Active',
                        referenceName:
                          ConfigCertificates[
                            certificate.certificate_type.toLowerCase()
                          ].name,
                      },
                      images:
                        ConfigCertificates[
                          certificate.certificate_type.toLowerCase()
                        ],
                      currencyInitialsCode: getCurrrencySymbolByInitials(
                        certificate.appliance_details.cost.currency
                      ),
                      checked: false,
                      expand: false,
                    }
                  : {
                      value: 0,
                      type: certificate.certificate_type,
                      currency: certificate.appliance_details.cost
                        ? certificate.appliance_details.cost.currency
                        : '',
                      info: {},
                      images: '',
                      currencyInitialsCode: getCurrrencySymbolByInitials(
                        certificate.appliance_details.cost.currency
                      ),
                      checked: false,
                      expand: false,
                    };
            }

            return value;
          }
        })
        .filter(item => {
          if (item) return item;
        });

      const totalTravelers = certificateInfoCollection.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );

      const certificateDataBy = getObjectTotalValueByCertificate(
        certificateInfoCollection
      );
      const { currencySymbol, currencyInitialsCode } =
        getCurrencySymbolCertificate(certificateInfoCollection);
      setCurrencySymbolCerfitificate(currencySymbol);
      setCurrencyInitialsCodeCerfitificate(currencyInitialsCode);
      setTotalTravel(totalTravelers);
      if (setTotalMonetization && type === CERTIFICATES)
        setTotalMonetization(parseFloat(totalTravelers).toFixed(2));
      setObjectTotalValueByCertificate(certificateDataBy);
      if (returnAllCertificate) returnAllCertificate(certificateInfoCollection);
    } catch (error) {
      if (!isCanceled.current) {
        setTotalTravel(0);
        if (setTotalMonetization && type === CERTIFICATES)
          setTotalMonetization(parseFloat(0).toFixed(2));
        setObjectTotalValueByCertificate({});
        if (returnAllCertificate) returnAllCertificate([]);
      }
    }
  };

  const getBenefits = async () => {
    try {
      const { data: benefits } =
        await productsAndBenefitsServices.getBenefitsByProduct(
          binOrPan,
          idiomForApi(),
          false,
          false
        );
      return benefits;
    } catch (error) {
      return null;
    }
  };

  async function matchBenefitsAndCreatedClaims(claims, benefits) {
    try {
      const matchListBenefits = await matchClaimsAndBenefits(
        claims,
        benefits,
        binOrPan,
        idiomForApi()
      );
      return matchListBenefits;
    } catch (error) {
      return benefits;
    }
  }

  const loadInfo = async (type, certificateData, claimData) => {
    isCanceled.current = false;
    setLoadingTotal(true);
    if (type === CERTIFICATES) {
      await getTotalCertificate(certificateData);
    } else {
      await getTotalClaims(claimData);
    }
    setLoadingTotal(false);
  };

  useEffect(() => {
    loadInfo(type, certificateData, claimData);
    return () => {
      isCanceled.current = true;
    };
  }, [type, certificateData, claimData]);

  const totalValue = total.toString().split('.');
  const totalFirstPart = totalValue[0] || parseFloat(0).toFixed(2);
  const totalDecimalPart = `.${totalValue[1]} ` || parseFloat(0).toFixed(2);

  const totalTravelValue = totalTravel.toFixed(2).toString().split('.');
  const totalCertificateFirstPart =
    totalTravelValue[0] || parseFloat(0).toFixed(2);
  const totalCertificateDecimalPart =
    `.${totalTravelValue[1]} ` || parseFloat(0).toFixed(2);

  return (type === CLAIMS && totalFirstPart == '0' && !isDashboard) ||
    (type === CERTIFICATES &&
      totalCertificateFirstPart == '0' &&
      !isDashboard) ? (
    <></>
  ) : (
    <div className={styles.Outercontainer}>
      <div className={`${styles[theme]} ${styles.container}`}>
        <div
          className={`${classBottom} ${styles.totalReimbursedDashboard}`}
          data-testid={`TotalReimbursed-${type}`}
        >
          <>
            <div className={styles.left}>
              <div className={`${styles[theme]} ${styles.icon}`}>
                <TotalReimbursedIcon width={35} height={35} />
              </div>
              <div
                className={`${styles[theme]} ${styles.containerDescription}`}
              >
                <h6 className={styles[theme]}>{title}</h6>
                <div className={`${styles[theme]} ${styles.ContainerTooltip}`}>
                  <span>{subtitle}</span>
                  {shouldShowTooltip && (
                    <div className={`${styles[theme]} ${styles.tooltip}`}>
                      <Tooltip
                        text={
                          REACT_APP_DATE_MONETIZATION_GO_LIVE
                            ? tooltip
                            : intl.TEXT_TOOLTIP_REIMBURSED
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${styles.right} ${classBorder} ${styles[theme]}`}>
              <div
                className={`${styles.containerValue} ${
                  showArrow
                    ? styles.flexContainerRow
                    : styles.flexContainerColumn
                }`}
              >
                {!loadingTotal ? (
                  <div
                    className={`${styles[theme]} ${styles.text}`}
                    data-testid={`valueReimbursed-${type}`}
                  >
                    {type === CLAIMS && (
                      <span
                        className={`${styles.totalAmount} ${styles[theme]}`}
                        data-testid={`totalAmount-${type}`}
                      >
                        {currencySymbol}
                        {totalFirstPart}
                        <span className={`${styles.decimal} ${styles[theme]}`}>
                          {totalDecimalPart}
                        </span>
                        <span
                          className={`${styles.currencyCode} ${styles[theme]}`}
                        >
                          {total > 0 ? currencyInitialsCode : ''}
                        </span>
                      </span>
                    )}
                    {type === CERTIFICATES && (
                      <span
                        className={`${styles.totalAmount} ${styles[theme]}`}
                        data-testid={`totalAmount-${type}`}
                      >
                        {currencySymbolCerfitificate}
                        {totalCertificateFirstPart}
                        <span className={`${styles.decimal} ${styles[theme]}`}>
                          {totalCertificateDecimalPart}
                        </span>
                        <span
                          className={`${styles.currencyCode} ${styles[theme]}`}
                        >
                          {totalTravel > 0
                            ? currencyInitialsCodeCerfitificate
                            : ''}
                        </span>
                      </span>
                    )}
                    <ALink
                      href={intl.LINK_DISCLAIMER}
                      className={styles.disclaimerResponsive}
                    >
                      {intl.LINK_DISCLAIMER_TEXT}
                    </ALink>
                  </div>
                ) : (
                  <Loading />
                )}
                {!showArrow && shouldShowLinkToProfile && (
                  <div className={`${styles[theme]} ${styles.redirectLink}`}>
                    <Link
                      to={
                        type === CERTIFICATES
                          ? '/profile/monetization#certificates'
                          : '/profile/monetization#claims'
                      }
                      className={styles[theme]}
                    >
                      {intl.BTN_VIEW_DETAILS}
                    </Link>
                    <ArrowRightIcon width={20} height={20} />
                  </div>
                )}

                {showArrow &&
                  !loadingTotal &&
                  ((type === CLAIMS && total > 0) ||
                    (type === CERTIFICATES && totalTravel > 0)) && (
                    <span
                      className={`${styles.arrow} ${
                        showInfo ? styles.showMoreActive : ''
                      }`}
                    >
                      <span
                        onClick={() => setShowInfo(!showInfo)}
                        className={showInfo ? styles.rotateIcon : ''}
                      >
                        <ChevronGeneric aria-label={intl.IMAGE_ARROW} />
                      </span>
                    </span>
                  )}
              </div>
            </div>
          </>
        </div>
        <ALink
          href={intl.LINK_DISCLAIMER}
          className={`${styles.disclaimer}${
            shouldShowLinkToProfile ? '' : ` ${styles.disclaimerWithMargin}`
          }`}
        >
          {intl.LINK_DISCLAIMER_TEXT}
        </ALink>
        {showInfo && !loadingTotal && (
          <ListInfoReimburstmentByReference
            currencyInitialsCode={
              type === CLAIMS
                ? currencyInitialsCode
                : currencyInitialsCodeCerfitificate
            }
            currencySymbol={
              type === CLAIMS ? currencySymbol : currencySymbolCerfitificate
            }
            objectTotalValueByClaim={objectTotalValueByClaim}
            objectTotalValueByCertificate={objectTotalValueByCertificate}
            type={type}
          />
        )}
      </div>
      {(shouldShowLinkSaving || (showArrow && showInfo)) &&
        !loadingTotal &&
        ((type === CLAIMS && total > 0) ||
          (type === CERTIFICATES && totalTravel > 0)) && (
          <div className={`${styles[theme]} ${styles.containerLink}`}>
            <Link
              to={
                type === CERTIFICATES
                  ? '/profile/monetization#certificates'
                  : '/profile/monetization#claims'
              }
              className={styles[theme]}
            >
              {intl.BTN_VIEW_ALL}
            </Link>
            <div className={styles.icon}>
              <ArrowRightIcon width={20} height={20} />
            </div>
          </div>
        )}
    </div>
  );
};

export default TotalReimbursed;
