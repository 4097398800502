import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ChevronDownIcon = props => {
  const { rotate, axa } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme, axa);
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="M15 5L9.41421 10.5858C8.63316 11.3668 7.36684 11.3668 6.58579 10.5858L1 5"
        stroke={config_icon.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronDownIcon;
