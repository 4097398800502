import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import styles from './AdditionalPassanger.module.scss';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../../../intl/idioms';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/ExtendedWarranty/consts';
import { getNationality } from '../../../../../../utils/formatInfoProfile';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';

const AdditionalPassangers = props => {
  const { claimDetails } = props;
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [additionalPassenger, setAdditionalPassenger] = useState([]);
  useEffect(() => {
    const additionalPassenger =
      (claimDetails && claimDetails.additional_passengers) || {};
    setAdditionalPassenger(additionalPassenger);
  }, [claimDetails]);

  const formatDate = date =>
    moment(date, 'YYYY-MM-DD').format(
      idiom === pt_BR ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
    );

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const getRelationshipToCardholder = additionalPassenger =>
    additionalPassenger && additionalPassenger.relationship
      ? cardholderRelationshipLabel[additionalPassenger.relationship]
      : '-';

  const getBirthDate = additionalPassenger =>
    additionalPassenger && additionalPassenger.birth_date
      ? formatDate(additionalPassenger.birth_date)
      : '-';

  const getFirstName = additionalPassenger =>
    additionalPassenger && additionalPassenger.first_name
      ? additionalPassenger.first_name
      : '-';
  const getLastName = additionalPassenger =>
    additionalPassenger && additionalPassenger.last_name
      ? additionalPassenger.last_name
      : '-';

  const nationality = additionalPassenger =>
    additionalPassenger &&
    additionalPassenger.nationalities &&
    additionalPassenger.nationalities[0]
      ? getNationality(additionalPassenger.nationalities[0], idiom)
      : '-';

  const getGender = (title, intl) => {
    switch (title) {
      case 'FM':
        return intl.LABEL_FEMALE;
      case 'ML':
        return intl.LABEL_MALE;
      case 'UNKNOWN':
        return intl.LABEL_UNKNOWN;
      default:
        return '';
    }
  };
  const gender = additionalPassenger =>
    additionalPassenger && additionalPassenger.gender
      ? getGender(additionalPassenger.gender, intl)
      : '-';
  return additionalPassenger.map((additional, index) => (
    <React.Fragment key={index}>
      <div className="col-12" data-testid="subtitleAdditionalPassengers">
        <h4 className={`${styles.itemSeparator} ${styles[themes]}`}>
          {intl.SUBTITLE_AREA(index + 1)}
        </h4>
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_NAME}
          value={`${getFirstName(additional)} ${getLastName(additional)}`}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_RELATIONSHIP_CARD_HOLDER}
          value={getRelationshipToCardholder(additional)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_GENDER} value={gender(additional)} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_BIRTH_DATE}
          value={getBirthDate(additional)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.NATIONALITY_LABEL}
          value={nationality(additional)}
        />
      </div>
    </React.Fragment>
  ));
};

export default AdditionalPassangers;
