/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Loading from '../../../../../components/Loading/Loading';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import { userServices } from '../../../../../services';
import DocumentUploadItem from './DocumentItem/DocumentUploadItem';
import styles from './PersonalDocumentsItem.module.scss';
import { formatCustomDate } from '../../../../../utils/date';
import { IntlContext } from '../../../../../intl';
import { isBR } from '../../../../../utils/country';
import { AppInsightTrackContext } from '../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const createListPersonalDocuments = (
  documents,
  idiom,
  country,
  trackEventUserAction
) => {
  trackEventUserAction(
    '#### (UUP FLOW - PROFILE) #### - VAI CRIAR A LISTA DE DOCUMENTOS DO UUP',
    { documents, idiom, country }
  );
  const document_nationalID = documents.find(
    document => document.document_type_name == 'National ID'
  );
  const document_proofOfResidency = documents.find(
    document => document.document_type_name == 'Proof of Residency'
  );

  trackEventUserAction('#### (UUP FLOW - PROFILE) #### - LISTA DE DOCUMENTOS', {
    document_nationalID,
    document_proofOfResidency,
    idiom,
    country,
  });

  const listAttachments = {
    attachments: [
      {
        document_type_name: document_nationalID
          ? document_nationalID.document_type_name
          : 'National ID',
        document_name: document_nationalID
          ? document_nationalID.document_name
          : '',
        attachment_id: document_nationalID
          ? document_nationalID.attachment_id
          : '',
        document_status: document_nationalID
          ? document_nationalID.document_status
          : 'PENDING',
        document_attached_at: document_nationalID
          ? formatCustomDate(
              document_nationalID.document_attached_at,
              null,
              idiom
            )
          : '',
        document_valid_until: document_nationalID
          ? formatCustomDate(
              document_nationalID.document_valid_until,
              null,
              idiom
            )
          : '',
      },
    ],
  };

  if (isBR(country)) {
    listAttachments.attachments.push({
      document_type_name: document_proofOfResidency
        ? document_proofOfResidency.document_type_name
        : 'Proof of Residency',
      document_name: document_proofOfResidency
        ? document_proofOfResidency.document_name
        : '',
      attachment_id: document_proofOfResidency
        ? document_proofOfResidency.attachment_id
        : '',
      document_status: document_proofOfResidency
        ? document_proofOfResidency.document_status
        : 'PENDING',
      document_attached_at: document_proofOfResidency
        ? formatCustomDate(
            document_proofOfResidency.document_attached_at,
            null,
            idiom
          )
        : '',
      document_valid_until: document_proofOfResidency
        ? formatCustomDate(
            document_proofOfResidency.document_valid_until,
            null,
            idiom
          )
        : '',
    });
  }

  trackEventUserAction(
    '#### (UUP FLOW - PROFILE) #### - LISTA DE DOCUMENTOS CRIADA',
    {
      listAttachments,
      idiom,
      country,
    }
  );
  return listAttachments;
};

const listExpectedDocuments = ({ attachments }) => {
  if (!attachments) return;
  return attachments.map((document, index) => (
    <DocumentUploadItem
      documentName={document.document_name}
      documentID={document.attachment_id}
      documentTypeName={document.document_type_name}
      documentStatus={document.document_status}
      documentAttachedDate={document.document_attached_at}
      documentValidUntil={document.document_valid_until}
      key={`document-${index}`}
    />
  ));
};

const PersonalDocumentsItem = () => {
  const { utils } = useContext(StateContext);
  const { idiom } = useContext(IntlContext);
  const { country } = useContext(IntlContext);
  const [loading, setLoading] = useState(true);
  const [documentList, setDocumentsList] = useState([]);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  useEffect(() => {
    const getPersonalDocuments = async () => {
      try {
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - VAI PEGAR OS ANEXOS DO USUÁRIO',
          true
        );
        const {
          data: { attachments },
        } = await userServices.getCHInfo(utils.getCn());
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - ANEXOS DO USUÁRIO',
          {
            attachments,
            idiom,
            country,
          }
        );
        const listPersonalDocuments = createListPersonalDocuments(
          attachments,
          idiom,
          country,
          trackEventUserAction
        );
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - LISTA DE DOCUMENTOS',
          {
            listPersonalDocuments,
            idiom,
            country,
          }
        );
        setDocumentsList(listPersonalDocuments);
        setLoading(false);
      } catch (error) {
        trackEventUserAction(
          `#### (UUP FLOW - PROFILE) ERRO PARA CRIAR A LISTA DE DOCUMENTOS `,
          {
            error,
            error_response: error && error.response ? error.response : error,
          }
        );
      }
    };

    getPersonalDocuments();
  }, []);

  return loading ? (
    <div className={styles.loadingWrapper}>
      <Loading />
    </div>
  ) : (
    listExpectedDocuments(documentList)
  );
};

export default PersonalDocumentsItem;
