/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntlContext } from '../../../../../../intl';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import styles from './DocumentUploadItem.module.scss';
import Button from '../../../../../../components/Button/Button';
import LoadingDots from '../../../../../../components/LoadingDots/LoadingDots';
import StatusLabel from '../../../../../../components/StatusLabel/StatusLabel';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import { extensions } from '../../../../../../utils/fileExtensionsValidade';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import { cmxService } from '../../../../../../services';
import DownloadIcon from '../../../../../../assets/icons/DownloadIcon';
import ModalWithImage from '../../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../../components/ModalResult/ModalResult';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const DocumentUploadItem = ({
  documentName,
  documentID,
  documentTypeName,
  documentStatus,
  documentAttachedDate,
  documentValidUntil,
}) => {
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [loading, setLoading] = useState(true);
  const [attachmentID, setAttachmentID] = useState(documentID);
  const [statusUpload, setStatusUpload] = useState(documentStatus);
  const [attachName, setAttachName] = useState(documentName);
  const [validUntil, setValidUntil] = useState(documentValidUntil);
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const fileReference = useRef();
  const attached = statusUpload !== 'PENDING';
  const { actions, utils } = useContext(StateContext);
  const { modal } = actions;
  const cn = utils.getCn();
  const documentDescription =
    documentTypeName === 'National ID'
      ? intl.DESCRIPTION_NATIONAL_ID
      : intl.DESCRIPTION_PROOF_OF_RESIDENCY;

  useEffect(() => {
    trackEventUserAction('#### (UUP FLOW - PROFILE) #### - DOCUMENTO ', {
      document_name: documentName,
      document_valid_until: documentValidUntil,
      document_attached_date: documentAttachedDate,
      document_type_name: documentTypeName,
      document_id: documentID,
    });
    const checkDocumentStatus =
      documentStatus === 'PENDING' && documentName ? 'REVIEW' : documentStatus;
    setStatusUpload(checkDocumentStatus);
  }, []);

  const getFileData = async () => {
    setLoading('Loading');
    const file = fileReference.current.files[0];
    const { name: document_name, type: content_type, size } = file;
    trackEventUserAction(
      '#### (UUP FLOW - PROFILE) #### - INICIOU O UPLOAD DO ARQUIVO ',
      {
        document_name,
        content_type,
        size,
      }
    );
    const reader = new FileReader();
    reader.readAsDataURL(file);
    await new Promise(resolve => {
      reader.onload = () => resolve();
    });
    const content_data = reader.result.substr(reader.result.indexOf(',') + 1);

    const sizeKbFile = parseFloat(size / 1024).toFixed(2);
    const sizeMBFile = parseFloat(sizeKbFile / 1024).toFixed(2);
    //check size minor or equall to 1.5MB

    if (sizeKbFile > 1536) {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - TAMANHO DO ARQUIVO MAIOR DO QUE O PERMITIDO ',
        {
          document_name,
          content_type,
          sizeKbFile,
        }
      );
      setLoading('Loading finished');

      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_SIZE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return;
    }

    if (!content_type || extensions.indexOf(content_type) < 0) {
      setLoading('Loading finished');
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - FORMATO DO ARQUIVO NÃO PERMITIDO ',
        {
          document_name,
          content_type,
        }
      );
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_TYPE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return;
    }

    const fileData = {
      content_data,
      content_type,
    };

    trackEventUserAction(
      '#### (UUP FLOW - PROFILE) #### - ARQUIVO SERÁ ENVIADO PARA O CMX ',
      {
        fileData,
      }
    );

    try {
      const responseCMX = await cmxService.uploadDocument(fileData);
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - ARQUIVO SALVO NO CMX ',
        {
          dataCMX: responseCMX && responseCMX.data ? responseCMX.data : null,
        }
      );
      const data = {
        document_type_name: documentTypeName,
        document_name: document_name,
        document_description: documentTypeName,
        external_attachment_id: responseCMX.data,
        content_type: content_type,
      };
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - INFORMAÇÃOS DO ARQUIVO SERÃO ENVIADAS PARA O SALESFORCE ',
        {
          data,
        }
      );
      setAttachName(document_name);
      const {
        data: { attachment_id },
      } = await cmxService.saveIdCMX(cn, data, idiomForApi());
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - INFORMAÇÕES SALVAS NO SALESFORCE ',
        {
          attachment_id,
        }
      );
      setAttachmentID(attachment_id);
      setStatusUpload('REVIEW');
      setLoading('Loading finished');
      fileReference.current.value = null;
    } catch (error) {
      fileReference.current.value = null;
      setLoading('Loading finished');
      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description &&
        error.response.data.error_description.startsWith(
          'ContentType_is_invalid_Please_verify'
        )
      ) {
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - ERROR CONTENT TYPE INVALIDO ',
          {
            error,
            error_response: error.response,
          }
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_TYPE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else if (error.response && error.response.status === 413) {
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - ERROR TAMANHO DO ARQUIVO INVALIDO ',
          {
            error,
            error_response: error.response,
          }
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_SIZE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else {
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - ERROR NO UPLOAD DO ARQUIVO  ',
          {
            error,
            error_response: error && error.response ? error.response : null,
          }
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.UNEXPECTED_ERROR_OCCURRED}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      }
    }
  };

  const removeFile = async () => {
    try {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - USUÁRIO VAI REMOVER O ARQUIVO  ',
        {
          attachmentID,
        }
      );
      setLoading('Loading');
      actions.modal.closeModal();
      await cmxService.deleteDocument(cn, attachmentID, idiomForApi());
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - ARQUIVO REMOVIDO',
        true
      );
      setAttachmentID('');
      setLoading('Loading finished');
      setStatusUpload('PENDING');
      setAttachName('');
      setValidUntil('');
    } catch (error) {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - ERROR AO REMOVER AQUIVO',
        {
          error,
          error_response: error && error.response ? error.response : error,
        }
      );
    }
  };

  const confirmRemoveFile = () => {
    trackEventUserAction(
      '#### (UUP FLOW - PROFILE) #### - ABRIU O MODAL PARA CONFIRMAR A REMOÇÃO DO ARQUIVO',
      true
    );
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.DELETE_DOCUMENT_TITLE}
        subtitle={intl.DELETE_DOCUMENT_SUBTITLE}
      >
        <ModalResult
          textConfirm={intl.BTN_REMOVE_DOCUMENT}
          clickConfirm={removeFile}
          textDeny={intl.TEXT_NO_KEEP_DOCUMENT}
          clickDeny={() => {
            actions.modal.closeModal();
          }}
          buttonConfirmType="borderRed"
        />
      </ModalWithImage>,
      true,
      false
    );
  };

  const uploadFile = () => {
    trackEventUserAction(
      '#### (UUP FLOW - PROFILE) #### - USUÁRIO APERTOU NO BOTÃO DE UPLOAD DO ARQUIVO',
      true
    );
    fileReference.current.click();
  };

  const downloadFile = async () => {
    try {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - VAI FAZER O DOWNLOAD DO ARQUIVO',
        true
      );
      const response = await cmxService.downloadFile(cn, attachmentID);

      var link = document.createElement('a');

      link.href = `data:application/octet-stream;base64, ${response.data.file}`;
      link.download = response.data.document_name;
      link.click();
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - DOWNLOAD CONCLUIDO',
        true
      );
    } catch (error) {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - ERRO AO TENTAR FAZER O DOWNLOAD',
        {
          error,
          error_reponse: error && error.response ? error.response : error,
        }
      );
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED_DOWNLOAD}
        />
      );
    }
  };

  return (
    <div className={styles.containerItem}>
      <span className={styles.textDocument}>{documentDescription}</span>
      {attachmentID ? (
        <div className={styles.infoDocuments}>
          <div className={`${styles[theme]} ${styles.infoDocumentsLink}`}>
            <div className={`${styles.attachName}`}>{attachName}</div>
            <span className={styles.buttonDownload} onClick={downloadFile}>
              {attachmentID && <DownloadIcon width={24} height={24} />}
            </span>
          </div>
          {documentAttachedDate && (
            <div className={styles.infoDocumentsDate}>
              <span>
                {intl.TEXT_POSTED_ON} {documentAttachedDate}
              </span>
            </div>
          )}

          {validUntil && documentStatus === 'ACCEPTED' && (
            <div className={styles.infoDocumentsDate}>
              <span>
                {intl.TEXT_VALID_UNTIL} {validUntil}
              </span>
            </div>
          )}
        </div>
      ) : null}

      <div className={styles.upload} data-testid="requiredLabel">
        <div className={styles.hideInput}>
          <input
            type="file"
            ref={fileReference}
            data-testid={`inputUpload-required`}
            onChange={getFileData}
          />
        </div>
        <Button
          id="actionButton"
          type="borderBlue"
          width="176px"
          height="53px"
          onClick={attached ? confirmRemoveFile : uploadFile}
          className={`buttonActive ${styles.widthButtonUpload} ${
            attached ? styles.backGroundBlue : ''
          }`}
          disabled={loading === 'Loading'}
        >
          {loading === 'Loading' ? (
            <>
              {attached ? intl.REMOVING : intl.LOADING} <LoadingDots />
            </>
          ) : (
            <>{attached ? intl.REMOVE : intl.ADD}</>
          )}
        </Button>

        <hr className={styles[theme]} />
        <div className={styles.statusArea}>
          <StatusLabel status={statusUpload} documentProfile />
        </div>
      </div>
    </div>
  );
};

export default DocumentUploadItem;
