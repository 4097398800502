import React, { useState, useEffect, useContext } from 'react';

import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { claimsServices, productsAndBenefitsServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import styles from './ProfileClaims.module.scss';
import ListClaimByStatus from './ListClaimByStatus/ListClaimByStatus';
import {
  review,
  pendingSend,
  payment,
  rejected,
  closed,
} from './StatusClaimBySubStatus';
import { matchClaimsAndBenefits } from '../../PrivateClaims/config_claim';
import TotalReimbursed from '../../../components/TotalReimbursed/TotalReimbursed';
import { CLAIMS } from '../../../utils/consts';
import { REACT_APP_TOTAL_REIMBURSEMENT } from '../../../utils/environments_variables';

let dataClaims = intl => [
  {
    status: 'Pending Send',
    substatus: pendingSend,
    title: intl.TITLE_PENDING,
    subtitle: intl.SUBTITLE_PENDING,
    messageEmpty: intl.TEXT_MSG_EMPTY_PENDING,
    id: 'pending',
    claims: [],
  },
  {
    status: 'Opened',
    substatus: review,
    title: intl.TITLE_REVIEW,
    subtitle: intl.SUBTITLE_REVIEW,
    messageEmpty: intl.TEXT_MSG_EMPTY_REVIEW,
    id: 'review',
    claims: [],
  },
  {
    status: 'Closed',
    substatus: payment,
    title: intl.TITLE_PAID,
    subtitle: intl.SUBTITLE_PAID,
    messageEmpty: intl.TEXT_MSG_EMPTY_PAID,
    id: 'paid',
    claims: [],
  },
  {
    status: 'Closed',
    substatus: rejected,
    title: intl.TITLE_REJECTED,
    subtitle: intl.SUBTITLE_REJECTED,
    messageEmpty: intl.TEXT_MSG_EMPTY_REJECTED,
    id: 'rejected',
    claims: [],
  },
  {
    status: 'Closed',
    substatus: closed,
    title: intl.TITLE_CLOSED,
    subtitle: intl.SUBTITLE_CLOSED,
    messageEmpty: intl.TEXT_MSG_EMPTY_CLOSED,
    id: 'closed',
    claims: [],
  },
];

const Profile = () => {
  const { translate, idiomForApi, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const [formatedData, setFormatedData] = useState(dataClaims(intl));
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();

  const filterClaimsData = claims => {
    let data =
      claims &&
      formatedData.map(dataClaim => {
        dataClaim.claims = claims.filter(_value =>
          dataClaim.status === 'Pending Send'
            ? _value.status === 'Pending Send'
            : _value.statuses &&
              _value.statuses.sub_status &&
              _value.statuses.sub_status.name &&
              dataClaim.substatus.includes(_value.statuses.sub_status.name) &&
              _value.status !== 'Pending Send'
        );
        return dataClaim;
      });
    return data;
  };

  useEffect(() => {
    const getGenerated = async () => {
      try {
        const userClaims = await claimsServices.getClaims(
          cn,
          binOrPan,
          idiomForApi()
        );
        const { data: benefits } =
          await productsAndBenefitsServices.getBenefitsByProduct(
            binOrPan,
            idiomForApi(),
            false,
            false
          );

        const mergeClaimsAndBenefits = await matchClaimsAndBenefits(
          userClaims.data,
          benefits,
          binOrPan,
          idiomForApi()
        );
        setBenefits(mergeClaimsAndBenefits);
        setFormatedData(filterClaimsData(userClaims.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    getGenerated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binOrPan, idiom]);

  const generateStatusArea = () => {
    return formatedData.map((value, index) => {
      return (
        <ListClaimByStatus
          key={index}
          value={value}
          intl={intl}
          benefits={benefits}
          lastItem={'Claims - Case Closed'}
          loading={loading}
          siteSection={'Profile'}
        />
      );
    });
  };
  return (
    <div className={styles.container}>
      {/* commented for refund rules review */}
      <div className={styles.contentAllClaims}>{generateStatusArea()}</div>
    </div>
  );
};

export default Profile;
