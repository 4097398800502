import React, { useContext } from 'react';
import styles from './SpecialBenefits.module.scss';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import imgBackground from '../../assets/images/offers/bannerOffersAndPromotions.png';
import { Link } from 'react-router-dom';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import arrowRight_afluent from '../../assets/images/icon/arrow-right-figma-afluent.svg';
import arrowRight_master from '../../assets/images/icon/arrow-right-figma-master.svg';
import ListCards from '../../components/PublicListCards/PublicListCards';
import { PublicContext } from '../../components/PublicContextParent/PublicContextParent';
import ListBenefits from './ListBenefits/ListBenefits';
import UpgradeMe from '../../components/UpgradeMe/UpgradeMe';
import KnowBenefits from '../../components/Sections/KnowBenefits/KnowBenefits';
import ArrowRightIcon from '../../assets/icons/ArrowRight';

const SpecialBenefits = () => {
  const { utils } = useContext(StateContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { productActive, setProductActive } = useContext(PublicContext);
  const isLogged = utils.isUserLogged();
  const card = utils.getSelectedCard();
  const theme = getGlobalTheme();
  const arrow_right = isAfluent() ? arrowRight_afluent : arrowRight_master;
  const cardSelected = isLogged ? card : productActive;
  const changeCardSelected = isLogged ? undefined : setProductActive;
  const productName = card ? card.name : productActive.name;

  const publicBanner = (
    <div
      className={`${styles.publicBanner} ${styles[theme]}`}
      style={{
        backgroundImage: `linear-gradient(180deg, #00000000 0%, #000000b3 71%, #000000f2 100%), url(${imgBackground})`,
      }}
    >
      <h1>{intl.TEXT_TITLE}</h1>
      <p>{intl.TEXT_SUBTITLE}</p>
      <div>
        <Link to="/your-subscription-benefits" className={styles[theme]}>
          {intl.TEXT_LOGIN}
        </Link>
        <ArrowRightIcon width={20} height={20} />
      </div>
    </div>
  );

  const privateBanner = (
    <div className={`${styles.privateBanner} ${styles[theme]}`}>
      <h1>{intl.TEXT_TITLE_PRIVATE(productName)}</h1>
      <p>{intl.TEXT_SUBTITLE_PRIVATE}</p>
    </div>
  );
  return (
    <div className={styles.container}>
      {isLogged ? privateBanner : publicBanner}
      <div className={styles.content}>
        <h2 id="mainContent" className={`${styles.title} ${styles[theme]}`}>
          {intl.TEXT_SPECIAL_BENEFITS(productName)}
        </h2>
        {!isLogged && (
          <div className={styles.cardsArea}>
            <ListCards
              cardSelected={cardSelected}
              setCardSelected={changeCardSelected}
            />
          </div>
        )}
        <ListBenefits cardSelected={cardSelected} />
      </div>
      {isLogged ? (
        <div className={styles.upgradeMeContainer}>
          <UpgradeMe />
        </div>
      ) : (
        <KnowBenefits />
      )}
    </div>
  );
};

export default SpecialBenefits;
