import Button from '../Button/Button';
import { withRouter } from 'react-router-dom';
import styles from './ButtonWithIcon.module.scss';
import * as translations from './intl';
import { useContext } from 'react';
import { IntlContext } from '../../intl';
const ButtonLogin = props => {
  const {
    type,
    width,
    height,
    value,
    onClick,
    img,
    textAlign,
    dataTestid,
    isSVGComponent,
    disabled,
    id,
  } = props;
  const { idiomForApi, translate } = useContext(IntlContext);

  const intl = translate(translations);
  let className = '';
  switch (textAlign) {
    case 'left': {
      className = styles.alignLeft;
      break;
    }
    case 'right': {
      className = styles.alignRight;
      break;
    }
    case 'center': {
      className = styles.alignCenter;
      break;
    }
    default: {
      className = styles.alignLeft;
    }
  }

  return (
    <Button
      id={id}
      type={type}
      width={width}
      height={height}
      onClick={onClick}
      css={`
        ${styles.button} ${className}
      `}
      dataTestid={dataTestid}
      disabled={disabled}
      typeHtml="button"
    >
      {isSVGComponent ? img : <img src={img} alt={intl.ICON} />} {value}
    </Button>
  );
};

export default withRouter(ButtonLogin);
