export const LABEL_BANK_COUNTRY = {
  en_US: 'Country',
  pt_BR: 'País',
  es_ES: 'País',
};

export const LABEL_BANK_NAME = {
  en_US: 'Bank name',
  pt_BR: 'Nome do banco',
  es_ES: 'Nombre del banco',
};

export const LABEL_ACCOUNT_TYPE = {
  en_US: 'Account type',
  pt_BR: 'Tipo de conta',
  es_ES: 'Tipo de cuenta',
};

export const LABEL_ACCOUNT_NUMBER = {
  en_US: 'Account number',
  pt_BR: 'Número da conta',
  es_ES: 'Número de la cuenta',
};

export const LABEL_SWIFT_CODE = {
  en_US: 'SWIFT code',
  pt_BR: 'Código SWIFT',
  es_ES: 'Código SWIFT',
};

export const LABEL_FULL_KEY = {
  en_US: 'Full key code',
  pt_BR: 'Código full key',
  es_ES: 'Código CLABE COMPLETA',
};

export const LABEL_CUIT = {
  en_US: 'CUIT/CUIL',
  pt_BR: '',
  es_ES: 'CUIT/CUIL',
};

export const LABEL_ABA = {
  en_US: 'ABA / Routing number',
  pt_BR: 'Número ABA',
  es_ES: 'Número ABA',
};

export const LABEL_IBAN = {
  en_US: 'IBAN code',
  pt_BR: 'Código IBAN',
  es_ES: 'Código IBAN',
};

export const LABEL_ACCOUNT_TYPE_CHECKING = {
  en_US: 'Checking account',
  pt_BR: 'Conta corrente',
  es_ES: 'Cuenta corriente',
};

export const LABEL_ACCOUNT_TYPE_SAVING = {
  en_US: 'Saving',
  pt_BR: 'Poupança',
  es_ES: 'Ahorro',
};

export const TITLE_BANK_INFORMATION = {
  en_US: 'Bank information',
  es_ES: 'Información bancaria',
  pt_BR: 'Informações bancárias',
};

export const CARD = {
  en_US: 'Card',
  pt_BR: 'Cartão',
  es_ES: 'Tarjeta',
};

export const EXPIRATION_DATE = {
  en_US: 'Expiration date',
  es_ES: 'Fecha de caducidad',
  pt_BR: 'Data de validade',
};

export const CARD_DELETED = {
  en_US: `You've removed this card`,
  es_ES: 'Has eliminado esta tarjeta',
  pt_BR: 'Você removeu este cartão',
};
