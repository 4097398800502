import { formatCustomDate } from '../../../../utils/date';

export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  es_ES: 'Aceptación  de términos y condiciones',
  pt_BR: 'Aceitação dos termos e condições',
};

export const TERMS_AND_CONDITIONS = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const ACCEPT_TERMS = {
  en_US: '',
  es_ES: 'He leido y acepto los terminos y condiciones.',
  pt_BR: '',
};

export const ACCEPT_TERMS_VOUCHERS_PER_YEAR = {
  en_US: '',
  es_ES: 'Entiendo y acepto que puedo canjear hasta cinco vales por año.',
  pt_BR: '',
};

export const ACCEPT_TERMS_INFORM_CARD_EXPIRATION = {
  en_US: '',
  es_ES:
    'Soy consciente de que es necesario informar la fecha de vencimiento de la tarjeta para garantizar que la misma esté activa.',
  pt_BR: '',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};

export const CINEPOLIS = {
  en_US: '',
  es_ES: `<a href="https://prod-axa.cdn.prismic.io/prod-axa/Zo_aCx5LeNNTxCQ__Te%CC%81rminosyCondicionesBanCoppelTeinvitaalcine.pdf" target="_blank">BanCoppel te invita al cine</a>`,
  pt_BR: '',
};

export const PARAGRAPH_MESSAGE = {
  en_US: '',
  es_ES: (date, idiom, country) =>
    `Los términos y condiciones aquí publicados se transcriben como referencia en fecha ${formatCustomDate(
      date,
      'MM/DD/YYYY',
      idiom,
      country
    )}, y su versión oficial y vigente se podrá consultar en cualquier momento. La versión oficial y vigente de los Términos y Condiciones de la Promoción se encuentran disponibles para su consulta durante la vigencia de la misma en <strong><a href="https://www.bancoppel.com" target="_blank">www.bancoppel.com</a></strong> en la sección de <strong><a href="https://urldefense.com/v3/__https://www.bancoppel.com/descubre-mas/tarjeta-oro/?utm_source=888&utm_medium=BAN&utm_campaign=BC_TDCO_CB&utm_content=TDCOROMINIB_MAR24&utm_term=BR_OPM__;!!BnkV9pdh5V0!HRxwe-_vSRI32b_YlUMecu2SuGcL-8K8OCluk_FdZqFOtbpT6gqh_SPn0fjppQjH7_ngToWIn6RBhmMRPMkWIieYYTY8uw$" target="_blank">Tarjeta de Crédito BanCoppel Oro</a></strong>.`,
  pt_BR: '',
};
