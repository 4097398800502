export const getInitialValues = claim => {
  const { statuses } = claim;
  const lastStatus = statuses[statuses.length - 1];
  const lastSubstatus =
    lastStatus.sub_statuses[lastStatus.sub_statuses.length - 1];

  return {
    reason: lastSubstatus ? lastSubstatus.description : 'Unknown',
    description: lastSubstatus ? lastSubstatus.decision_details : '',
    comment: '',
  };
};
