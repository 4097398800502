/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import { ConsultationVideoType } from '../../../../../../utils/consultationCategory';
import InfoVideoArea from './InfoVideoArea/InfoVideoArea';
import ReasonArea from './ReasonArea';
import UploadFile from './UploadFile';
import validationSchema from './validationSchema';
import * as translations from './../intl';
import * as translations_GeneralInfo from '../../../../Generate/ClaimFillForm/Form/intl';
import { IntlContext } from '../../../../../../intl';
import { teleconsutationFormThreeValues } from './../initialValues';

const AppointmentArea = props => {
  const { updateParent, submitRef, parentValues } = props;

  const { translate } = useContext(IntlContext);
  const intl_teleconsultation = translate(translations);
  const intl_GeneralInfo = translate(translations_GeneralInfo);
  const intl = { ...intl_teleconsultation, ...intl_GeneralInfo };

  const validationSchemaData = useMemo(() => validationSchema(intl), [intl]);

  const initialValues = useMemo(
    () =>
      teleconsutationFormThreeValues({ type: parentValues.consultation.type }),
    [parentValues.consultation.type]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaData}
      onSubmit={() => {}}
      enableReinitialize
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchemaData);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              role="btnSubmit"
            ></button>
            <ReasonArea {...props} />
            <UploadFile {...props} />

            {parentValues.consultation.type === ConsultationVideoType && (
              <InfoVideoArea {...props} />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default AppointmentArea;
