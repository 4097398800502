import React, { useContext, useEffect } from 'react';
import styles from './MenuProfile.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import NavHashLink from '../NavHashLink/NavHashLink';
import { withRouter } from 'react-router-dom';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { ENABLE_UUP } from '../../utils/environments_variables';
import { showOCT } from '../../pages/Claims/Generate/ClaimFillForm/Form/PaymentInformation/OCTFlow/oct-const';
import { isIssuerDomain } from '../../utils/banks';

const checkHash = (menuId, history) => {
  switch (menuId) {
    case 'account':
      if (!window.location.hash) {
        history.push('/profile/account#contact');
      }
      break;
    case 'certificates':
      if (!window.location.hash) {
        history.push('/profile/certificates#active');
      }
      break;
    case 'claims':
      if (!window.location.hash) {
        history.push('/profile/claims#pending');
      }
      break;
  }
};

const MakeSubmenu = props => {
  const { menuId, showVoiceIdLink } = props;
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const ENABLE_OCT = showOCT(country);

  const isBmp = isIssuerDomain();

  const checkWindowWidth = () => {
    if (window.screen.width > 990) {
      checkHash(menuId, props.history);
    }
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  switch (menuId) {
    case 'account':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <NavHashLink
                subtract="300"
                to="#contact"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_ACCOUNT_CONTACT_DETAILS}
              </NavHashLink>
            </li>
            {ENABLE_UUP && (
              <li>
                <NavHashLink
                  to="#personalDocuments"
                  activeClassName={styles.submenuActive}
                  className={styles[themes]}
                >
                  {intl.SUBMENU_PERSONAL_DOCUMENTS}
                </NavHashLink>
              </li>
            )}

            <li>
              <NavHashLink
                to="#cards"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {isBmp
                  ? intl.SUBMENU_ACCOUNT_YOUR_CARD
                  : intl.SUBMENU_ACCOUNT_YOUR_CARDS}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#bank"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_ACCOUNT_YOUR_BANK}
              </NavHashLink>
            </li>
            {ENABLE_OCT && (
              <li>
                <NavHashLink
                  to="#oct"
                  activeClassName={styles.submenuActive}
                  className={styles[themes]}
                >
                  {intl.SUBMENU_OCT}
                </NavHashLink>
              </li>
            )}

            <li>
              <NavHashLink
                to="#dependents"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_ACCOUNT_YOUT_DEPENDENTS}
              </NavHashLink>
            </li>

            {showVoiceIdLink && (
              <li>
                <NavHashLink
                  to="#voiceID"
                  activeClassName={styles.submenuActive}
                  className={styles[themes]}
                >
                  {intl.SUBMENU_VOICE_ID}
                </NavHashLink>
              </li>
            )}
          </ul>
        </div>
      );
    case 'certificates':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <NavHashLink
                subtract="300"
                to="#active"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_CERTIFICATES_ACTIVE_CERTIFICATES}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#past"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_CERTIFICATES_PAST_CERTIFICATES}
              </NavHashLink>
            </li>
          </ul>
        </div>
      );
    case 'claims':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <NavHashLink
                subtract="300"
                to="#pending"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_CERTIFICATES_PEDING_DOCUMENTATION}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#review"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_CERTIFICATES_URDER_REVIEW}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#paid"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_CERTIFICATES_TO_BE_PAID}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#rejected"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_CERTIFICATES_REJECTED}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#closed"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_CERTIFICATES_CLOSED}
              </NavHashLink>
            </li>
          </ul>
        </div>
      );
    case 'notifications':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <NavHashLink
                subtract="300"
                to="#all"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_NOTIFICATIONS}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#archived"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_ARCHIVED}
              </NavHashLink>
            </li>
          </ul>
        </div>
      );
    case 'help':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <NavHashLink
                subtract="300"
                to="#contactHelp"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_HELP_CONTACT_DETALIS}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_HELP_CARDS}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_HELP_BANK_INFORMATION}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_HELP_DEPENDENTS}
              </NavHashLink>
            </li>
          </ul>
        </div>
      );
    case 'monetization':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <NavHashLink
                subtract="300"
                to="#certificates"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_SAVING_CERTIFICATES}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#claims"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_SAVING_CLAIMS}
              </NavHashLink>
            </li>
          </ul>
        </div>
      );
    case 'vouchers':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <NavHashLink
                subtract="300"
                to="#active"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_VOUCHERS_ACTIVE}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                to="#past"
                activeClassName={styles.submenuActive}
                className={styles[themes]}
              >
                {intl.SUBMENU_VOUCHERS_PAST}
              </NavHashLink>
            </li>
          </ul>
        </div>
      );
    default:
      return <></>;
  }
};

export default withRouter(MakeSubmenu);
