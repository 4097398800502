import React, { useContext, useState, useEffect } from 'react';
import styles from './FrequentlyQuestions.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { PublicContext } from '../../../components/PublicContextParent/PublicContextParent';
import FaqList from '../../../components/FaqList';
import { faqService } from '../../../services';
import Loading from '../../../components/Loading/Loading';

const FrequentlyQuestions = () => {
  const [filterSelectedId, setFilterSelectedId] = useState(
    new URLSearchParams(window.location.search).get('filter') || 'filterAll'
  );
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { productActive } = useContext(PublicContext);
  const [loading, setLoading] = useState(true);
  const [faq, setFaq] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      const { hash } = window.location;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }
    }, 1000);
  }, []);

  const fetchFaq = async () => {
    try {
      const { data } = await faqService.getFaq(idiomForApi());
      const formattedFaqs = data.map(faq => ({
        id: faq.question_id,
        question: faq.question_text,
        answer: faq.answer_text,
        category: faq.category,
      }));
      setFaq(formattedFaqs);
      setLoading(false);
    } catch (error) {
      // No-op: Faq tab won't be displayed
    }
  };

  useEffect(() => {
    fetchFaq();
  }, [productActive]);

  return (
    <div id="mainContent" className={styles.container}>
      <h2 className={`${styles.title} ${styles[themes]}`}>
        {intl.FREQUENTLY_QUESTIONS_TITLE}
      </h2>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <FaqList items={faq} filterSelectedId={filterSelectedId} />
      )}
    </div>
  );
};

export default FrequentlyQuestions;
