/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import styles from '../../Upload.module.scss';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import { useHistory } from 'react-router-dom';
import { IntlContext } from '../../../../../intl';
import FileItem from './FileItem/FileItem';
import { emergency_medical_assistance } from '../../../../../utils/claims_name';
import * as translations from '../intl';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import {
  APPEAL_ENABLE,
  NOTIFICATIONS_ENABLE,
  REACT_APP_UPLOAD_CONCLUDE_ENABLE,
} from '../../../../../utils/environments_variables';
import { NotificationContext } from '../../../../../components/NotificationContextParent/NotificationContextParent';

const createListDocumentUpload = (
  documents,
  claimInfo,
  claimId,
  addDocumentAttached,
  removeDocumentAttached,
  required,
  addNewInput,
  isFromProfile,
  alreadyAttached
) => {
  return documents.map((data, index) => {
    return (
      <FileItem
        key={index}
        id={index}
        documents={documents}
        document={data}
        claimInfo={claimInfo}
        claimId={claimId}
        addDocumentAttached={addDocumentAttached}
        removeDocumentAttached={removeDocumentAttached}
        isOptional={!required}
        newInput={addNewInput}
        isFromProfile={isFromProfile}
        alreadyAttached={alreadyAttached}
      />
    );
  });
};

export const redirectHome = (history, actions) => {
  history.push('/dashboard');
  actions.modal.closeModal();
};

export const redirectClaims = (history, actions) => {
  history.push('/profile/claims');
  actions.modal.closeModal();
};

const newOptionalDocument = (
  { document_type_name, document_description, is_multiple_upload_allowed },
  index
) => {
  return {
    is_mandatory: false,
    document_type_name: document_type_name + `_${index}`,
    document_description,
    is_multiple_upload_allowed,
  };
};

const optionalDocumentsWithAdditionals = (expectedDocuments, attachments) => {
  const optionals = expectedDocuments.filter(
    document =>
      document.is_mandatory === false &&
      document.document_type_name === 'Additional Documents'
  );
  if (optionals.length === 0) {
    return [];
  }

  const attacheds = attachments.filter(
    attach => attach.document_type_name === 'Additional Documents'
  );

  if (attacheds.length === 0) {
    return optionals;
  }

  const formatAttacheds = attacheds.map((attached, index) => ({
    document_type_name:
      index === 0
        ? attached.document_type_name
        : attached.document_type_name + `_${index + 1}`,
    is_multiple_upload_allowed: optionals[0].is_multiple_upload_allowed,
    is_mandatory: optionals[0].is_mandatory,
    document_description: optionals[0].document_description,
  }));

  return formatAttacheds;
};

const ListFiles = props => {
  const {
    data,
    claimId,
    isFromProfile,
    setDisableSubmit,
    concludeUpload,
    textModalSubtitle,
  } = props;
  const { expectedDocuments, claimInfo } = data;
  const { attachments, statuses } = claimInfo;
  const LIMIT_ADDITIONAL_DOCUMENT =
    data &&
    data.claimInfo &&
    data.claimInfo.benefit_external_reference === emergency_medical_assistance
      ? 10
      : 5;
  const [alreadyAttached, setAlreadyAttached] = useState(attachments);
  const [optionalDocuments, setOptionalDocuments] = useState(
    expectedDocuments.filter(
      document =>
        document.is_mandatory === false &&
        document.document_type_name !== 'Additional Documents'
    )
  );
  const [additionalDocuments, setAdditionalDocuments] = useState(
    optionalDocumentsWithAdditionals(expectedDocuments, attachments)
  );
  const [showedAppealedModalOnLoad, setShowedAppealedModalOnLoad] =
    useState(false);

  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const intl = translate(translations);
  const history = useHistory();

  const requiredDocuments = expectedDocuments.filter(
    document => document.is_mandatory === true
  );

  const addDocumentAttached = newDocumentAttached => {
    const findDocument = expectedDocuments.find(expectDocument =>
      newDocumentAttached.document_type_name.includes(
        expectDocument.document_type_name
      )
    );

    if (
      findDocument &&
      findDocument.is_multiple_upload_allowed &&
      additionalDocuments.length < LIMIT_ADDITIONAL_DOCUMENT
    ) {
      const newDocument = newOptionalDocument(
        findDocument,
        additionalDocuments.length + 1
      );

      setAdditionalDocuments(optionalDocuments => [
        ...optionalDocuments,
        newDocument,
      ]);
    }

    setAlreadyAttached(alreadyAttached => [
      ...alreadyAttached,
      newDocumentAttached,
    ]);
  };

  const removeDocumentAttached = (document_type_name, attachment_id) => {
    const indexRemoveDocumentOptional = additionalDocuments.findIndex(
      document => document.document_type_name === document_type_name
    );
    if (
      indexRemoveDocumentOptional !== -1 &&
      additionalDocuments.length !== 1
    ) {
      setAdditionalDocuments(optionalDocuments => {
        const cloneOptionalDocuments = [...optionalDocuments];
        cloneOptionalDocuments.splice(indexRemoveDocumentOptional, 1);

        if (optionalDocuments.length === LIMIT_ADDITIONAL_DOCUMENT) {
          cloneOptionalDocuments.push(
            newOptionalDocument(
              optionalDocuments[0],
              optionalDocuments.length + 1
            )
          );
        }

        return cloneOptionalDocuments.map((optionalDocument, index) => {
          const ajustedTypeName =
            optionalDocument.document_type_name.split('_');
          return {
            ...optionalDocument,
            document_type_name:
              index === 0
                ? ajustedTypeName[0]
                : `${ajustedTypeName[0]}_${index + 1}`,
          };
        });
      });
    }

    const indexRemoveDocumentAttached = alreadyAttached.findIndex(
      document => document.attachment_id === attachment_id
    );
    if (indexRemoveDocumentAttached === -1) {
      return;
    }
    setAlreadyAttached(alreadyAttached => {
      const cloneAlreadyAttached = [...alreadyAttached];
      cloneAlreadyAttached.splice(indexRemoveDocumentAttached, 1);
      return cloneAlreadyAttached;
    });
  };

  const listRequiredDocumentUpload = createListDocumentUpload(
    requiredDocuments,
    claimInfo,
    claimId,
    addDocumentAttached,
    removeDocumentAttached,
    true,
    () => {},
    isFromProfile,
    alreadyAttached
  );
  const listOptionalDocumentUpload = createListDocumentUpload(
    optionalDocuments,
    claimInfo,
    claimId,
    addDocumentAttached,
    removeDocumentAttached,
    false,
    () => {},
    isFromProfile,
    alreadyAttached
  );
  const listAdditionalDocumentUpload = createListDocumentUpload(
    additionalDocuments,
    claimInfo,
    claimId,
    addDocumentAttached,
    removeDocumentAttached,
    false,
    () => {},
    isFromProfile,
    alreadyAttached
  );

  const checkComplete = () => {
    for (const requiredDoc of requiredDocuments) {
      const find = alreadyAttached.find(
        alreadyAttach =>
          alreadyAttach.document_type_name === requiredDoc.document_type_name
      );
      if (!find) {
        return false;
      }
    }
    return true;
  };

  const getIsAppealed = () => {
    try {
      const lastStatus = statuses[statuses.length - 1];
      const { name, description } =
        lastStatus.sub_statuses[lastStatus.sub_statuses.length - 1];
      return name === 'Claims - Case appealed' && description
        ? [true, description]
        : [false, ''];
    } catch (error) {
      return [false, ''];
    }
  };

  useEffect(() => {
    if (REACT_APP_UPLOAD_CONCLUDE_ENABLE) {
      const [isAppealed, appealReason] = getIsAppealed();
      let showAppealModal =
        APPEAL_ENABLE && isAppealed && !showedAppealedModalOnLoad;

      if (showAppealModal) {
        setShowedAppealedModalOnLoad(showAppealModal);
        actions.modal.showModal(
          false,
          false,
          <ModalInformation
            type="success"
            message={intl.TITLE_APPEALED_MODAL}
            subtitle={intl.SUBTITLE_APPEALED_MODAL(appealReason)}
            textBtn={intl.BTN_ATTACH_DOCUMENTS}
          />,
          true
        );
      }

      if (checkComplete()) {
        setDisableSubmit(false);
        if (!showAppealModal) {
          actions.modal.showModal(
            false,
            false,
            <ModalInformation
              type="success"
              message={intl.CAN_SENT}
              subtitle={intl.IMPORTANT_COMPLETE_ATTACHMENTS}
              textBtn={intl.BTN_CONCLUDE}
              clickBtn={concludeUpload}
              textBtnDeny={intl.BTN_ATTACH_DOCUMENTS}
            />,
            true
          );
        }
      } else {
        setDisableSubmit(true);
      }
    } else {
      if (checkComplete()) {
        actions.modal.showModal(
          false,
          false,
          <ModalInformation
            type="success"
            message={intl.HAS_BEEN_SENT}
            subtitle={textModalSubtitle}
            textBtn={intl.GO_HOME}
            clickBtn={() => redirectHome(history, actions)}
            textBtnDeny={intl.YOUR_CLAIMS}
            clickBtnDeny={() => redirectClaims(history, actions)}
          />,
          true
        );
        if (NOTIFICATIONS_ENABLE) {
          notificationActions.bellNotification.updateBellNotification();
        }
      }
    }
  }, [alreadyAttached]);

  useEffect(() => {
    if (
      additionalDocuments &&
      additionalDocuments.length > 0 &&
      additionalDocuments.length < LIMIT_ADDITIONAL_DOCUMENT &&
      additionalDocuments[0].is_multiple_upload_allowed
    ) {
      const attacheds = attachments.filter(
        attach =>
          attach.document_type_name ===
          additionalDocuments[0].document_type_name
      );

      if (attacheds.length > 0) {
        const newDocument = newOptionalDocument(
          additionalDocuments[0],
          additionalDocuments.length + 1
        );
        setAdditionalDocuments(optionalDocuments => [
          ...optionalDocuments,
          newDocument,
        ]);
      }
    }
  }, []);

  return (
    <div className={styles.filerListUpload}>
      <ul>
        {listAdditionalDocumentUpload}
        {listOptionalDocumentUpload}
        {listRequiredDocumentUpload}
      </ul>
    </div>
  );
};

export default ListFiles;
