import { Formik } from 'formik';
import React, { useContext, useRef } from 'react';
import { IntlContext } from '../../../intl';
import Button from '../../Button/Button';
import CheckBox from '../../CheckBox/CheckBox';
import Select from '../../Select/Select';
import { StateContext } from '../../StateContextParent/StateContextParent';
import * as translations from './intl';
import styles from './index.module.scss';
import { validationCancelClaim } from './validationSchema';
import ModalWithImage from '../../ModalWithImage/ModalWithImage';
import { claimsServices } from '../../../services';
import ModalInformation from '../../ModalInformation/ModalInformation';

const CancelClaimButton = ({ claimId, number }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { actions } = useContext(StateContext);

  const handleSubmit = async values => {
    try {
      actions.modal.showLoading(intl.TITLE_LOADING);
      await claimsServices.cancelByClaimId(claimId, {
        description: values.reason,
      });
      openModalSuccess();
    } catch (error) {
      const isAlreadyCancelled =
        error.response &&
        error.response.data &&
        error.response.data.error_description &&
        error.response.data.error_description
          .toLowerCase()
          .includes('this claim is already cancelled');
      openModalError(isAlreadyCancelled);
    }
  };

  const openModalSuccess = () =>
    actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="success"
        message={intl.TITLE_CANCEL_CLAIM_SUCCESS}
        clickBtn={() => window.location.reload()}
      />,
      true,
      true
    );

  const openModalError = isAlreadyCancelled =>
    actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="error"
        message={
          isAlreadyCancelled
            ? intl.TITLE_ERROR_ALREADY_CANCELLED
            : intl.TEXT_ERROR_OCCURRED
        }
        subtitle={
          isAlreadyCancelled ? intl.SUBTITLE_ERROR_ALREADY_CANCELLED : ''
        }
      />,
      true
    );

  const showModalCancelClaim = () => {
    const content = () => (
      <Formik
        initialValues={{ consent: false, reason: '' }}
        validationSchema={validationCancelClaim(intl)}
        onSubmit={handleSubmit}
      >
        {props => {
          const { values, errors, touched, setFieldValue, submitForm } = props;

          return (
            <div className={styles.content}>
              <CheckBox
                name="consent"
                value={values.consent}
                onClick={value => setFieldValue('consent', value)}
                error={errors.consent}
                touched={touched.consent}
              >
                {intl.LABEL_CONSENT(number)}
              </CheckBox>
              <div className={styles.reason_select}>
                <Select
                  name="reason"
                  label={intl.LABEL_REASON}
                  value={values.reason}
                  formikProps={props}
                >
                  <option value="">{intl.LABEL_REASON}</option>
                  <option value="MISTAKEN_INAPPROPRIATE">
                    {intl.OPTION_MISTAKEN_INAPPROPRIATE}
                  </option>
                  <option value="DUPLICATE">{intl.OPTION_DUPLICATE}</option>
                  <option value="REPENTANT_REGRETFULL">
                    {intl.OPTION_REPENTANT_REGRETFULL}
                  </option>
                </Select>
              </div>
              <Button
                type="borderRed"
                id={`confirm-cancel-${claimId}`}
                css={styles.modal_button}
                onClick={submitForm}
              >
                {intl.BTN_CONFIRM_CANCEL_CLAIM}
              </Button>
              <Button
                id={`keep-claim-${claimId}`}
                css={styles.modal_button}
                type="blueWithoutBorder"
                onClick={actions.modal.closeModal}
              >
                {intl.BTN_KEEP_CLAIM}
              </Button>
            </div>
          );
        }}
      </Formik>
    );

    return actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.TITLE_CANCEL_CLAIM(number)}
        subtitle={intl.SUBTITLE_CANCEL_CLAIM}
        content={content()}
      />,
      true,
      false
    );
  };

  return (
    <span>
      <Button
        id={`cancel-${claimId}`}
        type="blueWithoutBorder"
        onClick={showModalCancelClaim}
      >
        {intl.BTN_CANCEL_CLAIM}
      </Button>
    </span>
  );
};

export default CancelClaimButton;
