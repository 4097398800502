import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const CameraEnableIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M10.11 9.599c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2Zm0 6a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM17 18H3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1ZM8 2h4a1 1 0 0 1 1 1v1H7V3a1 1 0 0 1 1-1Zm11 2V3a1 1 0 0 0-2 0v1h-2V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4h-1Z"
        fill={`${config_icon.primary}`}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CameraEnableIcon;
