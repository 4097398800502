import React from 'react';
import styles from '../Logo.module.scss';

const logoSize = {
  BIGGER: styles.bigger,
  SMALL: styles.small,
  MEDIUM: styles.medium,
};

const LogoVisa = ({ color = '#FFF', size = 'BIGGER' }) => {
  return (
    <div style={{ color: color }} className={logoSize[size]}>
      <svg
        viewBox="0 0 220 35"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        role="img"
        aria-label="Visa - Everywhere you want to be"
      >
        <defs>
          <path id="path-1" d="M0 .222h108.203V36H0z" />
          <path id="path-3" d="M.196.112h7.261v8.224H.197z" />
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="currentColor"
          fillRule="evenodd"
        >
          <g id="Desktop-HD-Copy" transform="translate(-33 -42)">
            <g id="BARRA-VISA-HEAD-Copy">
              <g id="LOGO-VISA" transform="translate(33 42)">
                <g id="Group-3">
                  <path
                    d="M65.046 10.443c.028-1.238 1.182-2.558 3.709-2.897 1.25-.168 4.704-.297 8.619 1.55L78.91 1.76C76.805.974 74.1.222 70.731.222c-8.643 0-14.724 4.705-14.773 11.442-.06 4.98 4.34 7.759 7.654 9.414 3.404 1.696 4.55 2.785 4.534 4.3-.026 2.324-2.717 3.347-5.232 3.39-4.395.068-6.949-1.216-8.978-2.188l-1.585 7.582c2.041.96 5.81 1.8 9.723 1.838 9.184 0 15.19-4.643 15.221-11.838.038-9.134-12.333-9.636-12.249-13.719zM44.834.858L37.6 35.46h8.745L53.575.858h-8.74zM90.36 23.197l4.602-12.994 2.65 12.994H90.36zM101.144.857h-7.465c-1.677 0-3.092 1-3.721 2.536L76.84 35.461h9.178l1.826-5.17h11.214l1.057 5.17h8.088L101.143.858zm-69.246 0L22.8 24.41 19.117 4.38C18.685 2.144 16.98.858 15.084.858H.208L0 1.86c3.054.679 6.525 1.773 8.624 2.945 1.288.712 1.655 1.338 2.08 3.038l6.97 27.617h9.24L41.082.858h-9.183z"
                    id="Fill-1"
                    fill="currentColor"
                  />
                </g>
                <g id="Group-4" className={styles.textLogoVisa}>
                  <path
                    d="M146.969 12.39L149 7h-1.43l-1.478 4.437c-.185.547-.339 1.125-.477 1.578h-.03a20.205 20.205 0 0 0-.493-1.547L143.476 7H142l2.737 6.968c.077.173.109.281.109.36 0 .078-.047.188-.109.328-.322.702-.769 1.235-1.137 1.531a3.59 3.59 0 0 1-1.186.672l.339 1.141c.338-.063.985-.297 1.662-.876.923-.827 1.585-2.14 2.554-4.733"
                    id="Fill-4"
                    fill="currentColor"
                  />
                  <path
                    d="M120.093 25.439c-.185.546-.339 1.125-.477 1.577h-.032a20.35 20.35 0 0 0-.49-1.546L117.476 21H116l2.739 6.97c.076.17.108.28.108.36 0 .077-.047.186-.108.326-.323.705-.77 1.235-1.14 1.532a3.535 3.535 0 0 1-1.184.672l.339 1.14c.338-.062.985-.296 1.662-.874.923-.83 1.584-2.141 2.554-4.735L123 21h-1.43l-1.477 4.439z"
                    id="Fill-6"
                    fill="currentColor"
                  />
                  <path
                    d="M138.383 8.713h-.05l-.066-1.534H137c.049.727.065 1.517.065 2.44V15h1.448v-4.186c0-.226.018-.453.05-.662.198-1.05.922-1.81 1.942-1.81.198 0 .346.016.495.032v-1.34c-.133-.017-.248-.034-.396-.034-.97 0-1.86.663-2.221 1.713"
                    id="Fill-8"
                    fill="currentColor"
                  />
                  <path
                    d="M152.56 25.017c-.252.942-.455 1.785-.58 2.595h-.047A32.91 32.91 0 0 0 151.26 25l-1.174-4h-1.157l-1.236 4.067c-.25.875-.5 1.735-.674 2.545h-.047c-.14-.827-.344-1.67-.548-2.562L145.408 21H144l2.284 8h1.253l1.22-3.802c.266-.86.5-1.702.688-2.66h.032c.188.94.406 1.735.672 2.645L151.307 29h1.252L155 21h-1.362l-1.079 4.017z"
                    id="Fill-10"
                    fill="currentColor"
                  />
                  <path
                    d="M137.932 21h-1.495v4.784c0 .275-.052.534-.138.727-.27.647-.966 1.31-1.97 1.31-1.343 0-1.834-1.018-1.834-2.505V21H131v4.575c0 2.731 1.545 3.425 2.836 3.425 1.461 0 2.346-.824 2.719-1.454h.034l.085 1.277H138a26.462 26.462 0 0 1-.068-2.15V21z"
                    id="Fill-12"
                    fill="currentColor"
                  />
                  <path
                    d="M118.622 8c1.608 0 2 1.377 1.985 2.265h-4.198c.116-.968.74-2.266 2.213-2.266m2.935 6.51l-.262-.998c-.525.222-1.164.396-2.197.396-1.442 0-2.671-.776-2.705-2.646h5.559c.032-.158.048-.364.048-.649C122 9.203 121.328 7 118.737 7 116.427 7 115 8.822 115 11.135c0 2.297 1.475 3.865 3.902 3.865 1.262 0 2.147-.269 2.655-.491"
                    id="Fill-14"
                    fill="currentColor"
                  />
                  <path
                    d="M126.992 27.957h-.018c-1.438 0-2.453-1.266-2.453-2.94 0-1.456.762-2.974 2.504-2.974 1.759 0 2.452 1.628 2.452 2.925 0 1.74-1.066 2.989-2.485 2.989m.067-6.957c-2.25 0-4.059 1.501-4.059 4.064 0 2.417 1.725 3.936 3.923 3.936h.018c1.944 0 4.059-1.233 4.059-4.08 0-2.324-1.59-3.92-3.94-3.92"
                    id="Fill-16"
                    fill="currentColor"
                  />
                  <path
                    d="M132.622 8c1.608 0 2 1.377 1.985 2.265h-4.198c.116-.968.74-2.266 2.213-2.266m.28 7.001c1.26 0 2.147-.269 2.654-.491l-.261-.998c-.526.222-1.164.396-2.197.396-1.443 0-2.671-.776-2.706-2.646h5.56c.032-.158.048-.364.048-.649C136 9.203 135.328 7 132.737 7 130.427 7 129 8.822 129 11.135c0 2.297 1.475 3.865 3.902 3.865"
                    id="Fill-18"
                    fill="currentColor"
                  />
                  <path
                    d="M126.096 15L129 7h-1.406l-1.436 4.479c-.245.743-.46 1.405-.627 2.066h-.045a24.143 24.143 0 0 0-.597-2.066L123.437 7H122l2.813 8h1.283z"
                    id="Fill-20"
                    fill="currentColor"
                  />
                  <path
                    d="M187.992 27.957h-.018c-1.438 0-2.452-1.266-2.452-2.94 0-1.456.761-2.974 2.503-2.974 1.759 0 2.453 1.628 2.453 2.925 0 1.74-1.067 2.989-2.486 2.989m.067-6.957c-2.25 0-4.059 1.501-4.059 4.064 0 2.417 1.725 3.936 3.924 3.936h.017c1.945 0 4.059-1.233 4.059-4.08 0-2.324-1.588-3.92-3.94-3.92"
                    id="Fill-22"
                    fill="currentColor"
                  />
                  <path
                    d="M185.623 8c1.606 0 2 1.377 1.984 2.265h-4.197c.116-.968.738-2.266 2.213-2.266m2.935 6.51l-.263-.998c-.526.222-1.164.396-2.196.396-1.443 0-2.671-.776-2.705-2.646h5.556c.034-.158.05-.364.05-.649C189 9.203 188.328 7 185.738 7 183.428 7 182 8.822 182 11.135c0 2.297 1.476 3.865 3.903 3.865 1.26 0 2.146-.269 2.655-.491"
                    id="Fill-24"
                    fill="currentColor"
                  />
                  <path
                    d="M179.515 15v-4.186c0-.227.017-.454.048-.663.198-1.05.922-1.81 1.943-1.81.198 0 .346.016.494.033V7.032c-.132-.017-.246-.032-.395-.032-.972 0-1.859.662-2.221 1.712h-.051l-.065-1.535H178c.049.727.066 1.52.066 2.44V15h1.449z"
                    id="Fill-26"
                    fill="currentColor"
                  />
                  <path
                    d="M180.805 20l-1.5.398v1.313H178v.989h1.306v3.88c0 .84.174 1.476.594 1.874.365.339.94.546 1.653.546a4.87 4.87 0 0 0 1.36-.178l-.07-.988c-.226.058-.471.089-.888.089-.854 0-1.15-.502-1.15-1.388V22.7H183v-.99h-2.195V20z"
                    id="Fill-28"
                    fill="currentColor"
                  />
                  <g id="Group-32" transform="translate(204 21)">
                    <path
                      d="M1.66 3.466c.119-.993.764-2.33 2.294-2.33 1.667 0 2.074 1.418 2.059 2.33H1.659zm5.798.359c0-1.45-.697-3.713-3.384-3.713C1.676.112.196 1.985.196 4.362c0 2.362 1.532 3.974 4.048 3.974 1.309 0 2.227-.277 2.754-.504l-.273-1.026c-.542.227-1.206.407-2.277.407-1.496 0-2.772-.799-2.807-2.721h5.766c.033-.162.05-.374.05-.667z"
                      id="Fill-30"
                      fill="currentColor"
                    />
                  </g>
                  <path
                    d="M200.432 27.932c-.952 0-1.739-.64-1.996-1.572a2.594 2.594 0 0 1-.046-.489v-1.327c0-.213.031-.396.076-.563.257-1.007 1.104-1.648 2.01-1.648 1.422 0 2.177 1.266 2.177 2.761 0 1.694-.816 2.838-2.221 2.838m.484-6.652c-1.18 0-2.026.518-2.495 1.342h-.03V18h-1.33v8.925c0 .655-.016 1.403-.061 1.906h1.149l.075-1.22h.03c.56.977 1.391 1.389 2.449 1.389 1.634 0 3.297-1.313 3.297-3.967 0-2.228-1.27-3.753-3.084-3.753"
                    id="Fill-33"
                    fill="currentColor"
                  />
                  <path
                    d="M171.41 10.264c.115-.967.738-2.266 2.213-2.266 1.607 0 2 1.378 1.984 2.266h-4.198zM173.736 7C171.427 7 170 8.822 170 11.134c0 2.298 1.476 3.866 3.902 3.866 1.26 0 2.147-.269 2.655-.49l-.262-.998c-.523.221-1.163.395-2.197.395-1.443 0-2.671-.775-2.705-2.645h5.557c.034-.159.05-.364.05-.65 0-1.41-.672-3.612-3.263-3.612z"
                    id="Fill-35"
                    fill="currentColor"
                  />
                  <path
                    d="M154.476 8.537c.188.943.407 1.736.673 2.644L156.307 15h1.25L160 7h-1.36l-1.082 4.016c-.249.943-.452 1.786-.579 2.596h-.046c-.171-.81-.391-1.67-.673-2.612l-1.174-4h-1.158l-1.236 4.065c-.25.877-.501 1.737-.673 2.547h-.046c-.14-.827-.344-1.67-.547-2.562L150.408 7H149l2.285 8h1.25l1.223-3.802c.265-.858.499-1.702.687-2.661h.03z"
                    id="Fill-37"
                    fill="currentColor"
                  />
                  <path
                    d="M158.554 26.25c0 .142-.015.299-.062.441-.216.65-.854 1.282-1.849 1.282-.684 0-1.275-.412-1.275-1.33 0-1.484 1.694-1.754 3.186-1.722v1.328zm1.338-2.12c0-1.534-.56-3.13-2.876-3.13-.947 0-1.865.268-2.487.663l.311.933c.528-.363 1.26-.569 1.975-.569 1.537-.016 1.709 1.138 1.709 1.771v.159c-2.907-.017-4.524.996-4.524 2.845 0 1.107.778 2.198 2.301 2.198 1.072 0 1.896-.538 2.3-1.138h.047l.125.963H160c-.078-.521-.108-1.17-.108-1.833V24.13z"
                    id="Fill-39"
                    fill="currentColor"
                  />
                  <path
                    d="M163.495 10.476c0-.264.018-.465.103-.65a2.148 2.148 0 0 1 2.005-1.364c1.41 0 1.902 1.007 1.902 2.215V15H169v-4.461c0-2.588-1.766-3.208-2.871-3.208-.562 0-1.087.14-1.53.388a2.579 2.579 0 0 0-1.07.96h-.034V4H162v11h1.495v-4.524z"
                    id="Fill-41"
                    fill="currentColor"
                  />
                  <path
                    d="M172.804 20l-1.497.398v1.313H170v.989h1.307v3.88c0 .84.174 1.476.592 1.874.367.339.94.546 1.655.546.592 0 1.063-.09 1.36-.178l-.07-.988c-.227.058-.472.089-.89.089-.852 0-1.15-.502-1.15-1.388V22.7H175v-.99h-2.196V20z"
                    id="Fill-43"
                    fill="currentColor"
                  />
                  <path
                    d="M166.122 21c-1.347 0-2.288.726-2.693 1.454h-.033l-.084-1.277H162c.05.647.066 1.293.066 2.118V29h1.481v-4.72c0-.225.033-.485.1-.662.27-.791 1.028-1.455 1.986-1.455 1.398 0 1.886 1.067 1.886 2.327V29H169v-4.672c0-2.681-1.75-3.328-2.878-3.328"
                    id="Fill-45"
                    fill="currentColor"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>{' '}
    </div>
  );
};

export default LogoVisa;
