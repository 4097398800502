import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import ButtonWithIcon from '../../../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import iconTrashMaster from '../../../../../../../../../assets/images/icon/master/icon-trash.svg';
import iconTrashAfluent from '../../../../../../../../../assets/images/icon/afluent/icon-trash.svg';
import { ITEMS_OBJECT } from '../../../MissedConnection/consts';
import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';

const ItemRemove = props => {
  const { values, setFieldValue, index, intl } = props;
  const { isAfluent } = useContext(ThemeContext);

  const removeItem = event => {
    event.preventDefault();

    const item = [...values.incident.concerned_items];
    item.splice(index, 1);
    setFieldValue('incident.concerned_items', item);
  };

  const removeButton = () => {
    if (index === 0) return;
    return (
      <ButtonWithIcon
        type="blueWithoutBorder"
        dataTestid="BtnAddExpenseItem"
        onClick={removeItem}
        value={intl.BTN_REMOVE}
        isSVGComponent={true}
        img={<TrashIcon width={24} height={24} />}
      />
    );
  };

  return <div className="col-2">{removeButton()}</div>;
};

export default ItemRemove;
